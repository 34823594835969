import { useState } from 'react';

interface UseDeepLinkProps {
    appStoreUrls: {
        ios: string;
        android: string;
    };
    deepLinkSchemas: {
        ios: string;
        android: string;
    };
    fallbackUrl: string;
    timeoutDuration?: number;
}

interface UseDeepLinkReturn {
    isDeepLinking: boolean;
    openApp: () => void;
}

const useDeepLink = ({
    appStoreUrls,
    deepLinkSchemas,
    fallbackUrl,
    timeoutDuration = 3000,
}: UseDeepLinkProps): UseDeepLinkReturn => {
    const [isDeepLinking, setIsDeepLinking] = useState(false);

    const detectDeviceType = (): 'ios' | 'android' | undefined => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (/iphone|ipad|ipod/.test(userAgent)) {
            return 'ios';
        }

        if (/android/.test(userAgent)) {
            return 'android';
        }

        return undefined;
    };

    const deviceType = detectDeviceType();
    const appStoreUrl = deviceType ? appStoreUrls[deviceType] : fallbackUrl;
    const deepLinkSchema = deviceType ? deepLinkSchemas[deviceType] : fallbackUrl;

    const openApp = () => {
        if (deviceType) {
            setIsDeepLinking(true);
            const timeout = setTimeout(() => {
                setIsDeepLinking(false);
                window.location.href = appStoreUrl;
            }, timeoutDuration);

            document.addEventListener('visibilitychange', () => {
                if (!document.hidden) {
                    clearTimeout(timeout);
                    setIsDeepLinking(false);
                }
            });

            window.addEventListener('beforeunload', () => {
                clearTimeout(timeout);
                setIsDeepLinking(false);
            });

            document.addEventListener('visibilitychange', () => {
                if (!document.hidden) {
                    clearTimeout(timeout);
                    setIsDeepLinking(false);
                }
            });

            window.onload = () => {
                setTimeout(() => {
                    if (document.visibilityState === 'hidden') {
                        setIsDeepLinking(false);
                        window.location.href = appStoreUrl;
                    }
                }, 1500);
            };

            window.location.href = deepLinkSchema;
        } else {
            window.location.href = fallbackUrl;
        }
    };

    return { isDeepLinking, openApp };
};

export default useDeepLink;
