import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Input, Row } from 'antd';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import {
    Alert,
    AlertVariants,
    Card,
    MojButton,
    ReadOnlyField,
    ReadOnlyFieldBorderVariant,
    ReadOnlyFieldVariant,
} from '@core/components/shared';
import './RequestSuccess.scss';
import { ReactComponent as SuccessFlag } from '@core/assets/images/assets-icons-status.svg';
import { ReactComponent as RejectFlag } from '@core/assets/images/assets-states-failure.svg';
import { convertNumberWithFractionCurrency, getReadableNumber, getReadablePrice } from '@common/utils/numbers-utils';
import AddedBankAccount from '@profile/components/AddedBankAccount/AddedBankAccount';
import { useCopyText } from '@/common/hooks/useCopyText';
import { IMortgageEntity, RequestTypes } from '@common/models/Transaction';

type RequestSuccessProps = {
    hideActions?: boolean;
    requestNumber?: number;
    requestId: string;
    deedNumber?: number;
    deedDate?: string;
    area?: number;
    price?: number;
    iban?: string;
    sellerIban?: string;
    requestStatusText: string;
    statusNote?: string;
    mortgageValues?: IMortgageEntity;
    isSeller?: boolean;
    isReject?: boolean;
    isMortgage?: boolean;
    requestType?: RequestTypes;
    lastInstalmentDate?: string;
    taxAmount?: number;
}

const RequestSuccess = ({
    hideActions,
    requestNumber,
    deedNumber,
    deedDate,
    area,
    price,
    iban,
    sellerIban,
    requestStatusText,
    mortgageValues,
    statusNote,
    requestId,
    isReject,
    isMortgage,
    requestType,
    lastInstalmentDate,
    taxAmount
}: RequestSuccessProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { Icon } = useCopyText({
        text: requestNumber?.toString(),
        successMessage: t('shared.request-number-copied'),
    });
    const { Icon: CopyBankAccountIcon } = useCopyText({
        text: (iban || sellerIban || '').toString(),
        successMessage: t('shared.request-number-copied'),
    });

    const isPathMatch = (): boolean => {
        const pathname = window.location.pathname;
        if (pathname === `/transactions/${requestId}`) {
            return true;
        }
        return false;
    };

    const moveToHome = () => {
        navigate('/', { replace: true });
    };
    const moveToMyTransactions = () => {
        // reload the page to reset the stepper and move to step 1
        if (isPathMatch() === false) {
            navigate(`/transactions/${requestId}`, { replace: true });
        } else {
            navigate(0);
        }
    };

    return (
        <div className="buying-request-success">
            <Card>
                <div className="buying-request-success__icon">{isReject ? <RejectFlag /> : <SuccessFlag />}</div>
                <div
                    className={clsx('buying-request-success', {
                        'success-message': !isReject,
                        'reject-message': isReject,
                    })}
                >
                    {requestStatusText}
                </div>
                <Row justify="center" align="middle">
                    <div className="request-number__text">
                        {t('common.request-number')}:{' '}
                        <Input className="request-number__value" value={requestNumber} disabled />
                    </div>
                    <div className="request-number__copy-icon">
                        <Icon />
                    </div>
                    <Divider />
                </Row>

                {(deedNumber || deedDate || area) && <Row>
                    <Col flex="auto">
                        <ReadOnlyField
                            title={deedNumber}
                            label={t('common.deed-number')}
                            variant={ReadOnlyFieldVariant.primary}
                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                        />
                    </Col>
                    <Col flex="auto">
                        <ReadOnlyField
                            title={deedDate}
                            label={t('common.deed-date')}
                            variant={ReadOnlyFieldVariant.primary}
                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                        />
                    </Col>
                    <Col flex="auto">
                        <ReadOnlyField
                            title={getReadableNumber(area)}
                            label={t('common.deed-area')}
                            variant={ReadOnlyFieldVariant.primary}
                            unit={t('common.deed-unit')}
                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                        />
                    </Col>
                    <Divider />
                </Row>}
                {price!! > 0 &&
                    <Row>
                        <Col flex="auto">
                            <ReadOnlyField
                                title={price}
                                label={t(
                                    isMortgage ? (requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT ?
                                        'buying-requests.mortgage-details.new-amount' :
                                        'buying-requests.mortgage-details.amount')
                                        : 'common.transaction-amount-in-number'
                                )}
                                variant={ReadOnlyFieldVariant.primary}
                                unit={t('purchase-order.price-unit')}
                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                            />
                        </Col>
                        <Col flex="auto">
                            <ReadOnlyField
                                title={convertNumberWithFractionCurrency(price)}
                                label={t(
                                    isMortgage ? (requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT ?
                                        'buying-requests.mortgage-details.new-amount-written' :
                                        'buying-requests.mortgage-details.amount-written')
                                        : 'common.transaction-amount-in-words'
                                )}
                                variant={ReadOnlyFieldVariant.primary}
                                unit={t('purchase-order.price-unit')}
                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                            />
                        </Col>
                        {taxAmount && (
                            <>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        title={taxAmount}
                                        label={t('finance.foreign-transaction-tax-value')}
                                        variant={ReadOnlyFieldVariant.primary}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        small
                                        label={t('finance.total-after-tax')}
                                        title={getReadablePrice(price! + taxAmount)}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                        unit={`(${t('shared.sar')})`}
                                    />
                                </Col>
                            </>
                        )}

                        <Divider />
                    </Row>
                }

                {iban && (
                    <div className="iban">
                        <AddedBankAccount iban={iban} hideActions={true} noBorder={true} isVirtualIban={true}>
                            <CopyBankAccountIcon />
                        </AddedBankAccount>
                    </div>
                )}

                {sellerIban && (
                    <div className="iban">
                        <AddedBankAccount iban={sellerIban!!} hideActions={true} noBorder={true} isVirtualIban={false}>
                            <CopyBankAccountIcon />
                        </AddedBankAccount>
                    </div>
                )}

                {mortgageValues && mortgageValues.Name ? (
                    <>
                        {mortgageValues.BeneficiaryAmount ? (
                            <Row>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        label={requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT ?
                                            t('buying-requests.mortgage-details.new-amount') :
                                            t('buying-requests.mortgage-details.amount')}
                                        title={getReadablePrice(mortgageValues.BeneficiaryAmount)}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                        unit={`(${t('shared.sar')})`}
                                        variant={ReadOnlyFieldVariant.primary}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        label={requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT ?
                                            t('buying-requests.mortgage-details.new-amount-written') :
                                            t('buying-requests.mortgage-details.amount-written')}
                                        title={convertNumberWithFractionCurrency(mortgageValues.BeneficiaryAmount || 0)}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                        unit={`(${t('shared.sar')})`}
                                        variant={ReadOnlyFieldVariant.primary}
                                    />
                                </Col>
                                {/* <Col flex="auto">
                                    <ReadOnlyField
                                        title={dayjs(mortgageValues.LastInstalmentDate).format('DD/MM/YYYY')}
                                        label={t('shared.mortgage-claiming-date')}
                                        variant={ReadOnlyFieldVariant.primary}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    />
                                </Col> */}
                            </Row>
                        ) : (
                            <></>
                        )}

                        {mortgageValues ? (
                            <Row>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        label={t('buying-requests.mortgage-details.mortgagee-entity')}
                                        title={mortgageValues.Name}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                        variant={ReadOnlyFieldVariant.primary}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}
                        <Divider />
                    </>
                ) : (
                    <></>
                )}

                {statusNote && (
                    <div className="disclaimer-alert">
                        <Alert variant={AlertVariants.Warning}>{statusNote}</Alert>
                    </div>
                )}
            </Card>

            {!hideActions && (
                <Card className="buying-request-success__actions">
                    <Row gutter={8}>
                        <Col span={12}>
                            <MojButton color="primary" onClick={moveToHome}>
                                {t('purchase-order.action-requests')}
                            </MojButton>
                        </Col>
                        <Col span={12}>
                            <MojButton color="warning" onClick={moveToMyTransactions}>
                                {t('purchase-order.action-followup-order')}
                            </MojButton>
                        </Col>
                    </Row>
                </Card>
            )}
        </div>
    );
};

export default RequestSuccess;
