import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';

import { ReactComponent as PdfFileLogo } from '@core/assets/images/assets-icons-pdf-file-new.svg';
import { MojButton, MojPopup } from '@core/components/shared';
import MojLogo3xPng from '@core/assets/images/moj-logo@3x.png';
import SuccessPng from '@core/assets/images/success.png';
import DeedService from '@/common/services/deed.service';
import './DeedPdfDownloaderV2.scss';

const DeedPdfDownloaderV2 = (props) => {
    const { t } = useTranslation();
    const deedService = new DeedService();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [progressNumber, setProgressNumber] = React.useState<number>(0);
    const [inactiveLink, setInactiveLink] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);

    async function getDeedPdfBySerial() {
        try {
            setProgressNumber(20);

            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    setProgressNumber(30);
                    resolve();
                }, 500);
            });

            const response = await deedService.getDeedPdfBySerial({ deedSerial: props.deed.EncryptedSerial! });

            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    setProgressNumber(70);
                    resolve();
                }, 1000);
            });

            if (response?.IsSuccess === false) {
                if (response.ErrorDetails[0].ErrorCode === '401') {
                    setProgressNumber(100);
                    setShowLogoutPopup(true);
                    return;
                }

                setError(response.ErrorDetails[0].ErrorDescription);
                setInactiveLink(true);
                setTimeout(() => {
                    setProgressNumber(100);
                });
                return;
            } else {
                const blob = new Blob([response.data as unknown as Blob], { type: "application/pdf; charset=ascii" });

                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        setProgressNumber(100);
                        // Create a download link and trigger the download
                        const downloadLink = document.createElement('a');

                        downloadLink.href = window.URL.createObjectURL(blob);
                        downloadLink.download = props.deed.DeedNo + '.pdf';
                        downloadLink.click();
                        resolve();
                    });
                });
            }
        } catch (error) {
            console.log(error);
            handleClose();
        }
    }

    useEffect(() => {
        if (progressNumber === 100) {
            setTimeout(() => {
                handleClose();
            }, 3000);
        }
    }, [progressNumber]);

    const showModal = () => {
        setIsModalVisible(true);
        getDeedPdfBySerial();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setProgressNumber(0);
    };

    const handleClose = () => {
        setIsModalVisible(false);
        setInactiveLink(false);
        setProgressNumber(0);
    };


    const renderStartingProgress = () => {
        return (
            progressNumber !== 100 && !inactiveLink && (
                <div className="loader">
                    <img src={MojLogo3xPng} style={{ width: '100px', marginTop: '50px' }} alt="" />
                    <br />
                    <br />
                    <br />

                    <div className="lds-dual-ring"></div>
                    <h3>يتم إنشاء نسخة مطبوعة</h3>
                    <Progress
                        percent={progressNumber}
                        format={(percent: any) => `${percent.toString() + '%'}`}
                        strokeColor="#167c6a"
                    />
                    <p style={{ direction: 'rtl' }}>يفضل إستخدام متصفح chrome من الحاسب الآلي</p>
                </div>
            )
        );
    };

    const renderFinishedProgress = () => {
        return (
            progressNumber === 100 && !inactiveLink && (
                <div className="loader">
                    <img src={MojLogo3xPng} style={{ width: '100px', marginTop: '50px' }} alt="" />
                    <br />
                    <br />
                    <br />

                    <img src={SuccessPng} style={{ width: '100px' }} alt="" />

                    <h3>تم إنشاء نسخة مطبوعة</h3>
                    <Progress
                        percent={progressNumber}
                        format={(percent: any) => `${percent.toString() + '%'}`}
                        strokeColor="#167c6a"
                    />
                    <p style={{ direction: 'rtl' }}>يفضل إستخدام متصفح chrome من الحاسب الآلي</p>
                </div>
            )
        );
    };

    const renderInactiveLinkMessage = () => {
        return (
            inactiveLink && (
                <div style={{ textAlign: 'center' }}>
                    <img src={MojLogo3xPng} style={{ width: '100px', marginTop: '50px' }} alt="" />
                    <br />
                    <br />
                    <h3> {error} </h3>
                </div>
            )
        );
    };

    return (
        <div className="deed-pdf-container">
            <div className="review-head__pdf-logo" onClick={showModal}>
                {' '}<PdfFileLogo />{' '}
            </div>

            <MojPopup
                open={isModalVisible}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                footer={''}
                width={1000}
                centered
                className="fixed-height"
                style={{ height: '500px !important', overflow: 'hidden' }}
            >
                <div className="section pdf-downloader-modal-wrapper">
                    <div>
                        {renderStartingProgress()}
                        {renderFinishedProgress()}
                        {renderInactiveLinkMessage()}
                    </div>
                </div>
            </MojPopup>

            <MojPopup
                open={showLogoutPopup}
                title={t('auth.session-end')}
                footer={
                    <MojButton color={'success'} onClick={() => setShowLogoutPopup(false)}>
                        {t('shared.continue')}
                    </MojButton>
                }
            >
                <div style={{ textAlign: 'center' }}>
                    <p>{t('auth.session-end-you-will-be-logout')}</p>
                </div>
            </MojPopup>
        </div>
    );
};

export default DeedPdfDownloaderV2;
