import { IPreValidateResult } from '@/common/models/OpenMarketRequest';
import { Coordinate, IDeed } from '@common/models/Deed';
import { ErrorDetails } from '@common/models/GenericResponse';

export const getDeedConstrains = (deed: IDeed): string => {
    const constraints: string[] = [];
    if (deed?.IsRealEstateRERConstrained!! || deed?.AiniRegistrationFlag!!) return 'مسجل عينيًا';
    if (deed?.IsRealEstateMortgaged!!) constraints.push('مرهون');
    if (deed?.IsRealEstateConstrained!!) constraints.push('محجوز');
    if (deed?.IsRealEstateHalted!!) constraints.push('وقف');
    if (deed?.IsRealEstateTestamented!!) constraints.push('وصية');
    if (deed?.IsOwnerConstrained!!) constraints.push('محجوز');
    if (deed.DeedOwners?.length === 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1))
        constraints.push('محجوز');
    if (deed.DeedOwners?.length > 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1))
        constraints.push('محجوز');

    if (constraints.length === 0) {
        constraints.push('لايوجد قيود');
    }
    return Array.from(new Set(constraints)).join('، ');
};

// export const getDeedText  = (value: string) : string => {
//     {
//         if (value === null || '') return value;
//         if (value.includes("/"))
//         {
//             let data = value.split("/");
//             value = data[0];
//
//             data.forEach((char, index) => {
//                 value += "/"+ char.ConvertFromUtf32(1564) + data[index];
//             })
//             return value;
//         }
//         else
//             return value;
//
//     }
// }

export const isDeedValid = (deed: IDeed): boolean => {
    if (deed?.IsRealEstateMortgaged!!) return false;
    else if (deed?.IsRealEstateConstrained!!) return false;
    else if (deed?.IsRealEstateHalted!!) return false;
    else if (deed?.IsRealEstateTestamented!!) return false;
    else if (deed?.IsOwnerConstrained!!) return false;
    else if (deed.DeedOwners?.length === 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1))
        return false;
    else if (deed.DeedOwners?.length > 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1))
        return false;
    else return true;
};
export const getCanProceedWithDeed = (deed: IDeed): boolean => {
    return (
        deed?.IsRealEstateHalted!! ||
        deed?.IsRealEstateConstrained!! ||
        deed?.IsRealEstateTestamented!! ||
        deed?.IsOwnerConstrained!! ||
        !deed?.CanCreateRequest!! ||
        deed?.IsGiftConditioned!! ||
        deed?.DeedStatus !== 'A' ||
        deed?.DeedContainerStatus !== 'Active' ||
        (deed?.DeedOwners?.length === 1 && deed?.DeedOwners.some((value, index) => value.CONSTRAINED === 1)) ||
        (deed?.DeedOwners?.length > 1 && deed?.DeedOwners.some((value, index) => value.CONSTRAINED === 1))
    );
};

export const deedConstrainsHandler = (deed: IDeed, setErrorDetails: any, translate?: any) => {
    if (deed) {
        if (
            deed?.IsGiftConditioned!! ||
            deed?.IsRealEstateHalted!! ||
            deed?.IsRealEstateConstrained!! ||
            deed?.IsRealEstateTestamented!! ||
            deed?.DeedStatus !== 'A' ||
            deed?.DeedContainerStatus !== 'Active'
        ) {
            setErrorDetails(['يوجد قيود على وثيقة تملك العقار، لذلك لا يمكنك اجراء أي عمليات عليها.']);
        } else if (
            (deed.DeedOwners?.length === 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1)) ||
            (deed.DeedOwners?.length > 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1)) ||
            deed?.IsOwnerConstrained!!
        ) {
            setErrorDetails(['يوجد قيود على مالك العقار، لذلك لا يمكنك إجراء أي عملية.']);
        } else if (!deed?.CanCreateRequest) {
            setErrorDetails([translate('deed-inquiry.active-transaction-error')]);
        } else {
            setErrorDetails([]);
        }
    }
};

export const getDeedGiftConditionText = (deed: IDeed, translate: any): string => {
    if (deed?.IsGiftConditioned && deed?.GiftConditionExpiryDate!!) {
        let dStr = deed.GiftConditionExpiryDate?.toString();
        dStr = `${dStr?.slice(0, 4)}/${dStr?.slice(4, 6)}/${dStr?.slice(6)}`;
        return `${translate('deed-details.gift-Conditioned-restriction')} ${dStr}`;
    }

    return '';
};

export const getDeedGpt3mConditionText = (deed: IDeed, translate: any): string => {
    return `${translate('deed-details.gpt3m-Conditioned-restriction', {
        GPT3MConstraintEntityName: deed.GPT3MConstraintEntityName,
    })}`;
};

export interface CanCreateOpenMarketRequest {
    status: boolean;
    message?: string;
}

export function isBlockedOnOpenMarket(deed: IDeed): CanCreateOpenMarketRequest {
    if (deed?.IsRealEstateMortgaged) {
        return {
            status: true,
            message: 'لايمكن عرض العقار في العروض العقارية بسبب رهن العقار',
        };
    } else if (deed?.DeedRealState.some((r) => r.CityName === undefined || null)) {
        return {
            status: true,
            message: 'لايمكن عرض العقار في العروض العقارية بسبب نقص أحد بيانات العقار (المدينة)\n',
        };
    } else if (deed?.DeedRealState.some((r) => r.DistrictName === undefined || null)) {
        return {
            status: true,
            message: 'لايمكن عرض العقار في العروض العقارية بسبب نقص أحد بيانات العقار (الحي)\n',
        };
    } else if (deed?.DeedRealState.some((r) => r.PlanNumber === undefined || null)) {
        return {
            status: true,
            message: 'لايمكن عرض العقار في العروض العقارية بسبب نقص أحد بيانات العقار (رقم المخطط)\n',
        };
    } else if (deed?.DeedRealState.some((r) => r.LandNumber === undefined || null)) {
        return {
            status: true,
            message: 'لايمكن عرض العقار في العروض العقارية بسبب نقص أحد بيانات العقار (رقم القطعة)\n',
        };
    } else
        return {
            status: false,
        };
}

export function getPrevalidationErrors(preVResult: IPreValidateResult, t: any) {
    let errors: string[] = [];
    if (preVResult.RequestAttorenyActions) {
        for (var type in preVResult.RequestAttorenyActions) {
            if (preVResult.RequestAttorenyActions[type] === false) {
                errors.push('لا يمتلك الوكيل جميع البنود اللازمة لإتمام الاجراء!');
                break;
            }
        }
    }

    if (preVResult.DeedActiveTransactions) {
        for (type in preVResult.DeedActiveTransactions) {
            if (preVResult.DeedActiveTransactions[type] === true) {
                errors.push(t('deed-inquiry.active-transaction-error'));
                break;
            }
        }
    }

    if (!errors.length && preVResult.Errors) {
        errors = [...errors, ...preVResult.Errors];
    }

    return errors;
}

export function errorsListToErrorDetails(errors, code = ''): ErrorDetails[] {
    return errors.map((error) => {
        return {
            ErrorCode: code,
            ErrorDescription: error,
            Exception: '',
            Time: new Date(),
        };
    });
}

export const getRealestateLocationUrl = (realestateidentitynumber) => {
    return `/rid/${realestateidentitynumber}`;
};

export const generateGoogleMapUrl = (coordinate: Coordinate) => {
    return `http://maps.google.com/maps?q=${coordinate?.Latitude},${coordinate?.Longitude}`;
};

export const flipDate = (dateStr) => {
    if (!dateStr) return '';
    if (!dateStr.includes('/')) return dateStr;

    const dateParts = dateStr.split('/');

    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};
