import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ReactComponent as SuccessFlag } from '@core/assets/images/assets-icons-status.svg';
import { Card, MojButton } from '@/core/components/shared';
import './DURequestSuccess.scss';

type Props = PropsWithChildren<{
    status: ResultStatusType;
    title: string;
    subTitle?: string;
    backAction: () => void;
    okAction: () => void;
}>
const DURequestSuccess: React.FC<Props> = ({ status, title, children, subTitle, backAction, okAction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const moveToHome = () => {
        navigate('/', { replace: true });
    };
    const moveToMyRequests = () => {
        navigate(`/deed-upgrade/requests`, { replace: true });
    };
    return (
        <div className="buying-request-success">
            <Card>
                <div className="buying-request-success__icon">
                    <SuccessFlag />
                </div>
                <div className="buying-request-success__success-message">
                    {title}
                </div>
            </Card>

            {children ? <>{children}</> : <></>}

            <Card className="buying-request-success__actions">
                <Row gutter={8}>
                    <Col span={12}>
                        <MojButton color="primary" onClick={moveToHome}>
                            {t('purchase-order.action-requests')}
                        </MojButton>
                    </Col>
                    <Col span={12}>
                        <MojButton color="warning" onClick={moveToMyRequests}>
                            {'طلباتي'}
                        </MojButton>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default DURequestSuccess;