import React from 'react';
import { Card } from "@core/components/shared";
import { Col, Row } from "antd";
import "./_SremTerms.scss";

const terms: {header: string, content: string}[] = [
    {
        header: "بنود وشروط استخدام البورصة العقارية التابعة لوزارة العدل السعودية",
        content: "تعد هذه المنصة الإلكترونية (البورصة العقارية) تابعة لوزارة العدل بالمملكة العربية، (وسيشار إليها في هذه الشروط والأحكام بعبارة \"البورصة العقارية\" أو \"المنصة\")؛ وتقدّم هذه المنصة خدمات عامّة ذات صلة بالتداول العقاري.\n" +
            "\n" +
            "بالتسجيل في هذه المنصة فأنت تقرّ بعلمك التامّ بأنها تشتمل على خدمات عامة متاحة لاستخدامك الشخصي، وتخضع الخدمات المتاحة في المنصة للشروط والأحكام هذه ولأنظمة المملكة العربية السعودية.\n" +
            "\n" +
            "يعد وصولك ودخولك إلى المنصة موافقة تامّة دون قيد أو شرط على الشروط والأحكام هذه، سواء أكنت مستخدماً مسجلاً في المنصة أم لم تكن مسجلاً فيها، وتسري هذه الموافقة منك اعتباراً من تاريخ أول استخدام لك لهذه المنصة، ويصبح أي تعديل لهذه البنود والشروط نافذاً فور صدوره، ويعني استمرارك في استخدام هذه المنصة عقب إصدار أي تعديل قبولاً تاماً منك للتعديلات كافة التي تجريها المنصة؛ لذا تقع عليك مسؤولية قراءة الشروط والأحكام وتحديثاتها، لسريان كل ما يرد فيها على تعاملاتك داخل المنصة"
    },
    {
        header: "الروابط من وإلى المنصة:",
        content: "1. لا يسمح بنقل أو نسخ أو إنشاء روابط إلكترونية بالبورصة العقارية أو أي من محتوياتها، أو عرض أي منها في إطار، كما يحق للمنصة فرض الشروط التي تراها مناسبة عند موافقتها على إنشاء أي رابط إلكتروني لهذه المنصة أو أي من محتوياتها، أو عرض هذه المنصة، أو أي من محتوياتها في إطار.\n" +
            "\n" +
            "2. لا تعدّ المنصة بأي حال من الأحوال مشاركة أو مرتبطة بأي شكل كان بالعلامات، أو الشعارات، أو الرموز التجارية، أو أي وسائل، أو محتويات تظهر على مواقع الويب المرتبطة بهذه المنصة، أو أي من محتوياتها.\n" +
            "\n" +
            "3. للمنصة إيقاف أي ارتباط أياً كانت صورته من أي موقع آخر، وفق مطلق السلطة التقديرية للمنصة.\n" +
            "\n" +
            "4. لا تتحمل المنصة أي مسؤولية ناشئة عن وجود الروابط الخاصة بها في أي موقع آخر يتم الوصول إليها عبره"
    },
    {
        header: "الحماية من الفيروسات:",
        content: "لا تتحمل المنصة مسؤولية حدوث أي إشكال تقني لجهاز مستخدمها بسبب استخدامها؛ وتقع مسؤولية التأكد من توافر برنامج الحماية ضد الفيروسات على المستخدم، مع تأكيد المنصة على أنها تبذل كل الجهود لفحص واختبار محتوياتها وفق المعايير الفنية بشكل دوري.\n"
    },
    {
        header: "المطالبات:",
        content: "1. تعدّ الخدمات المقدمة عبر المنصة متاحة للاستخدام الشخصي للمستخدم، ولا تضمن المنصة تحقق نتيجة إتمام أي خدمة، كما يقرّ المستخدم باطلاعه على ذلك، وتنازله الكامل عن الحق في أي مطالبة متعلقة بحدوث أي أمر يعيق إتمام الخدمة، مهما كان سبب عدم إتمامها.\n" +
            "\n" +
            "2. لا يحق للمستخدم مطالبة المنصة بأي تعويضات ناشئة عن استعماله لها"
    },
    {
        header: "حدود المسؤولية:",
        content: "لا تتحمل المنصة مسؤولية حدوث أي تدخل سلبي يتمثل في إساءة استعمال الحساب الشخصي للمستخدم، ويتحمل المستخدم مسؤولية حفاظه على بيانات حسابه الشخصي فيها، وعلى المستخدم ألا يفصح عنها لأي أحد، ويتحمل المستخدم كافة الآثار المترتبة على ذلك."
    },
    {
        header: "إنهاء الاستخدام:",
        content: "يحق للمنصة وفق مطلق سلطتها التقديرية عند اشتباهها في إساءة استعمال أي حساب فيها أن تعطله، وتمنع أو تقيد استعمال المستخدم لخدماتها، لتحقيق الحماية والأمان."
    },
    {
        header: "حقوق الملكية الفكرية:",
        content: "1. تعدّ المنصة وكل ما فيها من بيانات، أو معلومات، أو رسوم تصويرية، أو شعارات، ملكاً حصرياً لوزارة العدل بالمملكة العربية السعودية، ويعدّ ذلك كله محمياً وفق الأنظمة ذات الصلة.\n" +
            "\n" +
            "2. لا يجوز بأي حال من الأحوال استخدام، أو تعديل البيانات، أو المعلومات، أو الرسوم التصويرية، أو الشعارات المتوافرة في المنصة، أو استغلالها بأي صورة كانت، وتقع المسؤولية القانونية على من يخالف ذلك"
    },
    {
        header: "أحكام عامة:",
        content: "1. تعد الجهات القضائية في المملكة العربية السعودية هي المختصة بالفصل في أي منازعات تنشأ عن استخدام المنصة.\n" +
            "\n" +
            "2. لا تعدّ المنصة مسؤولة عن حدوث أي خطأ في البيانات وإدخالها.\n" +
            "\n" +
            "3. تطبق الأنظمة واللوائح والقرارات السارية في المملكة العربية السعودية.\n" +
            "\n" +
            "4. تطبق الشروط والأحكام للخدمات الإلكترونية التي تصدرها وزارة العدل.\n" +
            "\n" +
            "5. تطبق الشروط والأحكام الواردة في الخدمات الإلكترونية المساندة بشكل مباشر أو غير مباشر على سبيل المثال لا الحصر (النفاذ الوطني الموحد، خدمات مركز المعلومات الوطني، خدمات المدفوعات السعودية)"
    },
    {
        header: "أحكام استخدام خدمات المنصة:",
        content: "باستخدامك للمنصة فأنت مقرّ وملتزم بالالتزامات الآتية:\n" +
            "\n" +
            "1. أنك تستخدم خدمات المنصة بنفسك، ولم تمنح أحداً غيرك حق الوصول إلى حسابك الشخصي في هذه المنصة، وتتحمل كافة الآثار القانونية المترتبة على استخدامك للمنصة.\n" +
            "\n" +
            "2. أن المعلومات والبيانات التي أدخلتها صحيحة ودقيقة، وحال ثبوت خلاف ذلك تتحمل الآثار النظامية للخطأ في المعلومات التي أدخلتها.\n" +
            "\n" +
            "3. أن كافة الوثائق التي بني عليها طلبك لإجراء أي خدمة تقدمها المنصة سليمة من الناحية النظامية؛ وليست محل أي نزاع أو إجراء لدى الجهات القضائية وغيرها من الجهات العامة والخاصة.\n" +
            "\n" +
            "4. أنك مستوعب للأحكام والآثار القانونية المترتبة على تنفيذ الخدمات التي تتيحها المنصة.\n" +
            "\n" +
            "5. أنه يحق للمنصة مشاركة المنصة لبيانات الصفقة التي تكون طرفاً فيها؛ ومعلوماتها ومعلومات الوثيقة العقارية كاملة بلا استثناء؛ وأنه ليس لمستخدم المنصة الحق في المطالبة بأي تعويض أو حق ينشأ عن نشر المنصة للمعلومات المذكورة في هذا البند بأي وجه من الوجوه.\n" +
            "\n" +
            "6. أن المنصة لا تتحمل أي آثار نظامية ناشئة عن العلاقة بينك وبين أطراف العملية، ويكون كلٌ منكم مسؤولاً في مواجهة الآخر.\n" +
            "\n" +
            "7. في شأن التصرف بالعقار بيعاً وشراءً وهبةً ورهناً فتقرّ أنك قبل إتمام أي تصرف عبر المنصة أنك قد عاينت العقار واطلعت على مواصفاته، وأنك على علم بثمنه بالوجه الذي ينفي الجهالة والغرر.\n" +
            "\n" +
            "8. إن إتمام التصرف بهبة عقار يخضع لإرادتك وأهليتك المعتبرة وفق الأحكام القانونية للهبة، وأن الهبة تتوقف على قبول الموهوب له لها.\n" +
            "\n" +
            "9. أن إدراج عرض عقار حر يخضع لموافقتك على عرض وثيقة العقار في قائمة العروض العقارية الحرة؛ وبمعرفتك أنه بمجرد عرضها يعدّ إيجاباً وموافقة على بيعها، وأنه من حق أي شخص طبيعي أو اعتباريّ شراء العين وفق الإجراءات التي تحددها المنصة، ودون الحاجة لأخذ موافقة لاحقة منك خلال فترة العرض المحددة على إتمام الصفقة.\n" +
            "\n" +
            "10. أن أي تصرف يجري عبر المنصة لا يعدّ نافذاً إلا بعد إتمام كافة الإجراءات والمتطلبات التي تحددها المنصة، وفي حال تعثر أي متطلب من متطلبات التصرف فيعدّ الطلب كأن لم يكن.\n" +
            "\n" +
            "11. أن التصرف في عمليات البيع والشراء للعقار لا يعدّ تاماً ومنتجاً لآثاره القانونية إلا بتمام الإفراغ، ولتمام الإفراغ يجب سداد كامل الثمن وإكمال المتطلبات والإجراءات التي تتطلبها المنصة.\n" +
            "\n" +
            "12. أنه لا يمكن إلغاء أي عملية في المنصة أو استرجاع المبالغ المرتبطة بتلك العمليّة إلا بعد انتهاء المدة التي تحددها المنصة لكل عملية، وللمنصة الحق في تمديد تلك المدة دون الرجوع إلى المستفيد وأخذ موافقته على ذلك، كما أن استرجاع أي مبلغ مودع في الحساب المخصص للصفقة سيكون عبر الحساب البنكي الذي حدده المشتري أو الحساب الذي صدرت منه الحوالة البنكية."
    }
]
const SremTerms = () => {
    return ( <div className="SremTerms">
        {
            terms.map((term, index) => {
                return <Card className="section__container" key={index}>
                    <Row gutter={0}>
                        <Col span="24">
                            <Card.Head className="section__title border-bottom">
                                {term.header}
                            </Card.Head>
                        </Col>
                        <Col span="24">
                            <Card.Body className="section__basics">
                                {term.content}
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            })
        }
    </div> );
}

export default SremTerms;
