import React from 'react';
import { Card } from "@core/components/shared";
import { Col, Row } from "antd";
import "./_SremPolicy.scss";

const terms: {header?: string, content: string}[] = [
    {
        header:"حول البورصة العقارية",
        content:'منذ انطلاق رؤية المملكة 2030، أخذت وزارة العدل على عاتقها القيام بدورها الطموح في تحقيق هذه الرؤية،' +
            ' وقد شاركت الوزارة بعدد من المبادرات المرتبطة ببرنامج التحول الوطني ومن أكبر تلك المبادرات مبادرة' +
            ' "رقمنة الثروة العقارية" والتي من أهدافها تحقيق الأمن العقاري وتوفير البنية التحية والتهيئة لأنشاء' +
            ' البورصة العقارية.'+"\n"+"\n"+
            'تشارك وزارة العدل في تحقيق هدفين رئيسيين في رؤية المملكة 2030 وهما "الارتقاء بجودة الخدمات المقدمة للمواطنين" و "تعزيز قيم العدالة والشفافية"، وتساهم البورصة العقارية في تحقيق السرعة في تنفيذ العمليات العقارية والدقة في توفير بياناتها بجودة وكفاءة عالية ومن جانب آخر تحقق البورصة الشفافية وحرية العرض والطلب مما يعزز العدالة في تكافؤ الفرص وفك الاحتكار.'

    },
    {
        header: "ماهي الأدوار التي سوف تقوم بها البورصة العقارية:",
        content: "البورصة العقارية هي امتداد للدور الطبيعي الذي تقوم به وزارة العدل في مجال إدارة وتسجيل الثروة العقارية، ممثلة بالأدوار الأساسية التالية:"
            + "\n" +"إدارة وتنفيذ عمليات انتقال الملكية العقارية بيعًا وشراءًا"
            +"\n" +"إدارة وتنفيذ عمليات الرهن العقاري"
            +"\n" +"العرض المباشر والشفاف لبيانات العمليات العقارية"
            +"\n"+"\n" +"على أن تستمر البورصة العقارية في التوسع وزيادة الخدمات العقارية عبرها."
    },
    {
        header: "من هي الفئات المستفيدة من البورصة العقارية:",
        content: "تقدم البورصة العقارية خدماتها لجميع المستفيدين الحاليين من الخدمات العقارية المقدمة من وزارة العدل سواء كانوا أفراد أم جهات اعتبارية."
    }
]
const SremAbout = () => {
    return ( <div className="SremTerms">
        {
            terms.map((term, index) => {
                return <Card className="section__container" key={index}>
                    <Row gutter={0}>
                        {term.header &&
                        <Col span="24">
                            <Card.Head className="section__title border-bottom">
                                {term.header}
                            </Card.Head>
                        </Col>
                        }
                        <Col span="24">
                            <Card.Body className="section__basics">
                                {term.content}
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            })
        }
    </div> );
}

export default SremAbout;
