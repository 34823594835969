import { PropsWithChildren } from 'react';
import './_LegendIcon.scss';

interface LegendIconProps {
    color: string;
    isProperty?: boolean;
    mapViewType?: string | null;
}

function LegendIcon({ color, isProperty, mapViewType, children }: Readonly<PropsWithChildren<LegendIconProps>>) {
    return (
        <div
            className="legend-icon"
            style={
                isProperty && mapViewType !== null
                    ? {
                        borderColor: color,
                        borderStyle: 'solid',
                    }
                    : {
                        backgroundColor: color,
                    }
            }
        >{children}</div>
    );
}

export default LegendIcon;
