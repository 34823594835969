import { action, computed, makeObservable, observable } from 'mobx';
import { IRealEstate } from '../models/Realestate';

export const myRealestateDefaultState = {
    page: 1,
    pageSize: 25,
    //asc:desc  0:1
    sortType: 1,
    sortField: 0,
    regionid: null
};

export const MyRealEstatesCategory = {
    Default: 1,
    Auction: 2,
    Epu: 3
}

export interface IMyRealestatesFilters {
    page: number;
    pageSize: number;
    sortType: number;
    sortField: number;
    deedNumber?: number;
    MortgagedOnly?: boolean;
    ExcludeMortgaged?: boolean;
    areaFrom?:number,
    areaTo?:number,
    planId?: number[],
    planText?: string,
    landNumber?: string,
    cityId?: number[] | null,
    districtId?: number[],
    realEstateTypes?: string[],
    realEsateIdentity?: number,
    regionid: number | null,
    BaseDeednumber?: number,
}

class MyRealestatesStore {
    @observable
    public realestates!: IRealEstate[];
    @observable
    public myRealestatesFilters: IMyRealestatesFilters = { ...myRealestateDefaultState };
    @observable
    public onResetFilters: boolean | undefined = undefined;
    @observable
    public realestateTypes: {
        Name: string;
        Id: number;
    }[] = [];

    constructor() {
        makeObservable(this);
        this.realestates = [];
    }

    @computed
    get isFiltersNotApplied(): boolean {
        return !this.myRealestatesFilters.deedNumber;
    }

    @action
    updateRealestates(realestates: IRealEstate[]) {
        this.realestates = [];
        this.realestates = realestates;
    }

    @action
    addRealestates(realestates: IRealEstate[]) {
        this.realestates = [...this.realestates, ...realestates];
    }

    @action
    updateFilters(filters: IMyRealestatesFilters) {
        this.myRealestatesFilters = filters;
    }

    @action
    resetFilters() {
        this.onResetFilters = !this.onResetFilters;
        this.myRealestatesFilters = { ...myRealestateDefaultState };
    }

    @action
    setRealestateTypes = (data: {
        Name: string;
        Id: number;
    }[]) => {
        this.realestateTypes = data;
    };
}

export const myRealestatesStore = new MyRealestatesStore();
