import React from 'react';
import './_MojList.scss';
import { List } from 'antd';
import { ListProps } from 'antd/lib/list';
import Empty from '@core/components/shared/Empty/Empty';
import { loaderStore } from '@common/stores/loader.store';
import { observer } from 'mobx-react-lite';

interface MojListProps extends ListProps<any> {
    EmptyMessage: string;
    isLoading?: boolean;
}

const MojList = ({ ...props }: MojListProps) => {
    let ListHolder;
    if (props.dataSource && props.dataSource.length > 0)
        ListHolder = <List loading={props.isLoading} dataSource={props.dataSource} renderItem={props.renderItem} />;
    else if (!props.isLoading && (!props.dataSource || props.dataSource.length === 0)) ListHolder = <Empty emptyMessage={props.EmptyMessage} />;
    return <div>{!loaderStore.isLoading && ListHolder}</div>;
};

export default observer(MojList);
