import { DeedOwner } from '@common/models/Deed';
import { authStore } from '@common/stores/auth.store';
import { NewRequestTypes } from "@common/enums/deal-request-types.enum";
import { getHijriDateFormatted } from "@common/utils/date-utils";
import { BeneficiaryTypes } from '../enums/beneficiary-types.enum';

export interface Seller {
    id: number;
    dobHijri: string;
    idType: number;
    ownershipAmount: number;
    iban?: string;
    mobileNumber?: string;
    name?: string;
    beneficiaryType?: BeneficiaryTypes;
}

export interface OpenMarketRequest {
    sellers: Seller[];
    deedSerial: number;
    vatReqNumber: string;
    amount?: number;
    expiryDateDays: number;
    otp?: number;
    buyers?: Buyer[];
    redfSerial?: string;
    vatSadadNumber?: string;
    requestType?: NewRequestTypes;
    IsGiftWithCondition?: boolean;
    GiftConditionEndDate?: string;
    otpVerify?: {
        key: string;
        otp: string;
    };
    eptmConfirmation ? : boolean,
    selectedRealEstateSerials?: number[]

}

export interface IOpenMarketRequestUpdate {
    requestId: string;
    vatReferenceNumber: string;
    dealAmount: number;
    vatSadadNumber?: string;
    otpVerify?: {
        key: string;
        otp: string;
    };
    additionalAmounts?: any;
}

export interface IAuctionVatUpdate {
    Id: string;
    VatReqNumber: string;
    VatSadadNumber?: number | string;
    otpVerify?: {
        key: string;
        otp: string;
    };
    additionalAmounts?: any;
}


export interface IPreValidateModel {
    deedSerial?: string,
    requestTypes: number[],
    skipAttorneyValidation?: boolean,
    skipActiveRequestsValidation?: boolean
}

export interface IPreValidateResult {
    RequestAttorenyActions?: any,
    DeedActiveTransactions?: any,
    Errors?: any[]
}

export interface Buyer extends Seller {

}

export interface IOwnerInfoBirthDateModel {
    ownerSerial: number;
    birthDateY: string;
    birthDateM: string;
    birthDateD: string;
}

export interface IOwnerBirthDateUpdateModel {
    deedSerial: string,
    ownerInfo: IOwnerInfoBirthDateModel[]
}

export const convertDeedOwnersToSellers = (owners: DeedOwner[]): Seller[] => {
    const _sellers: Seller[] = [];
    owners?.map((owner) => {
        var isCurrentSeller = authStore.getSellerInfo().id.toString() === owner.IdNumber;

        const seller: Seller = {
            id: +owner.IdNumber,
            ownershipAmount: owner.OwningAmount,
            idType: +owner.IdType,
            dobHijri: isCurrentSeller ? authStore.getSellerInfo().dobHijri : getHijriDateFormatted(owner.BirthDateYear, owner.BirthDateMonth, owner.BirthDateDay),
            name: owner.OwnerName
        };
        _sellers.push(seller);
    });
    return _sellers;
};

export interface ITaxInfo {
    RequestAmounts? : {
        Amount?: number;
        AmountType?: number;
    } [] | null;
}