import { RefundTermDto } from '@/modules/refund-mangement/RefundTermsAndConditions/RefundTermBuilder';
import { t } from 'i18next';
import { action, makeObservable, observable } from 'mobx';

class RefundManagmentStore {
    @observable
    public isLoading = false;
    @observable
    public newChosenIban = '';
    @observable
    AcknowledgmentValidated = false;
    @observable
    RefundTerms: RefundTermDto[] = [];
    @observable
    public disableSubmitButton: boolean = true;

    // IbanStep = 0   => OldIban , 1   => choose new one , 2  => Add new One
    @observable
    public IbanStep = -1;
    constructor() {
        makeObservable(this);
    }
    @action.bound
    setIbanStep(ibanStep: number) {
        this.IbanStep = ibanStep;
    }
    @action.bound
    setLoader(flag: boolean) {
        this.isLoading = flag;
    }
    @action.bound
    setNewChosenIban(newIban: string) {
        this.newChosenIban = newIban;
    }
    @action.bound
    getNewChosenIban() {
        return this.newChosenIban;
    }

    @action.bound
    setAllRefundTerms = (): void => {
        this.RefundTerms = [];
        if (this.RefundTerms?.length === 0) {
            this.RefundTerms.push(
                {
                    label: t('refund-management.refundTerms.term1'),
                    checked: false,
                    termId: 0,
                    visible: true,
                },
                {
                    label: t('refund-management.refundTerms.term2'),
                    checked: false,
                    termId: 1,
                    visible: true,
                }
            );
            this.RefundTerms.push({
                label: t('refund-management.refundTerms.term3'),
                checked: false,
                termId: 2,
                visible: true,
            });
        }
    };
    @action.bound
    getAllRefundTerms = () => {
        return this.RefundTerms;
    };

    @action.bound
    updateRefundTermVisiblity = (termId: number, isVisible: boolean) => {
        if (this.RefundTerms.length > 0) {
            this.setAllRefundTerms();
            this.RefundTerms.find((x) => x.termId == termId)!.visible = isVisible;
        }
    };

    @action.bound
    updateRefundTermCheck = (termId: number, isChecked: boolean) => {
        if (this.RefundTerms.length > 0) {
            this.RefundTerms.find((x) => x.termId == termId)!.checked = isChecked;
        }
    };
    @action.bound
    getIbanStep() {
        return this.IbanStep;
    }
    @action.bound
    resetRefundTerms() {
        return this.RefundTerms.forEach((term) => {
            term.checked = false;
        });
    }

    @action.bound
    NewIbanTerms() {
        this.RefundTerms = this.RefundTerms.filter((x) => x.termId > 0);
    }

    @action.bound
    AreTermsValidationsChecked = () => {
        var refundTermsStatus = false;
        if (this.IbanStep !== 0) {
            refundTermsStatus = this.RefundTerms.filter((x) => x.termId > 0).every((value) => value.checked);
        } else {
            refundTermsStatus = this.RefundTerms.every((value) => value.checked);
        }
        return refundTermsStatus;
    };
    @action.bound
    getSubmitDisabledStep = () => {
        var refundTermsResponse = this.AreTermsValidationsChecked();
        if (refundTermsResponse) {
            if (this.IbanStep == -1 || this.IbanStep == 0 || this.IbanStep == 1) {
                this.setDisableSubmitButton(false);
            } else {
                this.setDisableSubmitButton(true);
            }
        } else {
            this.setDisableSubmitButton(true);
        }
    };

    @action.bound
    setDisableSubmitButton= (status : boolean) => {
        this.disableSubmitButton = status;
    };
  
}

export const refundManagmentStore = new RefundManagmentStore();
