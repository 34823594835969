import React from 'react';
import clsx from 'clsx';
import './MojButton.scss';
import { Button, ButtonProps } from 'antd';

export interface MojButtonProps extends ButtonProps {
    color: 'success' | 'danger' | 'warning' | 'primary' | 'label' | 'darkGreen';
}

export function MojButton({ className, color, children, ...props }: MojButtonProps) {
    return (
        <Button type="primary" className={clsx(className, 'ant-btn-primary', `ant-btn-primary--${color}`)} {...props}>
            {children}
        </Button>
    );
}
