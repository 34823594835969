import { createClient } from '@common/services';
import { AxiosInstance } from 'axios';
import { IOrder } from '@/modules/deed-upgrade/models/Order.interface';

class DeedUpgradeService {
    HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_DU_BASE_URL}`);
    }
    // Restful Api's
    getRequests = async () => {
        return this.HttpClient.get('Order/myorders/1')
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ errpppr: error });
            });
    };

    getDeedSources = () => {
        return this.HttpClient.get(`DeedSource/GetVisibleDeedSource`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    getCities = () => {
        return this.HttpClient.get(`DeedSource/GetCities`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    getDistricts = (cityId: number) => {
        return this.HttpClient.get(`DeedSource/GetNeighborhoods/${cityId}`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    getLimitsLov = () => {
        return this.HttpClient.get(`DeedSource/getLimitsLov`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    getDuRequestDetails = async (id: string): Promise<any> => {
        try {
            const response = await this.HttpClient.get(`Order/Details/${id}`);
            return response;
        } catch (error) {
            console.error('Error getting request details:', error);
            return {
                IsSuccess: false,
                ErrorDetails: [{ ErrorDescription: 'حدث خطأ ما ، الرجاء المحاولة مرة اخرى' }],
            };
        }
    };

    getRealestateInformation = async (id: number) => {
        try {
            const response = await this.HttpClient.get(`RealEstateIdentity/RealEstateIdentitysByRequest/${id}`);
            return response;
        } catch (error) {
            console.error('Error getting request details:', error);
            return {
                IsSuccess: false,
                ErrorDetails: [{ ErrorDescription: 'حدث خطأ ما ، الرجاء المحاولة مرة اخرى' }],
            };
        }
    };
    validateConstructionLicenseNumber = async (id: number | string) => {
        try {
            const response = await this.HttpClient.get(`Order/ValidateMomrahConstructionLicenseNumber/${id}`);
            return response;
        } catch (error) {
            console.error('Error getting request details:', error);
            return {
                IsSuccess: false,
                ErrorDetails: [{ ErrorDescription: 'حدث خطأ ما ، الرجاء المحاولة مرة اخرى' }],
            };
        }
    };
    getRealestateIdentityInformation = async (id: number | string) => {
        try {
            const response = await this.HttpClient.get(`Order/GetRealestateIdentity/${id}`);
            return response;
        } catch (error) {
            console.error('Error getting request details:', error);
            return {
                IsSuccess: false,
                ErrorDetails: [{ ErrorDescription: 'حدث خطأ ما ، الرجاء المحاولة مرة اخرى' }],
            };
        }
    };
    validateMomrahConstructionLicenseNumberInformation = async (id: number | string) => {
        try {
            const response = await this.HttpClient.get(`Order/ValidateMomrahConstructionLicenseNumber/${id}`);
            return response;
        } catch (error) {
            console.error('Error getting request details:', error);
            return {
                IsSuccess: false,
                ErrorDetails: [{ ErrorDescription: 'حدث خطأ ما ، الرجاء المحاولة مرة اخرى' }],
            };
        }
    };

    updateOrder = (order: IOrder) => {
        return this.HttpClient.put(`Order/Update`, order)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };
    createOrder = (order: IOrder) => {
        return this.HttpClient.post(`Order/Create`, order)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    createPremiumOrder = (order) => {
        return this.HttpClient.post(`Order/CreateNew`, order)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    getOrderAttachment = async (attachmentId: string): Promise<any> => {
        try {
            const response = await this.HttpClient.post(`Order/GetContentImage`, `"${attachmentId}"`);
            return response;
        } catch (error) {
            console.error('Error getting request details:', error);
            return {
                IsSuccess: false,
                ErrorDetails: [{ ErrorDescription: 'حدث خطأ ما ، الرجاء المحاولة مرة اخرى' }],
            };
        }
    };

    deleteOrder = (id: string): Promise<any> => {
        return this.HttpClient.post(`Order/Delete/${id}`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    submitOrder = (id: string): Promise<any> => {
        return this.HttpClient.post(`Order/Submit`, `"${id}"`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    endOrder = (id: number): Promise<any> => {
        return this.HttpClient.post(`Order/EndOrderByUser`, `"${id}"`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    reSubmitRejectedOrder = (id: string): Promise<any> => {
        return this.HttpClient.post(`Order/ReSubmitRejected/${id}`)
            .then((response) => {
                return response;
            })
            .then((error) => {
                return error;
            })
            .catch((error) => {
                console.log({ error });
            });
    };
}

const instance = new DeedUpgradeService();

export default instance;
