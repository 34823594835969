import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputModes, InputTypes } from '@common/enums/input-properties.enum';
import { MojInput } from '@core/components/shared/MojInput/MojInput';
import { convertToEnglishNumbers } from '@common/utils/numbers-utils';
import './NumericAmountInput.scss';

interface NumericAmountInputProps {
    id: string;
    inputMode?: InputModes;
    type?: InputTypes;
    placeholder?: string;
    allowClear?: boolean;
    prefix?: string;
    formikProps?: any;
    onKeyPress: (event) => void;
}

const NumericAmountInput = ({
    id,
    inputMode = InputModes.Tel,
    type = InputTypes.Text,
    placeholder = '',
    allowClear = true,
    prefix,
    formikProps,
    onKeyPress,
    ...props
}: NumericAmountInputProps) => {
    const { t } = useTranslation();

    const inputProps = formikProps
        ? {
              ...formikProps,
              onChange: (e) => {
                  if (e.target.value) {
                      e.target.value = e.target.value.replace(/[،,]/g, '.');
                      e.target.value = convertToEnglishNumbers(e.target.value);
                  }
                  // Restrict to two decimal places
                  const regex = /^\d*\.?\d{0,2}$/;
                  if (!regex.test(e.target.value)) {
                      return; // Do not update the value if it doesn't match the regex
                  }

                  formikProps.onChange(e);
              },
          }
        : {};

    return (
        <MojInput
            type={type}
            maxLength={12}
            allowClear={allowClear}
            inputMode={inputMode}
            id={id}
            placeholder={placeholder || t('shared.enter-deal-amount')}
            prefix={prefix ?? t('shared.sar')}
            onKeyPress={onKeyPress}
            {...inputProps}
            {...props}
        />
    );
};

export default NumericAmountInput;
