import { Col, Divider, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as SuccessFlag } from '@core/assets/images/assets-icons-status.svg';
import { Alert, AlertVariants, Card, MojButton } from '@core/components/shared';
import { useCopyText } from '@/common/hooks/useCopyText';
import { PropsWithChildren } from 'react';

type RequestSuccessProps = {
    hideActions?: boolean;
    requestNumber?: number;
    requestStatusText: string;
    statusNote?: string;
}

const RequestSuccess = ({
    hideActions,
    requestNumber,
    requestStatusText,
    statusNote,
    children
}: PropsWithChildren<RequestSuccessProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { Icon } = useCopyText({
        text: requestNumber?.toString(),
        successMessage: t('shared.request-number-copied'),
    });

    const moveToHome = () => {
        navigate('/', { replace: true });
    };

    const moveToMyTransactions = () => {
        navigate(`/deed-upgrade/requests`, { replace: true });
    };

    return (
        <div className="buying-request-success">
            <Card>
                <div className="buying-request-success__icon">{<SuccessFlag />}</div>
                <div className={'buying-request-success success-message'}>
                    {requestStatusText}
                </div>
                {requestNumber && (
                    <Row justify="center" align="middle">
                        <div className="request-number__text">
                            {t('common.request-number')}:{' '}
                            <Input className="request-number__value" value={requestNumber} disabled />
                        </div>
                        <div className="request-number__copy-icon">
                            <Icon />
                        </div>
                        <Divider />
                    </Row>
                )}
                {statusNote && (
                    <div className="disclaimer-alert">
                        <Alert variant={AlertVariants.Warning}>{statusNote}</Alert>
                    </div>
                )}

                {children ? <>{children}</> : <></>}

            </Card>

            {!hideActions ? (
                <Card className="buying-request-success__actions">
                    <Row gutter={8}>
                        <Col span={12}>
                            <MojButton color="primary" onClick={moveToHome}>
                                {t('purchase-order.action-requests')}
                            </MojButton>
                        </Col>
                        <Col span={12}>
                            <MojButton color="warning" onClick={moveToMyTransactions}>
                                {t('purchase-order.action-followup-order')}
                            </MojButton>
                        </Col>
                    </Row>
                </Card>
            ) : <Card className="buying-request-success__actions">
                <Row gutter={8}>
                    <Col span={24}>
                        <MojButton color="primary" onClick={moveToHome}>
                            {t('purchase-order.action-requests')}
                        </MojButton>
                    </Col>

                </Row>
            </Card>}
        </div>
    );
};

export default RequestSuccess;
