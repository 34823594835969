import React from 'react';
import { MojButton, MojPopup } from '@core/components/shared';
import { useTranslation } from 'react-i18next';
import { authStore } from '@/common/stores/auth.store';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { ReactComponent as NafadIcon } from '@/core/assets/images/assets-logos-nafad-white.svg';
import { PlainLabel, PlainLabelVariant } from '@core/components/shared/PlainLabel/PlainLabel';

function AuthIAMPopUp() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    return (
        <MojPopup
            title={t('header.login')}
            open={authStore.isLoginRequired}
            onCancel={() => {
                authStore.hideLoginPopup();
                navigate('/');
            }}
            maskClosable={false}
            width={382}
            footer={
                <MojButton
                    style={{ height: '52px' }}
                    color={'darkGreen'}
                    onClick={() => authStore.login(window.location.pathname)}
                >
                    <Row justify="center" align="middle" gutter={[16, 0]}>
                        <Col>
                            <NafadIcon />
                        </Col>
                        <Col style={{ textAlign: 'start' }}>
                            <h6 style={{ color: 'white' }}>{t('auth.iam-login-throw')}</h6>
                            <h4 style={{ color: 'white' }}>{t('auth.iam')}</h4>
                        </Col>
                    </Row>
                </MojButton>
            }
        >
            <div style={{ textAlign: 'center' }}>
                <PlainLabel variant={PlainLabelVariant.disabled} title={t('auth.iam-content')} />
            </div>
        </MojPopup>
    );
}

export default observer(AuthIAMPopUp);
