import React, { FunctionComponent, PropsWithChildren, SVGProps, useMemo, HTMLAttributes } from "react";
import { Link } from 'react-router-dom';
import clsx from "clsx";

export function NavbarOption({
    Icon,
    FixedIcon,
    title,
    subtitle,
    suffix,
    noBorder,
    children,
    link,
    onClick,
    isDangerFlag,
}: PropsWithChildren<{
    Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    subtitle?: React.ReactNode;
    suffix?: React.ReactNode;
    noBorder?: boolean;
    link: string;
    FixedIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    onClick?: () => void;
    isDangerFlag?: boolean;
}>) {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const safelyHandleClick = React.useCallback(() => {
        setIsExpanded(!isExpanded);
        if (onClick) {
            onClick();
        }
    }, [onClick, isExpanded]);
    const Container = useMemo(() => {
        return ['#', ''].includes(link) ? EmptyComponent : Link;
    }, [link]);
    return (
        <Container className="nav-link" to={link}>
            <div
                className={clsx('navbar-option', {
                    'navbar-option--no-border': noBorder,
                    'navbar-option--has-children': !!children,
                })}
                onClick={safelyHandleClick}
            >
                <div className="navbar-option__content">
                    {Icon && (
                        <div className="navbar-option__icon">
                            <Icon />
                        </div>
                    )}

                    <div className="navbar-option__titles">
                        <div
                            className={clsx('navbar-option__title', {
                                danger: isDangerFlag,
                            })}
                        >
                            {title} {suffix ? <span className="option-suffix">{suffix}</span> : <></>}
                        </div>
                        {subtitle && <div className={clsx('navbar-option__subtitle')}>{subtitle}</div>}
                    </div>
                </div>
                {!!children && (
                    <div
                        className={clsx('navbar-expand-icon', {
                            'navbar-expand-icon--reversed': isExpanded,
                        })}
                    >
                        &nbsp;
                    </div>
                )}
                {FixedIcon && (
                    <div className={clsx('navbar-fixed-icon')}>
                        <FixedIcon />
                    </div>
                )}
            </div>
            <div
                className={clsx('navbar-option-children', {
                    'navbar-option-children--collapsed': !isExpanded,
                })}
            >
                {children}
            </div>
        </Container>
    );
}

const EmptyComponent = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
    return <div {...props}>{children}</div>;
};