import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertVariants, Card } from '@core/components/shared';
import { Col, Input, Radio, RadioChangeEvent, Row } from 'antd';
// @ts-ignore
import { MojFormItem } from '@core/components/shared/MojFormItem/MojFormItem';
import { FormikProps, FormikValues } from 'formik';
import { MojCheckbox } from "@core/components/shared/Checkbox/MojCheckbox";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import './_MortgageInfo.scss';
import NumericAmountInput from "@core/components/shared/NumericAmountInput/NumericAmountInput";
import { PlainLabel, PlainLabelVariant } from '@/core/components/shared/PlainLabel/PlainLabel';
import DatePickerInputText from '@/core/components/shared/DatePickerInputText/DatePickerInputText';
import { convertNumberWithFractionCurrency } from '@/common/utils/numbers-utils';
import { arabicToEnglishMap } from '@/common/utils/constants';

export type MortgageInfoProps = {
    price?: number;
    isDeal?: boolean;
    isGift?: boolean;
    isEptm?: boolean;
    isEpuMortgage?: boolean;
    deedArea?: number;
    formikProps: FormikProps<FormikValues>;
    sharedArea?: number;
    isGov?: boolean;
};

const initialDob = {
    day: '',
    month: '',
    year: '',
};

const MortgageInfo = ({ price, isDeal, isGift, isEptm, deedArea = 1, isEpuMortgage = false, sharedArea = deedArea, formikProps, isGov = false }: MortgageInfoProps) => {
    const { t } = useTranslation();
    if (!sharedArea) sharedArea = deedArea;
    const { errors, getFieldProps, touched, setFieldValue } = formikProps!!;
    const [dob, setDob] = useState(initialDob);

    const enteredPrice = useMemo(() => {
        return getFieldProps('mortgageAmount').value || 0;
    }, [getFieldProps]);

    const changeIdType = (e: React.ChangeEvent<HTMLInputElement> | RadioChangeEvent) => {
        setFieldValue('duration', e.target.value);
    };

    const changeEptmConfirmation = (e: CheckboxChangeEvent) => {
        setFieldValue('eptmConfirmation', e.target.checked);
    };

    const changeEpuConfirmation = (e: CheckboxChangeEvent) => {
        setFieldValue('epuConfirmation', e.target.checked);
    };

    const handleDateChange = date => {
        // Pad the day and month with leading zeros
        const paddedDay = date.day.toString().padStart(2, '0');
        const paddedMonth = date.month.toString().padStart(2, '0');

        setDob({ ...dob, day: date.day.toString(), month: date.month.toString(), year: date.year.toString() });

        if (date.year && date.month && date.day) {
            // Create a Date object with zero-padded day and month
            // Format the date as 'YYYY-MM-DD'
            const formattedDate = `${date.year}-${paddedMonth}-${paddedDay}`;
            setFieldValue('LastInstalmentDate', formattedDate);
        }
    }


    const getDateValue = () => {
        return (dob.year ? dob.year : '') + '-' + (dob.month ? String(dob.month) : '') + '-' + (dob.day ? String(dob.day) : '');
    }

    return (
        <div className="section">
            <Card className="section__container">
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card.Head className="section__title border-bottom">
                            <Col flex="1 1 auto">
                                {t(`${isDeal ? 'deal' : 'mortgage'}.details`)} {isEpuMortgage ? 'الجديد' : ''}
                                {isEptm && " " + `"${t("عقار مرهون")}"`}
                            </Col>
                        </Card.Head>
                        {!isEpuMortgage &&
                            <Card.Body>
                                <Row className="section__basics border-bottom">
                                    <Col flex="100%">
                                        <Alert variant={AlertVariants.Warning}>
                                            {t('shared.order-canceled-within-10d')}
                                        </Alert>
                                    </Col>
                                </Row>
                            </Card.Body>
                        }
                    </Col>
                </Row>

                <Row gutter={[16, 0]}>
                    {!isGift && (
                        <Col span={isDeal ? 12 : 24}>
                            <MojFormItem
                                id="mortgageAmount"
                                label={t(`${isDeal ? 'deal' : 'mortgage'}.price-as-number`) + ` ${isDeal ? t('deal.amount-for-the-seller') : ''}`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                validateStatus={errors['mortgageAmount'] && 'error'}
                                help={touched['mortgageAmount'] && errors['mortgageAmount']}
                            >
                                <NumericAmountInput
                                    id={'mortgageAmount'}
                                    placeholder={t(`${isDeal ? 'shared.enter-deal-amount' : 'shared.enter-mortgage-amount'}`)}
                                    formikProps={{ ...getFieldProps('mortgageAmount') }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9.،,]/.test(event.key) && !arabicToEnglishMap[event.key]) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </MojFormItem>
                        </Col>
                    )}
                    {isGift && (
                        <Col span={12}>
                            <MojFormItem
                                label={t(`deal.price-as-number`)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input disabled value={0} inputMode="numeric" />
                            </MojFormItem>
                        </Col>
                    )}
                    {isDeal && (
                        <Col span={12}>
                            <MojFormItem
                                label={t(`deal.price-per-meter`)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    disabled
                                    value={`${t('shared.currency-name')} ${enteredPrice > 0 ? (enteredPrice / sharedArea).toFixed(2) : 0}`}
                                    inputMode="numeric"
                                />
                            </MojFormItem>
                        </Col>
                    )}
                    <Col md={24} xs={24}>
                        <MojFormItem
                            label={t(`${isDeal ? 'deal' : 'mortgage'}.price-as-text`)}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input
                                disabled
                                value={convertNumberWithFractionCurrency(getFieldProps('mortgageAmount').value || 0)}
                                inputMode="numeric"
                            />
                        </MojFormItem>
                    </Col>

                    {
                        isEptm &&
                        <Col span="24">
                            <MojFormItem
                                htmlFor="eptmConfirmation"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                validateStatus={errors['eptmConfirmation'] && 'error'}
                                help={touched['eptmConfirmation'] && errors['eptmConfirmation']}
                            >
                                <MojCheckbox
                                    value={getFieldProps('eptmConfirmation').value}
                                    checked={getFieldProps('eptmConfirmation').value}
                                    onChange={(value) => {
                                        changeEptmConfirmation(value);
                                    }}
                                    className="checkbox-item space"
                                >
                                    مبلغ الرهن يتم تحديثه من قبل الجهة الراهنة بالإتفاق مع المشتري
                                </MojCheckbox>
                            </MojFormItem>
                        </Col>
                    }

                    {
                        isEpuMortgage &&
                        <Col span="24">
                            <MojFormItem
                                htmlFor="epuConfirmation"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                validateStatus={errors['epuConfirmation'] && 'error'}
                                help={touched['epuConfirmation'] && errors['epuConfirmation']}
                            >
                                <MojCheckbox
                                    value={getFieldProps('epuConfirmation').value}
                                    checked={getFieldProps('epuConfirmation').value}
                                    onChange={(value) => {
                                        changeEpuConfirmation(value);
                                    }}
                                    className="checkbox-item space"
                                >
                                    {t('shared.epu-alert')}
                                </MojCheckbox>
                            </MojFormItem>
                        </Col>

                    }
                    {
                        !isEpuMortgage ?
                            <Col span="24">
                                <MojFormItem
                                    label={t('shared.operation-days')}
                                    htmlFor="duration"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    validateStatus={errors['duration'] && 'error'}
                                    help={touched['duration'] && errors['duration']}
                                >
                                    {isGov
                                        ? <Input inputMode="numeric"
                                            value={getFieldProps('duration').value}
                                            onChange={(e) => changeIdType(e)}
                                            max={30}
                                            min={1}
                                        />
                                        : <Radio.Group onChange={(e) => changeIdType(e)} value={getFieldProps('duration').value}>
                                            <Radio value={10}>{'10 ' + t('shared.days')}</Radio>
                                        </Radio.Group>
                                    }
                                </MojFormItem>
                            </Col>
                            : null
                    }
                    {!isDeal && (
                        <>
                            <Col span="24">
                                <PlainLabel
                                    title={t('shared.mortgage-claiming-date')}
                                    label={''}
                                    variant={PlainLabelVariant.disabled}
                                    small
                                    noBorder
                                />
                            </Col>

                            <DatePickerInputText dateValue={getDateValue()} onChange={handleDateChange} formikProps={formikProps} calendarType="gregorian" />
                        </>
                    )}
                </Row>
            </Card>
        </div>
    );
};
export default MortgageInfo;
