import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Drawer, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Card, MojButton, MojPopup } from '@/core/components/shared';
import RealestateFilter from '@open-market/components/RealestateFilter/RealestateFilter';
import RealestateSort from '@open-market/components/RealestateSort/RealestateSort';
import Header from '@core/components/shared/Header/Header';
import RealStateList from '../../components/RealStateList/RealStateList';
import RealStateMap from '../../components/RealStateMap/RealStateMap';
import realestateAndDealsService from '@common/services/realestates-and-deals.service';
import { loaderStore } from '@/common/stores/loader.store';
import GenericResponse from '@/common/models/GenericResponse';
import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import './RealestateAndDealsPage.scss';

export type SortingType = {
    sortType: number;
    sortField: number;
};

export const realStateViews = { map: 'الخريطة العقارية', list: 'قائمة العقارات' };

const RealestateAndDealsPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const [selectedView, setSelectedView] = useState(realestateAndDealsStore?.selectedView);
    const [callMap, setCallMap] = useState<any>(false);
    const [callSearchByIdentity, setCallSearchByIdentity] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSortingType, setSelectedSortingType] = useState(realestateAndDealsStore.selectedSortingType);
    const [isOpened, setIsOpened] = useState(false);
    const [totalHits, setTotalHits] = useState(100);
    const isMapEnabled = true;

    const closeDrawer = () => {
        setIsOpened(!isOpened);
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const isMobile = React.useMemo(() => {
        return isMobileOrTabletDevice();
    }, []);

    useEffect(() => {
        getRealestateTypes();
    }, []);

    useEffect(() => {
        realestateAndDealsStore.setSelectedView(selectedView);
        return () => {
            realestateAndDealsStore.setSelectedView(realStateViews.list);
        };
    }, [selectedView]);

    useEffect(() => {
        if (params && params.feature && params.feature === 'realestate-map') {
            setSelectedView(realStateViews.map);
            realestateAndDealsStore.setSelectedView(realStateViews.map);
        } else {
            setSelectedView(realStateViews.list);
            realestateAndDealsStore.setSelectedView(realStateViews.list);
        }
    }, [params]);

    const loadRealestate = async (updateRealestatesList?: boolean) => {
        const delayDebounceFn = setTimeout(() => {
            if (updateRealestatesList) {
                loaderStore.setLoader(true);
            }

            const filters = { ...realestateAndDealsStore.realestateFilters };
            delete filters.locationSearchTerm;
            if (filters.hasOwnProperty('isOpenMarket')) {
                delete filters['isOpenMarket'];
            }
            if (filters.hasOwnProperty('isRealEstate')) {
                delete filters['isRealEstate'];
            }

            // Send Axios request here
            realestateAndDealsService
                .getFilteredRealestates(
                    updateRealestatesList === true ? 1 : currentPage,
                    realestateAndDealsStore.realestateOptions.includes(0),
                    realestateAndDealsStore.realestateOptions.includes(1),
                    realestateAndDealsStore.realestateOptions.includes(2),
                    realestateAndDealsStore.sortingType,
                    filters
                )
                .then((response: GenericResponse) => {
                    loaderStore.setLoader(false);
                    if (response?.IsSuccess!! && response?.Data!!) {
                        if (updateRealestatesList) {
                            realestateAndDealsStore.updateRealestates(response.Data.RealEstates);
                        } else {
                            realestateAndDealsStore.addRealestates(response.Data.RealEstates);
                        }
                        setTotalHits(response.Data.TotalHits);
                        if (isOpened) {
                            closeDrawer();
                        }
                    }
                    if (updateRealestatesList) {
                        setCurrentPage(2);
                    } else setCurrentPage(currentPage + 1);
                });
        }, 700);

        return () => clearTimeout(delayDebounceFn);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSort = ({ id, dir, key }: { id: number; dir: string; key: number }) => {
        realestateAndDealsStore.setSortingType({
            sortType: dir === 'asc' ? 1 : 0,
            sortField: id,
        });

        setSelectedSortingType(key);
        // loadRealestate(true);
        handleCancel();
    };

    const getRealestateTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getRealEstateTypes();

        if (response?.IsSuccess) {
            realestateAndDealsStore.setRealestateTypes(response.Data);
        }
    };

    // 0 -> Date
    // 1 -> Area
    // 2 -> Price
    const sortingList = [
        { id: 0, dir: 'asc', label: t('realestate-and-deals.sorting.default') },
        { id: 0, dir: 'asc', label: t('realestate-and-deals.sorting.new-to-old') },
        { id: 0, dir: 'desc', label: t('realestate-and-deals.sorting.old-to-new') },
        { id: 1, dir: 'asc', label: t('realestate-and-deals.sorting.area-large-to-small') },
        { id: 1, dir: 'desc', label: t('realestate-and-deals.sorting.area-small-to-large') },
        { id: 2, dir: 'asc', label: t('realestate-and-deals.sorting.price-high-to-low') },
        { id: 2, dir: 'desc', label: t('realestate-and-deals.sorting.price-low-to-high') },
    ];

    const changeViewHandler = (value) => {
        setSelectedView(value);
        realestateAndDealsStore.setSelectedView(value);
        if (value === realStateViews.map) {
            navigate('/open-market/realestate-map');
        } else {
            navigate('/open-market');
        }
    };

    return (
        <Row gutter={[isMobile ? 0 : { xs: 8, sm: 8, md: 16 }, 0]}>
            {!isMobile && realestateAndDealsStore.toolsVisible && realestateAndDealsStore.desktopFiltersVisible && (
                <Col flex={selectedView === realStateViews.map ? "25%" : "35%"}>
                    <Row gutter={0} className={selectedView === realStateViews.map ? 'map-filter' : ''}>
                        <RealestateFilter
                            hideCloseButton
                            isLoading={loaderStore.isLoading}
                            closeDrawer={closeDrawer}
                            selectedView={selectedView}
                            setCallSearchByIdentity={setCallSearchByIdentity}
                        />
                        {selectedView !== realStateViews.map && (
                            <RealestateSort
                                sortingList={sortingList}
                                selectedSortingType={selectedSortingType}
                                onClickAction={handleSort}
                                onClose={handleCancel}
                                showTitle={true}
                            />
                        )}
                    </Row>
                    <Card className="filters-actions">
                        <Row gutter={[16, 0]} justify="start" align="middle">
                            <Col flex="1">
                                <MojButton
                                    onClick={() => {
                                        realestateAndDealsStore.resetFilters();
                                    }}
                                    loading={loaderStore.isLoading}
                                    color="primary"
                                >
                                    {loaderStore.isLoading ? '' : t('realestate-and-deals.reset-filters')}
                                </MojButton>
                            </Col>
                            <Col flex="1">
                                <MojButton
                                    loading={loaderStore.isLoading}
                                    onClick={() => {
                                        if (selectedView === realStateViews.list) {
                                            loadRealestate(true);
                                        } else {
                                            setCallMap(true);
                                        }
                                    }}
                                    color="success"
                                >
                                    {loaderStore.isLoading ? '' : t('realestate-and-deals.submit-filters')}
                                </MojButton>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            )}
            <Col className='open-market-header' flex="auto">
                {realestateAndDealsStore.toolsVisible ?
                    <Header
                        headerTitle={""}
                        headerClassName={isMobile ? 'du-card--no-padding' : ''}
                        showModal={showModal}
                        closeDrawer={closeDrawer}
                        showActions={isMobile && selectedView === realStateViews.list}
                        radioGroup={{
                            options: [realStateViews.list, realStateViews.map],
                            onChangeValue: changeViewHandler,
                            currentValue: selectedView,
                        }}
                        hideSortActions={true}
                        isMapEnabled={isMapEnabled}
                    /> : <></>}
                {isMobile && (
                    <Drawer
                        placement="left"
                        onClose={() => {
                            realestateAndDealsStore.resetFilters();
                        }}
                        closable={false}
                        width="100%"
                        open={isOpened}
                        footer={
                            <Card>
                                <Row gutter={[16, 0]} justify="start" align="middle">
                                    <Col flex="1">
                                        <MojButton
                                            onClick={() => {
                                                realestateAndDealsStore.resetPage();
                                            }}
                                            loading={loaderStore.isLoading}
                                            color="primary"
                                        >
                                            {loaderStore.isLoading ? '' : t('realestate-and-deals.reset-filters')}
                                        </MojButton>
                                    </Col>
                                    <Col flex="1">
                                        <MojButton
                                            loading={loaderStore.isLoading}
                                            onClick={() => {
                                                if (selectedView === realStateViews.list) {
                                                    loadRealestate(true);
                                                } else {
                                                    setCallMap(true);
                                                    closeDrawer();
                                                }
                                            }}
                                            color="success"
                                        >
                                            {loaderStore.isLoading ? '' : t('realestate-and-deals.submit-filters')}
                                        </MojButton>
                                    </Col>
                                </Row>
                            </Card>
                        }
                    >
                        <RealestateFilter
                            isLoading={loaderStore.isLoading}
                            closeDrawer={closeDrawer}
                            selectedView={selectedView}
                            setCallSearchByIdentity={setCallSearchByIdentity}
                        />
                    </Drawer>
                )}
                {isMobile && (
                    <MojPopup
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closable={true}
                        style={{ top: 310 }}
                        width={'90%'}
                        footer={null}
                        destroyOnClose={true}
                        title={t('shared.sort-by')}
                    >
                        <RealestateSort
                            sortingList={sortingList}
                            selectedSortingType={selectedSortingType}
                            onClickAction={handleSort}
                            onClose={handleCancel}
                        />
                    </MojPopup>
                )}
                {selectedView === realStateViews.list && <RealStateList
                    loadRealestate={loadRealestate}
                    setCurrentPage={setCurrentPage}
                    totalHits={totalHits}
                />}
                {(selectedView === realStateViews.map) && (
                    <RealStateMap
                        callMap={callMap}
                        setCallMap={setCallMap}
                        callSearchByIdentity={callSearchByIdentity}
                        setCallSearchByIdentity={setCallSearchByIdentity}
                        showModal={closeDrawer}
                    />
                )}
            </Col>
        </Row>
    );
};

export default observer(RealestateAndDealsPage);
