import React from 'react';
import './MojFormItem.scss';
import { Form, FormItemProps } from 'antd';
import clsx from 'clsx';

interface MojFormItemProps extends FormItemProps {
    span?: number;
    help?: any;
}

export function MojFormItem({ span = 24, help,className, children, ...rest }: MojFormItemProps) {
    return (
        <Form.Item
            className={clsx('moj-form-item', className)}
            labelCol={{ span }}
            wrapperCol={{ span }}
            help={help}
            {...rest}
        >
            {children}
        </Form.Item>
    );
}
