import React, { useEffect, useState } from 'react'
import { EduLinkDeedByEnd, IUserInput } from '../../models/edit.deed.models'
import { REIdentityPoint } from '@/modules/MergeSplitRealestate/models/reIdentity.model';
import deedUpgradeService from '@/common/services/deed-upgrade.service';
import { MAP_LAT_LNG_SORTER } from '@/modules/open-market/components/RealStateMap/mapHelperFunctions';
import RealStateMapAdvanced from '@/modules/open-market/components/RealStateMap/RealStateMapAdvanced';
import { Alert, AlertVariants } from '@/core/components/shared';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

interface EDUFieldMapProps {
    input: IUserInput,
    onValidationChange: (isValid: boolean) => void
}
export default function EDUFieldMap({ input: { value, type }, onValidationChange }: EDUFieldMapProps) {
    const [reIdentityPoints, setReIdentityPoints] = useState<REIdentityPoint[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            if (type === EduLinkDeedByEnd.RealEstateIdentityNumber) {
                deedUpgradeService.getRealestateIdentityInformation(value).then((response: any) => {
                    if (!response) {
                        return;
                    }
                    if (response?.Data?.Data?.Result?.Coordinates) {
                        setReIdentityPoints([{
                            coordinates: response?.Data?.Data?.Result?.Coordinates.map(coordinate => [coordinate.Latitude, coordinate.Longitude].sort(MAP_LAT_LNG_SORTER))
                        }]);
                        setHasError(false);
                    } else {
                        setHasError(true);
                        setReIdentityPoints([]);
                    }

                    if (response.ErrorList?.length > 0) {
                        notification.error({
                            message: response.ErrorList[0]
                        });
                        onValidationChange(false);
                    }
                }
                );
            } else {
                deedUpgradeService.validateConstructionLicenseNumber(value).then((response: any) => {
                    if (!response) {
                        return;
                    }

                    if (response?.Data?.CoordinatesListData?.length > 0) {
                        setReIdentityPoints([{
                            coordinates: response?.Data?.CoordinatesListData.map(coordinate => [parseFloat(coordinate.Latitude), parseFloat(coordinate.Longitude)].sort(MAP_LAT_LNG_SORTER))
                        }]);
                        setHasError(false);
                    } else {
                        setHasError(true);
                        setReIdentityPoints([]);
                    }

                    if (!response?.Data?.IsValid) {
                        onValidationChange(false);
                    }

                    if (response.ErrorList?.length > 0) {
                        notification.error({
                            message: response.ErrorList[0]
                        });
                    }
                });
            }
        }
    }, [value, type]);
    return <>
        {reIdentityPoints?.length > 0 && !hasError ? <RealStateMapAdvanced
            reIdentityPoints={reIdentityPoints}
            callMap={false}
            setCallMap={() => { }}
            setCallSearchByIdentity={() => { }}
            callSearchByIdentity={false}
            showModal={() => { }}
            allowUpdateCoordinates={false}
            realestateIdentityError={""}
            blinkProperties={true}
            checkOverlapByIdentity={false}
        /> : <>
            <Alert variant={AlertVariants.Danger}>{t("shape-file-map.error")}</Alert>
        </>}
    </>
}
