import * as Yup from 'yup';
import { NationalIdTypes } from '@common/enums/national-id-types';

const phoneRegExp = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
export const nationalIdRegExp = new RegExp(`[1][\\d]{9}`);
const IqamaIdRegExp = new RegExp(`[2][\\d]{9}`);
const companyIdRegExp = new RegExp(`[7][\\d]{9}`);
const vatNumberRegex = new RegExp(`[61][\\d]{10}`);
// regex for gulf id to be from 10 to 16 digits.
export const gulfIdRegex = new RegExp(`[1-9][\\d]{9,15}`);

export const validationSchema1 = Yup.object().shape({
    deedNumber: Yup.string().when(`sectionType`, (sectionType) => {
        if (sectionType === 0) {
            return Yup.string().required('حقل مطلوب').length(12, `يجب ان تكون 12 خانات`);
        } else {
            return Yup.string().notRequired();
        }
    }),
    idType: Yup.number().required(),
    validationType: Yup.number().required(),
    idNumber: Yup.string()
        .when(`validationType`, (validationType) => {
            if (validationType === 0) {
                return Yup.string()
                    .test(`nationalId`, 'الهوية الوطنية يجب ان تبدأ برقم 1', function (value) {
                        const ref = Yup.ref(`idType`);
                        if (this.resolve(ref) == NationalIdTypes.NATIONAL_ID) {
                            if (!value?.startsWith(`1`)) {
                                return false;
                            }
                        }
                        return true;
                    })
                    .test(`unifiedNationalId`, 'هوية المقيم يجب ان تبدأ برقم 2', function (value) {
                        const ref = Yup.ref(`idType`);
                        if (this.resolve(ref) == NationalIdTypes.IQAMA_ID) {
                            if (!value?.startsWith(`2`)) {
                                return false;
                            }
                        }
                        return true;
                    })
                    .test(`equal`, 'الرقم الوطنى الموحد للمنشأه يجب ان يبدأ برقم 7', function (value) {
                        const ref = Yup.ref(`idType`);
                        if (this.resolve(ref) == NationalIdTypes.FACILITY_NUMBER) {
                            if (!value?.startsWith(`7`)) {
                                return false;
                            }
                        }
                        return true;
                    })
                    .length(10, `يجب ان تكون 10 خانات`)
                    .required('حقل مطلوب');
            } else {
                return Yup.string().notRequired();
            }
        })
        .matches(nationalIdRegExp, 'رقم الهوية الوطنية الذي أدخلته غير صحيح'),
    day: Yup.string().when(`validationType`, (validationType) => {
        if (validationType === 1) {
            return Yup.string().required('حقل مطلوب');
        } else {
            return Yup.string().notRequired();
        }
    }),
    month: Yup.string().when(`validationType`, (validationType) => {
        if (validationType === 1) {
            return Yup.string().required('حقل مطلوب');
        } else {
            return Yup.string().notRequired();
        }
    }),
    year: Yup.string().when(`validationType`, (validationType) => {
        if (validationType === 1) {
            return Yup.string().required('حقل مطلوب');
        } else {
            return Yup.string().notRequired();
        }
    }),
    realestateIdentityNo: Yup.string().when(`sectionType`, (sectionType) => {
        if (sectionType === 1) {
            return Yup.string().required('حقل مطلوب').min(14, `يجب ان تكون 14 خانات`).max(16, `يجب ان تكون 16 خانات`);
        } else {
            return Yup.string().notRequired();
        }
    }),
});

export const validationSchema2 = Yup.object().shape({
    phoneNumber: Yup.string().required('حقل مطلوب').matches(phoneRegExp, 'صيغة رقم الجوال: 05xxxxxxxx'),
});

export const phoneNumberValidator = Yup.string()
    .required('حقل مطلوب')
    .matches(phoneRegExp, 'صيغة رقم الجوال: 05xxxxxxxx');
export const mortgageAmountValidator = Yup.number()
    .required('حقل مطلوب')
    .moreThan(999, 'يجب أن تكون القيمة أكثر من 1000 ريال سعودي.')
    .lessThan(100000000001, 'يجب أن تكون القيمة أقل من 100 مليار ريال سعودي.')
    .typeError('يجب أن تكون القيمة رقمًا.');
export const dealAmountValidator = Yup.number()
    .required('حقل مطلوب')
    .moreThan(999, 'يجب أن تكون القيمة أكثر من 1000 ريال سعودي.')
    .lessThan(100000000001, 'يجب أن تكون القيمة أقل من 100 مليار ريال سعودي.')
    .typeError('يجب أن تكون القيمة رقمًا.');
export const requiredInput = Yup.string().required('حقل مطلوب');
export const notRequiredInput = Yup.string().notRequired();
export const selectIbanValidator = Yup.number().required('يجب تحديد رقم الآيبان');
export const requiredNumber = Yup.number().required('حقل مطلوب').typeError('يجب أن تكون القيمة رقمًا.');
export const ibanValidator = Yup.string()
    .required('يجب تحديد رقم الآيبان')
    .matches(/^([A-Z]){2}\d{4}[0-9A-Z]{18}$/, 'يجب أن يكون طول الآيبان ( IBAN ) مساويًا لـ 22.');

export const optionalIbanValidator = Yup.string().matches(
    /^([A-Z]){2}\d{4}[0-9A-Z]{18}$/,
    'يجب أن يكون طول الآيبان ( IBAN ) مساويًا لـ 22.'
);

export const nationalIdValidator = Yup.string()
    .required('حقل مطلوب')
    .matches(nationalIdRegExp, 'رقم الهوية الوطنية الذي أدخلته غير صحيح')
    .length(10);

export const vatNumberValidator = Yup.string()
    .required('حقل مطلوب')
    .matches(vatNumberRegex, 'الرقم المدخل لا يطابق تنسيق الرقم المرجعي للزكاة والدخل')
    .length(12);

export const iqamaIdValidator = Yup.string()
    .required('حقل مطلوب')
    .matches(IqamaIdRegExp, 'الرقم المدخل لا يطابق تنسيق الإقامة')
    .length(10);

export const companyIdValidator = Yup.string()
    .required('حقل مطلوب')
    .matches(companyIdRegExp, 'يجب ان يطابق تنسيق الرقم الوطنى الموحد. الرقم الوطنى الموحد للمنشأه يجب ان يبدأ برقم 7')
    .length(10);

export const gulfIdValidator = Yup.string()
    .required('حقل مطلوب')
    .matches(gulfIdRegex, 'رقم الهوية الخلجية الذي أدخلته غير صحيح')
    .length(16);

export const mortgageIdValidator = Yup.number()
    .required('حقل مطلوب')
    .typeError('يجب أن تكون القيمة رقمًا.')
    .test(`len`, 'يجب أن يتكون الحقل من 10 أرقام', (val) => (val || ``).toString().length === 10);

export const sadadVatNumberCondition = Yup.object().shape({
    vatSadadNumberTermsCheck: Yup.boolean().required().oneOf([true], `يجب الموافقة على الإقرار.`),
});
export const eptmConfirmationValidator = Yup.boolean().required().oneOf([true], `يجب الموافقة على الإقرار.`);

export const noValidator = Yup.object().shape({});

export const shareValidator = Yup.string().test(
    'fraction',
    'يمكنك فقط إدخال أرقام صالحة بحد أقصى 5 أرقام بعد العلامة العشرية',
    (value: string | undefined) => {
        const splitted = (value || '').toString().split('.');
        if (splitted.length > 1) {
            if (splitted[1].length > 5) {
                return false;
            }
        } else {
            return !Number.isNaN(parseFloat((value || '').toString()));
        }

        return true;
    }
);

export const requiredArabicInput = requiredInput.test('arabic-only', 'الرجاء إدخال الحروف العربية فقط', (value) =>
    /^[\u0600-\u06FF\u0660-\u06690-9\\/\s]+$/.test(value || '')
);

// validate duration input only numbers and min value 1 and max value 30.
export const durationValidator = Yup.number()
    .typeError('يجب أن تكون القيمة رقمًا.')
    .min(1, 'يجب أن تكون المدة يوم واحد على الأقل')
    .max(30, 'يجب أن تكون المدة أقل من أو تساوي 30 يوما');
