import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';

import './_RadioGroup.scss';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import { useMemo } from 'react';
import clsx from 'clsx';

export type RadioGroupType = {
    options?: string[];
    currentValue: string;
    onChangeValue: (e: RadioChangeEvent) => void;
};

const RadioGroup = ({ options = [], onChangeValue, currentValue }: RadioGroupType) => {
    const onChange = (e: RadioChangeEvent) => onChangeValue(e.target.value);
    const isMobile = useMemo(() => isMobileOrTabletDevice(), []);

    return (
        <div className={clsx('openMarketRadioButton', { mobileView: isMobile })}>
            <Radio.Group onChange={onChange} value={currentValue} buttonStyle={(isMobile ? "outline" : "solid")}>
                {options.map((option) => (
                    <Radio.Button value={option} key={option} className="RadioButton">
                        {option}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </div>
    );
};

export default RadioGroup;
