import { differenceInDays } from 'date-fns';
import dayjs from 'dayjs';

export enum EndDateTypes {
    YEAR = 1,
    DATE = 2,
}

export const getDateFormatted = (date: Date) => {
    return dayjs(date).format('YYYY/MM/DD');
};

export const getDateAndTimeFormatted = (date: Date) => {
    return dayjs(date).format('YYYY/MM/DD HH:mm:ss');
};

export const insertSlash = (date: string) => {
    return date.replace(/^(\d{4})(\d{2})/, '$1/$2/');
};

export const minutesFromSeconds = (baseSeconds: number) => {
    const minutes = Math.floor(baseSeconds / 60);
    const seconds = baseSeconds % 60;
    return `${pad(minutes)}:${pad(seconds)}`;
};

export const getHijriDateFormatted = (year: number, month: number, day: number) => {
    if(!year || !month || !day )
        return "";

    return `${year}/${pad(month)}/${pad(day)}`;
};

const pad = (n: number) => {
    return n.toString().padStart(2, '0');
};

export const getTime = (date?: Date) => {
    return date != null ? dayjs(date).millisecond() : 0;
}

export function isDateBeforeToday(dateToCheck: Date) {
    return dateToCheck.getTime() < new Date().setHours(0,0,0,0);
}

export function getDifferenceInDays(dateToCheck: Date) {
      return differenceInDays(dateToCheck, new Date());
}

export function isToday(date: Date) {
    return dayjs(date).isSame(new Date(), 'day');
}