import React, { HTMLAttributes } from 'react';
import { Col, Row } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './_UserAccordion.scss';
interface UserAccordionProps extends HTMLAttributes<HTMLDivElement> {
    isExpanded: boolean;
    setExpanded: (value: boolean) => void;
}
export const UserAccordion = ({ isExpanded, setExpanded, children }: UserAccordionProps) => {
    return (
        <Row justify="end" className="ms-width">
            {!isExpanded && (
                <Row
                    align="middle"
                    onClick={(e) => {
                        e.preventDefault();
                        setExpanded(true);
                    }}
                >
                    <Col>{children}</Col>
                    <CaretDownOutlined />
                </Row>
            )}
            {isExpanded && (
                <Row
                    align="middle"
                    onClick={(e) => {
                        e.preventDefault();
                        setExpanded(false);
                    }}
                >
                    <Col>{children}</Col>
                    <CaretUpOutlined />
                </Row>
            )}
        </Row>
    );
};
