import React from 'react';
import { MojButton } from '@core/components/shared';
import { useNavigate } from 'react-router-dom';
import './_NotFound.scss';
import { ReactComponent as EmptyIcon } from '@core/assets/images/assets-arts-empty-state.svg';
import { useTranslation } from 'react-i18next';

const NotFoundInfo = (props: { backUrl?: string }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { backUrl } = props;

    const handleClick = () => {
        if (backUrl) {
            return navigate(backUrl, { replace: true });
        }

        return navigate('/', { replace: true });
    };

    return (
        <div className="NotFound">
            <EmptyIcon />
            <h2>{t('shared.fetching-error')}</h2>
            <MojButton style={{ maxWidth: '200px' }} color={'primary'} onClick={handleClick}>
                {t('shared.back')}
            </MojButton>
        </div>
    );
};

export default NotFoundInfo;
