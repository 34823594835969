import { refundManagmentStore } from '@/common/stores/refundManagment.store';
import { Card } from '@/core/components/shared';
import { MojCheckbox } from '@/core/components/shared/Checkbox/MojCheckbox';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Classes from './__RefundTerms.module.scss';

interface Props {
    termsValidationsHandler : (flag: boolean) => void;
}
function RefundTerm({termsValidationsHandler} : Props) {
    //const { t } = useTranslation();
    const {updateRefundTermCheck ,getSubmitDisabledStep,AreTermsValidationsChecked,RefundTerms}
     = refundManagmentStore;
    return (
        <Card className="section__basics ">
            <Card.Head className="section__title border-bottom"></Card.Head>
            <Card.Body className={Classes.termsDiv}>
                {
                 RefundTerms.filter(x => x.visible).map((term, index) => {
                    return (
                        <Row className="single-acknowledgment" wrap={false} align="middle" key={`term-${index}`}>
                            <Col className={`review-buying-request__acknowledge-checkbox `}>
                                <MojCheckbox
                                    id={index.toString()}
                                    name={index.toString()}
                                    autoFocus={true}
                                    checked={term.checked}
                                    defaultChecked={false}
                                    onChange={(e) => {
                                        updateRefundTermCheck(term.termId,  e.target.checked);
                                        termsValidationsHandler(!AreTermsValidationsChecked());
                                        getSubmitDisabledStep();
                                    }}
                                    style={{ color: '#fff' }}
                                >
                                    {<p className={Classes.content}>{term.label}</p>}
                                </MojCheckbox>
                            </Col>
                        </Row>
                    );
                })
                }
            </Card.Body>
        </Card>
    );
}
export default observer(RefundTerm);
