import React, {ReactNode} from "react";
import {MojPopup} from "@core/components/shared";
import {ConfigProvider, ModalProps} from "antd";
import {useTranslation} from "react-i18next";

import "./HintPopup.scss";

interface OwnProps extends ModalProps {
    title: string;
    hideClose?: boolean;
    message: string | ReactNode;
    onCloseClick?: () => void;
    footer?: ReactNode;
    ghost?: boolean;
    ghostId?: string;
    visible?: boolean;
}

const HintPopup = ({title,
                       message,
                       hideClose,
                       onCloseClick,
                       footer = null,
                       ghost = false,
                       maskClosable = false,
                       visible = false,
                       ...modalProps} : OwnProps) => {
    const { i18n } = useTranslation();

    return (
        <ConfigProvider direction={i18n.dir(i18n.language)}>
            <MojPopup
                closable={!hideClose}
                title={title}
                open={visible}
                maskClosable={maskClosable}
                {...modalProps}
                onCancel={() => {
                    setTimeout(() => {
                        onCloseClick && onCloseClick();
                    }, 10);
                }}
                footer={footer}
            >
                {message}
            </MojPopup>
        </ConfigProvider>
    )
}

export default HintPopup;
