import { FunctionComponent } from 'react';
import { useTranslation } from "react-i18next";
import ReactDOMServer from "react-dom/server";
import { Col, Row } from "antd";

import { Card } from "@core/components/shared";
import { Term, Terms } from "@dashboard/components/TermsAndConditionsLayout/builder/TermsBuilder";
import { MojCheckbox } from "@core/components/shared/Checkbox/MojCheckbox";
import { ReactComponent as LinkIcon } from "@core/assets/images/assets-icons-link-blue.svg";
import { authStore } from "@common/stores/auth.store";
import { attorneyStore } from "@common/stores/attorney.store";

interface OwnProps {
    terms: Terms;
    updateTerms: (terms: Terms) => void;
}

type Props = OwnProps;

const termsAndConditionLink = ReactDOMServer.renderToString(
    <a href="https://srem.moj.gov.sa/terms" target="_blank" rel="noopener noreferrer">
        {' '}الشروط والأحكام<LinkIcon />{' '}
    </a>
);

const TermsAndConditions: FunctionComponent<Props> = ({ terms, updateTerms }) => {
    const { t } = useTranslation();

    function changeState(term: Term, index: number) {
        const temp = terms
        const _term = term
        _term.changeState()
        temp.terms[index] = _term
        updateTerms(temp)
    }

    return (
        <Card className="section__basics">
            <Card.Head className="section__title border-bottom">
                {t('review-buying-request.acknowledgment-title')}
            </Card.Head>
            <Card.Body>
                <Row className="single-acknowledgment">
                    {
                        attorneyStore.attorney && attorneyStore.attorney.PersonInfo ? t("terms.acknowledge").replace("${name}", attorneyStore.attorney?.PersonInfo?.Name)
                            .replace("${id}", attorneyStore.attorney?.PersonInfo?.SocialId.toString()) :
                            t("terms.acknowledge").replace("${name}", authStore?.userDetails?.Name)
                                .replace("${id}", authStore?.userDetails?.IdentityNo)
                    }

                    {/*{t("terms.acknowledge").replace("${name}", authStore.userDetails.Name)*/}
                    {/*    .replace("${id}", authStore.userDetails.IdentityNo)}*/}
                </Row>
                {terms.terms.map((term, index) => {
                    return <Row className="single-acknowledgment" wrap={false} align="middle" key={`term-${index}`}>
                        <Col className="review-buying-request__acknowledge-checkbox">
                            <MojCheckbox
                                id={index.toString()}
                                name={index.toString()}
                                autoFocus={true}
                                defaultChecked={term.isChecked}
                                onChange={(e) => {
                                    changeState(term, index);
                                }}
                            >
                                {
                                    term.label.includes('termsAndConditionLink') ?
                                        <div
                                            dangerouslySetInnerHTML={{ __html: term.label.replace("${termsAndConditionLink}", termsAndConditionLink) }} /> :
                                        term.label
                                }

                            </MojCheckbox>
                        </Col>
                    </Row>
                })}

            </Card.Body>
        </Card>
    );
};

export default TermsAndConditions;
