import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '@common/stores/auth.store';

type ComponentWithChildren = {
    children?: any;
};

const RequireAuth: FC<ComponentWithChildren> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(authStore.isLoggedIn);
    useEffect(() => {
        // Check if user logged in
        //    if not show IAM Popup
        if (authStore.user && authStore.isLoggedIn) {
            setIsLoggedIn(true);
            authStore.loadingUser(false);
            authStore.hideLoginPopup();
        } else if (!authStore.isLoggedIn && authStore.user === null && !authStore.isLoadingUser) {
            setIsLoggedIn(false);
            authStore.requireLoginPopup();
        }
    }, [authStore.user, authStore.isLoggedIn]);
    return isLoggedIn ? children : <>{}</>;
};

export default observer(RequireAuth);
