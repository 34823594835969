import React from 'react';
import '../MojInput/MojInput.scss';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

interface MojTextAreaProps extends TextAreaProps {}

export function MojTextArea({ ...props }: MojTextAreaProps) {
    return <Input.TextArea {...props} />
}
