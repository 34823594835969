import clsx from 'clsx';
import React, { HTMLProps } from 'react';
import { FieldWrapper } from '../index';
import './ReadOnlyField.scss';

export enum ReadOnlyFieldVariant {
    success = 'success',
    danger = 'danger',
    primary = 'primary',
    warning = 'warning',
    disabled = 'disabled',
}

export enum ReadOnlyFieldBorderVariant {
    success = 'success',
    warning = 'warning',
    primary = 'primary',
    danger = 'danger',
}

export interface ReadOnlyFieldProps extends Omit<Omit<HTMLProps<HTMLDivElement>, 'title'>, 'label'> {
    label: string | React.ReactNode;
    title: string | React.ReactNode;
    suffix?: string | React.ReactNode;
    noBorder?: boolean;
    variant?: ReadOnlyFieldVariant;
    className?: string;
    small?: boolean;
    large?: boolean;
    unit?: string;
    inline?: boolean;
    borderVariant?: ReadOnlyFieldBorderVariant;
    hasLtrContent?: boolean;
}
export function ReadOnlyField({
    title,
    label,
    suffix,
    noBorder,
    unit,
    variant = ReadOnlyFieldVariant.success,
    className,
    small,
    large,
    borderVariant = ReadOnlyFieldBorderVariant.success,
    inline,
    children,
    hasLtrContent,
    ...props
}: ReadOnlyFieldProps) {
    return (
        <div
            className={clsx(
                'read-only-field',
                `read-only-field--${variant}`,
                {
                    'read-only-field--no-border': noBorder,
                    'read-only-field--small': small,
                    'read-only-field--large': large,
                    'read-only-field--inline': inline,
                },
                className
            )}
            {...props}
        >
            <FieldWrapper
                className={`read-only-field-border--${borderVariant}`}
                label={label}
                unit={unit}
                isLtr={hasLtrContent}
                suffix={suffix}
            >
                {title}
                {children}
            </FieldWrapper>
        </div>
    );
}
