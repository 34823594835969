import { useHint } from '@/common/hooks';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function OtpIcon() {
    const { t } = useTranslation();

    const { Icon } = useHint({
        title: t('shared.info'),
        message: t('otp-confirmation.otp-hint'),
        ghostId: 'otpModal',
    });
    return (
        <Icon />
    )
}
