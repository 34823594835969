import { Alert, AlertVariants, Card, MojButton, MojPopup } from '@/core/components/shared';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RejectFlag } from '@core/assets/images/assets-states-failure.svg';
import './_UpdateIbanNotificationModal.scss';
import {  useState } from 'react';
import RefundTerm from '../RefundTermsAndConditions/RefundTerms';
import RefundService from '@common/services/refund.service';
import { UpdatePendingRefundOrderDto } from '@/common/models/RefundManagement';
import { loaderStore } from '@common/stores/loader.store';
import { observer } from 'mobx-react-lite';
import { PendingRefundFeedback } from '@/core/assets/enums/PendingRefundFeedback.enum';
import GenericResponse from '@/common/models/GenericResponse';
import SelectBankAccount from '@/modules/SellingOffer/components/SelectBankAccount/SelectBankAccount';
import { Formik, FormikProps } from 'formik';
import { refundManagmentStore } from '@/common/stores/refundManagment.store';

export interface Props {
    openUpdateIbanModal: boolean;
    currentRefundInfo: any;
    handleRefundResponseModal: (flag: boolean) => void;
    handleUpdateIbanHandler: (flag: boolean) => void;
}
function UpdateIbanModal({
    openUpdateIbanModal,
    currentRefundInfo,
    handleRefundResponseModal,
    handleUpdateIbanHandler,
}: Props) {
    const { t } = useTranslation();

    const refundService = new RefundService();
    const [errorList, setErrorList] = useState<string[] | undefined>(undefined);
    const [showtermsValidation, setTermsValidations] = useState(false);
    const { getNewChosenIban, IbanStep, disableSubmitButton } = refundManagmentStore;

    const updatePendingRefundOrder = async () => {
        loaderStore.setLoader(true);
        var object: UpdatePendingRefundOrderDto = {
            requestId: currentRefundInfo?.RequestId,
            recordId: currentRefundInfo?.Id,
            newIban: IbanStep !== 0 ? getNewChosenIban() : '',
            feedbackType:
                IbanStep === 0
                    ? PendingRefundFeedback.ReExecuteTransfer
                    : IbanStep === 1
                    ? PendingRefundFeedback.ReExecuteTransferWithIBan
                    : PendingRefundFeedback.UNKNOWN,
        };
        await updatePendingRefundOrderData(object);
    };

    const updatePendingRefundOrderData = async (model: UpdatePendingRefundOrderDto) => {
        await refundService.updatePendingRefundOrder(model).then((response: GenericResponse) => {
            if (response.Data !== null && response.Data !== undefined) {
                if (response.IsSuccess) {
                    handleRefundResponseModal(true);
                    handleUpdateIbanHandler(false);
                } else {
                    handleRefundResponseModal(false);
                }
                loaderStore.setLoader(false);
            } else if (response.ErrorList && response.ErrorList.length > 0) {
                setErrorList(response.ErrorList);
                loaderStore.setLoader(false);
            }
        });
    };

    return (
        <Row className="UpdateIbanNotification">
            <MojPopup
                title={<Col span={24}>{t('refund-management.headline')}</Col>}
                open={openUpdateIbanModal}
                zIndex={99999}
                className="RefundModal"
                closable={false}
                footer={''}
            >
                <Card className="content">
                    <Row gutter={[16, 16]}>
                        <Col className="rejectFlag">
                            <RejectFlag />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col className="refundMessageError" lg={24} md={24} sm={24} xs={24}>
                            {t('refund-management.refundErrorMsg')}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="messageDetail" lg={20} md={20} sm={24} xs={24}>
                            {t('refund-management.messageDetail', { orderId: currentRefundInfo?.RequestNumber })}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Formik
                                enableReinitialize
                                initialValues={{ selectedIban: '' }}
                                onSubmit={(values) => console.log(values)}
                            >
                                {(props: FormikProps<any>) => (
                                    <SelectBankAccount
                                        formikProps={props}
                                        showAddIbanAction={true}
                                        currentRefundInfo={currentRefundInfo}
                                        isRefund={true}
                                    />
                                )}
                            </Formik>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <RefundTerm termsValidationsHandler={setTermsValidations} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            {errorList && (
                                <Alert variant={AlertVariants.Danger}>{errorList.map((error) => error)}</Alert>
                            )}
                            {showtermsValidation && (
                                <Alert variant={AlertVariants.Danger}>{t('refund-management.termsValidation')}</Alert>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <MojButton
                                loading={loaderStore.isLoading}
                                onClick={updatePendingRefundOrder}
                                disabled={disableSubmitButton}
                                color={'success'}
                                style={{ marginTop: '15px' }}
                            >
                                {t('shared.ensureButton')}
                            </MojButton>
                        </Col>
                    </Row>
                </Card>
            </MojPopup>
        </Row>
    );
}

export default observer(UpdateIbanModal);
