import { Col, Input, Row } from 'antd';
import { arabicToEnglishMap } from '@/common/utils/constants';
import { dollarUSLocale } from '@/common/utils/numbers-utils';
import { Card } from '../Card/Card';

interface MinMaxField {
    value?: number | null;
    placeholder: string;
    onChange: (e) => void;
    onBlur: (e?) => void;
}

interface MinMaxProps {
    min: MinMaxField;
    max: MinMaxField;
    title: string;
}

export const MinMax = ({ min, max, title }: MinMaxProps) => {

    const handleReadableNumber = (value: number | undefined) => {
        if (!value) {
            return undefined;
        }

        return dollarUSLocale.format(value ?? 0);
    }

    const minOnChange = (e) => {
        let value = e.target.value;
        value = value.includes(',') ? value.replaceAll(",", "") : value;
        e.target.value = value;
        min.onChange(e);
    }

    const maxOnChange = (e) => {
        let value = e.target.value;
        value = value.includes(',') ? value.replaceAll(",", "") : value;
        e.target.value = value;
        max.onChange(e);
    }

    return (
        <Card className="section__container">
            <Row justify="start" align="middle">
                <Col flex="auto">
                    <Card.Head className="section__title">{title}</Card.Head>
                    <Card.Body>
                        <Row gutter={[16, 0]} justify="start" align="middle">
                            <Col flex="1">
                                <Input
                                    allowClear
                                    value={handleReadableNumber(min.value ?? undefined)}
                                    onChange={minOnChange}
                                    onBlur={min.onBlur}
                                    className="moj-input moj-input--success moj-input--fix-border"
                                    type="text"
                                    inputMode="numeric"
                                    placeholder={min.placeholder}
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key) && !arabicToEnglishMap[event.key]) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Col>
                            <Col flex="1">
                                <Input
                                    allowClear
                                    value={handleReadableNumber(max.value ?? undefined)}
                                    onChange={maxOnChange}
                                    onBlur={max.onBlur}
                                    className="moj-input moj-input--success moj-input--fix-border"
                                    type="text"
                                    inputMode="numeric"
                                    placeholder={max.placeholder}
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key) && !arabicToEnglishMap[event.key]) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};
