import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorDetails} from "@common/models/GenericResponse";
import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';
import "./ErrorAlert.scss";
import {Tooltip} from "antd";
import {MojPopup} from "@core/components/shared";


type IProps = {
    errorDetail: ErrorDetails;
    correlationId?: string;
}

const ErrorAlert = ({errorDetail, correlationId}: IProps) => {
    const {t} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <div className="error-detail-alert">
                {
                    errorDetail.ErrorCode && (
                        <span className="error-code">{`${t('shared.error')} ${errorDetail.ErrorCode} `}: </span>
                    )
                }

                <span className="error-desc">{errorDetail.ErrorDescription}</span>
                {(errorDetail.ErrorDescription.includes("0104") || errorDetail.ErrorCode === "0104") && // REX-2031
                    <a className="error-link" href="https://srem.moj.gov.sa/deedsequence" target="_blank" rel="noreferrer">تقديم طلب دراسة صك</a>
                }

                {
                    errorDetail.Exception && (
                        <span className="error-details-icon">
                            <Tooltip title={t('shared.view-error-details')}>
                                <InfoIcon onClick={() => setIsModalVisible(true)} />
                            </Tooltip>
                        </span>
                    )
                }
            </div>

            <MojPopup
                open={isModalVisible}
                title={t('shared.error-details')}
                onOk={() => {
                    setIsModalVisible(false);
                }}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                closable={true}
            >

                <div className="error-details-content">
                    <p className="correlation-id">Correlation Id: {correlationId}</p>
                    <p>
                        <span>{errorDetail.ErrorDescription}</span>
                    </p>
                    <p className="error-exception">
                        <strong>Error Exception:</strong>
                        <code>{errorDetail.Exception}</code>
                    </p>
                </div>

            </MojPopup>
        </>
    )
}

export default ErrorAlert;
