import React, { HTMLAttributes } from 'react';
import './_Label.scss';
import clsx from 'clsx';

export interface LabelProps extends HTMLAttributes<HTMLDivElement> {
    color: LabelVariant;
}

export enum LabelVariant {
    success = 'success',
    danger = 'danger',
    primary = 'primary',
    warning = 'warning',
    warningDark = 'warningDark',
    warningLight = 'warningLight',
    label = 'label',
    positive = 'positive',
    negative = 'negative',
    user = 'user',
    system = 'system',
    trans = 'transparent',
    draft = 'draft'
}

const MojLabel = ({ color, children, className, ...rest }: LabelProps) => {
    return (
        <div className={clsx(className, 'label', `label--${color ? color : LabelVariant.label}`)} {...rest}>
            {children}
        </div>
    );
};

export default MojLabel;
