import React from 'react';
import './_MojRadioButton.scss';
import { Radio, RadioProps } from 'antd';

export type MojRadioButtonProps = {};

const MojRadioButton = ({ className, children, ...props }: RadioProps) => {
    return (
        <Radio.Button className="MojRadioButton" {...props}>
            {children}
        </Radio.Button>
    );
};

export default MojRadioButton;
