export enum OtpRequestOperationType {
    Create = 1,
    Accept = 2,
    Reject = 3,
    Ignore = 4, //stop notifications
    update = 5,
    UpdateVat = 6,
    Bid = 7,
    Messaging = 8,
}
