import React, { useId } from "react";
import { Card } from "../Card/Card";
import { Col, Row, Select, Spin } from 'antd';
import { useTranslation } from "react-i18next";

interface AddressFormProps {
    fetching: boolean,
    address: {
        onSearch: (e?) => void,
        value?: string,
        onSelect: (e, v) => void,
        options: any[],
        show
    },
    plan: {
        show: boolean,
        onSearch: (string) => void,
        value?: string,
        disabled: boolean,
        onSelect: (e, v) => void
        options: any[],
    },
    land: {
        show: boolean,
        onSearch: (e) => void,
        disabled: boolean,
        onSelect: (e, v) => void,
        options: any[]
    }
}
export function AddressForm({
    address,
    plan,
    land,
    fetching
}: AddressFormProps) {
    const { t } = useTranslation();
    const id = useId();

return <Card className="section__container">
<Row justify="start">
    <Col span="24">
        <Card.Head className="section__title">{t('shared.realestate-address')}</Card.Head>
        <Card.Body>
            <Row gutter={[0, 0]} justify="start" align="middle">
                <Col span={24} className={'border-bottom'}>
                    {address.show && (
                        <Select
                            showSearch
                            className="moj-input moj-input--success location-search"
                            placeholder="البحث بالمدينة او الحي"
                            onSearch={address.onSearch}
                            showArrow={true}
                            autoClearSearchValue
                            value={address.value}
                            filterOption={false}
                            onSelect={address.onSelect}
                            defaultActiveFirstOption
                            optionFilterProp="children"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                        >
                            {address.options.map((option, i) => {
                                return (
                                    <Select.Option
                                        value={id + i + option.AreaSerial}
                                        key={id + i + option.AreaSerial}
                                        AreaLevel={option.AreaLevel}
                                        cityName={option.CityName}
                                        cityCode={option.CityCode}
                                        fullName={option.Full}
                                        districtName={option.DistrictName}
                                        AreaSerial={option.AreaSerial}
                                        districtCode={option.DistrictCode}
                                        RegionCode={option.RegionCode}
                                    >
                                        {option.Full}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Col>
            </Row>
            <Row gutter={[16, 0]} justify="start" align="middle">
                <Col flex="1">
                    {plan.show && (
                        <Select
                            showSearch
                            className="moj-input moj-input--success"
                            placeholder={t('shared.select-plan-number')}
                            onSearch={plan.onSearch}
                            showArrow={true}
                            disabled={plan.disabled}
                            filterOption={false}
                            onSelect={plan.onSelect}
                            defaultActiveFirstOption={false}
                            optionFilterProp="children"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                        >
                            {(plan.options || []).map((option, index) => {
                                return (
                                    <Select.Option
                                        value={option.Code}
                                        key={`${option.Code}-${index}`}
                                        code={option.Code}
                                        parentCode={option.ParentCode}
                                    >
                                        {option.FullName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Col>
                <Col flex="1">
                    {land.show && (
                        <Select
                            showSearch
                            className="moj-input moj-input--success"
                            placeholder={t('shared.select-plot-number')}
                            onSearch={land.onSearch}
                            showArrow={true}
                            disabled={land.disabled}
                            filterOption={false}
                            onSelect={land.onSelect}
                            defaultActiveFirstOption={false}
                            optionFilterProp="children"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                        >
                            {(land.options || []).map((option, index) => {
                                return (
                                    <Select.Option
                                        value={option.Serial}
                                        key={`${option.Serial}-${index}`}
                                        serial={option.FullName}
                                    >
                                        {option.FullName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Col>
            </Row>
        </Card.Body>
    </Col>
</Row>
</Card>

}