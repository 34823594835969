import { action, makeObservable, observable } from 'mobx';
import { AggregatedStat } from '@common/models/AggragatedStats';
import { GetGeneralStatsInput } from '@common/models/GeneralStats.input';
import { PeriodCategory } from '@common/utils/temp.utils';
import { Totals } from '@common/models/Totals';
import { ITransaction } from '@common/models/ITransaction';
import { TrendingList } from '@common/models/Trending';
import { DealEntry } from '@common/models/DealEntry';
import { SremVMIndex } from '@/common/models/SremIndex';

export default class DashboardStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    pausedLiveStream = false;

    @observable
    aggregatedStatsGroup: AggregatedStat[] = [];
    @observable
    selectedLevelDealEntryList: ITransaction[] = [];
    @observable
    trendingList: TrendingList = { TrendingDistricts: [], TrendingDistrictsPerCity: [] };
    
    @observable
    deals: DealEntry[] = [];
    @observable
    total: Totals = {
        TotalPrices: 0,
        TotalAreas: 0,
        MinPrice: 0,
        MaxPrice: 0,
        BestBid: 0,
        BestAsk: 0,
        Count: 0,
    };

    @observable
    showPercentage = false;

    @observable
    selectedTimeFrame: GetGeneralStatsInput = {
        areaType: 'C',
        areaSerial: 1,
        periodCategory: PeriodCategory.Day,
        period: 1,
    };

    @observable
    vmIndexes: SremVMIndex[] = [];

    @action
    pauseLiveStream = () => {
        this.pausedLiveStream = true;
    };

    @action
    resumeLiveStream = () => {
        this.pausedLiveStream = false;
    };

    @action
    updateShowPercentage = (flag: boolean) => {
        this.showPercentage = flag;
    };

    @action
    setAggregatedStat = (stats: AggregatedStat[]): void => {
        this.aggregatedStatsGroup = stats.sort((a, b) => +new Date(a.AggregationDate) - +new Date(b.AggregationDate));
    };
    @action
    setTotals = (totals: Totals): void => {
        this.total = totals;
    };
    @action
    setSelectedLevelDeals = (deals: ITransaction[]): void => {
        this.selectedLevelDealEntryList = deals;
    };
    @action
    setTrends = (tending: TrendingList): void => {
        this.trendingList = tending;
    };

    @action
    addNewDeal = (deal: DealEntry): void => {
        if (this.pausedLiveStream) {
            return;
        }
        const temp: DealEntry[] = this.deals;
        const isExist = temp.find((value) => value.id === deal.id);

        if (isExist) {
            return;
        }
        temp.unshift(deal);
        const list = temp.sort((a, b) => +new Date(a.date) - +new Date(b.date)).reverse();
        if (list.length > 5) list.pop();
        this.deals = [...list];
        //Insert into selected level deals when same to the selected area
        // if ( deal.districtCode === this.selectedArea.areaSerial ) {
        //     this.selectedLevelDealEntryList.unshift(
        //         {
        //             Id: deal.id,
        //             TransAmount: deal.price,
        //             TransDate: deal.date,
        //             TransArea: deal.area,
        //             RegionSerial: deal.regionCode,
        //             RegionName: deal.regionName,
        //             CitySerial: deal.cityCode,
        //             CityName: deal.cityName,
        //             NHSerial: deal.districtCode,
        //             NHName: deal.districtName,
        //             PlanSerial: 0,
        //             PlanText: '',
        //             Plan: deal.planNumber,
        //             LandNumber: deal.landNumber,
        //             Change: deal.change,
        //             CreatedAt: deal.date.toString(),
        //             PricePerMeterSquare: deal.pricePerMeterSquare
        //         }
        //     );
        // }
        return;
    };

    @action
    setVMIndexes = (list: SremVMIndex[]): void => {
        this.vmIndexes = list;
    }
}

export const dashboardStore = new DashboardStore();
