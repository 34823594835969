import React, {useEffect} from 'react';
import {Col, Input, Row, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {FormikProps, FormikValues} from 'formik';
import {ReactComponent as DropdownIcon} from '@/core/assets/images/assets-icons-arrow-down.svg';
import {Card, Tabs} from '@core/components/shared';
import {MojFormItem} from '@core/components/shared/MojFormItem/MojFormItem';
import {PlainLabel, PlainLabelVariant} from '@core/components/shared/PlainLabel/PlainLabel';
import DatePickerInputText from "@core/components/shared/DatePickerInputText/DatePickerInputText";
import MojNationalIdInput from "@core/components/shared/MojNationalIdInput/MojNationalIdInput";
import {convertToEnglishNumbers} from "@common/utils/numbers-utils";
import {NationalIdTypes} from "@common/enums/national-id-types";
import './_MortgageInquiryForm.scss';

export type DeedInquiryFormProps = {
    formikProps: FormikProps<FormikValues>;
};

const MortgageInquiryForm = ({ formikProps }: DeedInquiryFormProps) => {
    const { values, errors, getFieldProps, touched, setFieldValue } = formikProps!!;

    const { t } = useTranslation();
    const [inquiryType, setInquiryType] = React.useState(values ? values['validationType'] : 0);

    const onTabChange = (tabId: number) => {
        setInquiryType(tabId);
        setFieldValue('validationType', tabId);
    };

    useEffect(() => {
        setInquiryType(0);
    }, []);

    const getDateValue = () => {
        return (formikProps.values['year'] ? formikProps.values['year'] : '') + '-' + (formikProps.values['month'] ? String(formikProps.values['month']) : '') + '-' + (formikProps.values['day'] ? String(formikProps.values['day']) : '');
    }

    if (!values) {
        return <></>;
    }

    let deedNumberProps = {...getFieldProps('deedNumber')};
    const deedNumberFieldProps = {...deedNumberProps, onChange: e => {
            if (e.target.value) {
                e.target.value = convertToEnglishNumbers(e.target.value);
            }

            deedNumberProps.onChange(e);
        }}

    return (
        <>
            <Card className="section__container">
                <Row gutter={[16, 0]}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card.Head className="section__title border-bottom">
                            <Col flex="1 1 auto">{t('shared.deed-info')}</Col>
                        </Card.Head>
                    </Col>
                    <Col span="24">
                        <MojFormItem
                            label={t('shared.deed-number')}
                            htmlFor="deedNumber"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            validateStatus={errors['deedNumber'] && 'error'}
                            help={touched['deedNumber'] && errors['deedNumber']}
                        >
                            <Input
                                maxLength={12}
                                allowClear
                                inputMode={'numeric'}
                                id="deedNumber"
                                placeholder={t('shared.enter-deed-number')}
                                {...deedNumberFieldProps}
                            />
                        </MojFormItem>
                    </Col>
                    <Col span={24} className="inquiry-type">
                        <Tabs
                            className="divide-ownership__tabs"
                            value={inquiryType}
                            options={[
                                {
                                    value: 0,
                                    label: t('deed-inquiry.deed-number-belongs-to-owner'),
                                    disabled: false,
                                },
                                {
                                    value: 1,
                                    label: t('common.deed-date'),
                                },
                            ]}
                            onChange={(e) => onTabChange(e.target.value)}
                        ></Tabs>
                    </Col>
                    {inquiryType === 0 ? (
                        <>
                            <Col span={12}>
                                <MojFormItem
                                    label={t('shared.id-type')}
                                    htmlFor="idType"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    validateStatus={errors['idType'] && 'error'}
                                    help={touched['idType'] && errors['idType']}
                                >
                                    <Select
                                        id="idType"
                                        defaultValue={1}
                                        value={values.idType}
                                        suffixIcon={<DropdownIcon />}
                                        onSelect={(value: any, option: any) => {
                                            setFieldValue('idType', value);
                                        }}
                                    >
                                        <Select.Option value={NationalIdTypes.NATIONAL_ID}>{t('shared.national-id')}</Select.Option>
                                        <Select.Option value={NationalIdTypes.IQAMA_ID}>{t('shared.iqama-id')}</Select.Option>
                                        <Select.Option value={NationalIdTypes.FACILITY_NUMBER}>{t('shared.facility-number')}</Select.Option>
                                    </Select>
                                </MojFormItem>
                            </Col>

                            <Col span="12">
                                <MojNationalIdInput formik={formikProps} fieldLabel={t('shared.id-number')} fieldName='idNumber' placeholder={t('shared.enter-national-id')} />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col span="24">
                                <PlainLabel
                                    title={t('shared.deed-date')}
                                    label={''}
                                    variant={PlainLabelVariant.primary}
                                    small
                                    noBorder
                                />
                            </Col>

                            <DatePickerInputText formikProps={formikProps} dateValue={getDateValue()} />

                            {/*<Col span="8">*/}
                            {/*    <MojFormItem*/}
                            {/*        htmlFor="day"*/}
                            {/*        labelCol={{ span: 24 }}*/}
                            {/*        wrapperCol={{ span: 24 }}*/}
                            {/*        validateStatus={errors['day'] && 'error'}*/}
                            {/*        help={touched['day'] && errors['day']}*/}
                            {/*    >*/}
                            {/*        <Select*/}
                            {/*            placeholder={t('calendar.day')}*/}
                            {/*            onSelect={(_value, option) => {*/}
                            {/*                setFieldValue('day', _value);*/}
                            {/*            }}*/}
                            {/*            showSearch*/}
                            {/*        >*/}
                            {/*            {days.map((value, index) => {*/}
                            {/*                return (*/}
                            {/*                    <Select.Option value={value} key={value}>*/}
                            {/*                        {value}*/}
                            {/*                    </Select.Option>*/}
                            {/*                );*/}
                            {/*            })}*/}
                            {/*        </Select>*/}
                            {/*    </MojFormItem>*/}
                            {/*</Col>*/}
                            {/*<Col span="8">*/}
                            {/*    <MojFormItem*/}
                            {/*        htmlFor="month"*/}
                            {/*        labelCol={{ span: 24 }}*/}
                            {/*        wrapperCol={{ span: 24 }}*/}
                            {/*        validateStatus={errors['month'] && 'error'}*/}
                            {/*        help={touched['month'] && errors['month']}*/}
                            {/*    >*/}
                            {/*        <Select*/}
                            {/*            placeholder={t('calendar.month')}*/}
                            {/*            onSelect={(_value, option) => {*/}
                            {/*                setFieldValue('month', _value);*/}
                            {/*            }}*/}
                            {/*            showSearch*/}
                            {/*        >*/}
                            {/*            {months.map((value, index) => {*/}
                            {/*                return (*/}
                            {/*                    <Select.Option value={value.number} key={value.number}>*/}
                            {/*                        {value.name}*/}
                            {/*                    </Select.Option>*/}
                            {/*                );*/}
                            {/*            })}*/}
                            {/*        </Select>*/}
                            {/*    </MojFormItem>*/}
                            {/*</Col>*/}
                            {/*<Col span="8">*/}
                            {/*    <MojFormItem*/}
                            {/*        htmlFor="year"*/}
                            {/*        labelCol={{ span: 24 }}*/}
                            {/*        wrapperCol={{ span: 24 }}*/}
                            {/*        validateStatus={errors['year'] && 'error'}*/}
                            {/*        help={touched['year'] && errors['year']}*/}
                            {/*    >*/}
                            {/*        <Select*/}
                            {/*            placeholder={t('calendar.year')}*/}
                            {/*            onSelect={(_value, option) => {*/}
                            {/*                setFieldValue('year', _value);*/}
                            {/*            }}*/}
                            {/*            showSearch*/}
                            {/*        >*/}
                            {/*            {deedYears.map((value, index) => {*/}
                            {/*                return (*/}
                            {/*                    <Select.Option value={value} key={value}>*/}
                            {/*                        {value}*/}
                            {/*                    </Select.Option>*/}
                            {/*                );*/}
                            {/*            })}*/}
                            {/*        </Select>*/}
                            {/*    </MojFormItem>*/}
                            {/*</Col>*/}
                        </>
                    )}
                </Row>
            </Card>
        </>
    );
};
export default MortgageInquiryForm;
