export interface CompanyInfo {
    socialId: number;
    socialIdType: number;
    name: string;
    birthDateHijri: number;
    mobileNo: string;
    gender: string;
    firstName: string;
    fatherName: string;
    grandFatherName: string;
    familyName: string;
}
export interface PersonInfo {
    SocialId: number;
    SocialIdType: number;
    FirstName: string;
    FatherName: string;
    GrandFatherName: string;
    FamilyName: string;
    MobileNo?: any;
    BirthDateHijri: number;
    Gender: string;
    SocialIdExpiryDateHijri: number;
    SocialIdIssuedDateHijri: number;
    SocialIdIssuedPlaceCode: number;
    SocialIdIssuedPlace?: any;
    NationalityName?: any;
    HafezaNumber: number;
    IsDead: number;
    Name: string;
    NationalityCode: number;
    Email?: any;
}

export interface SupportedAction {
    ActionId: number;
    ActionText: string;
}

export interface Control {
    controlID: number;
    controlLable: string;
    controlAttorneyTypeName: string;
}

export interface Attorney {
    SessionId?: string;
    CompanyInfo?: CompanyInfo;
    Controls?: Control[];
    PersonInfo?: PersonInfo;
    DelegatorType?: number;
}

export interface AttorneyItem {
    Id: string;
    AttorneyCode: number;
    Delegator: string;
    DelegatorId: string;
    ExpiryDate?: Date;
    DelegatorType: number;
    SupportedActions: SupportedAction[];
}

export interface AttorneyDelegator {
    FullName: string;
    IdentityNo: string;
    DateOfBirth: string;
    PersonType: PersonType;
    HasInvalidBirthDate: false;
}

export enum DelegatorTypes {
    Person = 1,
    Organisation = 2,
    Heir = 3,
    Establishment = 4,
    Government = 5,
}

export enum PersonType {
    UnKnown,
    Deceased,
    Heir,
    Delegator,
    Delegatee,
}

export type AddHeirsPayload = {
    attorneyCode: string;
    delegatorId: string;
    iban: string;
    heirsCode?: number;
    delegatorDob?: string;
    missingDobs: MissingDobs[];
};

export type MissingDobs = {
    dateOfBirth: string;
    idNumber: string;
    PersonType: PersonType
}