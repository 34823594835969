import { FunctionComponent, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import camelcaseKeys from 'camelcase-keys';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import {
    APIProvider,
    Map,
    MapControl,
    ControlPosition,
    useMap,
    AdvancedMarker,
} from '@vis.gl/react-google-maps';

import realestateAndDealsService from '@common/services/realestates-and-deals.service';
import { loaderStore } from '@/common/stores/loader.store';
import GenericResponse from '@/common/models/GenericResponse';
import { DEFAULT_CENTER, realestateAndDealsAdvancedStore } from '@/common/stores/realestate-and-deals-advanced.store';
import { BOUNDS_OBJECT, INITIAL_VIEW_STATE, MAP_LNG_ADJUSTMENT, MAP_LOAD_PROPERTIES_THROTTLE, center } from '@/common/utils/constants';
import { observer } from 'mobx-react-lite';

import './_RealStateMap.scss';
import { setDefaults, OutputFormat } from 'react-geocode';
import { MAP_LAT_LNG_SORTER, fixPolygon, getIntersection, hexToRgbA, sortCoordinatesByMagnitude, transformData } from './mapHelperFunctions';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import { useTranslation } from 'react-i18next';
import { nFormatter } from '../../utils/open-market.utils';
import { featureOptions } from '../MapViewLegend/MapViewLegend';
import clsx from 'clsx';
import MapViewLegendAdvanced from '../../../../core/components/shared/MapViewLegendAdvanced/MapViewLegendAdvanced';

export type SortingType = {
    sortType: number;
    sortField: number;
};

const getColorByStatus = (d: any) => {
    const [r, g, b, o] = (d?.properties?.polygonColor || d?.properties?.lineColor) ? hexToRgbA(d?.properties?.polygonColor || d?.properties?.lineColor) : [0, 255, 255, -1];
    const opacity = o === -1 ? 80 : 140;
    return [r, g, b, opacity];
};
const getLineColor = (d: any) => {
    return d?.geometry?.type === 'Point'
        ? [160, 160, 180, 0]
        : d?.properties?.lineColor ? hexToRgbA(d?.properties?.lineColor) : [0, 255, 255, 255];
};
const getText = (d: any) => {
    if (d?.geometry?.type === 'Point') {
        return d?.properties?.text;
    } else if (d.realEstateIdentityNo) {
        return d.realEstateIdentityNo;
    }
    return null;
};
const getAppBaseURL = () => {
    const fullURL = window.location.href;
    const realtivePath = window.location.pathname;
    return fullURL.replace(realtivePath, '');
};
const getIcon = (d: any) => {
    const color = d?.properties?.openMarketOrders ? 'yellow' : d?.properties?.purchaseOrders ? 'green' : 'pink';
    return {
        url: `${getAppBaseURL()}/${color}.svg`,
        width: 20,
        height: 20,
    };
};
const getRealStateData = async (coordinates: object, zoom: number | undefined) => {
    const filters = { ...realestateAndDealsAdvancedStore.realestateFilters };
    // @ts-ignore
    delete filters.locationSearchTerm;
    if (filters.hasOwnProperty('isOpenMarket')) {
        delete filters['isOpenMarket'];
    }
    if (filters.hasOwnProperty('isRealEstate')) {
        delete filters['isRealEstate'];
    }
    const randomData = zoom &&
        zoom < 15 &&
        (realestateAndDealsAdvancedStore.realestateFilters?.realEsateIdentity === null ||
            realestateAndDealsAdvancedStore.realestateFilters?.realEsateIdentity === undefined);

    let response = await realestateAndDealsService.getRealestatesMapData(
        realestateAndDealsAdvancedStore.realestateOptions.includes(0),
        realestateAndDealsAdvancedStore.realestateOptions.includes(1),
        realestateAndDealsAdvancedStore.realestateOptions.includes(2),
        coordinates,
        { ...filters, isWithCoordinates: true },
        {
            randomData: !!randomData,
            mapViewType: realestateAndDealsAdvancedStore.mapViewType,
        },
    );
    if (response?.IsSuccess!! && response?.Data!!) {
        return camelcaseKeys(sortCoordinatesByMagnitude(response.Data), { deep: true });
    }
};

const updateInfoWindowWithPrice = (features: any[]) => {
    const featuresWithPrice = features?.map((feature) => {
        let base: any = {};
        if (feature.geometry && feature.geometry.coordinates && feature.properties.openMarketOrders) {
            // feature.geometry && feature.geometry.coordinates && feature.properties.openMarketOrders
            if (feature.geometry.coordinates[0][0]) {
                let coordinatesAtZero = 0;
                let coordinatesAtOne = 0;


                feature.geometry.coordinates[0].forEach((coordinate) => {
                    coordinatesAtZero += coordinate[0];
                    coordinatesAtOne += coordinate[1];
                });
                base.coordinates = [coordinatesAtZero / feature.geometry.coordinates[0].length, coordinatesAtOne / feature.geometry.coordinates[0].length];
                base.backgroundColor = feature.properties.lineColor;
                base.textColor = feature.properties.backgroundColor;
                base.text = !!feature.properties.openMarketPrice ? nFormatter(feature.properties.openMarketPrice, 2) : "";
            }
        }

        return base;
    }
    );
    realestateAndDealsAdvancedStore.featuresWithPrice = (featuresWithPrice || []).filter((feature) => feature.text && feature.coordinates);
}
const getLayers = (fetchedData: any, reIdentityPoints: any, zoom, opacity, checkOverlapByIdentity) => {
    let intersections: any[] = [];
    if (fetchedData?.features || reIdentityPoints?.length > 0) {
        intersections = getIntersection(transformData(fixPolygon(reIdentityPoints)), fetchedData, checkOverlapByIdentity);
    }

    // const data = fetchedData && fetchedData.features ? {...fetchedData, features: [...fetchedData.features, ...transformData(reIdentityPoints).features] } : transformData(reIdentityPoints);
    // if(intersections?.length > 0) {
    //     data.features = [...data.features, ...intersections];
    // }
    const saudiArabiaLabel = [{ text: 'Saudi Arabia', coordinates: [center?.lng, center?.lat] }];
    const textLayer: any = new TextLayer({
        id: 'text-layer',
        data: [...saudiArabiaLabel],
        pickable: true,
        opacity: opacity || 1,
        getPosition: (d: { text: string; coordinates: Array<number> }) => d.coordinates as any,
        getText: (d: { text: string; coordinates: Array<number> }) => d.text,
        getSize: 18,
        getColor: [0, 0, 0],
        textCharacterSet: 'auto',
        getBackgroundColor: [33, 43, 53],
        sizeUnits: 'pixels',
    });
    const geoJsonLayer: any = new GeoJsonLayer({
        id: 'geojson-layer',
        // @ts-ignore
        data: fetchedData,
        opacity: 1,
        pickable: true,
        filled: true,
        stroked: true,
        pointType: zoom && zoom < 15 ? 'icon' : 'circle+text',
        getFillColor: (d: any) => {
            return getColorByStatus(d) as any;
        },
        getLineColor: (d: any) => {
            return getLineColor(d) as any;
        },
        getLineWidth: () => 3,
        lineWidthUnits: 'pixels',
        getPointRadius: () => 0,
        getText:
            zoom
                ? (d: any) => {
                    return getText(d) || '';
                }
                : () => '',
        textFontFamily: 'IBM Plex Sans Arabic',
        textFontWeight: 500,
        textCharacterSet: 'auto',
        getTextColor: (d) => d?.properties?.lineColor ? hexToRgbA(d?.properties?.lineColor) as any : [238, 244, 240, 255],
        getTextBackgroundColor: () => [33, 43, 53],
        getTextSize: () => 18,
        getIcon: (d: any) => getIcon(d),
        getPosition: (d: { coordinates: Array<number> }) => {
            return d.coordinates;
        },
        getIconSize: () => 20,
        iconSizeUnits: 'pixels',
        // onHover: updateTooltip,
    });

    const reIdentityLayer: any = new GeoJsonLayer({
        id: 'reIdentity-layer',
        // @ts-ignore
        data: transformData(reIdentityPoints),
        opacity: opacity || 1,
        pickable: true,
        filled: true,
        stroked: true,
        pointType: zoom && zoom < 15 ? 'icon' : 'circle+text',
        getFillColor: (d: any) => {
            return getColorByStatus(d) as any;
        },
        getLineColor: (d: any) => {
            return getLineColor(d) as any;
        },
        getLineWidth: () => 3,
        lineWidthUnits: 'pixels',
        getPointRadius: () => 0,
        getText:
            zoom
                ? (d: any) => {
                    return getText(d) || '';
                }
                : () => '',
        textFontFamily: 'IBM Plex Sans Arabic',
        textFontWeight: 500,
        textCharacterSet: 'auto',
        getTextColor: (d) => d?.properties?.lineColor ? hexToRgbA(d?.properties?.lineColor) as any : [238, 244, 240, 255],
        getTextBackgroundColor: () => [33, 43, 53],
        getTextSize: () => 18,
        getIcon: (d: any) => getIcon(d),
        getPosition: (d: { coordinates: Array<number> }) => {
            return d.coordinates;
        },
        getIconSize: () => 20,
        iconSizeUnits: 'pixels',
        // onHover: updateTooltip,
    });

    const intersectionLayer: any = new GeoJsonLayer({
        id: 'intersection-layer',
        // @ts-ignore
        data: intersections || [],
        pickable: true,
        filled: true,
        stroked: true,
        opacity: opacity || 1,
        pointType: zoom && zoom < 15 ? 'icon' : 'circle+text',
        getFillColor: (d: any) => {
            return getColorByStatus(d) as any;
        },
        getLineColor: (d: any) => {
            return getLineColor(d) as any;
        },
        getLineWidth: () => 3,
        lineWidthUnits: 'pixels',
        getPointRadius: () => 0,
        getText:
            zoom && zoom > 17
                ? (d: any) => {
                    return getText(d) || '';
                }
                : () => '',
        textFontFamily: 'IBM Plex Sans Arabic',
        textFontWeight: 500,
        textCharacterSet: 'auto',
        getTextColor: (d) => d?.properties?.lineColor ? hexToRgbA(d?.properties?.lineColor) as any : [238, 244, 240, 255],
        getTextBackgroundColor: () => [33, 43, 53],
        getTextSize: () => 18,
        getIcon: (d: any) => getIcon(d),
        getPosition: (d: { coordinates: Array<number> }) => {
            return d.coordinates;
        },
        getIconSize: () => 20,
        iconSizeUnits: 'pixels',
        // onHover: updateTooltip,
    });

    return { textLayer, geoJsonLayer, intersectionLayer, reIdentityLayer, intersections };
}
const loadRealestate = async (
    coordinates: object,
    zoom: number | undefined,
    callMap: boolean,
    callSearchByIdentity: boolean,
    setNoData: (flag: boolean) => void,
    setViewState: (viewstate: {
        latitude: number;
        longitude: number;
        zoom: number;
        pitch: number;
        bearing: number;
    }) => void,
    setShowIdentityPinPosition: (showFlag: boolean) => void,
    reIdentityPoints,
    loadElasticResults: boolean,
    checkOverlapByIdentity
) => {
    let fetchedData: any = null;
    let openMarketOrders = 0;
    let purchaseOrders = 0;
    let realestates = 0;

    if (loadElasticResults) {
        if (
            realestateAndDealsAdvancedStore.realestateFilters?.realEsateIdentity &&
            !isNaN(realestateAndDealsAdvancedStore.realestateFilters?.realEsateIdentity as number) &&
            callSearchByIdentity
        ) {
            const identity = realestateAndDealsAdvancedStore.realestateFilters?.realEsateIdentity;
            realestateAndDealsAdvancedStore.resetFilters();
            realestateAndDealsAdvancedStore.updateFilters({
                ...realestateAndDealsAdvancedStore.realestateFilters,
                realEsateIdentity: identity,
                realEstateIdentities: [identity.toString()],
            });
            realestateAndDealsAdvancedStore.updateRealestatesOptions([0, 1, 2]);
            realestateAndDealsAdvancedStore.setMapViewType(null);

            fetchedData = await getRealStateData(coordinates, zoom);
            updateInfoWindowWithPrice(fetchedData?.features);
            openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
            purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
            realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;

            if (fetchedData?.features?.length === 0) {
                setNoData(true);
                setShowIdentityPinPosition(false);
                fetchedData = fetchedData?.features;
                openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
            } else {
                let centerPointCoords = fetchedData?.features?.filter((feature) => feature?.geometry?.type === 'Point')[0]
                    ?.geometry?.coordinates;
                setViewState({
                    latitude: centerPointCoords?.[1],
                    longitude: centerPointCoords?.[0],
                    zoom: 17,
                    pitch: 0,
                    bearing: 0,
                });
                realestateAndDealsAdvancedStore.setSearchByIdentityPosition({
                    lat: centerPointCoords?.[1],
                    lng: centerPointCoords?.[0],
                });
                setShowIdentityPinPosition(true);
            }
        } else {
            if ((zoom || 5) >= 4) {
                if ((zoom || 1) === 4 && realestateAndDealsAdvancedStore.pointerFeatures === null) {
                    fetchedData = await getRealStateData(coordinates, zoom);
                    openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                    purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                    realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
                    updateInfoWindowWithPrice(fetchedData?.features);
                    realestateAndDealsAdvancedStore.setPointersFeatures(fetchedData);
                    realestateAndDealsAdvancedStore.setDataMessage(null);
                    setShowIdentityPinPosition(false);
                } else if ((zoom || 1) < 18 && realestateAndDealsAdvancedStore.pointerFeatures) {
                    fetchedData = realestateAndDealsAdvancedStore.pointerFeatures;
                    openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                    purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                    realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
                    realestateAndDealsAdvancedStore.setDataMessage(null);
                    setShowIdentityPinPosition(false);
                    realestateAndDealsAdvancedStore.featuresWithPrice = [];
                } else {
                    fetchedData = await getRealStateData(coordinates, zoom);
                    updateInfoWindowWithPrice(fetchedData?.features);
                    openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                    purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                    realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;

                    if (fetchedData?.features?.length === 0) {
                        if (callMap) {
                            setNoData(true);
                        }
                        fetchedData = fetchedData?.features;
                        openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                        purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                        realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
                        setShowIdentityPinPosition(false);
                    } else {
                        realestateAndDealsAdvancedStore.setDataMessage(`${fetchedData?.features?.length / 2}`);
                    }
                }
                setTimeout(() => {
                    setNoData(false);
                }, 7000);
            }
        }
    }

    const { textLayer, geoJsonLayer, intersectionLayer, reIdentityLayer, intersections } = getLayers(fetchedData, reIdentityPoints, zoom, 1, checkOverlapByIdentity);
    return { layers: [geoJsonLayer, textLayer, intersectionLayer, reIdentityLayer], counts: { openMarketOrders: Math.ceil(openMarketOrders / 2), purchaseOrders: Math.ceil(purchaseOrders / 2), realestates: Math.ceil(realestates / 2) }, fetchedData, reIdentityData: transformData(reIdentityPoints), intersections };
};

export const DeckGlOverlay = (props: any) => {
    let {
        callMap,
        setCallMap,
        setNoData,
        callSearchByIdentity,
        setCallSearchByIdentity,
        setViewState,
        setShowIdentityPinPosition,
        locateMe,
        realEstateTypes,
        mapViewType,
        setCounts,
        reIdentityPoints,
        loadElasticResults,
        blink,
        checkOverlapByIdentity,
        setZoomLvl
    } = props;
    const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);
    const map = useMap("e1d0c7dfc80cc429");
    const [firstLoaded, setFirstLoaded] = useState(false);
    const [originalLayers, setOriginalLayers] = useState();
    const [allLayers, setAllLayers] = useState<any[]>([]);
    const lastRun = useRef<number | null>();
    const [opacity, setOpacity] = useState(1);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>();

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        if (blink) {
            intervalRef.current = setInterval(() => {
                setOpacity(opacity => opacity === 1 ? 0.00001 : 1);
            }, 1000);

            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (deck && blink) {
            const { geoJsonLayer, intersectionLayer, reIdentityLayer, textLayer } = getLayers(originalLayers, reIdentityPoints, 18, opacity, checkOverlapByIdentity);
            deck.setProps({ ...props, layers: [textLayer, geoJsonLayer, reIdentityLayer, intersectionLayer] });
        }
    }, [opacity, blink, reIdentityPoints, deck]);

    useEffect(() => {
        deck.setMap(map);
        setDefaults({
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            language: 'en',
            region: 'SA',
            outputFormat: OutputFormat.JSON,
        });

        const boundsObj = BOUNDS_OBJECT;
        let bounds = new window.google.maps.LatLngBounds();
        bounds.extend({ lat: boundsObj.sw.lat, lng: boundsObj.sw.lng });
        bounds.extend({ lat: boundsObj.ne.lat, lng: boundsObj.ne.lng });
        map?.fitBounds(bounds);
        map?.setOptions({
            restriction: {
                latLngBounds: bounds,
                strictBounds: true,
            },
        });

        map?.addListener('idle', () => {
            const diff = lastRun.current ? Date.now() - lastRun.current : 1001;
            if (diff > MAP_LOAD_PROPERTIES_THROTTLE) {
                lastRun.current = Date.now();
                loadData(map?.getCenter()?.toJSON(), map?.getZoom() || 0, true);
            }
        });
        map?.addListener('zoom_changed', () => {
            realestateAndDealsAdvancedStore.setZoomLvl(map?.getZoom() || 0);
            setZoomLvl(map?.getZoom() || 0);
        });
        map?.addListener('center_changed', () => {
            const center = map?.getCenter()?.toJSON();
            realestateAndDealsAdvancedStore.setCenter(center!);
            // loadData(map?.getCenter()?.toJSON(), map?.getZoom() || 0, true);
            // setViewState(viewState => ({ ...viewState, latitude: center?.lat, longitude: center?.lng }))
        });
    }, [map]);

    const loadData = async (searchCords: any, zoom: number | undefined, loadElasticResultsOverride: boolean) => {
        const realestates = await loadRealestate(
            {
                Latitude: searchCords?.lat,
                Longitude: searchCords?.lng,
            },
            zoom,
            callMap,
            callSearchByIdentity,
            setNoData,
            setViewState,
            setShowIdentityPinPosition,
            reIdentityPoints,
            loadElasticResults && loadElasticResultsOverride,
            checkOverlapByIdentity
        );

        if (realestates.fetchedData) {
            setOriginalLayers(realestates.fetchedData);
        }
        setCounts(realestates.counts);
        setCallMap(false);
        setCallSearchByIdentity(false);
        setTimeout(() => {
            loaderStore.setLoader(false);
        }, 3000);
    };

    useEffect(() => {
        let layers: any = {};
        if (realestateAndDealsAdvancedStore.showRealestateDataOnMap) {
            layers = getLayers(originalLayers, reIdentityPoints, map?.getZoom(), 1, checkOverlapByIdentity);
        } else {
            layers = getLayers(null, reIdentityPoints, map?.getZoom(), 1, checkOverlapByIdentity);
        }
        if (layers.geoJsonLayer) {
            layers.geoJsonLayer.opacity = opacity;
        }

        if (layers.intersectionLayer) {
            layers.intersectionLayer.opacity = opacity;
        }

        setAllLayers([layers.textLayer, layers.geoJsonLayer, layers.intersectionLayer, layers.reIdentityLayer]);

        deck.setProps({ ...props, layers: [layers.textLayer, layers.geoJsonLayer, layers.reIdentityLayer, layers.intersectionLayer] });
    }, [realestateAndDealsAdvancedStore.showRealestateDataOnMap, map, originalLayers]);

    useEffect(() => {
        // loadData(map?.getCenter()?.toJSON(), map?.getZoom());
    }, [realEstateTypes, mapViewType]);
    useEffect(() => {
        // if (callMap) loadData(map?.getCenter()?.toJSON(), map?.getZoom());
    }, [callMap]);
    useEffect(() => {
        // if (callSearchByIdentity) loadData(map?.getCenter()?.toJSON(), map?.getZoom());
    }, [callSearchByIdentity]);

    useEffect(() => {
        if (reIdentityPoints && reIdentityPoints.length > 0 && reIdentityPoints[0].coordinates[0][0] && reIdentityPoints[0].coordinates[0][1]) {
            setFirstLoaded(true);
            loadData({ lat: reIdentityPoints[0].coordinates[0][1], lng: reIdentityPoints[0].coordinates[0][0] }, map?.getZoom(), !firstLoaded);
            if (!firstLoaded) {
                setTimeout(() => {
                    map?.setZoom(18);
                    map?.setCenter({ lat: reIdentityPoints[0].coordinates[0][1], lng: reIdentityPoints[0].coordinates[0][0] })
                    realestateAndDealsAdvancedStore.setZoomLvl(18);
                    setZoomLvl(18);
                    realestateAndDealsAdvancedStore.setCenter({ lat: reIdentityPoints[0].coordinates[0][1], lng: reIdentityPoints[0].coordinates[0][0] })
                }, 1000);

                setViewState(({
                    latitude: reIdentityPoints[0].coordinates[0][1],
                    longitude: reIdentityPoints[0].coordinates[0][0],
                    zoom: 10,
                    pitch: 0,
                    bearing: 0
                }));
            } else {
                const center = map?.getCenter()?.toJSON()!;

                if (center.lat !== DEFAULT_CENTER.lat && center.lng !== DEFAULT_CENTER.lng) {
                    map?.setCenter({
                        lat: center.lat - MAP_LNG_ADJUSTMENT,
                        lng: center.lng - MAP_LNG_ADJUSTMENT
                    });
                    setTimeout(() => {
                        map?.setCenter({
                            lat: center.lat,
                            lng: center.lng
                        });

                    }, 500);
                }

            }
        }
    }, [reIdentityPoints, firstLoaded, setViewState]);

    return <></>;
};

interface RealStateMapProps {
    callMap: boolean;
    setCallMap: any;
    callSearchByIdentity: boolean;
    setCallSearchByIdentity: (flag: boolean) => void;
    showModal: () => void;
    reIdentityPoints: any[];
    loadElasticResults?: boolean;
    allowUpdateCoordinates?: boolean;
    realestateIdentityError?: string;
    blinkProperties?: boolean;
    checkOverlapByIdentity?: boolean;
}
const RealStateMapAdvanced: FunctionComponent<RealStateMapProps> = ({
    callMap,
    setCallMap,
    callSearchByIdentity,
    setCallSearchByIdentity,
    reIdentityPoints: _reIdentityPoints,
    showModal,
    loadElasticResults = true,
    allowUpdateCoordinates,
    realestateIdentityError,
    blinkProperties,
    checkOverlapByIdentity
}) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState(null);
    const [position, setPosition] = useState<any>(null);
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
    const [noData, setNoData] = useState(false);
    const [mapType, setMapType] = useState(false);
    const [dataMessage, setDataMessage] = useState(null);
    const [showIdentityPinPosition, setShowIdentityPinPosition] = useState(false);
    const isMobile = useMemo(() => isMobileOrTabletDevice(), []);
    const [counts, setCounts] = useState({ openMarketOrders: 0, purchaseOrders: 0, realestates: 0 });
    const [showLegendWithNumbers, setShowLegendWithNumbers] = useState(true);
    const [reIdentityPoints, setReIdentityPoints] = useState<any[]>(_reIdentityPoints);
    const [updatingCoordinates, setUpdatingCoordinates] = useState(false);
    const [reIdentityCenter, setReIdentityCenter] = useState<{ lat: number, lng: number }>();
    const [zoomLvl, setZoomLvl] = useState(18);

    const { t } = useTranslation();

    useEffect(() => {
        if (showPopup && currentData) {
            setTimeout(() => {
                const legendWithNumbersYPosition = document.getElementById('legendWithNumbers')?.getBoundingClientRect().top;
                const popupYPosition = document.getElementById('map-info')?.getBoundingClientRect().top;
                if (legendWithNumbersYPosition && popupYPosition) {
                    // check if legendWithNumbersYPosition is within 10px (up/down) of popupYPosition
                    if (Math.abs(legendWithNumbersYPosition - popupYPosition) <= 26) {
                        setShowLegendWithNumbers(false);
                    } else {
                        setShowLegendWithNumbers(true);
                    }
                } else {
                    setShowLegendWithNumbers(true);
                }
            }, 1000);

        } else {
            setShowLegendWithNumbers(true);
        }
    }, [currentData, showPopup]);

    useEffect(() => {
        let basePoints: any[] = [];
        let pointAtZero = 0;
        let pointAtOne = 0;
        if (reIdentityPoints?.length > 0) {
            reIdentityPoints.forEach((point) => {
                basePoints = [...basePoints, ...point.coordinates];
            });
            basePoints.forEach(point => {
                pointAtZero += point[0];
                pointAtOne += point[1];
            });
            setReIdentityCenter({ lat: pointAtOne / basePoints.length, lng: pointAtZero / basePoints.length });
        }
    }, [reIdentityPoints]);

    const getRealestateTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getRealEstateTypes();

        if (response?.IsSuccess) {
            realestateAndDealsAdvancedStore.setRealestateTypes(response.Data);
            loaderStore.setLoader(false);
        }
    };
    const getMapViewTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getMapViewTypes();
        if (!response?.IsSuccess) return false;
        loaderStore.setLoader(false);
        realestateAndDealsAdvancedStore.setMapViews(camelcaseKeys(response.Data, { deep: true }));
    };

    const onREIdentityUpdate = useCallback((e, reIdentityPoint, coordinate) => {
        const _reIdentityPoints = [...reIdentityPoints];
        const targetReIdentityPoint = _reIdentityPoints.find((reIdentity) => reIdentity.realEstateIdentityNo === reIdentityPoint.realEstateIdentityNo);
        if (targetReIdentityPoint) {
            const index = _reIdentityPoints.indexOf(targetReIdentityPoint);
            const pointIndex = targetReIdentityPoint.coordinates.findIndex(index => index[0] === coordinate[0] && index[1] === coordinate[1]);
            if (pointIndex => 0) {
                _reIdentityPoints[index].coordinates[pointIndex] = [e.latLng?.lng(), e.latLng?.lat()];
                setReIdentityPoints(_reIdentityPoints);

                const payload = {
                    realEstateIdentityNo: reIdentityPoint.realEstateIdentityNo,
                    newCoordinates: _reIdentityPoints[index].coordinates.map((point, index) => ({ point_no: index + 1, latitude: point[1], longitude: point[0] }))
                };
                setUpdatingCoordinates(true);
                realestateAndDealsService.updateCoordinates(payload.realEstateIdentityNo, payload.newCoordinates).then(response => {
                    setUpdatingCoordinates(false);
                })
            }
        }
    }, [reIdentityPoints]);

    const onResetCoordinates = useCallback(() => {
        if (reIdentityPoints && reIdentityPoints.length > 0) {
            const ids = reIdentityPoints.map(reIdentity => reIdentity.realEstateIdentityNo).filter(id => !!id);
            realestateAndDealsService.resetCoordinates(ids)
                .then((response) => {
                    if (response && response.isSuccess && response.data && Object.keys(response.data).length > 0) {
                        setReIdentityPoints(Object.keys(response.data).map(key => response.data[key]?.length > 0 ? ({ realEstateIdentityNo: key, coordinates: response.data[key].map(point => [point.latitude, point.longitude].sort(MAP_LAT_LNG_SORTER)) }) : null).filter(identity => !!identity));
                    }
                })
        }
    }, [reIdentityPoints]);

    useEffect(() => {
        realestateAndDealsAdvancedStore.setMapViewType(null);
    }, []);

    const controls = useMemo(() => {
        const base = ["layers", "location", "full-screen", "filters"];
        if (loadElasticResults) {
            base.push("data-layers");
        }

        if (allowUpdateCoordinates) {
            base.push("reset-coordinates");
        }

        return base as featureOptions[];
    }, [loadElasticResults, allowUpdateCoordinates])

    return (
        <div className={clsx("map-contanier", { fullScreen: !realestateAndDealsAdvancedStore.toolsVisible, updatingCoordinates })} style={!realestateAndDealsAdvancedStore.toolsVisible ? { height: '100vh' } : {}}>
            {viewState && (
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}>
                    <Map
                        // TODO: below was commented because of console warning that styles cannot be used with mapId
                        // styles={[
                        //     {
                        //         featureType: 'poi',
                        //         stylers: [{ visibility: 'off' }],
                        //     },
                        //     {
                        //         featureType: 'transit',
                        //         stylers: [{ visibility: 'off' }],
                        //     },
                        //     {
                        //         featureType: 'administrative.country',
                        //         elementType: 'labels',
                        //         stylers: [{ visibility: 'off' }],
                        //     },
                        // ]}
                        id="e1d0c7dfc80cc429"
                        mapId={'e1d0c7dfc80cc429'}
                        // defaultZoom={viewState.zoom}
                        // center={{ lat: viewState.latitude, lng: viewState.longitude }}
                        // zoom={4}
                        zoom={zoomLvl}
                        maxZoom={20}
                        // onZoomChanged={setZoom}
                        center={center}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        viewState={viewState}
                        initialViewState={INITIAL_VIEW_STATE}
                        mapTypeId={!mapType ? 'hybrid' : 'roadmap'}
                        className="map"
                    >
                        <DeckGlOverlay
                            initialViewState={INITIAL_VIEW_STATE}
                            controller={true}
                            callMap={callMap}
                            setNoData={setNoData}
                            setDataMessage={setDataMessage}
                            setCallMap={setCallMap}
                            callSearchByIdentity={callSearchByIdentity}
                            setCallSearchByIdentity={setCallSearchByIdentity}
                            setViewState={setViewState}
                            setShowIdentityPinPosition={setShowIdentityPinPosition}
                            realEstateTypes={realestateAndDealsAdvancedStore.realestateOptions}
                            mapViewType={realestateAndDealsAdvancedStore.mapViewType}
                            setCounts={setCounts}
                            reIdentityPoints={reIdentityPoints}
                            loadElasticResults={loadElasticResults}
                            blink={blinkProperties}
                            checkOverlapByIdentity={checkOverlapByIdentity}
                            setZoomLvl={setZoomLvl}
                            onClick={(data: any) => {
                                if (realestateAndDealsAdvancedStore.zoomLvl > 15) {
                                    setPosition({ lat: data?.coordinate[1], lng: data?.coordinate[0] });
                                    setCurrentData(data?.object?.properties);
                                    setShowPopup(true);
                                    setNoData(false);
                                    setShowIdentityPinPosition(false);
                                }
                            }}
                        />
                        <MapControl position={isMobile ? ControlPosition.LEFT_BOTTOM : ControlPosition.BOTTOM_CENTER}>
                            <MapViewLegendAdvanced
                                enumKey={'propertyStatusEnums'}
                                isProperty={true}
                                mapViewType={realestateAndDealsAdvancedStore.mapViewType}
                                isMobile={isMobile}
                                locateMe={() => { }}
                                mapType={mapType}
                                setMapType={setMapType}
                                showModal={showModal}
                                dataLayers={realestateAndDealsAdvancedStore.showRealestateDataOnMap}
                                updateDataLayers={realestateAndDealsAdvancedStore.toggleShowDataOnMap}
                                features={controls}
                                onResetCoordinates={onResetCoordinates}
                            />
                            {realestateAndDealsAdvancedStore.mapViewType !== null && (
                                <MapViewLegendAdvanced
                                    enumKey={'mapViewsEnums'}
                                    isProperty={false}
                                    mapViewType={realestateAndDealsAdvancedStore.mapViewType}
                                    isMobile={isMobile}
                                    locateMe={() => { }}
                                    mapType={mapType}
                                    setMapType={setMapType}
                                    showModal={showModal}
                                    dataLayers={realestateAndDealsAdvancedStore.showRealestateDataOnMap}
                                    updateDataLayers={realestateAndDealsAdvancedStore.toggleShowDataOnMap}
                                    features={controls}
                                    onResetCoordinates={onResetCoordinates}
                                />
                            )}
                        </MapControl>

                        {allowUpdateCoordinates && reIdentityPoints?.length > 0 ? reIdentityPoints.map((reIdentityPoint, index) => <>
                            {reIdentityPoint?.coordinates?.length > 0 ? reIdentityPoint?.coordinates.map((coordinate, _index) => {
                                return <AdvancedMarker draggable onDragEnd={e => { onREIdentityUpdate(e, reIdentityPoint, coordinate) }} key={`marker-coordinate-${index}-${_index}`} position={{ lat: coordinate[1], lng: coordinate[0] }} >
                                    <div className='draggable-point'></div>
                                </AdvancedMarker>
                            }) : <></>}
                        </>) : <></>}

                        {reIdentityCenter && realestateIdentityError ? <AdvancedMarker key={`advance-marker-re-identity-message`} position={reIdentityCenter}>
                            <div className='re-identity-error-message'>
                                {realestateIdentityError}
                            </div>
                            <div className='re-identity-error-message__chin'></div>
                        </AdvancedMarker> : <></>}

                        {/* pins with price */}
                        {realestateAndDealsAdvancedStore.zoomLvl > 15 && realestateAndDealsAdvancedStore.featuresWithPrice && realestateAndDealsAdvancedStore.featuresWithPrice.length > 0 ? realestateAndDealsAdvancedStore.featuresWithPrice.map((marker, index) => {
                            return <AdvancedMarker key={`advance-marker-${index}`} position={{ lat: marker.coordinates[1], lng: marker.coordinates[0] }}>
                                <div className='pin-price' style={{ backgroundColor: marker.backgroundColor, marginBottom: realestateAndDealsAdvancedStore.zoomLvl >= 18 ? 32 : 0 }}>
                                    {marker.text}
                                </div>
                                <div className='chin'></div>
                            </AdvancedMarker>
                        }) : <></>}
                    </Map>
                </APIProvider>
            )}
        </div>
    );
};

export default observer(RealStateMapAdvanced);
