import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BankLogoVariants,
    ReadOnlyField,
    ReadOnlyFieldBorderVariant,
    ReadOnlyFieldVariant,
} from '@core/components/shared';
import clsx from 'clsx';
import { ReactComponent as SDADADIcon } from '@core/assets/images/SVG/assets-logo-sadad.svg';
import { useCopyText } from "@common/hooks";
import './SadadInfo.scss';

export default function SadadInfo({
    iban,
    hasBorder,
    noBorder,
}: PropsWithChildren<{
    iban: string;
    bankLogoVariant?: BankLogoVariants;
    hasBorder?: boolean;
    noBorder?: boolean;
}>) {
    const { t } = useTranslation();
    const { Icon } = useCopyText({
        text: iban!.toString(),
        successMessage: t('shared.sadad-number-copied'),
    });

    return (
        <div
            className={clsx('sadad-info', {
                'sadad-info--has-border': hasBorder,
            })}
        >
            <div className="sadad-info__number">
                <ReadOnlyField
                    label={t('sadad.sadad-number')}
                    title={iban}
                    noBorder={noBorder}
                    variant={ReadOnlyFieldVariant.primary}
                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                    small
                />
                <Icon className="copy" />
            </div>
            <div className="sadad-info__logo">
                <SDADADIcon />
            </div>
        </div>
    );
}
