import React from 'react';
import { Col } from 'antd';
import { MojCheckbox } from '@core/components/shared/Checkbox/MojCheckbox';
import { Card } from '@core/components/shared';
import { observer } from 'mobx-react-lite';
import { realestateAndDealsStore } from '@common/stores/realestate-and-deals.store';

export type RealestateCheckboxFiltersProps = {};

const RealestateCheckboxFilters = ({}: RealestateCheckboxFiltersProps) => {
    const checkboxOptions = [
        { label: 'العقارات المعروضة', value: 0 },
        { label: 'العقارات المطلوبة', value: 1 },
        { label: 'العقارات المتاحة', value: 2 },
    ];

    const checkboxGroupHandler = (option: { label: string; value: number }) => {
        // making sure you can't uncheck the only remaining option
        // business want's at all times at least one option is checked
        if (
            realestateAndDealsStore.realestateOptions!!.length - 1 === 0 &&
            realestateAndDealsStore.realestateOptions?.includes(option.value)
        )
            return;

        if (realestateAndDealsStore.realestateOptions?.includes(option.value)) {
            realestateAndDealsStore.updateRealestatesOptions!!(
                realestateAndDealsStore.realestateOptions.filter((item) => item !== option.value)
            );
        } else {
            realestateAndDealsStore.updateRealestatesOptions!!([
                ...realestateAndDealsStore.realestateOptions!!,
                option.value,
            ]);
        }
    };
    return (
        <Card.Body>
            <Col flex="auto" className="flex-layout">
                {checkboxOptions.map((value, index) => {
                    return (
                        <MojCheckbox
                            value={value.value}
                            key={index}
                            checked={realestateAndDealsStore.realestateOptions?.includes(value.value)}
                            onChange={() => {
                                checkboxGroupHandler(value);
                            }}
                            className="checkbox-item"
                        >
                            {value.label}
                        </MojCheckbox>
                    );
                })}
            </Col>
        </Card.Body>
    );
};
export default observer(RealestateCheckboxFilters);
