import { BuyingMethods as PaymentMethod } from '@/core/assets/enums';
import { IDeed } from '@common/models/Deed';

export interface DeedRealState {
    Serial: number;
    RegionCode: string | null;
    Area: number;
    AreaText: string | null;
    LocationDescription: string | null;
    LandNumber: string | null;
    CONSTRAINED: number;
    HALT: number;
    PAWNED: number;
    TESTAMENT: number;
    NorthLimitCode: string | null;
    NorthLimitDescription: string | null;
    NorthLimitLength: number;
    NorthLimitLengthChar: string | null;
    SouthLimitCode: string | null;
    SouthLimitDescription: string | null;
    SouthLimitLength: number;
    SouthLimitLengthChar: string | null;
    EastLimitCode: string | null;
    EastLimitDescription: string | null;
    EastLimitLength: number;
    EastLimitLengthChar: string | null;
    WestLimitCode: string | null;
    WestLimitDescription: string | null;
    WestLimitLength: number;
    WestLimitLengthChar: string | null;
    RealEstateRemarks: string | null;
    CityName: string | null;
    CityCode: number;
    DistrictName: string | null;
    DistrictCode: number;
    BlockNumber?: any;
    PlanNumber: string | null;
    RealestateIdentityNumber?: any;
    IsNorthRiyadhExceptioned: number;
    RealEstateTypeCode: number;
    RealEstateTypeName: string;
    selectedTag: string;
}

export interface DeedDetails {
    DeedSerial: number;
    DeedNo: number;
    OwnerId: number;
    MortgageeSerial: number;
    MortgageeName?: any;
    MortgageeId?: any;
    DeedDateYear: number;
    DeedDateMonth: number;
    DeedDateDay: number;
    DeedDate: string | null;
    DeedDateNum: number;
    DeedCity: string | null;
    DeedArea: number;
    DeedAreaText: string | null;
    DeedStatus: string | null;
    DeedText: string | null;
    TransactionTypeCode: string | null;
    TransactionTypeName: string | null;
    DeedContainerStatus?: string;
    DeedLimits?: any;
    DeedLocation?: any;
    DeedOwners: any[];
    DeedRealState: DeedRealState[];
    SremOrderStats: SremOrderStats;
    DeedSource: String;
}

export interface SremOrderStats {
    PurchaseOrders?: any;
    OpenMarketPrice?: any;
    OpenMarketOrders?: any;
    PrivateSellOrders?: any;
    OpenMarketDate?: any;
    RequestId?: number;
}

export interface Seller {
    Id: number;
    BeneficiaryType: number;
    DobHijri: string | null;
    Name: string | null;
    MobileNumber?: any;
    Email?: any;
    IdType: number;
    Gender?: any;
    SocialId: number;
    Ownership: number;
    SellingOwnership?: number;
    Iban?: string;
}

export interface Buyer {
    Id: number;
    BeneficiaryType: number;
    DobHijri: string | null;
    Name: string | null;
    MobileNumber?: any;
    Email?: any;
    IdType: number;
    Gender?: any;
    SocialId?: number;
    Iban?: string;
    Ownership?: number;
}

export interface History {
    TransactionDate: Date;
    Id: number;
    Name: string;
    Note: string;
    Step: number;
}

export interface IVatDetails {
    VatReqNumber: string | null;
    VatSadadNumber: string | null;
    VatStatus: number;
}

export enum RequestTypes {
    PrivateSell = 1,
    Gift = 2,
    MortagageSell = 3,
    OpenMarket = 4,
    PurchaseOrder = 5,
    Ept3m = 6,
    ReleaseMortgage = 7,
    CreateMortgage = 8,
    MortgageTransfer = 9,
    AcceptEPUMortageDeed = 12,
    DeedOwnerShipSeparation = 15,
    DeedRealEstateSeparation = 16,
    UPDATE_MORTGAGE_AMOUNT = 17,
    GPT3M = 19,
    REGISTER_REALSTATES = 21,
    PRIVATE_DEAL_3RD_PARTY = 24,
    EPT3M_3RD_PARTY = 23,
    CREATE_MORTGAGE_ThirdParty = 29,
    CONTACT_OWNER = 34,
}

interface Coordinate {
    point_no: number;
    latitude: number;
    longitude: number;
}
interface RealEstateCoordinates {
    RealEstateIdentityNo: number;
    Jsoncoordinates: string;
    Coordinates: Coordinate[];
}

export interface Transaction {
    Id: string | null;
    Amount: number;
    TargetAmount?: number;
    MeterPrice: number;
    DeedNumber: number;
    DeedSerial: number;
    VirtualIban?: any;
    RequestType: RequestTypes;
    RequestTypeName: string | null;
    Status: string | null;
    LandNumber: any;
    PlanNumber: any;
    City: any;
    District: any;
    DeedDetails?: IDeed;
    NewDeedDetails?: IDeed;
    Sellers?: Seller[] | null;
    Buyers?: Buyer[];
    MortgageEntities?: IMortgageEntity[];
    Mortgagees?: IMortgageEntity[];
    Mortgagors?: Seller[];
    History: History[];
    SremOrderStats: SremOrderStats;
    RequestNumber?: number;
    Actions: ITransactionAction[];
    StatusStep: number;
    ExpiryDate?: Date;
    CreatedOn?: string | null;
    VatDetails?: IVatDetails;
    StatusId?: number;
    VirtualAccountBalance?: number;
    ActionNeeded: boolean;
    SelectedRealEstateSerials?: Array<number> | null;
    BeneficiaryType: number;
    PreferredPaymentMethod?: PaymentMethod;
    PricePerMeter?: number;
    SremRealestateDescription?: string;
    Area?: number;
    RealEstateIdentityNo?: number;
    LastInstalmentDate?: string;
    ResidentTaxAmount?: number;
    TransactionArea?: number;
    canUpdatePurchaseAmount?: boolean;
    HighestBidAmount?: number;
    AuctionInfo?: {
        EndDate: Date;
        LaunchDate: Date;
        HighestBidAmount: number;
        ReservedAmount: number;
        UserLastBid: number;
    };
    AdditionalAmounts?: {
        SplitMergeFees: number;
    };
    realEstateIdentityCoordinates?: RealEstateCoordinates[];
    ContactWithOwnerDetails?: IContactOwnerReason;
}

export interface IContactOwnerReason {
    Amount?: number;
    ContactReason: string;
    ContactReasonId?: number;
    ServiceFees?: number;
}

export interface ITransactionAction {
    ActionType: number;
    IsDisabled: false;
}

export interface IRelatedTransaction {
    Id: string;
    Amount: number;
    RequestNumber: number;
    RequestType: number;
    CreatedOn: Date;
    StatusId: number;
    Status?: any;
    Sellers: Seller[];
    Buyers: Buyer[];
}

export interface IMortgageEntity {
    SocialId?: string;
    BeneficiaryType?: number;
    Name?: string;
    MobileNumber?: string;
    Email?: string;
    IdType?: number;
    Ownership?: number;
    BeneficiaryAmount?: number;
    Iban?: string;
    Attorneys?: BeneficiaryAttorney[];
    LastInstalmentDate?: string;
}

export interface BeneficiaryAttorney {
    SocialId: number;
    MobileNumber: string;
    Name: string;
}
export interface BeneficiaryActionsCountDto {
    PendingTransactionsCount: number;
    BeneficiaryId: number;
}

export interface CheckVatDetailsRequest {
    requestId?: string;
    reqNumber?: string;
    deedSerial?: number;
    deedNumber?: string;
    dealAmount?: string;
    buyers?: Buyer[];
    requestType?: number;
    gaztVerificationType?: number;
}
