import { AxiosInstance } from 'axios';
import { createClient } from '@common/services/index';
import { SortingType } from '@open-market/screens/realestate-and-deals/RealestateAndDealsPage';

class RealestatesAndDealsService {
    private HttpClient!: AxiosInstance;
    private AdminHttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_DI_API_URL}api/v1`);
        this.AdminHttpClient = createClient(`${process.env.REACT_APP_DI_API_URL}api/v1/admin`);
    }

    getAddressInfo = async (term: string) => {
        return this.HttpClient.get(`/AddressInfo/SearchAddress?Term=${term}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    searchPlans = async (parentCode: number, parentLevel: string, term: string) => {
        return this.HttpClient.get(
            `/AddressInfo/SearchPlansElk?ParentCode=${parentCode}&ParentLevel=${parentLevel}&Term=${term}`
        )
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    searchLands = async (areaCode: number, areaLevel: string, parentCodes: string, term: string) => {
        return this.HttpClient.get(
            `/AddressInfo/SearchLandsElk?AreaCode=${areaCode}&AreaLevel=${areaLevel}&ParentCodes=${parentCodes}&Term=${term}`
        )
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    getFilteredRealestates = async (
        page: number,
        isOpenMarket: boolean,
        isPurchaseOrder: boolean,
        isRealEstate: boolean,
        sortingType: SortingType,
        filters: any,
        constraintsFilter?: any
    ) => {
        const keys = Object.keys(filters);
        const object: any = {};
        for (let key of keys) {
            object[key] = filters[key] ?? null;
        }
        return this.HttpClient.post(`/SremRealEstates/FilteredRealEstates`, {
            page: page,
            pageSize: 50,
            sortType: sortingType.sortType,
            sortField: sortingType.sortField,
            isOpenMarket: isOpenMarket,
            isPurchaseOrder: isPurchaseOrder,
            isRealEstate: isRealEstate,
            filter: {
                ...filters,
                isAgricalture: filters.isAgricalture ? 1 : 0,
                constraintsFilter: constraintsFilter,
            },
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    getRealestatesInfoData = async (
        page: number,
        isOpenMarket: boolean,
        isPurchaseOrder: boolean,
        isRealEstate: boolean,
        sortingType: SortingType,
        filters: any,
        constraintsFilter?: any
    ) => {
        const keys = Object.keys(filters);
        const object: any = {};
        for (let key of keys) {
            object[key] = filters[key] ?? null;
        }
        return this.HttpClient.post(`/SremRealEstates/RealestateData`, {
            page: page,
            pageSize: 50,
            sortType: sortingType.sortType,
            sortField: sortingType.sortField,
            isOpenMarket: isOpenMarket,
            isPurchaseOrder: isPurchaseOrder,
            isRealEstate: isRealEstate,
            filter: {
                ...filters,
                isAgricalture: filters.isAgricalture ? 1 : 0,
                constraintsFilter: constraintsFilter,
            },
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    getShamilTransaction = async (
        page: number,
        sortingType: SortingType,
        filters: any,
        transactionFormatFilter?: number[]
    ) => {
        const keys = Object.keys(filters);
        const object: any = {};
        for (let key of keys) {
            object[key] = filters[key] ?? null;
        }
        return this.HttpClient.post(`/SremRealEstates/ShamilTransactions`, {
            page: page,
            pageSize: 50,
            sortType: sortingType.sortType,
            sortField: sortingType.sortField,
            ...filters,
            transactionFormatFilter: transactionFormatFilter,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    getRealestateBySerial = async (body: { realEstateSerial: string }) => {
        // force body to be inside qutation marks
        return await this.HttpClient.post(`/SremRealEstates/RealEstateDetails`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getRealEstateTypes = async () => {
        return await this.HttpClient.get('SremRealEstates/RealEstateTypes')
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getCSVDownloadStatus = async (myRealestatesFilters) => {
        return await this.HttpClient.post('MySrem/CheckRealEstatesFileStatus', myRealestatesFilters)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyRealEstatesCsv = async (payload) => {
        return await this.HttpClient.post('MySrem/GetMyRealEstatesCsv', payload, { responseType: 'blob' })
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyRealEstatesCsvFile = async () => {
        return await this.HttpClient.post('MySrem/DownloadRealEstatesFile', {}, { responseType: 'blob' })
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    triggerCSVPreparation = async (payload) => {
        return await this.HttpClient.post('MySrem/GetRealEstateFile', payload)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    resetCSVFile = async (payload) => {
        return await this.HttpClient.post('MySrem/ResetMyRealEstatesFile', payload)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyRealEstatesCsvFileNew = async (body) => {
        return await this.HttpClient.post('MySrem/GetDeesCsvByOwnerId', body, { responseType: 'blob' })
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getRealestatesMapStatsData = async (
        isOpenMarket: boolean,
        isPurchaseOrder: boolean,
        isRealEstate: boolean,
        boundsCoordinates: object,
        filters: any,
        geoProperties: object,
        constraintsFilter?: any
    ) => {
        const keys = Object.keys(filters);
        const object: any = {};
        for (let key of keys) {
            object[key] = filters[key] ?? null;
        }

        const additionalFilters: any = {};
        // @ts-ignore
        if (geoProperties.randomData) {
            additionalFilters.isOpenMarket = true;
            additionalFilters.isRealEstate = false;
            additionalFilters.isPurchaseOrder = false;
        }

        return this.HttpClient.post(`/SremRealEstates/FilteredGeoRealEstatesRegions`, {
            // page: page,
            // pageSize: 50,
            // sortType: sortingType.sortType,
            // sortField: sortingType.sortField,
            isOpenMarket: isOpenMarket,
            isPurchaseOrder: isPurchaseOrder,
            isRealEstate: isRealEstate,
            boundsCoordinates,
            ...additionalFilters,
            filter: {
                ...filters,
                isAgricalture: filters.isAgricalture ? 1 : 0,
                constraintsFilter: constraintsFilter,
            },
            geoProperties: geoProperties,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    getRealestatesMapData = async (
        // page: number,
        // sortingType: SortingType,
        isOpenMarket: boolean,
        isPurchaseOrder: boolean,
        isRealEstate: boolean,
        coordinates: object,
        filters: any,
        geoProperties: object,
        constraintsFilter?: any
    ) => {
        const keys = Object.keys(filters);
        const object: any = {};
        for (let key of keys) {
            object[key] = filters[key] ?? null;
        }

        const additionalFilters: any = {};
        // @ts-ignore
        if (geoProperties.randomData) {
            additionalFilters.isOpenMarket = true;
            additionalFilters.isRealEstate = false;
            additionalFilters.isPurchaseOrder = false;
        }

        return this.HttpClient.post(`/SremRealEstates/FilteredGeoRealEstates`, {
            // page: page,
            // pageSize: 50,
            // sortType: sortingType.sortType,
            // sortField: sortingType.sortField,
            isOpenMarket: isOpenMarket,
            isPurchaseOrder: isPurchaseOrder,
            isRealEstate: isRealEstate,
            coordinates: coordinates,
            ...additionalFilters,
            filter: {
                ...filters,
                isAgricalture: filters.isAgricalture ? 1 : 0,
                constraintsFilter: constraintsFilter,
            },
            geoProperties: geoProperties,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };
    getLocationOnSearch = async (searchValue: string) => {
        return this.HttpClient.get(
            // `https://maps.googleapis.com/maps/api/place/textsearch/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&input=${searchValue}&inputtype=textquery`,
            `https://places.googleapis.com/v1/places:${searchValue}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Goog-Api-Key': `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                    'X-Goog-FieldMask': '*',
                },
                withCredentials: false,
            }
        )
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };
    getMapViewTypes = async () => {
        return this.HttpClient.get(`/MapView/MapViewTypes`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    updateCoordinates = async (
        realEstateIdentityNo: number,
        newCoordinates: {
            point_no: number;
            latitude: number;
            longitude: number;
        }[]
    ) => {
        return this.AdminHttpClient.post(`/RealEstate/UpdateCoordinates`, {
            realEstateIdentityNo,
            newCoordinates,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    resetCoordinates = async (realEstateIdentityNos: number[]) => {
        return this.AdminHttpClient.post(`/RealEstate/ResetCoordinates`, realEstateIdentityNos)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };
}

const instance = new RealestatesAndDealsService();

export default instance;
