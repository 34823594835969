import { AxiosInstance } from 'axios';
import { createClient } from '@common/services/index';
import { message } from "antd";
import { AddHeirsPayload } from '../models/Attorney';

export default class AttorneyService {
    HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_PROFILE_API_URL}api/v1`);
    }

    error = () => {
        message.config({
            top:0
        })
        message.error('فشل تمديد الجلسة');
    };

    initiateSession = async (id: string) => {
        return await this.HttpClient.post(`Delegation/InitiateSession`, `"${id}"`)
            .then((res) => {
                sessionStorage.setItem('attorney-id',id);
                return res;
            })
            .catch((reason) => {
                this.error();
                return reason;
            });
    };

    extendSession = async () => {
        return await this.HttpClient.post(`Delegation/ExtendSessionExpiryDate`)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    terminateSession = async () => {
        return await this.HttpClient.post(`Delegation/TerminateSession`)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyAttornies = async () => {
        return await this.HttpClient.get(`Attorney/MyAttornies`)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getAttorneyIbans = async (instanceId: string) => {
        return await this.HttpClient.get(`Attorney/AttorneyIbans/${instanceId}`)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    addPersonalAttorney = async (body: {
        attorneyCode: string;
        delegatorId: string;
        delegatorDob: string;
        iban: string;
    }) => {
        return await this.HttpClient.post(`Attorney/AddPersonalAttorney`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    addOrganizationAttorney = async (body: { attorneyCode: string; delegatorId: string; iban: string }) => {
        return await this.HttpClient.post(`Attorney/AddOrganizationAttorney`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    addHeirsAttorney = async (body: AddHeirsPayload) => {
        return await this.HttpClient.post(`Attorney/AddHeirAttorney`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    addAttorneyIban = async (body: { instanceId: string; iban: string }) => {
        return await this.HttpClient.post(`Attorney/AddAttorneyIban`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    updateAttorneyIban = async (body: { instanceId: string; iban: string; isActive: boolean }) => {
        return await this.HttpClient.post(`Attorney/ChangeAttorneyIban`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getAttorney = async (body: {attorneyCode: string, delegatorType: number, heirsCode: number, entityId: number}) => {
        return await this.HttpClient.post(`Attorney/GetAttorney`, body)
            .then((res) => {
                return res;
            })
            .catch(reason => {
                return reason;
            })
    }

    addEstablishmentAttorney = async (body: { attorneyCode: string; delegatorId: string; iban: string }) => {
        return await this.HttpClient.post(`Attorney/AddEstablishmentAttorney`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };

    addGovernmentAttorney = async (body: { attorneyCode: string; delegatorId: string; iban: string }) => {
        return await this.HttpClient.post(`Attorney/AddGovernmentAttorney`, body)
            .then((res) => {
                return res;
            })
            .catch((reason) => {
                return reason;
            });
    };
}

export const attorneyService = new AttorneyService();
