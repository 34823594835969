import { useTranslation } from 'react-i18next';
import { Alert, AlertVariants, Card, IbanInput, MojButton } from '@core/components/shared';
import { Col, Radio, Row } from 'antd';
import { ErrorDetails } from '@common/models/GenericResponse';
import ErrorAlert from '@core/components/shared/ErrorAlert/ErrorAlert';
import { observer } from 'mobx-react-lite';
import { DelegatorTypes } from '@/common/models/Attorney';

type Props = {
    title: string;
    onChange: (iban: string) => void;
    onValidationChange: (isValid: boolean) => void;
    onBackClick: () => void;
    ibanValid: boolean;
    onSubmit: () => void;
    isLoading: boolean;
    errorDetails?: ErrorDetails[];
    hideBack?: boolean;
    correlationId?: string;
    hideSubmit?: boolean;
    isRefund?: boolean;
    handleRefundSubmitDisabled?: (state: number) => void;
    handleSelectedIban?: (selectedIban: string | null) => void;
    attorneyType?: number;
}

function AddingBankAccountForm(props: Props) {
    const { t } = useTranslation();
    
    return (
        <div className="section">
            <Card className="section__container">
                <Row>
                    <Col xs={24}>
                        <Card.Head className="section__title">
                            {props.isRefund ? (
                                <Row>
                                    <Col>
                                        <Radio>{t('refund-management.chooseIban')}</Radio>
                                    </Col>
                                </Row>
                            ) : (
                                props.title
                            )}
                        </Card.Head>
                        {props.attorneyType === DelegatorTypes.Heir ? (
                        <Card.Body>
                            <Row className="section__basics border-bottom">
                                <Col flex="100%">
                                    <Alert variant={AlertVariants.Warning}>
                                    {t('attorney.heir-iban-disclaimer')}
                                    </Alert>
                                </Col>
                            </Row>
                        </Card.Body>
                         ) : ""}
                        <Card.Body className="section__basics">
                            <IbanInput
                                disabled={false}
                                onChange={props.onChange}
                                isRefund={props?.isRefund}
                                onValidationChange={props.onValidationChange}
                                handleSelectedIban= {props?.handleSelectedIban}
                            />
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
            <Card>
                <Row gutter={8}>
                    {props.errorDetails && props.errorDetails.length > 0 && (
                        <Col span={24}>
                            <Card hasAnimations={true} noPadding={true}>
                                <Alert variant={AlertVariants.Danger}>
                                    {props.errorDetails.map((value, index) => {
                                        return <ErrorAlert errorDetail={value} correlationId={props.correlationId} key={`error-line-${index}`} />
                                    })}
                                </Alert>
                            </Card>
                        </Col>
                    )}
                    {!props.hideBack && (
                        <Col span={12}>
                            <MojButton color="primary" onClick={props.onBackClick}>
                                {t('profile.adding-bank-account.cancel')}
                            </MojButton>
                        </Col>
                    )}
                    {!props.hideSubmit && (
                        <Col span={props.hideBack ? 24 : 12}>
                            <MojButton
                                color="success"
                                disabled={!props.ibanValid}
                                onClick={props.onSubmit}
                                loading={props.isLoading}
                            >
                                {!props.isLoading ? t('profile.adding-bank-account.verify') : null}
                            </MojButton>
                        </Col>
                    )}
                </Row>
            </Card>
        </div>
    );
}

export default observer(AddingBankAccountForm);
