/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { message, notification, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

import { IMultiStepContainer } from '@core/components/shared/MultiStepContainer/interface/multi-step-container.interface';
import SelectEditFields, { onChangeEditFieldsProps } from '@/modules/srem/components/SelectEditFields/SelectEditFields';
import EditReasonSupportedFilesForm from './components/EditReasonSupportedFilesForm/EditReasonSupportedFilesForm';
import useMultiStepContainer from '@core/components/shared/MultiStepContainer/util/useMultiStepContainer';
import MultiStepContainer from '@core/components/shared/MultiStepContainer/MultiStepContainer';
import RealEstatesServices, { realEstatesServices } from '@common/services/realestate.services';
import { requiredInput } from '@core/components/shared/DeedInquiryForm/validator';
import NewDealManager from '../SellingOffer/screens/new-deal/NewDealManager';
import NotFoundInfo from '@core/components/shared/NotFound/NotFoundInfo';
import RequestSuccess from './components/RequestSuccess/RequestSuccess';
import EditFieldsForm from './components/EditFieldsForm/EditFieldsForm';
import { OtpResponse } from '@/core/interfaces/OtpResponse.interface';
import DeedDetails from '@dashboard/screens/DeedDetails/DeedDetails';
import OpenMarketService from '@common/services/open-market.service';
import OtpForm from '@property-mortgage/components/OtpForm/OtpForm';
import SellOrderService from '@common/services/sell-order.service';
import { IdentityNumberPayload } from '@/common/models/Realestate';
import GenericResponse from '@common/models/GenericResponse';
import { loaderStore } from '@common/stores/loader.store';
import { OtpRequestType } from '@/core/assets/enums';
import { IDeed } from '@/common/models/Deed';
import { useHint } from '@/common/hooks';
import { IUserInput, DuDeedGroup, EduLinkDeedBy, EduLinkDeedByEnd } from './models/edit.deed.models';
import EDUFieldMap from './components/EDUFieldMap/EDUFieldMap';
import { Card, MojAccordion } from '@/core/components/shared';
import clsx from 'clsx';
import { REIdentityPoint } from '../MergeSplitRealestate/models/reIdentity.model';
import { MAP_LAT_LNG_SORTER } from '../open-market/components/RealStateMap/mapHelperFunctions';
import RealStateMapAdvanced from '../open-market/components/RealStateMap/RealStateMapAdvanced';

const expandedAccordions = Array.from({ length: 100 }, (_, i) => i);

const EditDeed = () => {
    const _useMultiStepContainer = useMultiStepContainer();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { Icon } = useHint({
        title: t('shared.info'),
        message: t('shared.add-more-iban-info'),
        maskClosable: true,
    });

    const realestatesService = useMemo(() => new RealEstatesServices(), []);
    const openMarketService = useMemo(() => new OpenMarketService(), []);
    const sellOrderService = useMemo(() => new SellOrderService(), []);
    const [isLoading, setIsLoading] = useState(false);
    const [deed, setDeed] = useState<IDeed>();
    const [newRequestResponse, setNewRequestResponse] = React.useState<{ OrderId: number }>();
    const [otpConfiguration, setOtpConfiguration] = useState<OtpResponse | undefined>();
    const [canProceedStepOne, setCanProceedStepOne] = useState(true);
    const [editValues, setEditValues] = useState<onChangeEditFieldsProps | null>();
    const [checkboxes, setCheckboxes] = useState<{ value: number, label: string, multiSelectAllowed: boolean, isInputFieldRequired: boolean }[]>([]);
    const [groupId, updateGroupId] = useState<DuDeedGroup>();
    const [group, setGroup] = useState([]);
    const [userInput, setUserInput] = useState<IUserInput[]>([]);
    const [reIdentityPoints, setReIdentityPoints] = useState<REIdentityPoint[]>([]);

    useEffect(() => {
        _useMultiStepContainer.setSteps(stepper.length);
        getDeedBySerial();
    }, []);

    const handleEditFieldsChange = useCallback((values: onChangeEditFieldsProps) => {
        setEditValues(values);
    }, [editValues]);

    async function getDeedBySerial() {
        loaderStore.setLoader(true);
        const response: GenericResponse = await realestatesService.getMyDeedBySerial({ deedSerial: id!! });

        if (!response) {
            return;
        }

        if (response?.IsSuccess!!) {
            if (response.Data) {
                const deed = response.Data.Deed as IDeed;
                setDeed(deed);

                const preValidationResult: GenericResponse = await NewDealManager.preValidateForDuEditDeed(response.Data.Deed as IDeed, t)

                const validationErrors = NewDealManager.getValidationErrors(response.Data.Deed as IDeed, preValidationResult.Data, t);
                if (validationErrors.length) {
                    _useMultiStepContainer.setCorrelationAndErrors(preValidationResult.CorrelationId, validationErrors);
                    setCanProceedStepOne(false);
                }
            }
        } else {
            _useMultiStepContainer.setCorrelationAndErrors(response.CorrelationId, response.ErrorDetails);
        }
        loaderStore.setLoader(false);
    }

    async function getAllGroupsForAllowedEditFields() {
        loaderStore.setLoader(true);
        const response: GenericResponse = await sellOrderService.getAllGroupsForAllowedEditFields();
        loaderStore.setLoader(false);
        if (response.IsSuccess) {
            setGroup(response.Data);
        } else {
            _useMultiStepContainer.setErrorList(response.ErrorList);
            _useMultiStepContainer.setCorrelationId(response.correlationId);
        }
    }

    const getChildsByGroupId = async (groupId: number, deedSerial: string) => {
        loaderStore.setLoader(true);
        const response: GenericResponse = await sellOrderService.getChildsByGroupId(groupId, Number(deedSerial));
        loaderStore.setLoader(false);
        if (response.IsSuccess) {
            _useMultiStepContainer.setErrorList([]);
            setCheckboxes(response.Data.map(item => ({
                label: item.FiledName,
                value: item.FiledID,
                multiSelectAllowed: item.MultiSelectAllowed,
                isInputFieldRequired: item.IsInputFieldRequired
            })));
        } else {
            _useMultiStepContainer.setErrorList(response.ErrorList);
            _useMultiStepContainer.setCorrelationId(response.correlationId);
        }
    }

    const sendOtp = useCallback(async (isRetrying?: boolean) => {
        const response: GenericResponse = await sellOrderService.sendOtp({
            requestType: OtpRequestType.DU_EDIT_REQUEST,
            smsParameters: {
                deedNumber: deed?.DeedNo.toString(),
                dealAmount: "0",
            }
        });

        if (response.IsSuccess && response.Data && response.Data.MessageSent) {
            setOtpConfiguration(response.Data);
            if (!isRetrying) {
                _useMultiStepContainer.nextStep();
            }
        } else {
            _useMultiStepContainer.setErrorDetails(response.ErrorDetails);
            _useMultiStepContainer.setCorrelationId(response.correlationId);
        }
    }, [_useMultiStepContainer]);

    const EditFieldsFormBuilder = useCallback(() => {
        const initialValues = {};
        const validationSchema = Yup.object().shape({});
        const submitForm = async (values?: any) => {
            if (groupId && id) {
                getChildsByGroupId(groupId, id);
                _useMultiStepContainer.nextStep();
            } else {
                _useMultiStepContainer.setCorrelationAndErrors("", [t("يجب اختيار نوع التعديل")]);
                return;
            }
        };

        return { initialValues, validationSchema, submitForm };
    }, [_useMultiStepContainer]);

    const handleEditUserInputs = (inputs) => {
        setUserInput([...(inputs || []).filter(input => input.type === editValues?.fields[0] && input.isValid)]);
    }

    const handleEditFlow = (e) => {
        updateGroupId(e.target.value);
        setEditValues(null);
    }

    const ChooseFieldsFormBuilder = useCallback(() => {
        const initialValues = {};
        const submitForm = async (values?: FormikValues) => {
            if (!isFormValid(editValues!)) {
                _useMultiStepContainer.setCorrelationAndErrors("", [t("edit-deed.form-error")]);
                return;
            }

            const validInputs = (userInput || []).filter(input => input.isValid);

            if (groupId === DuDeedGroup.EDIT_REALESTATE && validInputs.length === 0) {
                _useMultiStepContainer.setCorrelationAndErrors("", [t(`edit-request.errors.code-${editValues?.fields[0]}`)]);
                return;
            }

            _useMultiStepContainer.setCorrelationAndErrors("", []);
            _useMultiStepContainer.nextStep();
            setReIdentityPoints([]);
            sendOtp();
        };

        const isFormValid = (editValues: onChangeEditFieldsProps | null): boolean => {
            return !!editValues && editValues.fields.length > 0;
        };

        return { initialValues, submitForm };
    }, [_useMultiStepContainer, editValues, groupId, userInput]);

    const otpFormBuilder = useCallback(() => {
        const initialValues = {
            otpNumber: '',
        };
        const validationSchema = Yup.object().shape({
            otpNumber: requiredInput,
        });

        const submitForm = async (values?: typeof initialValues) => {
            setIsLoading(true);
            try {
                const otpResponse: GenericResponse = await sellOrderService.verifyOtp({
                    key: otpConfiguration!.Key,
                    otp: values!.otpNumber,
                });
                if (otpResponse && otpResponse.Data) {
                    if (!otpResponse.Data.IsValid) {
                        setOtpConfiguration({
                            ...otpConfiguration!,
                            RemainingSeconds: otpResponse.Data.RemainingPeriod,
                            RemainingRetries: otpResponse.Data.RemainingRetries,
                        });
                        message.warn(t('otp.invalid'));
                        setIsLoading(false);
                        return;
                    } else {
                        if (groupId === DuDeedGroup.EDIT_REALESTATE) {
                            const payload = {
                                otpVerify: {
                                    key: otpConfiguration?.Key,
                                    otp: +values?.otpNumber!!
                                },
                                DeedLinkDto: {
                                    attachementModel: editValues?.attachment ? {
                                        fileName: editValues?.attachment?.fileName,
                                        size: editValues?.attachment?.size,
                                        contentType: editValues?.attachment?.contentType,
                                        fileBase64: editValues?.attachment?.fileBase64,
                                    } : undefined,
                                    EditNotes: editValues?.reason
                                },
                                DeedSerial: id,
                                DependOnRealEstateIdentityArea: !!editValues?.dependOnRealEstateIdentityArea,
                                UserInput: userInput.filter(input => input.isValid).map(input => input.value),
                                LinkProcessType: EduLinkDeedByEnd.RealEstateIdentityNumber === editValues?.fields[0] ? EduLinkDeedBy.RealEstateIdentityNumber : EduLinkDeedBy.LetterNumber,
                            }

                            processDeedLinkRequest(payload);
                        } else {
                            const baseObject = {
                                otpVerify: {
                                    key: otpConfiguration?.Key,
                                    otp: +values?.otpNumber!!
                                },
                                orderDto: {
                                    deedNo: deed?.DeedNo.toString(),
                                    attachementModel: editValues?.attachment ? {
                                        fileName: editValues?.attachment?.fileName,
                                        size: editValues?.attachment?.size,
                                        contentType: editValues?.attachment?.contentType,
                                        fileBase64: editValues?.attachment?.fileBase64,
                                    } : undefined,
                                    orderEditedFields: editValues?.fields,
                                    editNotes: editValues?.reason
                                }
                            }

                            createEditDeedRequest(baseObject);
                        }
                    }
                }
            } catch (e) {
                message.warn(t('otp.invalid'));
                return;
            }
            setIsLoading(false);
        };

        return { initialValues, validationSchema, submitForm };
    }, [
        _useMultiStepContainer,
        openMarketService,
        sellOrderService,
        otpConfiguration,
        t,
        deed
    ]);

    async function createEditDeedRequest(payload) {
        loaderStore.setLoader(true);
        const res: GenericResponse = await sellOrderService.createEditDeedRequest(payload);
        loaderStore.setLoader(false);
        if (res.IsSuccess) {
            setNewRequestResponse(res.Data);
            _useMultiStepContainer.nextStep();
        } else {
            _useMultiStepContainer.setErrorDetails(res.ErrorDetails);
            _useMultiStepContainer.setCorrelationId(res.correlationId);
        }
    }

    async function processDeedLinkRequest(payload) {
        const res: GenericResponse = await sellOrderService.processDeedLinkRequest(payload);
        if (res?.Data?.CoordinatesCollection?.length > 0) {
            setReIdentityPoints((res?.Data?.CoordinatesCollection.map(point => ({
                coordinates: point.Coordinates.map(coordinate => [coordinate.latitude, coordinate.longitude].sort(MAP_LAT_LNG_SORTER))
            }))));
        }

        if (res?.Data?.ApiResponse?.Data?.OrderId) {
            setNewRequestResponse(res.Data.ApiResponse.Data);
            setReIdentityPoints([]);
        }

        if (res.IsSuccess) {
            _useMultiStepContainer.nextStep();
        } else {
            _useMultiStepContainer.setErrorDetails(res.ErrorDetails);
            _useMultiStepContainer.setCorrelationId(res.correlationId);
        }
    }

    const updateRealEstateIdentityNumberHandler = async (RestateIdentity) => {
        const payload: IdentityNumberPayload = {
            ReferenceNumber: deed?.DeedSerial!,
            DeedNumber: deed?.DeedSerial!,
            RestateIdentity: RestateIdentity,
            RequestTypeId: 1,
            IsLandType: true,
            EntityId: 3000
        }
        const response: GenericResponse = await realEstatesServices.updateRealEstateIdentityNumber(payload);

        if (response.IsSuccess) {
            // show alert that the identity number has been updated successfully from antd.
            notification.success({
                message: response.data.messageStatus,
                placement: 'bottomRight',
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            _useMultiStepContainer.setErrorDetails(response.ErrorList || ["فشل تحديث الهوية العقارية"]);
            _useMultiStepContainer.setCorrelationId(response.correlationId);
        }
    }

    const handleInputValidationChange = (input: IUserInput, index: number, isValid: boolean) => {
        const newInputs = [...userInput];
        newInputs[index] = { ...input, isValid };
        setUserInput(newInputs);
    }

    const stepper: IMultiStepContainer[] = React.useMemo(() => {
        return [
            {
                component: () => {
                    return <DeedDetails hideActions deed={deed} hideDetails={false} updateIdentityNumber={updateRealEstateIdentityNumberHandler} />;
                },
                onSubmit: () => {
                    if (canProceedStepOne || _useMultiStepContainer.errorDetails.length === 0) {
                        getAllGroupsForAllowedEditFields();
                        _useMultiStepContainer.nextStep()
                    }
                },
                values: null,
                disableNext: !canProceedStepOne || _useMultiStepContainer.errorDetails.length > 0,
                validationSchema: null,
                confirmButtonText: t('shared.continue'),
                cancelButtonText: t(t('shared.back')),
            },
            {
                component: () => {
                    return <EditFieldsForm onRadioChange={handleEditFlow} group={group} />;
                },
                onSubmit: EditFieldsFormBuilder().submitForm,
                initialValues: EditFieldsFormBuilder().initialValues,
                values: EditFieldsFormBuilder().initialValues,
                validationSchema: EditFieldsFormBuilder().validationSchema,
                confirmButtonText: t('shared.continue'),
                cancelButtonText: t(t('shared.back')),
            },
            {
                component: () => {
                    return (<>
                        <SelectEditFields
                            values={editValues?.fields || []}
                            reason={editValues?.reason}
                            checkboxes={checkboxes || {}}
                            onChange={handleEditFieldsChange}
                            attachment={editValues?.attachment}
                            handleEditUserInputs={handleEditUserInputs}
                            inputs={userInput}
                            dependOnRealEstateIdentityArea={true}
                        />
                        {/* @ts-ignore */}
                        <EditReasonSupportedFilesForm values={editValues?.fields || []} onChange={handleEditFieldsChange} />
                        {(userInput || []).length > 0 ? <Card>
                            <MojAccordion defaultActiveKey={expandedAccordions}>
                                {userInput.map((userInput, index) =>
                                    <MojAccordion.Item key={index} header={<Row className={clsx("section__title border-bottom", { 'danger': !userInput.isValid })}>{userInput.value}</Row>}>
                                        <EDUFieldMap key={index} input={userInput} onValidationChange={(isValid) => handleInputValidationChange(userInput, index, isValid)} />
                                    </MojAccordion.Item>
                                )}
                            </MojAccordion> </Card> : <></>}
                    </>);
                },
                onSubmit: ChooseFieldsFormBuilder().submitForm,
                initialValues: ChooseFieldsFormBuilder().initialValues,
                values: ChooseFieldsFormBuilder().initialValues,
                validationSchema: null,
                confirmButtonText: t('shared.continue'),
                cancelButtonText: t(t('shared.back')),
                // disableNext: shouldDisableAddBuyers(),
            },
            {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return !otpConfiguration ? (
                        <> </>
                    ) : (
                        <><OtpForm
                            onRetry={() => sendOtp(true)}
                            otpConfiguration={otpConfiguration}
                            {...formikProps}
                        />
                            <Card>
                                <Card.Body>
                                    {reIdentityPoints?.length > 0 ? reIdentityPoints.map(polygon => <RealStateMapAdvanced
                                        reIdentityPoints={[polygon]}
                                        callMap={false}
                                        setCallMap={() => { }}
                                        setCallSearchByIdentity={() => { }}
                                        callSearchByIdentity={false}
                                        showModal={() => { }}
                                        allowUpdateCoordinates={false}
                                        realestateIdentityError={""}
                                        blinkProperties={true}
                                        checkOverlapByIdentity={false}
                                    />) : <></>}
                                </Card.Body>
                            </Card>
                        </>
                    );
                },
                onSubmit: otpFormBuilder().submitForm,
                initialValues: otpFormBuilder().initialValues,
                values: otpFormBuilder().initialValues,
                validationSchema: otpFormBuilder().validationSchema,
                confirmButtonText: t('vat.ok'),
                cancelButtonText: t(t('shared.back')),
                disableNextOnInvalid: true,
            },
            {
                component: () => {
                    return (
                        <RequestSuccess
                            requestNumber={newRequestResponse?.OrderId || undefined}
                            requestStatusText={(groupId === DuDeedGroup.EDIT_REALESTATE && !newRequestResponse?.OrderId) ? t("edit-deed.edit-success") : t("edit-deed.success-heading")}
                            statusNote={(groupId === DuDeedGroup.EDIT_REALESTATE && !newRequestResponse?.OrderId) ? "" : t("edit-deed.success-note")}
                            hideActions={groupId === DuDeedGroup.EDIT_REALESTATE && !newRequestResponse?.OrderId}
                        >
                            {reIdentityPoints?.length > 0 ? reIdentityPoints.map(polygon => <RealStateMapAdvanced
                                reIdentityPoints={[polygon]}
                                callMap={false}
                                setCallMap={() => { }}
                                setCallSearchByIdentity={() => { }}
                                callSearchByIdentity={false}
                                showModal={() => { }}
                                allowUpdateCoordinates={false}
                                realestateIdentityError={""}
                                blinkProperties={true}
                                checkOverlapByIdentity={false}
                            />) : <></>}
                        </RequestSuccess>
                    );
                },
                confirmButtonText: t('vat.ok'),
                cancelButtonText: t(t('shared.back')),
                hideActions: !!newRequestResponse || groupId === DuDeedGroup.EDIT_REALESTATE,
            },
        ];
    }, [
        ChooseFieldsFormBuilder,
        Icon,
        deed,
        newRequestResponse,
        otpFormBuilder,
        t,
        otpConfiguration,
        _useMultiStepContainer,
    ]);

    useEffect(() => {
        _useMultiStepContainer.setSteps(stepper.length);
    }, [deed, stepper]);

    return (
        <>
            {!deed && !loaderStore.isLoading ? (
                <NotFoundInfo />
            ) : (
                <MultiStepContainer
                    stepper={stepper}
                    stepperHeader={t("edit-deed.title")}
                    currentStep={_useMultiStepContainer.currentStep}
                    previousStep={_useMultiStepContainer.previousStep}
                    isLoading={isLoading}
                    // errorList={_useMultiStepContainer.errorList}
                    errorDetails={_useMultiStepContainer.errorDetails}
                    correlationId={_useMultiStepContainer.correlationId}
                    hideActions={!!newRequestResponse}
                />
            )}
        </>
    );
};
export default observer(EditDeed);
