import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import LegendIcon from '../LegendIcon/LegendIcon';
import RealStateMap from '../RealStateMap/RealStateMap';
import './_Legend.scss';

function Legend({ color, label, isProperty }) {
    return (
        <div className="Legend">
            <LegendIcon color={color} isProperty={isProperty} mapViewType={realestateAndDealsStore.mapViewType} />
            <p className="LegendLabel">{label}</p>
        </div>
    );
}

export default Legend;
