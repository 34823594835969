import React, { useEffect, useState } from 'react';
import { Card, MojButton, MojPopup } from '@core/components/shared';
import { useTranslation } from 'react-i18next';
import { authStore } from '@/common/stores/auth.store';
import { observer } from 'mobx-react-lite';
import { PlainLabel, PlainLabelVariant } from '../PlainLabel/PlainLabel';
import AttorneyService from '@common/services/attorney.service';
import { attorneyStore } from '@common/stores/attorney.store';
import { Col, Row } from "antd";
import { Attorney } from "@common/models/Attorney";
import { AddedIban } from "@common/models/UserDetails";
import { loaderStore } from "@common/stores/loader.store";

function AttorneyExpiringPopUp() {
    const isAttorneySessionExpired = attorneyStore.isAttorneySessionEnded;
    const attorneyService = new AttorneyService();

    // let checkUserIdleTimeout: any = null;

    const {t} = useTranslation();
    const attorneyExtend = async () => {
        const attorneyId = sessionStorage.getItem('attorney-id')!!;
        try {
            if ( attorneyStore.attorney ) {
                if ( attorneyId ) {
                    attorneyStore.clearAttorney().then(() => {
                        attorneyService.initiateSession(attorneyId.toString()).then((res) => {
                            if ( res.IsSuccess ) {
                                attorneyStore.setAttorney(res.Data as Attorney);
                                attorneyService.getAttorneyIbans(attorneyId.toString()).then((res) => {
                                    attorneyStore.setSelectedAttorneyIbans(res.Data as AddedIban[]);
                                });
                                attorneyStore.startAttorneySession();
                            }

                        })
                    });
                }

            } else {
                await attorneyStore.clearAttorney();
                attorneyStore.startAttorneySession();
            }
        } catch (error) {
            await attorneyStore.clearAttorney();
        }
    };

    // useEffect(() => {
    //     const automaticRenewSession = () => {
    //
    //         if(isAttorneySessionExpired && !authStore.isTokenExpiring) {
    //
    //             if(checkUserIdleTimeout) {
    //                 clearTimeout(checkUserIdleTimeout);
    //                 checkUserIdleTimeout = null;
    //             }
    //
    //             checkUserIdleTimeout = setTimeout(() => {
    //                 attorneyExtend();
    //             }, 1000);
    //         }
    //     }
    //
    //     document.onmousemove = automaticRenewSession;
    //     document.onmousedown = automaticRenewSession;
    //     document.ontouchstart = automaticRenewSession;
    //     document.onclick = automaticRenewSession;
    //     document.onkeydown = automaticRenewSession;
    //     document.onscroll = automaticRenewSession;
    //
    //     return () => clearTimeout(checkUserIdleTimeout);
    //
    // }, [isAttorneySessionExpired]);

    return (
        <MojPopup
            title={t('auth.expiring-popup.title')}
            open={isAttorneySessionExpired && !authStore.isTokenExpiring}
            onCancel={attorneyStore.clearAttorney}
            maskClosable={false}
            footer={
                <Card noPadding>
                    <Row gutter={[16, 0]} justify="start" align="middle">
                        <Col span="12">
                            {
                                <MojButton
                                    color={'success'}
                                    onClick={attorneyExtend}
                                    loading={loaderStore.isLoading}
                                >
                                    {t("auth.attorney-expiring-popup.extend")}
                                </MojButton>
                            }
                        </Col>
                        <Col span="12">
                            <MojButton color={'warning'} onClick={async () => {
                                const res = await attorneyStore.clearAttorney()
                                if ( res ) attorneyStore.startAttorneySession()
                            }}
                            >
                                {t("auth.attorney-expiring-popup.finish")}
                            </MojButton>
                        </Col>
                    </Row>
                </Card>
            }
        >
            <div style={{textAlign: 'center'}}>
                <p>{t("auth.attorney-expiring-popup.title")}</p>
            </div>
        </MojPopup>
    );
}

export default observer(AttorneyExpiringPopUp);
