import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { ReactComponent as SvgIcon } from '@/core/assets/images/assets-icons-alert.svg';
import { ReactComponent as InfoIcon } from '@/core/assets/images/assets-icons-info.svg';
import './Alert.scss';

export enum AlertVariants {
    Danger = 'danger',
    Success = 'success',
    Warning = 'warning',
    Primary = 'primary',
    Default = 'default',
}

export function Alert({
    children,
    variant,
    className,
    hasCloseIcon,
}: PropsWithChildren<{
    variant: AlertVariants;
    className?: string;
    hasCloseIcon?: boolean;
}>) {
    return (
        <div className={clsx(className, 'moj-alert', `moj-alert--${variant}`)}>
            {(variant === AlertVariants.Danger || variant === AlertVariants.Warning) && (
                <div className="moj-alert__icon">
                    <SvgIcon />
                </div>
            )}
            {variant === AlertVariants.Default && (
                <div className="moj-alert__icon">
                    <InfoIcon />
                </div>
            )}
            <div className="moj-alert__content">{children}</div>
            {hasCloseIcon && <div>X</div>}
        </div>
    );
}
