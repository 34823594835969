import { Link } from 'react-router-dom';
import { User } from 'oidc-client-ts';
import { useTranslation } from 'react-i18next';
import { Badge, Col, Row, Tag } from 'antd';

import { ReactComponent as BurgerMenu } from '@/core/assets/images/assets-icons-menu.svg';
import { ReactComponent as BrandLogo } from '@/core/assets/images/brand-logo-primary.svg';
import { ReactComponent as CloseIcon } from '@/core/assets/images/assets-icons-close-header.svg';
import { loaderStore } from '@/common/stores/loader.store';
import { Attorney, DelegatorTypes } from '@/common/models/Attorney';
import { UserAccordion } from '../shared/UserAccordion/UserAccordion';
import { Card, MojButton } from '../shared';

type BrandProps = {
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
    beneficiaryCount?: number;
    isLoggedIn: boolean;
    authedAttorney?: Attorney;
    isExpanded2: boolean;
    setIsExpanded2: (value: boolean) => void;
    userInfo: User | null | undefined;
    login: () => void;
};

export const Brand: React.FC<BrandProps> = ({
    isExpanded,
    setIsExpanded,
    beneficiaryCount,
    isLoggedIn,
    authedAttorney,
    isExpanded2,
    setIsExpanded2,
    userInfo,
    login,
}) => {
    const { t: translate } = useTranslation();

    return (
        <Card noPadding header className="mobile-header__content">
            <div className="left-section">
                {isExpanded ? (
                    <CloseIcon id="close" onClick={() => setIsExpanded(false)} />
                ) : (
                    <Badge size="default" dot={beneficiaryCount! > 0}>
                        <BurgerMenu
                            id="menu"
                            onClick={() => {
                                setIsExpanded(true);
                            }}
                        />
                    </Badge>
                )}
                <Link to="/">
                    <BrandLogo className="moj-logo" />
                </Link>
            </div>
            <div className="right-section">
                {!isLoggedIn && (
                    <MojButton
                        onClick={() => {
                            loaderStore.setLoader(true);
                            login();
                        }}
                        color="warning"
                    >
                        {translate('header.login')}
                    </MojButton>
                )}
                {isLoggedIn && !authedAttorney && (
                    <UserAccordion isExpanded={isExpanded2} setExpanded={setIsExpanded2}>
                        <Tag color="#00ad68" className="max-lines">
                            {`${userInfo?.profile['http://iam.gov.sa/claims/arabicFirstName']!!} 
                                ${userInfo?.profile['http://iam.gov.sa/claims/arabicFamilyName']!!}`}
                        </Tag>
                        <span id="dyna_userName" style={{ display: 'none' }}>
                            {userInfo?.profile['http://iam.gov.sa/claims/userid'] as string}
                        </span>
                    </UserAccordion>
                )}
                {authedAttorney && (
                    <UserAccordion isExpanded={isExpanded2} setExpanded={setIsExpanded2}>
                        <Col span={24}>
                            <p>
                                {translate('shared.attorney-for')}:
                                {authedAttorney.DelegatorType === DelegatorTypes.Heir && (
                                    <span className="heirsHeadLine">{translate('shared.heirs')}</span>
                                )}
                            </p>
                        </Col>
                        <Row>
                            <Col>
                                <Tag color="#f6b221" className="max-lines">
                                    {authedAttorney.PersonInfo?.Name!!}{' '}
                                </Tag>
                            </Col>
                        </Row>
                    </UserAccordion>
                )}
            </div>
        </Card>
    );
};
