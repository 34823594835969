
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from 'history';

const browserHistory = createBrowserHistory({basename: ''});
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=80f82693-8944-48e5-ab29-78a0d6667e60;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/',
        extensions: [reactPlugin],
        appId:'',
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory}
        }
    }
});

const telemetryInitializer = (envelope) => {
    envelope.tags["frontend.instance"] = process.env.REACT_APP_ENV;
}
appInsights.addTelemetryInitializer(telemetryInitializer);
appInsights.loadAppInsights();
export {reactPlugin, appInsights};
