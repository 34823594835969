import { action, makeObservable, observable } from 'mobx';
import { Attorney, AttorneyItem } from '@common/models/Attorney';
import { AddedIban } from '@common/models/UserDetails';
import { attorneyService } from '@common/services/attorney.service';

export default class AttorneyStore {
    constructor() {
        makeObservable(this);
    }
    @observable
    isLoading = false;
    @observable
    isSessionsInitiated = true;
    @observable
    errorList!: string[];

    @observable
    attorney!: Attorney | undefined;

    //@observable
    //attorneyId!: String | undefined;

    @observable
    attornies!: AttorneyItem[];
    @observable
    selectedAttorney!: AttorneyItem;
    @observable
    selectedAttorneyIbans!: AddedIban[];

    @observable
    isAttorneySessionEnded = false;

    @action
    getAttorneyId = (): string => {
        return sessionStorage.getItem('attorney-id')!!;
    };

    @action
    setLoading = (bool: boolean): void => {
        if (bool) this.setError([]);
        this.isLoading = bool;
    };

    @action.bound
    setSessionReInit = (bool: boolean): void => {
        this.isSessionsInitiated = bool;
    };

    @action.bound
    setAttorney = (attorney: Attorney): void => {
        sessionStorage.setItem('attorney-token', attorney.SessionId!!);
        sessionStorage.setItem('attorney', JSON.stringify(attorney!!));
        this.attorney = attorney;
    };
    @action
    clearAttorney = async () => {
        this.attorney = undefined;
        sessionStorage.removeItem('attorney-token');
        sessionStorage.removeItem('attorney-id');
        sessionStorage.removeItem('attorney');
        return true;
    };
    @action
    setAttornies = (attornies: AttorneyItem[]): void => {
        this.attornies = attornies;
    };
    @action.bound
    setSelectedAttorney = (id: string): void => {
        this.selectedAttorney = this.attornies.find((value) => {
            return value.Id === id;
        })!!;
    };
    @action.bound
    setSelectedAttorneyIbans = (ibans: AddedIban[]): void => {
        this.selectedAttorneyIbans = ibans;
    };
    @action
    setError = (error: string[]) => {
        this.errorList = error;
    };
    @action
    fetchAttorney = () => {
        if (this.attorney === undefined) {
            if (sessionStorage.getItem('attorney')) {
                this.setAttorney(JSON.parse(sessionStorage.getItem('attorney')!!));
                //this.attorneyId = sessionStorage.getItem('attorney-id')!!;
                this.extendsAttorneySession();
            } else this.clearAttorney();
        }
    };
    @action.bound
    extendsAttorneySession = (): void => {
        attorneyService.extendSession().then(() => {
            attorneyService.getAttorneyIbans(this.getAttorneyId()).then((res) => {
                attorneyStore.setSelectedAttorneyIbans(res.Data as AddedIban[]);
            });
        });
    };

    @action.bound
    getAttorneyIbans = () => {
        attorneyService.getAttorneyIbans(this.getAttorneyId()).then((res) => {
            attorneyStore.setSelectedAttorneyIbans(res.Data as AddedIban[]);
        });
    };

    @action
    isAttorneyLoggedIn = () => {
        return !!this.attorney;
    };
    @action
    endAttorneySession = () => {
        this.isAttorneySessionEnded = true;
    };

    @action
    startAttorneySession = () => {
        this.isAttorneySessionEnded = false;
    };
}

export const attorneyStore = new AttorneyStore();
