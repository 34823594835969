import { useCallback, useEffect, useState } from 'react';
import { Col, Divider, Input, Result, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Card, MojButton } from '@/core/components/shared';
import { useCopyText } from '@/common/hooks';
import "./Payment.scss";

const PaymentSuccess = () => {
    const { search } = useLocation();
    const { t } = useTranslation();
    const queryParams = new URLSearchParams(search);
    const requestId = queryParams.get('requestId');
    const [remainingSeconds, setRemainingSeconds] = useState(10);

    const { Icon } = useCopyText({
        text: requestId?.toString(),
        successMessage: t('shared.request-number-copied'),
    });

    useEffect(() => {
        if (remainingSeconds <= 0) {
            window.close();
        }
    }, [remainingSeconds]);

    const runTimer = useCallback(() => {
        const interval = setInterval(() => {
            setRemainingSeconds((prev) => Math.max((prev - 1), 0));
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        runTimer();
    }, [runTimer]);

    return (
        <Row align="middle">
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }} xl={{ span: 8, offset: 8 }}>
                <Card className="payment">
                    <Result
                        status="success"
                        title={t('payment.payment-success')}
                        subTitle={<>
                            <div className="request-number__text">
                                {t('common.request-number')}:{' '}
                                <Input className="request-number__value" value={requestId!} disabled />
                            </div>
                            <div className="request-number__copy-icon">
                                <Icon />
                            </div>
                            <Divider />
                        </>}
                        extra={
                            <Row gutter={12}>
                                <Col span={24}>
                                    {t("payment.auto-close-message", { remainingSeconds })}
                                </Col>
                                <Col span={24} className='action-container'>
                                    <Col span={12}>
                                        <MojButton color="primary" onClick={() => {
                                            window.close();
                                        }} className="btn">
                                            {t('shared.close')}
                                        </MojButton>
                                    </Col>
                                </Col>
                            </Row>
                        }
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default PaymentSuccess;
