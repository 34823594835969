import { createClient } from '@common/services/index';
import { AxiosInstance } from 'axios';
import { TransactionFilter } from '@common/stores/transactions.store';
import { SortingType } from '@/modules/open-market/screens/realestate-and-deals/RealestateAndDealsPage';

export default class TransactionsServices {
    diNetworkInstance!: AxiosInstance;
    sremInstance!: AxiosInstance;

    constructor() {
        this.diNetworkInstance = createClient(`${process.env.REACT_APP_DI_API_URL}api/v1`);
        this.sremInstance = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
    }

    getMyTransactions = async (page: number, filters: TransactionFilter, transactionsOptions: string[]) => {
        var requestTypes: number[] = [];
        if (transactionsOptions?.length > 0) {
            transactionsOptions.forEach((options) => {
                const opts = options && options.length ? [...options.split(',').map((t) => Number(t))] : [];
                requestTypes = [...requestTypes, ...opts];
            });
        }

        return await this.diNetworkInstance
            .post(`MySrem/Transactions`, {
                page: page,
                pageSize: 25,
                //asc:desc  0:1
                sortType: filters.sortType,
                sortField: filters.sortField,
                requestType: requestTypes,
                //requestStatus: filters.requestStatus,
                RequestStatusesIds: filters.RequestStatusesIds,
                priceFrom: filters.priceFrom,
                priceTo: filters.priceTo,
                owner: filters.owner?.id ? filters.owner : null,
                deedNumber: filters.deedNumber ?? null,
                RequestNumber: filters.RequestNumber ?? null
            })
            .then((response: any) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyPendingTransactions = async (page: number, filters: TransactionFilter, transactionsOptions: string[]) => {
        var requestTypes: number[] = [];
        if (transactionsOptions?.length > 0) {
            transactionsOptions.forEach((options) => {
                const opts = options && options.length ? [...options.split(',').map((t) => Number(t))] : [];
                requestTypes = [...requestTypes, ...opts];
            });
        }

        return await this.diNetworkInstance
            .post(`MySrem/GetMyActionNeededTransactions`, {
                page: page,
                pageSize: 25,
                //asc:desc  0:1
                sortType: filters.sortType,
                sortField: filters.sortField,
                requestType: requestTypes,
                //requestStatus: filters.requestStatus,
                RequestStatusesIds: filters.RequestStatusesIds,
                priceFrom: filters.priceFrom,
                priceTo: filters.priceTo,
                owner: filters.owner?.id ? filters.owner : null,
                deedNumber: filters.deedNumber ?? null,
                RequestNumber: filters.RequestNumber ?? null
            })
            .then((response: any) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };
    getShamilTransactionsByNationalId = async (page: number, sortingType: SortingType, filters: any, transactionFormatFilter?: number[]) => {
      const keys = Object.keys(filters);
      const object: any = {};
      for (let key of keys) {
          object[key] = filters[key] ?? null;
      }
      return this.diNetworkInstance.post(
          `/SremRealEstates/ShamilTransactionsByNationalId`,
          {
              page: page,
              pageSize: 50,
              sortType: sortingType.sortType,
              sortField: sortingType.sortField,
              ...filters,
              transactionFormatFilter: transactionFormatFilter
          }
      )
          .then((res) => {
              return res;
          })
          .catch(err => {
              return err;
          });
  }
    getArchivedTransaction = async (transactionId: string) => {
        return await this.diNetworkInstance.get(`ShamilTransactionById/${transactionId}`)
        .then((response: any) => {
            return response;
        }).catch((reason) => {
            return reason;
        });
    }

    getTransaction = async (transactionId: string) => {
        return await this.diNetworkInstance
            .get(`MySrem/Transaction/${transactionId}`)
            .then((response: any) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getBeneficiaryActionsCount = async () => {
        return await this.diNetworkInstance
        .get('MySrem/GetBenfenciaryActionsCount')
        .then((response : any) => {
          return response;
        }).catch((exception) => {
            return exception;
        });
    }

    updateTransactionReadability = async (requestId : string | null) => {
        return await this.diNetworkInstance.post('MySrem/MarkRequestAsRead',{RequestId : requestId})
        .then((response : any) => {
          return response;
        }).catch((exception) => {
            return exception;
        });
    }

    getMyTransactionsStatuses = async () => {
        return await this.diNetworkInstance.get('MySrem/GetMyTransactionsStatuses')
            .then((response : any) => {
                return response;
            }).catch((exception) => {
                return exception;
            });
    }

    getMyTransactionTypes = async () => {
        return await this.diNetworkInstance.get('MySrem/Transaction/Filters')
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
               return error;
            });
    }

    getExtendDurationOptions = async (transactionId: string) => {
        return await this.sremInstance.get(`OpenMarket/GetRequestToExtend?requestId=${transactionId}`)
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
               return error;
            });
    }

    extendTransactionDuration = async (requestId: string, expiryDate: string, expiryDateDays: number= 0) => {
        return await this.sremInstance.post(`/OpenMarket/UpdateRequestExpiryDate`, { expiryDateDays, expiryDate, requestId })
            .then((response: any) => {
                return response;
            })
            .catch((error) => {
               return error;
            });
    }
}
