import React, { useEffect, useState } from 'react';
import { MojButton, MojPopup } from '@core/components/shared';
import { useTranslation } from 'react-i18next';
import { authStore } from '@/common/stores/auth.store';
import { observer } from 'mobx-react-lite';
import { PlainLabel, PlainLabelVariant } from '../PlainLabel/PlainLabel';
import AttorneyService from '@common/services/attorney.service';
import { attorneyStore } from '@common/stores/attorney.store';
import { Attorney } from "@common/models/Attorney";
import { AddedIban } from "@common/models/UserDetails";

function TokenExpiringPopUp() {
    const isTokenExpiring = authStore.isTokenExpiring;
    const attorneyService = new AttorneyService();
    const { t } = useTranslation();

    // let checkUserIdleTimeout: any = null;

    const attorneyExtend = async () => {
        try {
            if (attorneyStore.attorney) {
                const attorneyId = sessionStorage.getItem('attorney-id')!!;
                attorneyStore.clearAttorney().then(() => {
                    attorneyService.initiateSession(attorneyId.toString()).then((res) => {
                        if (res.IsSuccess) {
                            attorneyStore.setAttorney(res.Data as Attorney);
                            attorneyService.getAttorneyIbans(attorneyId.toString()).then((res) => {
                                attorneyStore.setSelectedAttorneyIbans(res.Data as AddedIban[]);
                            });

                        }
                        attorneyStore.startAttorneySession();
                    })
                });
            } else {
                await attorneyStore.clearAttorney();
                attorneyStore.startAttorneySession();
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const silentLogin = async () => {
        try {
            const res = await authStore.silentLogin();
            if ( res ){
                await attorneyExtend();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getFormattedTime = (timeInSeconds: number): string => {
        const seconds = timeInSeconds % 60;

        const minutes = ((timeInSeconds - seconds) / 60) % 60;

        const hours = (timeInSeconds - minutes * 60 - seconds) / 60 / 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
    };

    const [timeLeft, setTimeLeft] = useState(
        parseInt(process.env.REACT_APP_IAM_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME!)
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1 < 0 ? 0 : timeLeft - 1);
        }, 1000);

        return () => clearTimeout(timer);
    });

    useEffect(() => {
        if (isTokenExpiring) {
            setTimeLeft(parseInt(process.env.REACT_APP_IAM_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME!));
        }
    }, [isTokenExpiring]);

    // useEffect(() => {
    //     const automaticRenewSession = () => {
    //
    //         if(timeLeft <= 10 && isTokenExpiring) {
    //
    //             if(checkUserIdleTimeout) {
    //                 clearTimeout(checkUserIdleTimeout);
    //                 checkUserIdleTimeout = null;
    //             }
    //
    //             checkUserIdleTimeout = setTimeout(() => {
    //                 silentLogin();
    //                 setTimeLeft(parseInt(process.env.REACT_APP_IAM_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME!));
    //             }, 1000);
    //         }
    //     }
    //
    //     document.onmousemove = automaticRenewSession;
    //     document.onmousedown = automaticRenewSession;
    //     document.ontouchstart = automaticRenewSession;
    //     document.onclick = automaticRenewSession;
    //     document.onkeydown = automaticRenewSession;
    //     document.onscroll = automaticRenewSession;
    //
    //     return () => clearTimeout(checkUserIdleTimeout);
    //
    // }, [timeLeft]);

    return (
        <MojPopup
            title={t('auth.expiring-popup.title')}
            open={isTokenExpiring}
            onCancel={authStore.logout}
            maskClosable={false}
            footer={
                <MojButton color={'success'} onClick={silentLogin}>
                    {t('shared.continue')}
                </MojButton>
            }
        >
            <div style={{ textAlign: 'center' }}>
                <p>{t('auth.expiring-popup.going-to-expire-in')}</p>
                <PlainLabel variant={PlainLabelVariant.danger} title={getFormattedTime(timeLeft)}></PlainLabel>
            </div>
        </MojPopup>
    );
}

export default observer(TokenExpiringPopUp);
