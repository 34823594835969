import { getBankLogo } from '@/common/utils/bank-logo.util/bank-logos.util';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldWrapper, ReadOnlyField, ReadOnlyFieldVariant } from '..';
import './IbanInput.scss';
import { Input, InputProps, Row, Col, ConfigProvider } from 'antd';
import {FormikProps, FormikValues} from "formik";
import {MojFormItem} from "@core/components/shared/MojFormItem/MojFormItem";
import { observer } from 'mobx-react-lite';
import {convertToEnglishNumbers} from "@common/utils/numbers-utils";

export type IbaninputProps = {
    label?: string | React.ReactNode;
    initialValue?: string;
    readonly?: boolean;
    onChange?: (iban: string) => void;
    onValidationChange?: (isValid: boolean) => void;
    prefix?: string;
    isRefund? : boolean;
    handleSelectedIban?:(selectedIban: string | null) => void;
    formikProps?: FormikProps<FormikValues>;
    required?: boolean;
};

interface IIbaninputProps extends Omit<InputProps, 'onChange' | 'prefix' | 'value'>, IbaninputProps {}

export function IbanInput({
    label,
    readonly,
    initialValue,
    prefix = 'SA',
    onChange: originalOnChange,
    onValidationChange,
    isRefund,
    handleSelectedIban,
    formikProps,
    required = false,
    ...inputProps
}: IIbaninputProps) {
    const [enteredIban, setEnteredIban] = React.useState(initialValue || '');
    const { t } = useTranslation();
    const bankLogo = React.useMemo(() => {
        const fullIbanNumber = readonly && enteredIban.startsWith(prefix) ? enteredIban : `${prefix}${enteredIban}`;
        return getBankLogo(fullIbanNumber);
    }, [enteredIban, prefix, readonly]);

    React.useEffect(() => {
        const isValid = /^\d{4}[0-9A-Z]{18}$/.test(enteredIban);
        onValidationChange && onValidationChange(isValid);
    }, [enteredIban, onValidationChange]);

    const inputId = inputProps && inputProps['id'] ? inputProps['id'] : 'iban';

    const onInputChange = useCallback(
        (e) => {
            e.currentTarget.value = convertToEnglishNumbers(e.currentTarget.value).toLocaleUpperCase();

            const pureValue = e.currentTarget.value.replace(/^[SAsa\s]+/, '');

            if(pureValue.length >= 23) {
                return;
            }

            setEnteredIban(pureValue);
            let fullIbanNumber = pureValue ? `${prefix}${pureValue}` : '';
            if (originalOnChange) {
                originalOnChange(fullIbanNumber);
            }

            if(formikProps) {
                const ev = e;
                ev.currentTarget.value = fullIbanNumber;
                ev.target.value = fullIbanNumber;
                formikProps.getFieldProps(inputId).onChange(ev);
            }
        },
        [setEnteredIban, originalOnChange, prefix]
    );

    return (
        <ConfigProvider direction="ltr">
            <div className="iban-input">
                <div className="iban-input__text-field">
                    {!readonly && (
                        <FieldWrapper label={label || t('common.bank-iban-number')} required={!!required}>
                            <MojFormItem
                                htmlFor={inputId}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                validateStatus={(formikProps && formikProps.errors[inputId]) && 'error'}
                                help={formikProps && formikProps.touched[inputId] && formikProps.errors[inputId]}
                            >
                            <Input
                                prefix={
                                    <>
                                        {bankLogo && <img className="logo-image" src={bankLogo} alt="bank logo" />}
                                        {!bankLogo && <div className="fake-bank-logo" />}
                                    </>
                                }
                                lang="en"
                                dir={'ltr'}
                                type="search"
                                maxLength={24}
                                onInput={onInputChange}
                                onChange={(e) => e.target.value === '' && onInputChange(e)}
                                placeholder={'xx xxxx xxxx xxxx xxxx xxxx'}
                                suffix={'SA'}
                                // mask={"1111111111111111111111"}
                                {...inputProps}
                                value={enteredIban}
                                inputMode="numeric"
                                // onKeyPress={(event) => {
                                //     if (!/[0-9.]/.test(event.key)) {
                                //         event.preventDefault();
                                //     }
                                // }}
                                style={{ paddingInlineEnd: 0 }}
                            />
                            </MojFormItem>
                        </FieldWrapper>
                    )}
                    {readonly && (
                        <Row>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    label={label || t('common.bank-iban-number')}
                                    variant={ReadOnlyFieldVariant.primary}
                                    title={enteredIban}
                                    small
                                    noBorder
                                />
                            </Col>
                            <Col>
                                {bankLogo && <img className="logo-image" src={bankLogo} alt="bank logo" />}
                                {!bankLogo && <div className="fake-bank-logo" />}
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </ConfigProvider>
    );
}
export default observer(IbanInput);
