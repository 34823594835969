import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps, FormikValues } from 'formik';
import { Alert, AlertVariants, Card } from '@core/components/shared';
import { Col, Input, Row } from 'antd';
import { MojFormItem } from '@core/components/shared/MojFormItem/MojFormItem';
import { OtpResponse } from '@/core/interfaces/OtpResponse.interface';
import { getFormattedTime } from '@/common/utils/forms-utils/forms-utils';
import './OtpForm.scss';
import clsx from 'clsx';
import { convertToEnglishNumbers } from "@common/utils/numbers-utils";
import { arabicToEnglishMap } from '@/common/utils/constants';
import OtpIcon from './OtpIcon';

type OtpFormikProps = FormikProps<FormikValues>;

interface OtpFormProps extends Partial<OtpFormikProps> {
    otpConfiguration: OtpResponse;
    onRetry?: () => void;
    onChange?: (e) => void;
    hideHint?: boolean;
}

const OtpForm = ({
    handleSubmit,
    values,
    errors = {},
    getFieldProps,
    onChange = (e: string) => { },
    otpConfiguration,
    onRetry = () => { },
    hideHint,
    setFieldValue,
    handleBlur,
    dirty,
    isValid,
    touched = {},
    isSubmitting,
}: OtpFormProps) => {
    const { t } = useTranslation();
    const [config, updateConfig] = useState(otpConfiguration);
    const [otp, setOtp] = useState('');

    const memoizedIcon = useMemo(() => {
        return <>{<OtpIcon />}</>
    }, []);

    useEffect(() => {
        if (
            otpConfiguration.Key !== config.Key ||
            otpConfiguration.RemainingRetries !== config.RemainingRetries ||
            config.Attempt !== otpConfiguration.Attempt
        ) {
            updateConfig({ ...otpConfiguration });
        }
    }, [otpConfiguration, config]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (config.RemainingSeconds === 0) {
                clearInterval(intervalId);
            } else {
                updateConfig({ ...config, RemainingSeconds: config.RemainingSeconds - 1 });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [config]);

    return (
        <div className="section">
            <Card className="section__container">
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card.Head className="section__title border-bottom">
                            <Col flex="1 1 auto">{t('otp-confirmation.otp-input-message')}</Col>
                            <Col>{!hideHint && <>{memoizedIcon}</>}</Col>
                        </Card.Head>
                        <Card.Body>
                            <Row className="section__basics border-bottom">
                                <Col flex="100%">
                                    <Alert variant={AlertVariants.Warning}>{t('shared.absher-mobile-alert')}</Alert>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Col>
                </Row>
                <Row gutter={[0, 0]}>
                    <Col span="24">
                        <MojFormItem
                            label={t('otp-confirmation.verification-code')}
                            htmlFor="otpNumber"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            validateStatus={errors['otpNumber'] && 'error'}
                            help={touched['otpNumber'] && errors['otpNumber']}
                        >
                            {getFieldProps ? (
                                <Input
                                    maxLength={4}
                                    allowClear
                                    placeholder="XXXX"
                                    id="otpNumber"
                                    inputMode={'tel'}
                                    disabled={otpConfiguration.RemainingRetries === 0}
                                    {...getFieldProps('otpNumber')}
                                    onChange={e => {
                                        e.target.value = convertToEnglishNumbers(e.target.value);
                                        const fieldProps: any = { ...getFieldProps('otpNumber') }
                                        fieldProps.onChange(e);
                                    }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key) && !arabicToEnglishMap[event.key]) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            ) : (
                                <Input
                                    maxLength={4}
                                    allowClear
                                    placeholder="XXXX"
                                    id="otpNumber"
                                    disabled={otpConfiguration.RemainingRetries === 0}
                                    inputMode={'tel'}
                                    onChange={(e) => {
                                        e.target.value = convertToEnglishNumbers(e.target.value);
                                        setOtp(e.currentTarget.value);
                                        onChange && onChange(e.currentTarget.value);
                                    }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={otp}
                                />
                            )}
                        </MojFormItem>
                    </Col>
                </Row>
                <Row className="section__otp-actions">
                    <Col className="not-received" span={24}>
                        {t('otp.not-received')}
                    </Col>
                    <Col
                        className={clsx('resend-action', config.RemainingSeconds === 0 && 'resend-action--active')}
                        span={24}
                    >
                        <span
                            onClick={() => {
                                if (config.RemainingSeconds <= 0) {
                                    onRetry();
                                }
                            }}
                            className={clsx('resend', config.RemainingSeconds === 0 && 'resend--active')}
                        >
                            {t('otp.resend')}
                        </span>
                        {config.RemainingSeconds !== 0 && (
                            <span className="seconds">{getFormattedTime(config.RemainingSeconds)}</span>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};
export default OtpForm;
