import React, { FunctionComponent } from 'react';
import { ReactComponent as FilterIcon } from '@/core/assets/images/assets-icons-filters.svg';
import { ReactComponent as SearchIcon } from '@/core/assets/images/assets-icons-search.svg';
import { ReactComponent as SortIcon } from '@/core/assets/images/assets-icons-sort.svg';
import { ReactComponent as CloseIcon } from '@/core/assets/images/assets-icons-close.svg';
import { ReactComponent as InfoIcon } from '@/core/assets/images/assets-icons-info.svg';
import { ReactComponent as DownloadIcon } from '@/core/assets/images/assets-icons-download.svg';
import { ReactComponent as FilterNotificationIcon } from "@/core/assets/images/assets-icons-filters-notifications.svg";
import CSVIcon from "@core/assets/images/csv.png";
import './FilterButton.scss';
import { Link, LinkProps } from 'react-router-dom';

type Props = Omit<LinkProps, 'to'> & {
    variant: FilterButtonVariants;
    to?: LinkProps['to'];
};

export enum FilterButtonVariants {
    search,
    filter,
    sort,
    close,
    info,
    download,
    filterNotification,
    csv
}

const FilterButton: FunctionComponent<Props> = ({variant, to, ...props}) => {
    function Icon() {
        switch (variant) {
            case FilterButtonVariants.search:
                return <SearchIcon/>;
            case FilterButtonVariants.filter:
                return <FilterIcon/>;
            case FilterButtonVariants.filterNotification:
                return <FilterNotificationIcon />;
            case FilterButtonVariants.sort:
                return <SortIcon/>;
            case FilterButtonVariants.close:
                return <CloseIcon/>;
            case FilterButtonVariants.info:
                return <InfoIcon/>;
            case FilterButtonVariants.download:
                return <DownloadIcon/>;
            case FilterButtonVariants.csv:
                return <img src={CSVIcon} alt="CSV" />;
            default:
                return <SortIcon/>;
        }
    }

    return (
        <Link to={to || '#'} {...props}>
            <Icon/>
        </Link>
    );
};

export default FilterButton;
