import { action, makeObservable, observable } from 'mobx';
import { Attorney } from '@common/models/Attorney';

export default class SessionStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    isLoading = false;
    @observable
    isSessionsInitiated = true;
    @observable
    errorList!: string[];

    @observable
    attoreny!: Attorney;

    @action
    setLoading = (bool: boolean): void => {
        if (bool) this.setError([]);
        this.isLoading = bool;
    };

    @action
    setSessionReInit = (bool: boolean): void => {
        this.isSessionsInitiated = bool;
    };

    @action
    setAttorney = (attoreny: Attorney): void => {
        this.attoreny = attoreny;
    };

    @action
    setError = (error: string[]) => {
        this.errorList = error;
    };
}

export const sessionStore = new SessionStore();
