import React, { FunctionComponent, useMemo } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getReadableNumber, getReadableNumberWithFormat } from '@common/utils/numbers-utils';
import { ReadOnlyField, ReadOnlyFieldVariant } from '../ReadonlyField/ReadonlyField';
import MojLabel, { LabelVariant } from '@core/components/shared/Label/MojLabel';
import { getDateFormatted } from '@/common/utils/date-utils';
import { Transaction } from '@common/models/Transaction';
import './TransactionCard.scss';

interface OwnProps {
    transaction: Transaction;
    showAmount?: boolean;
    isRealEstateInfo?: boolean;
}

export const labelColor = (statusStep: number): LabelVariant | undefined => {
    if (statusStep === 1) return LabelVariant.positive;
    else if (statusStep === 2) return LabelVariant.negative;
    else if (statusStep === 3) return LabelVariant.user;
    else if (statusStep === 4) return LabelVariant.system;
    else if (statusStep === 50) return LabelVariant.danger;
};

type Props = OwnProps;

const TransactionCard: FunctionComponent<Props> = ({ transaction, showAmount = false, isRealEstateInfo = false }) => {
    const { t } = useTranslation();
    const createdDate = useMemo(() => {
        if (transaction.CreatedOn) {
            return getDateFormatted(new Date(transaction.CreatedOn as string));
        }
    }, [transaction]);

    const transactionPrice = () => {
        return transaction.PricePerMeter ? <>{getReadableNumberWithFormat(transaction.PricePerMeter)}</> : null;
    };

    const formattedArea = useMemo(() => {
        return `${transaction.District ? `${transaction.District},` : ''} ${transaction.City || ''}`;
    }, [transaction]);

    const areaInfo = React.useMemo(() => {
        // check if Area not equal to TransactionArea
        const areaText = getReadableNumber(transaction.Area);
        const suffix =
            transaction.Area !== transaction?.TransactionArea ? (
                <MojLabel className={'shared-tag'} color={LabelVariant.warning}>
                    {t(`${t('realestate.shared')}`)}
                </MojLabel>
            ) : (
                ''
            );
        return {
            area: suffix ? (
                <>
                    {areaText} {suffix}
                </>
            ) : (
                areaText
            ),
        };
    }, [transaction]);

    return (
        <div className="transaction-card">
            <Row justify="start" align="middle" gutter={[0, 8]}>
                <Col className="card-col__area" xs={24}>
                    {transaction.ActionNeeded && (
                        <sup
                            style={{ marginBottom: '10px' }}
                            data-show="true"
                            className="ant-scroll-number ant-badge-dot notification-flag"
                        ></sup>
                    )}
                    <h3>{formattedArea}</h3>
                    {isRealEstateInfo && <h5>{transaction.SremRealestateDescription}</h5>}
                </Col>

                {transaction.RealEstateIdentityNo && (
                    <Col className="card-col" xs={24}>
                        <ReadOnlyField
                            title={transaction.RealEstateIdentityNo}
                            label={t('shared.realestate-identity-no')}
                            variant={ReadOnlyFieldVariant.success}
                            small
                        />
                    </Col>
                )}

                <Col className="card-col" flex="1">
                    <ReadOnlyField
                        title={transaction.RequestTypeName}
                        label={t('my-transaction.transaction-type')}
                        variant={ReadOnlyFieldVariant.success}
                    />
                </Col>

                <Col className="card-col" flex="1">
                    <ReadOnlyField
                        title={transaction.LandNumber}
                        label={t('realestate.land-number')}
                        variant={ReadOnlyFieldVariant.primary}
                    />
                </Col>

                <Col className="card-col" flex="1">
                    <ReadOnlyField
                        title={transaction.PlanNumber}
                        label={t('realestate.plan-number')}
                        variant={ReadOnlyFieldVariant.primary}
                    />
                </Col>

                {transaction.Status && (
                    <Col className="card-col__label" flex="2">
                        <MojLabel color={labelColor(transaction.StatusStep)!!}>{transaction.Status}</MojLabel>
                    </Col>
                )}
            </Row>
            {showAmount && (
                <Row justify="start" align="middle" gutter={[0, 8]}>
                    <Col className="card-col" flex="1">
                        <ReadOnlyField
                            title={getReadableNumberWithFormat(transaction.Amount)}
                            label={isRealEstateInfo ? t('shared.value') : t('shared.deal-value')}
                            variant={ReadOnlyFieldVariant.success}
                        />
                    </Col>
                    <Col className="card-col" flex="1">
                        <ReadOnlyField
                            title={transactionPrice()}
                            label={t('deal.price-per-meter')}
                            unit={t('shared.sar-per-meter')}
                            variant={ReadOnlyFieldVariant.primary}
                            small
                        />
                    </Col>
                    <Col className="card-col" flex="1">
                        <ReadOnlyField
                            title={createdDate}
                            label={'تاريخ العملية'}
                            variant={ReadOnlyFieldVariant.primary}
                            small
                        />
                    </Col>
                    <Col className="card-col" flex="1">
                        <ReadOnlyField
                            label={t('shared.area')}
                            title={areaInfo.area}
                            variant={ReadOnlyFieldVariant.primary}
                            unit={t('shared.square-meter')}
                        />
                    </Col>
                </Row>
            )}
            {createdDate && !showAmount && (
                <Row justify="end">
                    <Col className="transaction-date">{createdDate}</Col>
                </Row>
            )}
        </div>
    );
};

export default TransactionCard;
