import { Switch, SwitchProps } from 'antd';
import clsx from 'clsx';
import React from 'react';
import './MojSwitch.scss';

export enum MOJSwitchVariants {
    Success = 'success',
    Danger = 'danger',
}

interface MojSwitchProps extends SwitchProps {
    variant: MOJSwitchVariants;
}
export function MojSwitch({ variant, className, ...props }: MojSwitchProps) {
    return <Switch className={clsx('moj-switch', `moj-switch--${variant}`, className)} {...props} />;
}
