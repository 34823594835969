import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Alert, AlertVariants, Card, ReadOnlyField, ReadOnlyFieldVariant } from '@/core/components/shared';
import { ReactComponent as SadadLogo } from '@core/assets/images/bank-logo-sadad.svg';
import { IVatDetails } from '@common/models/Transaction';
import Classes from './SadadTaxInfo.module.scss';

export function SadadTaxInfo({ vatDetails }: { vatDetails: IVatDetails }) {
    const { t } = useTranslation();

    return (
        <div className={Classes.section}>
            <Card className="section__container">
                <Row align="middle">
                    <Col flex="1">
                        <Card.Head className="section__title border-bottom">{t('sadad.info.title')}</Card.Head>
                    </Col>
                </Row>
                <Card.Body className={Classes.marginTop}>
                    <Row className={Classes.marginBottom}>
                        {vatDetails.VatReqNumber && (
                            <Col sm={8} xs={24}>
                                <ReadOnlyField
                                    label={t('sadad.info.reference-number')}
                                    title={vatDetails.VatReqNumber}
                                    variant={ReadOnlyFieldVariant.success}
                                />
                            </Col>
                        )}
                        {vatDetails.VatStatus > 0 && (
                            <Col sm={8} xs={12}>
                                <ReadOnlyField
                                    label={t('sadad.info.payment-status')}
                                    title={t(`sadad.${vatDetails.VatStatus === 1 ? 'paid' : 'unpaid'}`)}
                                    variant={
                                        vatDetails.VatStatus === 1
                                            ? ReadOnlyFieldVariant.success
                                            : ReadOnlyFieldVariant.danger
                                    }
                                />
                            </Col>
                        )}
                        <Col sm={8} xs={12}>
                            <SadadLogo className={Classes.sadadLogo} />
                        </Col>
                    </Row>
                    <Row>
                        {vatDetails.VatSadadNumber && (<>
                            <Col span={12}>
                                <ReadOnlyField
                                    label={t('sadad.info.sadad-invoice-number')}
                                    title={vatDetails.VatSadadNumber}
                                    variant={ReadOnlyFieldVariant.success}
                                />
                            </Col>

                            <Col xs={24}>
                                <Alert variant={AlertVariants.Warning}>{t('shared.sadad-alert')}</Alert>
                            </Col>
                        </>)}
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}
