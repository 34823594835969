import GenericResponse from '@/common/models/GenericResponse';
import userProfileService from '@/common/services/user-profile.service';
import { loaderStore } from '@/common/stores/loader.store';
import AddingBankAccountForm from '@/modules/profile/components/AddingBankAccountForm/AddingBankAccountForm';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from "@common/stores/auth.store";
import {errorsListToErrorDetails} from "@common/utils/deed-utils";
import { refundManagmentStore } from '@/common/stores/refundManagment.store';
import {attorneyStore} from "@common/stores/attorney.store";
import AttorneyService from "@common/services/attorney.service";


interface AddIbanProps {
    onBackClick?: () => void;
    onValidationChange?: (flag: boolean) => void;
    onAddIban?: (iban: string) => void;
    hideBack?: boolean;
    hideSubmit?: boolean;
    isRefund?: boolean;
    handleRefundSubmitDisabled?: (state: number) => void;
    handleSelectedIban?: (selectedIban: string| null) => void;
}

export function AddIban({
    onBackClick,
    onValidationChange,
    onAddIban,
    hideBack,
    hideSubmit,
    isRefund,
    handleRefundSubmitDisabled,
    handleSelectedIban
}: AddIbanProps) {
    const { t } = useTranslation();
    const [updatedIban, setUpdatedIban] = useState('');
    const [isIbanValid, updateIbanValidity] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [errorList, setErrorList] = useState<string[]>([]);
    const [errorDetails, setErrorDetails] = useState<any[]>([]);
    const [correlationId, setCorrelationId] = useState('');
    const {setNewChosenIban} = refundManagmentStore;

    const attorneyService = new AttorneyService();

    const setErrorDetailsObj = (_errorDetails?: any[]) => {
        if(!_errorDetails || _errorDetails.length === 0) {
            setErrorDetails([]);
        } else {
            if(typeof _errorDetails[0] === "string") {
                setErrorDetails(errorsListToErrorDetails(_errorDetails));
            } else {
                setErrorDetails(_errorDetails);
            }
        }
    }

    const handleIbanSubmit = useCallback(() => {
        loaderStore.setLoader(true);
        setIsLoading(true);

        if (attorneyStore.attorney) {
            addIbanForAttorney();
        } else if(authStore.userDetails) {
            addIbanForUser();
        } else {
            addIbanForUser();
        }

    }, [updatedIban]);

    const addIbanForUser = () => {
        userProfileService.addIban(updatedIban).then((response: GenericResponse) => {
            setIsLoading(false);
            loaderStore.setLoader(false);
            if (response.IsSuccess && response.Data && response.Data.Updated) {
                if (onAddIban) {
                    onAddIban(updatedIban);
                    authStore.noIbans=false;
                    setNewChosenIban(updatedIban);
                }

                return;
            }

            setErrorDetailsObj(response.ErrorDetails ?? response.ErrorList);
            setCorrelationId(response.CorrelationId);
        }).catch(error => {
            setIsLoading(false);
        });
    }

    const addIbanForAttorney = () => {
        attorneyService.addAttorneyIban({ instanceId: attorneyStore.getAttorneyId(), iban: updatedIban }).then((response: GenericResponse) => {
            setIsLoading(false);
            loaderStore.setLoader(false);

            if (response?.IsSuccess!!) {
                if (onAddIban) {
                    onAddIban(updatedIban);
                    setNewChosenIban(updatedIban);
                }

                return;
            }

            setErrorDetailsObj(response.ErrorDetails ?? response.ErrorList);
            setCorrelationId(response.CorrelationId);
        }).catch(error => {
            setIsLoading(false);
        });
    }

    return (
        <AddingBankAccountForm
            title={t('profile.adding-bank-account.title')}
            onChange={(iban) => setUpdatedIban(iban)}
            onValidationChange={(isValid) => {
                if (onValidationChange) {
                    onValidationChange(isValid);
                }
                updateIbanValidity(isValid);
            }}
            onBackClick={() => {
                onBackClick && onBackClick();
            }}
            hideBack={!!hideBack}
            ibanValid={isIbanValid}
            onSubmit={handleIbanSubmit}
            isLoading={isLoading}
            errorDetails={errorDetails}
            correlationId={correlationId}
            hideSubmit={hideSubmit}
            isRefund={isRefund}
            handleRefundSubmitDisabled={handleRefundSubmitDisabled}
            handleSelectedIban={handleSelectedIban}
            attorneyType={attorneyStore.attorney?.DelegatorType}
        />
    );
}
