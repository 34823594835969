import React from 'react';
import { observer } from 'mobx-react-lite';

import { Col, Row } from 'antd';
import { Card } from '@core/components/shared';
import { MojCheckbox } from '@core/components/shared/Checkbox/MojCheckbox';
import LegendIcon from '../../LegendIcon/LegendIcon';

import { realestateAndDealsStore } from '@common/stores/realestate-and-deals.store';
import { useTranslation } from 'react-i18next';

import './NewRealestateCheckboxFilters.scss';
import { mapViews } from '@/common/utils/constants';

const RealestateCheckboxFilters = ({ closeDrawer }) => {
    const { t } = useTranslation();
    const checkboxOptions = [
        { label: 'معروض', value: 0, purchaseOrders: 0, openMarketOrders: 1 },
        { label: 'مطلوب', value: 1, purchaseOrders: 1, openMarketOrders: 0 },
        { label: 'متاح', value: 2, purchaseOrders: 0, openMarketOrders: 0 },
    ];

    const checkboxGroupHandler = (option: { label: string; value: number }) => {
        // making sure you can't uncheck the only remaining option
        // business want's at all times at least one option is checked
        if (
            realestateAndDealsStore.realestateOptions.length - 1 === 0 &&
            realestateAndDealsStore.realestateOptions?.includes(option.value)
        )
            return;

        if (realestateAndDealsStore.realestateOptions?.includes(option.value)) {
            realestateAndDealsStore.updateRealestatesOptions(
                realestateAndDealsStore.realestateOptions.filter((item) => item !== option.value)
            );
        } else {
            realestateAndDealsStore.updateRealestatesOptions([
                ...realestateAndDealsStore.realestateOptions,
                option.value,
            ]);
        }
        closeDrawer();
    };

    const checkAllhandler = () => {
        let flag = false;
        checkboxOptions.forEach((item) => {
            if (!realestateAndDealsStore.realestateOptions?.includes(item.value)) {
                flag = true;
            }
        });
        if (flag) {
            realestateAndDealsStore.updateRealestatesOptions([0, 1, 2]);
        } else {
            realestateAndDealsStore.updateRealestatesOptions([]);
        }
        closeDrawer();
    };

    return (
        <Card.Body>
            <Col flex="auto" className="filter-container">
                <Row>
                    <Col span={24} className="filter-title-container">
                        <p className="filter-title">{t('checkbox-filter.lbl-realstate-status')}</p>
                    </Col>
                </Row>
                <Row className="filter-selection">
                    <>
                        <div className="filter-select-option" key={3}>
                            <MojCheckbox
                                value={3}
                                key={3}
                                checked={
                                    realestateAndDealsStore.realestateOptions?.includes(0) &&
                                    realestateAndDealsStore.realestateOptions?.includes(1) &&
                                    realestateAndDealsStore.realestateOptions?.includes(2)
                                }
                                onChange={() => {
                                    checkAllhandler();
                                }}
                                className="moj-input"
                            >
                                {t('checkbox-filter.lbl-all')}
                            </MojCheckbox>
                        </div>
                        {realestateAndDealsStore?.mapViews?.['propertyStatusEnums'].map((value, index) => {
                            let option = checkboxOptions.filter(
                                (item) =>
                                    item?.purchaseOrders === value?.purchaseOrder &&
                                    item.openMarketOrders === value?.openMarketOrder
                            )?.[0];
                            return (
                                <div className="filter-select-option" key={option?.value}>
                                    <MojCheckbox
                                        value={option?.value}
                                        key={option?.value}
                                        checked={realestateAndDealsStore.realestateOptions?.includes(option?.value)}
                                        onChange={() => {
                                            checkboxGroupHandler(option);
                                        }}
                                        className="checkbox-item"
                                    >
                                        {option?.label}
                                    </MojCheckbox>
                                    <LegendIcon
                                        color={value?.color}
                                        isProperty={true}
                                        mapViewType={realestateAndDealsStore.mapViewType}
                                    />
                                </div>
                            );
                        })}
                    </>
                </Row>
            </Col>
        </Card.Body>
    );
};
export default observer(RealestateCheckboxFilters);
