import { AxiosInstance, AxiosResponse } from 'axios';
import GenericResponse from '@common/models/GenericResponse';
import { createClient } from '@common/services/index';
import { MortgageeRequest } from '@common/models/MortgageRequest';
import { IOwnerBirthDateUpdateModel } from '@common/models/OpenMarketRequest';

export default class MortgageService {
    HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
    }

    getDeedRelease = async (body: {
        deedNumber: string;
        idNumber: string;
        idType: string;
    }): Promise<GenericResponse> => {
        let res: GenericResponse;
        res = await this.HttpClient.get(`Deed/GetDeed/Release/${body.deedNumber}/${body.idNumber}/${body.idType}`)
            .then((response) => {
                return <GenericResponse>response.data;
            })
            .catch((reason) => {
                return reason;
            });

        return res;
    };

    validateDeed = async (body: { deedNumber: string; idNumber: string; idType: string }) => {
        await this.HttpClient.get(`Validation/Deed`)
            .then((response) => {
                return response.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    validateMortagor = async (body: { deedNumber: string; idNumber: string; idType: string }) => {
        await this.HttpClient.get(`Validation/Mortagor`)
            .then((response) => {
                return response.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    validateMortagee = async (body: { deedNumber: string; idNumber: string; idType: string }) => {
        await this.HttpClient.get(`Validation/Mortagee`)
            .then((response) => {
                return response.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    validateMortageInfo = async (body: { deedNumber: string; idNumber: string; idType: string }) => {
        return await this.HttpClient.get(`Validation/MortageInfo`)
            .then((response) => {
                return response.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getCompanyMortgagedDeeds = async (body: {
        company700Id: string;
        pageSize: number;
        pageNumber: number;
        count: number;
    }) => {
        return await this.HttpClient.get(
            `Deed/GetCompanyMortgagedDeeds/${body.company700Id}/${body.pageSize}/${body.pageNumber}/${body.count}`
        )
            .then((response: AxiosResponse<any>) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    create = async (body: MortgageeRequest) => {
        return await this.HttpClient.post(`EpmRequest/Create`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    release = async (body: MortgageeRequest) => {
        return await this.HttpClient.post(`EpmRequest/Release`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    AcceptEPU = async (body: MortgageeRequest) => {
        return await this.HttpClient.post(`EpmRequest/AcceptEPU`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    accept = async (body: MortgageeRequest) => {
        return await this.HttpClient.post(`EpmRequest/Accept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    reject = async (body: any) => {
        return await this.HttpClient.post(`EpmRequest/Reject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    rejectOldAttorney = async (body: any) => {
        return await this.HttpClient.post(`MortgageTransfer/RejectOld`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    rejectNewAttorney = async (body: any) => {
        return await this.HttpClient.post(`MortgageTransfer/RejectNew`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    cancel = async (body: any) => {
        return await this.HttpClient.post(`EpmRequest/Cancel`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    releaseRequest = async (body: {
        deedNumber: string;
        deedSerial: number;
        ownerNationalId: string;
        ownerIdType: number;
        ownerDobHijri: string;
    }) => {
        return await this.HttpClient.post(`EpmRequest/Release`, body)
            .then((response: AxiosResponse<any>) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };
    transfer = async (body: MortgageeRequest, isSecondaryOwner?: boolean) => {
        return await this.HttpClient.post(
            !isSecondaryOwner ? `MortgageTransfer/Create` : 'MortgageTransfer/AcceptMultiMortgagee',
            body
        )
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    acceptTransfer = async (body: MortgageeRequest, newAttorney: boolean) => {
        return await this.HttpClient.post(
            `MortgageTransfer/Accept${newAttorney ? 'NewMortgagee' : 'OldMortgagee'}`,
            body
        )
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    updateDeedOwnersBirthDate = async (input: IOwnerBirthDateUpdateModel) => {
        return await this.HttpClient.post(`EpmRequest/UpdateMortgageOwnerDOB`, input)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    updateMortgageAmount = async (body: MortgageeRequest) => {
        return await this.HttpClient.post(`EpmRequest/UpdateMortgageAmount`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    releaseConstrain = async (body: any) => {
        return await this.HttpClient.post(`GPT3MOrder/ReleaseConstrain`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    MortgagorAcceptEPU = async (body: {
        RequestId: string;
        DeedSerial: number;
        OtpVerify: {
            Key: string;
            Otp: number;
        };
        MortgageAmount: number;
    }) => {
        return await this.HttpClient.post(`EpmRequest/MortgagorAcceptEPU`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    MortgagorRejectEPU = async (body: any) => {
        return await this.HttpClient.post(`EpmRequest/MortgagorRejectEPU`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    EPMRejectByMortgagee = async (body: any) => {
        return await this.HttpClient.post(`EpmRequest/Entity/Reject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    EPMAcceptByMortgagee = async (body: any) => {
        return await this.HttpClient.post(`EpmRequest/Entity/Accept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
}

export const mortgageServiceInstance = new MortgageService();
