import { authStore } from '@/common/stores/auth.store';
import { ITermsBuilder } from '@dashboard/components/TermsAndConditionsLayout/builder/ITermsBuilder';
import i18n from 'i18next';

export class Term {
    label: string;
    isChecked?: boolean;

    constructor(label: string) {
        this.label = label;
        this.isChecked = false;
    }

    changeState(): void {
        this.isChecked = !this.isChecked;
    }
}

export class Terms implements ITermsBuilder {
    terms: Term[] = [];
    t = i18n.t;

    constructor() {
        this.global();
    }

    addToOpenMarketTermsForSellers(): void {
        this.appendTerm(this.t('terms.open-market.1'));
    }

    agreeOnMortgageTerms(): void {
        this.appendTerm(this.t('terms.mortgage.1'));
    }

    agreeOnTermsForSellersAndBuyers(): void {
        this.appendTerm(this.t('terms.seller-buyer.1'));
        this.appendTerm(this.t('terms.seller-buyer.2'));

        // this.terms.push(
        //     new Term(this.t('terms.seller-buyer.1')),
        //     new Term(this.t('terms.seller-buyer.2')),
        // )
    }

    draftDUTerms(): Terms {
        this.appendFirstTerm(this.t('deed-upgrade.create-update.final-term5'));
        this.appendFirstTerm(this.t('deed-upgrade.create-update.final-term4'));
        this.appendFirstTerm(this.t('deed-upgrade.create-update.final-term3'));
        this.appendFirstTerm(this.t('deed-upgrade.create-update.final-term2'));
        this.appendFirstTerm(this.t('deed-upgrade.create-update.final-term1'));

        this.appendFirstTerm(
            this.t('deed-upgrade.create-update.acknowledgment', {
                userName: authStore.user?.profile['http://iam.gov.sa/claims/arabicName'],
                idNumber: authStore.user?.profile['http://iam.gov.sa/claims/userid'],
            })
        );

        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.1'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.2'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.3'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.4'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.5'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.6'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.7'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.8'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.9'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.10'));
        this.appendTerm(this.t('deed-upgrade.create-update.draft-terms.11'));
        return this;
    }

    contactOwnerTerms(): Terms {
        this.appendTerm(this.t('terms.contact-owner.terms1'));
        this.appendTerm(this.t('terms.contact-owner.terms2'));

        return this;
    }

    agreeOnTermsForSellersOnly(): void {
        this.appendTerm(this.t('terms.seller.1'));
    }

    footerTerms(): void {
        this.appendTerm(this.t('terms.footer.1'));
    }

    giftTermsForGiver(): void {
        this.appendTerm(this.t('terms.giver.1'));
    }

    giftTermsForReceiver(): void {
        this.appendTerm(this.t('terms.receiver.1'));
    }

    giftTermsForGiverAndReceiver(): void {
        this.appendTerm(this.t('terms.giver-receiver.1'));
    }

    global(): void {
        this.terms.push(
            new Term(this.t('terms.global.2')),
            new Term(this.t('terms.global.3')),
            new Term(this.t('terms.global.4')),
            new Term(this.t('terms.global.5')),
            new Term(this.t('terms.global.6')),
            new Term(this.t('terms.global.7'))
        );
    }

    isAllTermsChecked(): boolean {
        return this.terms.every((value) => value.isChecked);
    }

    appendTerm(label): Terms {
        const check = this.terms.find((t) => t.label === label);

        if (check === undefined) {
            this.terms.push(new Term(label));
        }

        return this;
    }

    appendFirstTerm(label): Terms {
        const check = this.terms.find((t) => t.label === label);

        if (check === undefined) {
            this.terms.unshift(new Term(label));
        }

        return this;
    }

    reset(): void {
        this.terms = [];
        this.global();
    }
}
