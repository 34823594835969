import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { User } from 'oidc-client-ts';
import { useTranslation } from 'react-i18next';

import { Card, ClickOutsideListener, ReadOnlyFieldVariant } from '../shared';
import UserInfoCard from '../shared/UserInfoCard/UserInfoCard';
import { Attorney, AttorneyItem, DelegatorTypes } from '@/common/models/Attorney';
import { getAttornyType } from '@/common/utils/constants';
import { NavbarOption } from './Navbar/NavbarOption';
import AttorneyService from '@/common/services/attorney.service';
import { AddedIban } from '@/common/models/UserDetails';
import { loaderStore } from '@/common/stores/loader.store';
import { attorneyStore } from '@/common/stores/attorney.store';
import { NavbarSubOption } from './Navbar/NavbarSubOption';
import { ReactComponent as ArrowLeft } from '@/core/assets/images/assets-icons-arrows-left.svg';
import { FEATURES } from '@/common/utils/features';

type ProfileMenuProps = {
    isExpanded2: boolean;
    setIsExpanded2: (value: boolean) => void;
    userInfo: User | null | undefined;
    authedAttorney?: Attorney;
    setIsExpanded: (value: boolean) => void;
    attorneys: AttorneyItem[] | null;
    getAttorneys?: () => void;
    logout: () => void;
};

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
    isExpanded2,
    setIsExpanded2,
    userInfo,
    authedAttorney,
    setIsExpanded,
    attorneys,
    getAttorneys,
    logout,
}) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const attorneyService = new AttorneyService();

    const initiateAttorneySession = async (id: string) => {
        loaderStore.setLoader(true);
        const res = await attorneyService.initiateSession(id);
        if (res.IsSuccess) {
            navigate('/', { replace: true });
            attorneyStore.setAttorney(res.Data as Attorney);
            attorneyService.getAttorneyIbans(id).then((res) => {
                attorneyStore.setSelectedAttorneyIbans(res.Data as AddedIban[]);
            });
        }
        loaderStore.setLoader(false);
    };

    const terminateAttorneySession = async () => {
        loaderStore.setLoader(true);
        const res = await attorneyService.terminateSession();
        if (res.IsSuccess) {
            attorneyStore.clearAttorney();
        } else attorneyStore.clearAttorney();
        loaderStore.setLoader(false);
        navigate('/', { replace: true });
    };

    return (
        <div
            className={clsx('mobile-header__options', {
                'mobile-header__options--hidden': !isExpanded2,
            })}
        >
            <ClickOutsideListener
                onClickOutside={() => {
                    setIsExpanded2(false);
                }}
            >
                <Card
                    className={clsx('mobile-header__navbar', {
                        'mobile-header__navbar--hidden': !isExpanded2,
                    })}
                    noPadding
                >
                    {userInfo !== undefined && (
                        <UserInfoCard
                            title={translate('shared.personal-info')}
                            infoList={[
                                {
                                    label: translate('shared.name'),
                                    value: `${userInfo?.profile['http://iam.gov.sa/claims/arabicName']}`,
                                },
                                {
                                    label: translate('shared.id-number'),
                                    value: `${userInfo?.profile['http://iam.gov.sa/claims/userid']!!}`,
                                },
                            ]}
                        />
                    )}
                    {authedAttorney !== undefined && (
                        <UserInfoCard
                            title={translate('shared.wakala-info')}
                            variant={ReadOnlyFieldVariant.warning}
                            infoList={[
                                {
                                    label: translate('shared.attorney-for'),
                                    value: authedAttorney.PersonInfo?.Name!!,
                                },
                                {
                                    label: translate('attorney.for'),
                                    value: getAttornyType(authedAttorney?.DelegatorType!),
                                },
                                {
                                    label:
                                        authedAttorney?.DelegatorType! === DelegatorTypes.Organisation
                                            ? translate('shared.facility-number')
                                            : translate('attorney.individual-attorney-id-number'),
                                    value: authedAttorney.PersonInfo?.SocialId.toString()!!,
                                },
                            ]}
                        />
                    )}
                    {authedAttorney && (
                        <NavbarOption
                            onClick={() => {
                                terminateAttorneySession!!();
                                setIsExpanded(false);
                            }}
                            FixedIcon={ArrowLeft}
                            title={translate('العودة للحساب الشخصي')}
                            link="#"
                        />
                    )}
                    <NavbarOption
                        title={translate('header.wakalat')}
                        link="#"
                        onClick={() => {
                            if (attorneys === undefined) {
                                getAttorneys!!();
                            }

                            if (attorneys === undefined || (attorneys && attorneys!!.length === 0)) {
                                navigate('/profile/attorney');
                                setIsExpanded2(false);
                            }
                        }}
                    >
                        {attorneys !== undefined &&
                            attorneys!!.length > 0 &&
                            attorneys?.map((attorney, index) => {
                                return (
                                    <NavbarSubOption
                                        onClick={async () => {
                                            await initiateAttorneySession(attorney.Id);
                                            setIsExpanded2(false);
                                        }}
                                        key={index}
                                        FixedIcon={ArrowLeft}
                                        title={`#${attorney.AttorneyCode} - ${getAttornyType(
                                            attorney.DelegatorType
                                        )} - ${attorney.Delegator}`}
                                        link="#"
                                    />
                                );
                            })}
                    </NavbarOption>

                    <NavbarOption
                        onClick={() => {
                            setIsExpanded2(false);
                        }}
                        title={translate('profile.title')}
                        link="/profile"
                    />
                    {FEATURES.WALLET ? <NavbarOption
                        onClick={() => {
                            setIsExpanded2(false);
                        }}
                        title={translate('profile.wallet')}
                        link="/profile/wallet"
                    /> : <></>}
                    <NavbarOption
                        onClick={() => {
                            loaderStore.setLoader(true);
                            logout();
                            setIsExpanded(false);
                        }}
                        noBorder
                        title={translate('header.logout')}
                        link="#"
                        isDangerFlag
                    />
                </Card>
            </ClickOutsideListener>
        </div>
    );
};
