import i18n from 'i18next';
import { DelegatorTypes } from '../models/Attorney';

export const CONSTANTS = {
    COMPANY_700: '7001234567',
    AREA_PERCENTAGE: 1000,
    OWNERSHIP_PERCENTAGE: 100000, // 5 fraction
    OWNERSHIP_FIXED: 6,
};

export const getAttornyType = (socialIdType: number) => {
    if (socialIdType === DelegatorTypes.Person) {
        return i18n.t('attorney.person');
    } else if (socialIdType === DelegatorTypes.Organisation) {
        return i18n.t('attorney.organization');
    } else if (socialIdType === DelegatorTypes.Heir) {
        return i18n.t('attorney.heir');
    } else if (socialIdType === DelegatorTypes.Establishment) {
        return i18n.t('attorney.establishment-owner');
    } else if (socialIdType === DelegatorTypes.Government) {
        return i18n.t('attorney.authorized-by-government');
    }

    return '';
};

export const getPercentageArea = (number): number => {
    let fraction = number % 1;

    let truncatedNumber;

    if (fraction !== 0) {
        truncatedNumber = Math.floor(number * CONSTANTS.AREA_PERCENTAGE) / CONSTANTS.AREA_PERCENTAGE;
    } else {
        truncatedNumber = number;
    }

    return truncatedNumber;
};

export const getPercentageOwnership = (number): number => {
    let fraction = number % 1;
    let fractionLength = number && fraction ? number.toString().split('.')[1]?.length : number;

    if (fractionLength <= CONSTANTS.OWNERSHIP_FIXED) {
        return number;
    }

    let truncatedNumber;

    if (fraction !== 0 || fraction.toString().startsWith('0')) {
        truncatedNumber = Math.floor(number * CONSTANTS.OWNERSHIP_PERCENTAGE) / CONSTANTS.OWNERSHIP_PERCENTAGE;
    } else {
        truncatedNumber = number;
    }

    return truncatedNumber;
};

export const arabicToEnglishMap = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
    '.': '.',
    ',': '.',
    '،': '.',
};

export const SUPPORT_CONFIG = {
    TICKET_TYPE: 2,
    APPLICATION_CODE: 'RealEstateMarket',
    MAIN_CATEGORY_ID: 'f8be72c9-e3b5-ee11-8ff7-005056871141', //"c608d581-dabe-ee11-900d-00505687e050"
};

export const center = {
    lat: 24.774265,
    lng: 46.738586,
};

export const INITIAL_VIEW_STATE = {
    latitude: 24.774265,
    longitude: 46.738586,
    zoom: 4,
    pitch: 0,
    bearing: 0,
};

export const mapViews = {
    mapViewsEnums: [
        {
            mapViewType: 'meterPriceView',
            ranges: [
                { range: '0-299', color: '#CBE4ED' },
                { range: '300-399', color: '#98C7D9' },
                { range: '400-499', color: '#73BFDD' },
                { range: '500-599', color: '#6EB1C9' },
                { range: '>599', color: '#459ABA' },
            ],
        },
        {
            mapViewType: 'areaView',
            ranges: [
                { range: '0-299', color: '#FAE7EA' },
                { range: '300-399', color: '#F5CFD5' },
                { range: '400-499', color: '#F1B7BF' },
                { range: '500-599', color: '#EC9FAA' },
                { range: '>599', color: '#E78795' },
            ],
        },
        {
            mapViewType: 'openMarketPriceView',
            ranges: [
                { range: '0-299', color: '#EFEAEC' },
                { range: '300-399', color: '#DFD4D9' },
                { range: '400-499', color: '#BEAAB3' },
                { range: '500-599', color: '#9E7F8E' },
                { range: '>599', color: '#7D5568' },
            ],
        },
    ],
    propertyStatusEnums: [
        { color: '#F6B221', purchaseOrders: 0, openMarketOrders: 1 },
        { color: '#007951', purchaseOrders: 1, openMarketOrders: 0 },
        { color: '#7C96B0', purchaseOrders: 0, openMarketOrders: 0 },
    ],
};

export const mapViewLabels = {
    property: 'map-view-labels.lbl-property',
    MeterPriceView: 'map-view-labels.lbl-meter-price',
    AreaView: 'map-view-labels.lbl-area',
    OpenMarketPriceView: 'map-view-labels.lbl-open-market-price',
};


export const DEFAULT_DATE_DAYS = '10';

export const MAP_LAT_ADJUSTMENT = -0.000090009999900;

export const PREMIUM_DU_FEE = 200;

export const MAP_LOAD_PROPERTIES_THROTTLE = 1000;

export const MAP_ID = "e1d0c7dfc80cc429";

export const MAP_LNG_ADJUSTMENT = -0.000090000000009;


export const BOUNDS_OBJECT = {
    "ne": {
        "lat": 32.154284,
        "lng": 55.6666666
    },
    "sw": {
        "lat": 16.29,
        "lng": 34.4815001
    }
}