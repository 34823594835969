export enum InputModes {
    Decimal = 'decimal',
    Number = 'numeric',
    Text = 'text',
    Tel = 'tel',
    Search = 'search',
    Email = 'email'
}

export enum InputTypes {
    Text = 'text',
    Number = 'number',
    Tel = 'tel',
    Email = 'email',
    Date = 'date',
    Color = 'color'
}
