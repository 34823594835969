import React, { HTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { usePresence } from 'framer-motion';
import { RowProps } from 'antd';
import './Card.scss';

interface CardProps extends HTMLAttributes<HTMLDivElement>, RowProps {
    noPadding?: boolean;
    hasAnimations?: boolean;
    header?: boolean;
}

export function Card({
    noPadding,
    header,
    className,
    hasAnimations,
    children,
    ...props
}: PropsWithChildren<CardProps>) {
    const [isPresent, safeToRemove] = usePresence();
    const transition = React.useMemo(() => ({ type: 'spring', stiffness: 500, damping: 50, mass: 1 }), []);

    const animations = React.useMemo(
        () => ({
            layout: true,
            initial: 'out',
            animate: isPresent ? 'in' : 'out',
            whileTap: 'tapped',
            variants: {
                in: { scaleY: 1, opacity: 1 },
                out: { scaleY: 0, opacity: 0, zIndex: -1 },
                tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } },
            },
            onAnimationComplete: () => !isPresent && safeToRemove,
            transition,
        }),
        [isPresent, transition, safeToRemove]
    );
    return (
        <div
            {...(hasAnimations ? { animations } : {})}
            className={clsx(
                'du-card',
                {
                    'du-card--no-padding': noPadding,
                    'du-card--header': header,
                },
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
}

function CardHead({
    className,
    hasPadding,
    hasBorder,
    children,
    ...props
}: PropsWithChildren<{ className?: string; hasPadding?; hasBorder?}>) {
    return (
        <div
            className={clsx(
                'du-card__head',
                hasPadding && `du-card__head--has-padding`,
                hasBorder && `du-card__head--has-border`,
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
}

function CardBody({ className, children, ...props }: PropsWithChildren<{ className?: string }>) {
    return <div className={clsx('du-card__body', className)} {...props}>{children}</div>;
}

Card.Head = CardHead;
Card.Body = CardBody;
