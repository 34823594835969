import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as GooglePlayIcon } from '@core/assets/images/SVG/assets-icons-google-play.svg';
import { ReactComponent as AppStoreIcon } from '@core/assets/images/SVG/assets-icons-app-store.svg';
import { ReactComponent as TwitterIcon } from '@/core/assets/images/assets-icons-twitter.svg';
import { ReactComponent as SremLogo } from '@/core/assets/images/srem_logo_without_words.svg';
import PoweredByIcon from '@/core/assets/images/thiqah-logo.png';
import YoutubeIcon from '@/core/assets/images/youtube_icon.png';
import MojLogo from '@/core/assets/images/moj_footer_logo.png';
import './Footer.scss';
import DGA from "@core/assets/images/DGA.png";

export function MobileFooter() {
    const { t } = useTranslation();

    return (
        <div className="app-footer">
            <div className="app-footer__container">
                <div className="app-footer__providers">
                    <a href="https://raqmi.dga.gov.sa/platforms/platforms/f99b464a-3f26-4fab-8493-440ce9d3f20b/platform-license" rel="noopener noreferrer" target="_blank">
                        <img src={DGA} alt='الحكومة الرقمية' className="digital-gov-logo" />
                    </a>

                    <img className="moj-logo" src={MojLogo} alt="moj-logo" />

                    <div className="poweredBy-logo">
                        <img src={PoweredByIcon} alt="" />
                        <p>Powered by</p>
                    </div>
                </div>

                <div className="social-links">
                    <a href="https://www.youtube.com/c/ksamoj">
                        <img src={YoutubeIcon} className="youtube-icon icon" alt="youtube-icon" />
                    </a>
                    <a href="https://twitter.com/MojKsa">
                        <TwitterIcon className="twitter-icon icon" />
                    </a>
                    <a
                        href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%A8%D9%88%D8%B1%D8%B5%D8%A9-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9/id6446234209?l=ar"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <AppStoreIcon />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.srem.moj&hl=en_US"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <GooglePlayIcon />
                    </a>
                </div>
            </div>

            <div className="app-footer__bottom">
                <div className="copyright-text">
                    {t('footer.copyright-text', { year: new Date().getFullYear() })}
                </div>
                <div className="links">
                    <Link className="nav-link" to={'/terms'} replace={true} target="_blank" rel="noopener">
                        {t('footer.terms')}
                    </Link>
                    |
                    <Link className="nav-link" to={'/about'} replace={true} target="_blank" rel="noopener">
                        {t('footer.about-market')}
                    </Link>
                    |
                    <Link className="nav-link" to={'/FAQs'} replace={true} target="_blank" rel="noopener">
                        {t('footer.faq')}
                    </Link>
                </div>
                <div className="srem-logo">
                    <SremLogo />
                </div>
            </div>
        </div>
    );
}
