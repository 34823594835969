import React from 'react';
import { Col, Row } from 'antd';
import { ReadOnlyField, ReadOnlyFieldVariant } from '@core/components/shared';
import './_UserInfoCard.scss';
export type UserInfoCardProps = {
    title?: string;
    infoList?: { label: string; value: string }[];
    variant?: ReadOnlyFieldVariant;
};

const UserInfoCard = ({ title, infoList, variant }: UserInfoCardProps) => {
    return (
        <Row className="UserInfoCard">
            <Col span="24">
                <ReadOnlyField label={title!!} title="" noBorder />
            </Col>
            {infoList?.map((value, index) => {
                return (
                    <Col span="24" key={index}>
                        <ReadOnlyField
                            label={value.label}
                            title={value.value}
                            inline={true}
                            noBorder
                            variant={variant}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default UserInfoCard;
