import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import { Formik } from 'formik';
import { IMultiStepContainer } from '@core/components/shared/MultiStepContainer/interface/multi-step-container.interface';
import Header from '@core/components/shared/Header/Header';
import { Alert, AlertVariants, Card, MojButton } from '@core/components/shared';
import { Footer } from 'antd/es/layout/layout';
import { ErrorDetails } from "@common/models/GenericResponse";
import ErrorAlert from "@core/components/shared/ErrorAlert/ErrorAlert";
import './_MultiStepContainer.scss';

export type MultiStepContainerProps = {
    stepper: IMultiStepContainer[];
    stepperHeader: string;
    currentStep: number;
    previousStep: () => void;
    isLoading: boolean;
    hideProgressBar?: boolean;
    errorList?: string[];
    errorDetails?: ErrorDetails[];
    hideSubmit?: boolean;
    submitButtonColor?: 'success' | 'danger' | 'warning' | 'primary' | 'label';
    hideActions?: boolean;
    customBackAction?: () => void;
    validateOnMount?: boolean;
    enableReinitialize?: boolean;
    clearErrorList?: (errorsList: string[]) => void;
    clearErrorDetails?: (errorDetails: ErrorDetails[]) => void;
    enableScrollTop?: boolean;
    correlationId?: string;
    isModal?: boolean;
    progressBarSpan?: number;
    fullView?: boolean;
    thirdButtonColor?: 'success' | 'danger' | 'warning' | 'primary' | 'label';
};

const MultiStepContainer = ({
    stepper,
    stepperHeader,
    currentStep,
    previousStep,
    isLoading,
    hideProgressBar,
    errorList,
    errorDetails,
    hideSubmit,
    customBackAction,
    submitButtonColor = 'success',
    hideActions,
    validateOnMount = false,
    enableReinitialize = true,
    clearErrorList,
    clearErrorDetails,
    enableScrollTop = true,
    correlationId,
    isModal = false,
    progressBarSpan = 16,
    fullView = true,
    thirdButtonColor = 'success',
}: MultiStepContainerProps) => {
    const navigate = useNavigate();

    const componentWrapperRef = React.useRef(null);

    useEffect(() => {
        if (enableScrollTop) {
            setTimeout(() => {
                if (componentWrapperRef.current) {
                    //const currEle: any = componentWrapperRef.current;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }, 300);
        }
    }, [currentStep]);

    return (
        <Formik
            onSubmit={(values, { validateForm, resetForm }) => {
                validateForm(values).then((r) => {
                    stepper[currentStep].onSubmit(values, { resetForm });
                });
            }}
            validateOnBlur={false}
            validateOnMount={validateOnMount}
            validateOnChange={true}
            initialValues={stepper[currentStep].initialValues}
            validationSchema={stepper[currentStep].validationSchema}
            enableReinitialize={enableReinitialize}
        >
            {(formikProps) => {
                if (stepper[currentStep] && stepper[currentStep].onValuesChange) {
                    stepper[currentStep].onValuesChange!(formikProps.values);
                }

                if (stepper[currentStep] && stepper[currentStep].onValidationChange) {
                    stepper[currentStep].onValidationChange!(formikProps.isValid);
                }

                return (
                    <Form className="new-mortgage">
                        <Row className={`${isModal ? 'full-view-modal' : ''} ${fullView ? 'full-view' : ''}`} align="top">
                            {!stepper[currentStep].hideHeader && (
                                <Col xs={24}>
                                    <Header
                                        headerTitle={stepperHeader}
                                        progressbar={((currentStep + 1) / stepper.length) * 100}
                                        hideProgressBar={hideProgressBar || stepper[currentStep].hideProgressbar}
                                        progressBarSpan={progressBarSpan}
                                    />
                                </Col>
                            )}
                            <Col xs={24} ref={componentWrapperRef}>{stepper[currentStep].component(formikProps)}</Col>
                        </Row>

                        {!hideActions && !stepper[currentStep].hideActions && (
                            <Footer className="ant-layout-footer__light">
                                <Card>
                                    <Row gutter={[16, 16]} justify="start" align="middle">
                                        {stepper[currentStep].children && (
                                            <Col span={24}>{stepper[currentStep].children()!!}</Col>
                                        )}
                                        {errorDetails && errorDetails.length > 0 && (
                                            <Col span={24}>
                                                <Card hasAnimations={true} noPadding={true}>
                                                    <Alert variant={AlertVariants.Danger}>
                                                        {errorDetails.map((value, index) => {
                                                            return <ErrorAlert errorDetail={value} correlationId={correlationId} key={`error-line-${index}`} />
                                                        })}
                                                    </Alert>
                                                </Card>
                                            </Col>
                                        )}
                                        <Col flex="auto">
                                            <MojButton
                                                disabled={stepper[currentStep].disablePrevious}
                                                onClick={() => {
                                                    if (stepper[currentStep]?.customBackAction) {
                                                        stepper[currentStep].customBackAction!();
                                                        if (clearErrorDetails) {
                                                            clearErrorDetails([]);
                                                        }
                                                    } else if (currentStep === 0 && customBackAction) {
                                                        customBackAction();
                                                    } else if (currentStep === 0) {
                                                        navigate(-1);
                                                    } else previousStep();
                                                }}
                                                color="primary"
                                            >
                                                {stepper[currentStep].cancelButtonText}
                                            </MojButton>
                                        </Col>
                                        {!stepper[currentStep].hideSubmit && (
                                            <Col flex="auto">
                                                <MojButton
                                                    disabled={stepper[currentStep].disableNext}
                                                    onClick={
                                                        formikProps.isValid === false ?
                                                            () => formikProps.handleSubmit() :
                                                            formikProps.values
                                                                ? () => formikProps.handleSubmit()
                                                                : stepper[currentStep].onSubmit
                                                    }
                                                    color={
                                                        stepper[currentStep].submitButtonColor ?? submitButtonColor!!
                                                    }
                                                    loading={isLoading}
                                                >
                                                    {!isLoading && stepper[currentStep].confirmButtonText}
                                                </MojButton>
                                            </Col>
                                        )}
                                        {stepper[currentStep].showThirdButton && (
                                            <Col flex="auto">
                                                <MojButton
                                                    disabled={stepper[currentStep].disableNext}
                                                    onClick={
                                                        formikProps.isValid === false ?
                                                            () => formikProps.handleSubmit() :
                                                            formikProps.values
                                                                ? () => formikProps.handleSubmit()
                                                                : stepper[currentStep].onThirdButtonSubmit
                                                    }
                                                    color={
                                                        stepper[currentStep].thirdButtonColor ?? thirdButtonColor!!
                                                    }
                                                    loading={isLoading}
                                                >
                                                    {!isLoading && stepper[currentStep].thirdButtonText}
                                                </MojButton>
                                            </Col>
                                        )}
                                    </Row>
                                </Card>
                            </Footer>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MultiStepContainer;
