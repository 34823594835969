import { action, makeObservable, observable } from 'mobx';

class LoaderService {
    @observable
    public isLoading = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setLoader(flag: boolean) {
        this.isLoading = flag;
    }
}

export const loaderStore = new LoaderService();
