import React, { useCallback, useEffect, useState } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Input } from 'antd';
import { MojFormItem } from '@core/components/shared/MojFormItem/MojFormItem';
import { GregorianYear, HijriYear } from '@common/utils/calender.utills';
import { arabicToEnglishMap } from '@/common/utils/constants';
import { convertMixNumbersToEnglish } from '@/common/utils/numbers-utils';
import './DatePickerInputText.scss';

export type CalendarType = 'hijri' | 'gregorian';

type DatePickerInputTextProps = {
    formikProps?: FormikProps<FormikValues>;
    dateValue?: string | null;
    onChange?: (value) => void;
    dayId?: string;
    monthId?: string;
    yearId?: string;
    arrayKey?: any;
    itemIndex?: number;
    calendarType?: CalendarType;
    showLabels?: boolean;
    allowInvalidDate?: boolean;
};

const DatePickerInputText = ({
    formikProps,
    dateValue,
    onChange,
    dayId = 'day',
    monthId = 'month',
    yearId = 'year',
    arrayKey,
    itemIndex,
    calendarType = 'hijri',
    showLabels = false,
    allowInvalidDate = false,
}: DatePickerInputTextProps) => {
    const { t } = useTranslation();

    let dateArray = dateValue ? dateValue.split('-') : [];

    const [day, setDay] = useState<string | undefined>(dateArray.length > 0 ? dateArray[2] : undefined);
    const [month, setMonth] = useState<string | undefined>(dateArray.length > 0 ? dateArray[1] : undefined);
    const [year, setYear] = useState<string | undefined>(dateArray.length > 0 ? dateArray[0] : undefined);
    useEffect(() => {
        if (onChange) {
            if ((day !== undefined && month !== undefined && year !== undefined) || allowInvalidDate) {
                onChange({ day, month, year });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, month, year, allowInvalidDate]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dateArray = dateValue ? dateValue.split('-') : [];

        setDay(dateArray.length > 0 ? dateArray[2] : undefined);
        setMonth(dateArray.length > 0 ? dateArray[1] : undefined);
        setYear(dateArray.length > 0 ? dateArray[0] : undefined);
    }, [dateValue]);

    const getFieldName = useCallback(
        (fieldId) => {
            if (arrayKey !== undefined && itemIndex !== undefined) {
                return `${arrayKey}[${itemIndex}].${fieldId}`;
            }

            return fieldId;
        },
        [arrayKey, itemIndex]
    );

    const getFieldErrors = useCallback(
        (fieldId) => {
            if (arrayKey !== undefined && itemIndex !== undefined) {
                const errors = formikProps && formikProps.errors[arrayKey] ? formikProps.errors[arrayKey] : null;

                return errors && errors[itemIndex][fieldId];
            }

            return formikProps && formikProps.errors[fieldId];
        },
        [arrayKey, formikProps, itemIndex]
    );

    const getDayValidateStatus = () => {
        if ((Number(day) > 30 || Number(day) <= 0) && formikProps?.touched[dayId]) {
            return 'error';
        }

        if (getFieldErrors(dayId) && formikProps?.touched[dayId]) {
            return 'error';
        }

        return '';
    };

    const getMonthValidateStatus = () => {
        if ((Number(month) > 12 || Number(month) <= 0) && formikProps?.touched[monthId]) {
            return 'error';
        }

        if (getFieldErrors(monthId) && formikProps?.touched[monthId]) {
            return 'error';
        }

        return '';
    };

    const getYearValidateStatus = useCallback(() => {
        const isHijri = calendarType === 'hijri';
        const currentYear = isHijri ? HijriYear : GregorianYear;
        const minYear = isHijri ? 1200 : 1700;

        if (formikProps?.touched[yearId]) {
            if (!isHijri && !year) {
                return 'error';
            }

            if (year && Number(year) < minYear) {
                return 'error';
            }

            if (isHijri && year && currentYear - Number(year) < 18) {
                return 'error';
            }

            if (year && getFieldErrors(yearId)) {
                return 'error';
            }

            if (year && year.toString().length < 4) {
                return 'error';
            }
        }

        return '';
    }, [calendarType, year, getFieldErrors, yearId]);

    const getFieldHelpText = (fieldId) => {
        if (formikProps) {
            if (arrayKey !== undefined && itemIndex !== undefined) {
                const errors = formikProps.errors[arrayKey] ? formikProps.errors[arrayKey] : null;

                const fieldErrors = errors && errors[itemIndex][fieldId];

                const touched = formikProps.touched[arrayKey] ? formikProps.touched[arrayKey] : null;

                const fieldTouched = touched && touched[itemIndex][fieldId];

                return fieldErrors || fieldTouched ? (fieldErrors as string) : '';
            } else {
                return formikProps
                    ? formikProps.touched[fieldId] && formikProps.errors[fieldId]
                        ? (formikProps.errors[fieldId] as string)
                        : ''
                    : '';
            }
        }

        return '';
    };

    const handleYearChange = (e) => {
        if (Number.isInteger(Number(e.target.value)) && e.target.value) {
            const year = Number(e.target.value.replaceAll(/\D/g, ''));
            const isHijri = calendarType === 'hijri';
            const maxYear = isHijri ? HijriYear : undefined;
            if (isHijri) {
                if (year > 0 && (!maxYear || year <= maxYear)) {
                    if (formikProps) {
                        formikProps.setFieldValue(getFieldName(yearId), year.toString());
                    }

                    setYear(year.toString());
                }
            } else {
                // handle gregorian year input validation
                if (year > 0 && year < 3050) {
                    if (formikProps) {
                        formikProps.setFieldValue(getFieldName(yearId), year.toString());
                    }

                    setYear(year.toString());
                }
            }
        } else {
            if (formikProps) {
                formikProps.setFieldValue(getFieldName(yearId), '');
            }

            setYear('');
        }
    };

    const validateDifferentLocale = useCallback(
        (e, min, max, id, setter) => {
            const splitted = e.target.value.split('');
            const last = splitted[splitted.length - 1];
            const remainder = e.target.value.length > 1 ? e.target.value.substring(0, e.target.value.length - 1) : '';
            if ((Number.isInteger(Number(e.target.value)) || arabicToEnglishMap[last]) && e.target.value) {
                let day;
                const trimmed = e.target.value.replaceAll(/[^\p{Number}]/gu, '');
                if (arabicToEnglishMap[trimmed] || arabicToEnglishMap[last]) {
                    day = Number(`${remainder}${arabicToEnglishMap[last]}`);
                } else {
                    day = Number(trimmed);
                }
                if (day > min && max ? day <= max : true) {
                    if (formikProps) {
                        formikProps.setFieldValue(getFieldName(id), day.toString());
                    }

                    setter(day.toString());
                }
            } else {
                if (formikProps) {
                    formikProps.setFieldValue(getFieldName(id), '');
                }

                setter('');
            }
        },
        [formikProps, getFieldName]
    );

    const handleDayInputChange = useCallback(
        (e) => {
            const isHijri = calendarType === 'hijri';
            const max = isHijri ? 30 : 31;
            validateDifferentLocale(e, 0, max, dayId, setDay);
        },
        [dayId, validateDifferentLocale, calendarType]
    );

    const handleMonthInputChange = useCallback(
        (e) => {
            validateDifferentLocale(e, 0, 12, monthId, setMonth);
        },
        [monthId, validateDifferentLocale]
    );

    const handleYearInputChange = useCallback(
        (e) => {
            const isHijri = calendarType === 'hijri';
            const maxYear = isHijri ? HijriYear : GregorianYear;
            e.target.value = convertMixNumbersToEnglish(e.target.value);

            if (isHijri && maxYear) {
                validateDifferentLocale(e, 0, maxYear, yearId, setYear);
            } else if (isHijri && !maxYear) {
                validateDifferentLocale(e, 0, undefined, yearId, setYear);
            } else {
                if (!isHijri) {
                    validateDifferentLocale(e, 0, 3050, yearId, setYear);
                } else {
                    setYear(undefined);
                }
            }
        },
        [yearId, calendarType, validateDifferentLocale]
    );

    return (
        <>
            <Col span="8">
                <MojFormItem
                    htmlFor={getFieldName(dayId)}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={getDayValidateStatus()}
                    help={getFieldHelpText(dayId)}
                    label={showLabels ? t('calendar.day') : undefined}
                >
                    <Input
                        placeholder={t('calendar.day')}
                        inputMode={'numeric'}
                        allowClear
                        value={day}
                        {...formikProps?.getFieldProps(dayId)}
                        onChange={handleDayInputChange}
                    />
                </MojFormItem>
            </Col>
            <Col span="8">
                <MojFormItem
                    htmlFor={getFieldName(monthId)}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={getMonthValidateStatus()}
                    help={getFieldHelpText(monthId)}
                    label={showLabels ? t('calendar.month') : undefined}
                >
                    <Input
                        placeholder={t('calendar.month')}
                        inputMode={'numeric'}
                        allowClear
                        value={month}
                        {...formikProps?.getFieldProps(monthId)}
                        onChange={handleMonthInputChange}
                    />
                </MojFormItem>
            </Col>
            <Col span="8">
                <MojFormItem
                    htmlFor={getFieldName(yearId)}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={getYearValidateStatus()}
                    help={getFieldHelpText(yearId)}
                    label={showLabels ? t('calendar.year') : undefined}
                >
                    <Input
                        placeholder={t('calendar.year')}
                        inputMode={'numeric'}
                        allowClear
                        value={year}
                        {...formikProps?.getFieldProps(yearId)}
                        onChange={handleYearInputChange}
                        maxLength={4}
                        minLength={4}
                    />
                </MojFormItem>
            </Col>
        </>
    );
};

export default DatePickerInputText;
