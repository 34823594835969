import React from "react";
import {MojFormItem} from "@core/components/shared/MojFormItem/MojFormItem";
import {MojInput} from "@core/components/shared/MojInput/MojInput";
import {useTranslation} from "react-i18next";
import {FormikProps, FormikValues} from "formik";

import "./MojNationalIdInput.scss";
import {convertToEnglishNumbers} from "@common/utils/numbers-utils";
import { arabicToEnglishMap } from "@/common/utils/constants";

interface NationalIdInputProps {
    formik: FormikProps<FormikValues>,
    fieldName?: string,
    fieldLabel?: string,
    placeholder?: string
}

const MojNationalIdInput = ({formik, fieldLabel, fieldName = 'id', placeholder}: NationalIdInputProps) => {
    const {t} = useTranslation();

    const {errors, touched, getFieldProps, setFieldValue} = formik;

    const label = fieldLabel ? fieldLabel : t('shared.facility-national');

    const fieldProps = () => {
        let idFieldProps: any = {onKeyPress: (event) => { if (!/[0-9.]/.test(event.key) && !arabicToEnglishMap[event.key]) {event.preventDefault() } } };

        if(getFieldProps(fieldName)) {
            idFieldProps = {...idFieldProps, ...getFieldProps(fieldName)};
            const idFieldPropsOnChange = idFieldProps.onChange;

            const idFieldOnChange = e => {
                convertToEnglish(e);

                if(e.target.value && e.target.value.length >= 11) {
                    e.preventDefault();
                    return;
                }

                idFieldPropsOnChange(e);
            }

            idFieldProps = {...idFieldProps, onChange: idFieldOnChange};
        } else {
            const idFieldOnChange = e => {
                convertToEnglish(e);

                if(e.target.value && e.target.value.length >= 11) {
                    e.preventDefault();
                    return;
                }
            }

            idFieldProps = {...idFieldProps, onChange: idFieldOnChange};
        }

        return idFieldProps;
    }

    const convertToEnglish = (e: { target: { value: string; }; }) => {
        if(e.target.value) {
            e.target.value = convertToEnglishNumbers(e.target.value);
            setFieldValue(fieldName, e.target.value);
        }
    }

    return (
        <MojFormItem
            label={label}
            htmlFor={fieldName}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            validateStatus={errors[fieldName] && 'error'}
            help={touched[fieldName] && errors[fieldName]}
            className='national-id-input'
        >
            <MojInput allowClear id={fieldName} inputMode="numeric" {...fieldProps()} placeholder={placeholder ? placeholder : t('shared.enter-national-id')} />
        </MojFormItem>
    )
}

export default MojNationalIdInput;
