import { FunctionComponent, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import camelcaseKeys from 'camelcase-keys';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import {
    APIProvider,
    Map,
    MapControl,
    ControlPosition,
    InfoWindow,
    useMap,
    Marker,
    useMarkerRef,
    AdvancedMarker,
} from '@vis.gl/react-google-maps';

import MapInfo from '../MapInfo/MapInfo';
import SearchBar from '../MapInfo/SearchBar';
import MapViewLegend from '../MapViewLegend/MapViewLegend';
import NoData from '../MapInfo/NoData';

import realestateAndDealsService from '@common/services/realestates-and-deals.service';
import { loaderStore } from '@/common/stores/loader.store';
import GenericResponse from '@/common/models/GenericResponse';
import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import { INITIAL_VIEW_STATE, MAP_ID, MAP_LOAD_PROPERTIES_THROTTLE, center } from '@/common/utils/constants';
import { observer } from 'mobx-react-lite';
import { IMapRealestate } from '@/core/interfaces/MapRealstate.interface';

import './_RealStateMap.scss';
import { geocode, RequestType, setDefaults, OutputFormat } from 'react-geocode';
import { hexToRgbA } from './mapHelperFunctions';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import LegendWithNumbers from '../MapInfo/LegendWithNumbers';
import MojLabel, { LabelVariant } from '@/core/components/shared/Label/MojLabel';
import { useTranslation } from 'react-i18next';
import { nFormatter } from '../../utils/open-market.utils';

export type SortingType = {
    sortType: number;
    sortField: number;
};

const getColorByStatus = (d: any) => {
    const [r, g, b, o] = hexToRgbA(`${d?.properties?.polygonColor}`);
    // hardcode opacity to 140
    return [r, g, b, 140];
};
const getLineColor = (d: any) => {
    return d?.geometry?.type === 'Point'
        ? [160, 160, 180, 0]
        : hexToRgbA(d?.properties?.lineColor);
};
const getText = (d: any) => {
    if (d?.geometry?.type === 'Point') {
        return d?.properties?.text;
    }
    return null;
};
const getAppBaseURL = () => {
    const fullURL = window.location.href;
    const realtivePath = window.location.pathname;
    return fullURL.replace(realtivePath, '');
};
const getIcon = (d: any) => {
    const color = d?.properties?.openMarketOrders ? 'yellow' : d?.properties?.purchaseOrders ? 'green' : 'pink';
    return {
        url: `${getAppBaseURL()}/${color}.svg`,
        width: 20,
        height: 20,
    };
};

const getRealStateStats = async (coordinates: object, zoom: number | undefined) => {
    const filters = { ...realestateAndDealsStore.realestateFilters };
    delete filters.locationSearchTerm;
    if (filters.hasOwnProperty('isOpenMarket')) {
        delete filters['isOpenMarket'];
    }
    if (filters.hasOwnProperty('isRealEstate')) {
        delete filters['isRealEstate'];
    }
    const randomData = zoom &&
        zoom < 15 &&
        (realestateAndDealsStore.realestateFilters?.realEsateIdentity === null ||
            realestateAndDealsStore.realestateFilters?.realEsateIdentity === undefined);
    let response = await realestateAndDealsService.getRealestatesMapStatsData(
        realestateAndDealsStore.realestateOptions.includes(0),
        realestateAndDealsStore.realestateOptions.includes(1),
        realestateAndDealsStore.realestateOptions.includes(2),
        coordinates,
        { ...filters, isWithCoordinates: true },
        {
            randomData,
            mapViewType: realestateAndDealsStore.mapViewType,
        },
    );
    if (response?.Data?.RealEstateCount) {
        return camelcaseKeys(response.Data, { deep: true });
    }
};

const getRealStateData = async (coordinates: object, zoom: number | undefined) => {
    const filters = { ...realestateAndDealsStore.realestateFilters };
    delete filters.locationSearchTerm;
    if (filters.hasOwnProperty('isOpenMarket')) {
        delete filters['isOpenMarket'];
    }
    if (filters.hasOwnProperty('isRealEstate')) {
        delete filters['isRealEstate'];
    }
    const randomData = zoom &&
        zoom < 15 &&
        (realestateAndDealsStore.realestateFilters?.realEsateIdentity === null ||
            realestateAndDealsStore.realestateFilters?.realEsateIdentity === undefined);

    let response = await realestateAndDealsService.getRealestatesMapData(
        realestateAndDealsStore.realestateOptions.includes(0),
        realestateAndDealsStore.realestateOptions.includes(1),
        realestateAndDealsStore.realestateOptions.includes(2),
        coordinates,
        { ...filters, isWithCoordinates: true },
        {
            randomData,
            mapViewType: realestateAndDealsStore.mapViewType,
        },
    );
    if (response?.IsSuccess!! && response?.Data!!) {
        return camelcaseKeys(response.Data, { deep: true });
    }
};

const updateInfoWindowWithPrice = (features: any[]) => {
    const featuresWithPrice = features?.map((feature) => {
        let base: any = {};
        if (feature.geometry && feature.geometry.coordinates && feature.properties.openMarketOrders) {
            // feature.geometry && feature.geometry.coordinates && feature.properties.openMarketOrders
            if (feature.geometry.coordinates[0][0]) {
                let coordinatesAtZero = 0;
                let coordinatesAtOne = 0;


                feature.geometry.coordinates[0].forEach((coordinate) => {
                    coordinatesAtZero += coordinate[0];
                    coordinatesAtOne += coordinate[1];
                });
                base.coordinates = [coordinatesAtZero / feature.geometry.coordinates[0].length, coordinatesAtOne / feature.geometry.coordinates[0].length];
                base.backgroundColor = feature.properties.lineColor;
                base.textColor = feature.properties.backgroundColor;
                base.text = !!feature.properties.openMarketPrice ? nFormatter(feature.properties.openMarketPrice, 2) : "";
            }
        }

        return base;
    }
    );
    realestateAndDealsStore.featuresWithPrice = (featuresWithPrice || []).filter((feature) => feature.text && feature.coordinates);
}
const loadRealestate = async (
    coordinates: object,
    zoom: number | undefined,
    callMap: boolean,
    callSearchByIdentity: boolean,
    setNoData: (flag: boolean) => void,
    setViewState: (viewstate: {
        latitude: number;
        longitude: number;
        zoom: number;
        pitch: number;
        bearing: number;
    }) => void,
    setShowIdentityPinPosition: (showFlag: boolean) => void
) => {
    let fetchedData: any = null;
    let openMarketOrders = 0;
    let purchaseOrders = 0;
    let realestates = 0;
    if (
        realestateAndDealsStore.realestateFilters?.realEsateIdentity &&
        !isNaN(realestateAndDealsStore.realestateFilters?.realEsateIdentity) &&
        callSearchByIdentity
    ) {
        const identity = realestateAndDealsStore.realestateFilters?.realEsateIdentity;
        realestateAndDealsStore.resetFilters();
        realestateAndDealsStore.updateFilters({
            ...realestateAndDealsStore.realestateFilters,
            realEsateIdentity: identity,
            realEstateIdentities: [identity.toString()],
        });
        realestateAndDealsStore.updateRealestatesOptions([0, 1, 2]);
        realestateAndDealsStore.setMapViewType(null);

        fetchedData = await getRealStateData(coordinates, zoom);
        updateInfoWindowWithPrice(fetchedData?.features);
        openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
        purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
        realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;

        if (!fetchedData || fetchedData?.features?.length === 0) {
            setNoData(true);
            setShowIdentityPinPosition(false);
            fetchedData = fetchedData?.features;
            openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
            purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
            realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
        } else {
            let centerPointCoords = fetchedData?.features?.filter((feature) => feature?.geometry?.type === 'Point')[0]
                ?.geometry?.coordinates;
            setViewState({
                latitude: centerPointCoords?.[1],
                longitude: centerPointCoords?.[0],
                zoom: 19.6,
                pitch: 0,
                bearing: 0,
            });
            realestateAndDealsStore.setSearchByIdentityPosition({
                lat: centerPointCoords?.[1],
                lng: centerPointCoords?.[0],
            });
            setShowIdentityPinPosition(true);
        }
    } else if (!callSearchByIdentity) {
        if ((zoom || 1) >= 4) {
            if ((zoom || 1) < 15 && (realestateAndDealsStore.pointerFeatures === null || callMap)) {
                fetchedData = await getRealStateData(coordinates, zoom);

                if (!callMap) {
                    const fetchedCountsData = await getRealStateStats(coordinates, zoom);
                    openMarketOrders = (fetchedCountsData?.openMarketCount || 0) * 2;
                    purchaseOrders = (fetchedCountsData?.purchaseOrderCount || 0) * 2;
                    realestates = (fetchedCountsData?.realEstateCount || 0) * 2;
                } else {
                    openMarketOrders = -1;
                    purchaseOrders = -1;
                    realestates = -1;
                }

                updateInfoWindowWithPrice(fetchedData?.features);
                realestateAndDealsStore.setPointersFeatures(fetchedData);
                realestateAndDealsStore.setDataMessage(null);
                setShowIdentityPinPosition(false);
            } else if ((zoom || 1) < 15) {
                fetchedData = realestateAndDealsStore.pointerFeatures;
                const fetchedCountsData = await getRealStateStats(coordinates, zoom);
                openMarketOrders = (fetchedCountsData?.openMarketCount || 0) * 2;
                purchaseOrders = (fetchedCountsData?.purchaseOrderCount || 0) * 2;
                realestates = (fetchedCountsData?.realEstateCount || 0) * 2;

                realestateAndDealsStore.setDataMessage(null);
                setShowIdentityPinPosition(false);
                realestateAndDealsStore.featuresWithPrice = [];
            } else {
                fetchedData = await getRealStateData(coordinates, zoom);
                updateInfoWindowWithPrice(fetchedData?.features);
                openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;

                if (fetchedData?.features?.length === 0) {
                    if (callMap) {
                        setNoData(true);
                    }
                    fetchedData = fetchedData?.features;
                    openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
                    purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
                    realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
                    setShowIdentityPinPosition(false);
                } else {
                    realestateAndDealsStore.setDataMessage(`${fetchedData?.features?.length / 2}`);
                }
            }
            setTimeout(() => {
                setNoData(false);
            }, 7000);
        }
    }

    const saudiArabiaLabel = [{ text: 'Saudi Arabia', coordinates: [center?.lng, center?.lat] }];
    const textLayer: any = new TextLayer({
        id: 'text-layer',
        data: [...saudiArabiaLabel],
        pickable: true,
        getPosition: (d: { text: string; coordinates: Array<number> }) => d.coordinates,
        getText: (d: { text: string; coordinates: Array<number> }) => d.text,
        getSize: 18,
        getColor: [0, 0, 0],
        textCharacterSet: 'auto',
        getBackgroundColor: [33, 43, 53],
        sizeUnits: 'pixels',
    });
    const geoJsonLayer: any = new GeoJsonLayer({
        id: 'geojson-layer',
        data: fetchedData,
        pickable: true,
        filled: true,
        stroked: true,
        pointType: zoom && zoom < 15 ? 'icon' : 'circle+text',
        getFillColor: (d: any) => {
            return getColorByStatus(d);
        },
        getLineColor: (d: any) => {
            return getLineColor(d);
        },
        getLineWidth: () => 3,
        lineWidthUnits: 'pixels',
        getPointRadius: () => 0,
        getText:
            zoom && zoom > 17
                ? (d: any) => {
                    return getText(d) || '';
                }
                : () => '',
        textFontFamily: 'IBM Plex Sans Arabic',
        textFontWeight: '500',
        textCharacterSet: 'auto',
        getTextColor: (d) => d?.properties?.lineColor ? hexToRgbA(d?.properties?.lineColor) : [0, 0, 0],
        getTextBackgroundColor: () => [33, 43, 53],
        getTextSize: () => 18,
        getIcon: (d: any) => getIcon(d),
        getPosition: (d: { coordinates: Array<number> }) => d.coordinates,
        getIconSize: () => 20,
        iconSizeUnits: 'pixels',
        // onHover: updateTooltip,
    });
    const counts = {
        openMarketOrders: openMarketOrders === -1 ? -1 : Math.ceil(openMarketOrders / 2),
        purchaseOrders: purchaseOrders === -1 ? -1 : Math.ceil(purchaseOrders / 2),
        realestates: realestates === -1 ? -1 : Math.ceil(realestates / 2)
    };
    if (zoom && zoom >= 4 && zoom <= 15) {
        return { layers: [textLayer, geoJsonLayer], counts }
    }
    return { layers: [geoJsonLayer], counts };
};

export const DeckGlOverlay = (props: any) => {
    let {
        callMap,
        setCallMap,
        setNoData,
        callSearchByIdentity,
        setCallSearchByIdentity,
        setViewState,
        setShowIdentityPinPosition,
        locateMe,
        realEstateTypes,
        mapViewType,
        setCounts
    } = props;
    const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);
    const map = useMap(MAP_ID);
    const lastRun = useRef<number | null>();

    useEffect(() => {
        deck.setMap(map);
        setDefaults({
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            language: 'en',
            region: 'SA',
            outputFormat: OutputFormat.JSON,
        });

        geocode(RequestType.ADDRESS, 'Saudi Arabia').then(({ results }) => {
            const boundsObj = {
                ne: results[0]?.geometry?.viewport?.northeast,
                sw: results[0]?.geometry?.viewport?.southwest,
            };
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend({ lat: boundsObj.sw.lat, lng: boundsObj.sw.lng });
            bounds.extend({ lat: boundsObj.ne.lat, lng: boundsObj.ne.lng });
            map?.fitBounds(bounds);
            map?.setOptions({
                restriction: {
                    latLngBounds: bounds,
                    strictBounds: true,
                },
            });
        });
        google.maps.event.addListenerOnce({ ...map }, 'tilesloaded', locateMe);
        map?.addListener('idle', () => {
            const diff = lastRun.current ? Date.now() - lastRun.current : MAP_LOAD_PROPERTIES_THROTTLE + 1;
            if (diff > MAP_LOAD_PROPERTIES_THROTTLE) {
                lastRun.current = Date.now();

                const zoom = map?.getZoom();
                let coordinates: any = map?.getCenter()?.toJSON();

                if (zoom && zoom >= 4 && zoom <= 15) {
                    const bounds = map?.getBounds();
                    if (!bounds) return;

                    const sw = bounds.getSouthWest();
                    const ne = bounds.getNorthEast();
                    coordinates = {
                        North: ne.lat(),
                        South: sw.lat(),
                        East: ne.lng(),
                        West: sw.lng(),
                    }
                }

                loadData(coordinates, map?.getZoom() || 0);
            }
        });
        map?.addListener('zoom_changed', () => {
            realestateAndDealsStore.setZoomLvl(map?.getZoom() || 0);
        });
        // map?.addListener('center_changed', () => {
        //     console.log("center_CHANGED")
        // });
    }, [map]);

    const loadData = async (searchCords: any, zoom: number | undefined) => {
        const latLng = searchCords?.lat && searchCords?.lng ? {
            Latitude: searchCords?.lat,
            Longitude: searchCords?.lng,
        } : searchCords;
        const realestates = await loadRealestate(
            latLng,
            zoom,
            callMap,
            callSearchByIdentity,
            setNoData,
            setViewState,
            setShowIdentityPinPosition
        );


        // @ts-ignore
        deck.setProps({ ...props, layers: realestates.layers!! });
        if (realestates.counts?.openMarketOrders !== -1 || realestates.counts?.purchaseOrders !== -1 || realestates.counts?.realestates !== -1) {
            setCounts(realestates.counts);
        }
        setCallMap(false);
        setCallSearchByIdentity(false);
        setTimeout(() => {
            loaderStore.setLoader(false);
        }, 3000);
    };

    useEffect(() => {
        const zoom = map?.getZoom();
        let coordinates: any = map?.getBounds()?.getCenter()?.toJSON();

        if (zoom && zoom >= 4 && zoom <= 15) {
            const bounds = map?.getBounds();
            if (!bounds) return;

            const sw = bounds.getSouthWest();
            const ne = bounds.getNorthEast();
            coordinates = {
                North: ne.lat(),
                South: sw.lat(),
                East: ne.lng(),
                West: sw.lng(),
            }
        }
        loadData(coordinates, map?.getZoom());
    }, [realEstateTypes, mapViewType]);
    useEffect(() => {
        if (callMap) loadData(map?.getBounds()?.getCenter()?.toJSON(), map?.getZoom());
    }, [callMap]);
    useEffect(() => {
        if (callSearchByIdentity) loadData(map?.getBounds()?.getCenter()?.toJSON(), map?.getZoom());
    }, [callSearchByIdentity]);

    return null;
};

interface RealStateMapProps {
    callMap: boolean;
    setCallMap: any;
    callSearchByIdentity: boolean;
    setCallSearchByIdentity: (flag: boolean) => void;
    showModal: () => void;
}
const RealStateMap: FunctionComponent<RealStateMapProps> = ({
    callMap,
    setCallMap,
    callSearchByIdentity,
    setCallSearchByIdentity,
    showModal
}) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState(null);
    const [markerRef, marker] = useMarkerRef();
    const [position, setPosition] = useState<any>(null);
    const [currentPosition, setCurrentPosition] = useState<any>(null);
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
    const [noData, setNoData] = useState(false);
    const [geoLocation, setGeoLocation] = useState<any>(null);
    const [mapType, setMapType] = useState(false);
    const [dataMessage, setDataMessage] = useState(null);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [showIdentityPinPosition, setShowIdentityPinPosition] = useState(false);
    const isMobile = useMemo(() => isMobileOrTabletDevice(), []);
    const [counts, setCounts] = useState({ openMarketOrders: 0, purchaseOrders: 0, realestates: 0 });
    const [showLegendWithNumbers, setShowLegendWithNumbers] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        if (showPopup && currentData) {
            setTimeout(() => {
                const legendWithNumbersYPosition = document.getElementById('legendWithNumbers')?.getBoundingClientRect().top;
                const popupYPosition = document.getElementById('map-info')?.getBoundingClientRect().top;
                if (legendWithNumbersYPosition && popupYPosition) {
                    // check if legendWithNumbersYPosition is within 10px (up/down) of popupYPosition
                    if (Math.abs(legendWithNumbersYPosition - popupYPosition) <= 26) {
                        setShowLegendWithNumbers(false);
                    } else {
                        setShowLegendWithNumbers(true);
                    }
                } else {
                    setShowLegendWithNumbers(true);
                }
            }, 1000);

        } else {
            setShowLegendWithNumbers(true);
        }
    }, [currentData, showPopup]);

    const getRealestateTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getRealEstateTypes();

        if (response?.IsSuccess) {
            realestateAndDealsStore.setRealestateTypes(response.Data);
            loaderStore.setLoader(false);
        }
    };
    const getMapViewTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getMapViewTypes();
        if (!response?.IsSuccess) return false;
        loaderStore.setLoader(false);
        realestateAndDealsStore.setMapViews(camelcaseKeys(response.Data, { deep: true }));
    };

    const closeInfoWindow = () => {
        setShowPopup(false);
        setShowIdentityPinPosition(true);
    };

    const showPosition = (position: any) => {
        setPermissionDenied(false);
        setCurrentPosition({ lat: position?.coords?.latitude, lng: position?.coords?.longitude });
        setViewState({
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
            zoom: 17,
            pitch: 0,
            bearing: 0,
        });
    };

    const getRealestateLink = useCallback((realestate: IMapRealestate) => {
        if (realestate.openMarketOrders && realestate.openMarketPrice) {
            realestateAndDealsStore.updateRealestatePrice(realestate?.serial, realestate.openMarketPrice);
            return navigate(`/transactions/${realestate?.requestId}`);
        } else {
            return navigate(`/open-market/order/${realestate?.serial}`);
        }
    }, []);

    const locateMe = () => {
        navigator?.permissions?.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                // clear the previous counts results
                setTimeout(() => {
                    setCounts({ openMarketOrders: 0, purchaseOrders: 0, realestates: 0 });
                }, 1000);
                setPermissionDenied(false);
                navigator?.geolocation?.getCurrentPosition(showPosition, () => { setPermissionDenied(true) }, { enableHighAccuracy: true });
            } else if (result.state === 'prompt') {
                navigator?.geolocation?.getCurrentPosition(showPosition, () => setPermissionDenied(true), { enableHighAccuracy: true });
            } else {
                setPermissionDenied(true);
                setTimeout(() => {
                    setPermissionDenied(false);
                }, 7000);
            }
        });
    };

    useEffect(() => {
        loaderStore.setLoader(true);
        getRealestateTypes();
        getMapViewTypes();
        realestateAndDealsStore.setMapViewType(null);
        // locateMe();
    }, []);

    useEffect(() => {
        if (geoLocation !== null)
            setViewState({
                latitude: geoLocation?.lat,
                longitude: geoLocation?.lng,
                zoom: 15,
                pitch: 0,
                bearing: 0,
            });
    }, [geoLocation]);

    const getBg = useCallback((feature: any) => {
        return feature.backgroundColor;
    }, [])

    return (
        <div className="map-contanier" style={!realestateAndDealsStore.toolsVisible ? { height: '82vh' } : {}}>
            {viewState && (
                <APIProvider libraries={["places", "marker"]} apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}>
                    <Map
                        // TODO: below was commented because of console warning that styles cannot be used with mapId
                        // styles={[
                        //     {
                        //         featureType: 'poi',
                        //         stylers: [{ visibility: 'off' }],
                        //     },
                        //     {
                        //         featureType: 'transit',
                        //         stylers: [{ visibility: 'off' }],
                        //     },
                        //     {
                        //         featureType: 'administrative.country',
                        //         elementType: 'labels',
                        //         stylers: [{ visibility: 'off' }],
                        //     },
                        // ]}
                        id={MAP_ID}
                        mapId={MAP_ID}
                        zoom={4}
                        center={center}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        viewState={viewState}
                        initialViewState={INITIAL_VIEW_STATE}
                        mapTypeId={mapType ? 'hybrid' : 'roadmap'}
                        className="map"
                    >
                        <DeckGlOverlay
                            initialViewState={INITIAL_VIEW_STATE}
                            controller={true}
                            callMap={callMap}
                            setNoData={setNoData}
                            setDataMessage={setDataMessage}
                            setCallMap={setCallMap}
                            callSearchByIdentity={callSearchByIdentity}
                            setCallSearchByIdentity={setCallSearchByIdentity}
                            setViewState={setViewState}
                            setShowIdentityPinPosition={setShowIdentityPinPosition}
                            setCurrentPosition={setCurrentPosition}
                            locateMe={locateMe}
                            realEstateTypes={realestateAndDealsStore.realestateOptions}
                            mapViewType={realestateAndDealsStore.mapViewType}
                            setCounts={setCounts}
                            onClick={(data: any) => {
                                if (realestateAndDealsStore.zoomLvl > 15) {
                                    setPosition({ lat: data?.coordinate[1], lng: data?.coordinate[0] });
                                    setCurrentData(data?.object?.properties);
                                    setShowPopup(true);
                                    setNoData(false);
                                    setShowIdentityPinPosition(false);
                                }
                            }}
                        />
                        {realestateAndDealsStore.toolsVisible ? <MapControl position={ControlPosition.TOP_CENTER}>
                            <div className="mapControlContainer">
                                <SearchBar setGeoLocation={setGeoLocation} mapType={mapType} setMapType={setMapType} />
                                {(showLegendWithNumbers) ? <LegendWithNumbers {...counts} /> : <></>}
                                {noData && <NoData label="map-info.lbl-no-data" />}
                                {permissionDenied && <NoData label="map-info.lbl-no-permission" />}
                                {!isMobile && realestateAndDealsStore.zoomLvl <= 10 ? <div className='zoom-label'>
                                    <MojLabel color={LabelVariant.warningDark}>{t("map-info.zoom-in-to-view-more")}</MojLabel>
                                </div> : <></>}
                            </div>
                        </MapControl> : <></>}
                        {realestateAndDealsStore.toolsVisible && (isMobile && realestateAndDealsStore.zoomLvl <= 10) ? <MapControl position={ControlPosition.BOTTOM_CENTER}>
                            <div className='zoom-label'>
                                <MojLabel color={LabelVariant.warningDark}>{t("map-info.zoom-in-to-view-more")}</MojLabel>
                            </div>
                        </MapControl> : <></>}
                        {realestateAndDealsStore?.mapViews && (
                            <MapControl position={isMobile ? ControlPosition.LEFT_BOTTOM : ControlPosition.BOTTOM_CENTER}>
                                <MapViewLegend
                                    enumKey={'propertyStatusEnums'}
                                    isProperty={true}
                                    mapViewType={realestateAndDealsStore.mapViewType}
                                    isMobile={isMobile}
                                    locateMe={locateMe}
                                    mapType={mapType}
                                    setMapType={setMapType}
                                    showModal={showModal}
                                />
                                {/* {realestateAndDealsStore.mapViewType !== null && (
                                    <MapViewLegend
                                        enumKey={'mapViewsEnums'}
                                        isProperty={false}
                                        mapViewType={realestateAndDealsStore.mapViewType}
                                        isMobile={isMobile}
                                        locateMe={locateMe}
                                        mapType={mapType}
                                        setMapType={setMapType}
                                        showModal={showModal}
                                    />
                                )} */}
                            </MapControl>
                        )}

                        {realestateAndDealsStore.zoomLvl > 17 && currentPosition && (
                            <Marker position={currentPosition} />
                        )}
                        {realestateAndDealsStore.zoomLvl > 17 &&
                            showIdentityPinPosition &&
                            realestateAndDealsStore.searchByIdentityPosition && (
                                <Marker position={realestateAndDealsStore.searchByIdentityPosition} />
                            )}
                        {!showIdentityPinPosition && showPopup && currentData && (
                            <>
                                <Marker position={position} ref={markerRef} />
                                <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
                                    <div id="map-info" className="map-info">
                                        <MapInfo data={currentData} handleViewMore={getRealestateLink} />
                                    </div>
                                </InfoWindow>
                            </>
                        )}

                        {/* pins with price */}
                        {realestateAndDealsStore.zoomLvl > 15 && realestateAndDealsStore.featuresWithPrice && realestateAndDealsStore.featuresWithPrice.length > 0 ? realestateAndDealsStore.featuresWithPrice.map((marker, index) => {
                            return <AdvancedMarker key={`advance-marker-${index}`} position={{ lat: marker.coordinates[1], lng: marker.coordinates[0] }}>
                                <div className='pin-price' style={{ backgroundColor: marker.backgroundColor, marginBottom: realestateAndDealsStore.zoomLvl >= 18 ? 32 : 0 }}>
                                    {marker.text}
                                </div>
                                <div className='chin'></div>
                            </AdvancedMarker>
                        }) : <></>}
                    </Map>
                </APIProvider>
            )}
        </div>
    );
};

export default observer(RealStateMap);
