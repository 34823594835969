import { IDeed } from '@/common/models/Deed';
import { IPreValidateModel, IPreValidateResult } from '@/common/models/OpenMarketRequest';
import { errorsListToErrorDetails, getPrevalidationErrors } from '@/common/utils/deed-utils';
import { realEstatesServices } from '@/common/services/realestate.services';
import { OtpRequestType } from '@/core/assets/enums';
import GenericResponse from '@common/models/GenericResponse';

class NewDealManager {
    async preValidateAllPrivateDealForGPT3M(deed?: IDeed | undefined, t?: any) {
        var requestTypesForValidation = [OtpRequestType.GPT3M, OtpRequestType.GIFT];

        var model: IPreValidateModel = {
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: requestTypesForValidation,
            skipActiveRequestsValidation: false,
            skipAttorneyValidation: true,
        };

        var result: GenericResponse = await realEstatesServices.prevalidateDeed(model);

        return result;
    }
    async preValidateForDuEditDeed(deed?: IDeed | undefined, t?: any) {
        var requestTypesForValidation = [OtpRequestType.DU_EDIT_REQUEST];

        var model: IPreValidateModel = {
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: requestTypesForValidation,
            skipActiveRequestsValidation: false,
            skipAttorneyValidation: true,
        };

        var result: GenericResponse = await realEstatesServices.prevalidateDeed(model);

        return result;
    }

    async preValidateForAuction(deed?: IDeed | undefined, t?: any) {
        var requestTypesForValidation = [OtpRequestType.AUCTION];
            //OtpRequestType.Auction

        var model: IPreValidateModel = {
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: requestTypesForValidation,
            skipActiveRequestsValidation: false,
            skipAttorneyValidation: true,
        };

        var result: GenericResponse = await realEstatesServices.prevalidateDeed(model);

        return result;
    }

    async preValidateForSplitRealestate(deed?: IDeed | undefined, t?: any) {
        var requestTypesForValidation = [OtpRequestType.SPLIT_REAL_ESTATES];
            //OtpRequestType.Auction

        var model: IPreValidateModel = {
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: requestTypesForValidation,
            skipActiveRequestsValidation: false,
            skipAttorneyValidation: true,
        };

        var result: GenericResponse = await realEstatesServices.prevalidateDeed(model);

        return result;
    }

    async preValidateForSplitLand(deed?: IDeed | undefined, t?: any) {
        var requestTypesForValidation = [OtpRequestType.SPLIT_REAL_ESTATES2];
            //OtpRequestType.Auction

        var model: IPreValidateModel = {
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: requestTypesForValidation,
            skipActiveRequestsValidation: false,
            skipAttorneyValidation: true,
        };

        var result: GenericResponse = await realEstatesServices.prevalidateDeed(model);

        return result;
    }

    async prevalidateAllPrivateDeal(deed?: IDeed | undefined, t?: any) {
        var requestTypesForValidation = [OtpRequestType.DEAL, OtpRequestType.GIFT, OtpRequestType.OPENMARKET];
        if (deed?.IsRealEstateMortgaged) {
            requestTypesForValidation = [OtpRequestType.EPTM];
        }

        var model: IPreValidateModel = {
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: requestTypesForValidation,
            skipActiveRequestsValidation: false,
            skipAttorneyValidation: true,
        };

        var result: GenericResponse = await realEstatesServices.prevalidateDeed(model);

        return result;
    }

    async prevalidateForType(deed?: IDeed | undefined, requestType?: OtpRequestType, t?: any) {
        var result: GenericResponse = await realEstatesServices.prevalidateDeed({
            deedSerial: deed?.DeedSerial.toString(),
            requestTypes: [requestType!!],
        });

        return result;
    }

    getValidationErrorsForTypePreValidate(preValidateResponse: GenericResponse, deed?: IDeed | undefined, t?: any) {
        const errors = this.getValidationErrors(deed, preValidateResponse.Data, t);

        return errorsListToErrorDetails(errors);
    }

    getValidationErrors(deed: IDeed | undefined, preVResult: IPreValidateResult, translate?: any) {
        var errors: string[] = [];
        if (preVResult) {
            errors = getPrevalidationErrors(preVResult, translate);
        } else {
            var error = this.getConstraintErrors(deed, translate);
            if (error) {
                errors.push(error);
            }
        }

        return errors;
    }

    getConstraintErrors = (deed: IDeed | undefined, translate?: any) => {
        if (deed) {
            if (
                deed?.IsGiftConditioned!! ||
                deed?.IsRealEstateHalted!! ||
                deed?.IsRealEstateConstrained!! ||
                deed?.IsRealEstateTestamented!! ||
                deed?.DeedStatus !== 'A' ||
                deed?.DeedContainerStatus !== 'Active'
            ) {
                return 'يوجد قيود على وثيقة تملك العقار، لذلك لا يمكنك اجراء أي عمليات عليها.';
            } else if (
                (deed.DeedOwners?.length === 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1)) ||
                (deed.DeedOwners?.length > 1 && deed.DeedOwners.some((value, index) => value.CONSTRAINED === 1)) ||
                deed?.IsOwnerConstrained!!
            ) {
                return 'يوجد قيود على مالك العقار، لذلك لا يمكنك إجراء أي عملية.';
            } else if (!deed?.CanCreateRequest) {
                return translate('deed-inquiry.active-transaction-error');
            }
        }

        return null;
    };
}

const newDealManager = new NewDealManager();
export default newDealManager;
