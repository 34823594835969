import React, { useCallback, useEffect, useState } from 'react';
import { Col, Input, notification, Row, Select, SelectProps, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import debounce from 'lodash.debounce';
import FilterButton, { FilterButtonVariants } from '../../../../core/components/shared/FilterButton/FilterButton';
import { AddressForm, Card, MinMax, MojButton } from '../../../../core/components/shared';
import { ILocation } from '@/common/models/ILocation';
import realestateAndDealsService from '@common/services/realestates-and-deals.service';
import { RealestateAndDealsFilterProps } from '@/common/models/realestate-filters';
import GenericResponse from '@/common/models/GenericResponse';
import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import RealestateCheckboxFilters from '@open-market/components/RealestateFilter/RealestateCheckboxFilters/RealestateCheckboxFilters';
import { convertMixNumbersToEnglish, convertToEnglishNumbers } from '@common/utils/numbers-utils';
import './_RealestateFilter.scss';
import { arabicToEnglishMap } from '@/common/utils/constants';
import { realStateViews } from '../../screens/realestate-and-deals/RealestateAndDealsPage';
import NewRealestateCheckboxFilters from './NewRealestateCheckboxFilters/NewRealestateCheckboxFilters';
import NewRealestateMapViewFilters from './NewRealestateMapViewFilters/NewRealestateMapViewFilters';

export type RealestateFilterProps = {
    closeDrawer: () => void;
    onChange?: (filters: RealestateAndDealsFilterProps) => void;
    isLoading?: boolean;
    hideCloseButton?: boolean;
    selectedView?: string;
    setCallSearchByIdentity: (flag: boolean) => void;
};

const initialFilters = {
    cityId: undefined,
    districtId: undefined,
    areaFrom: undefined,
    areaTo: undefined,
    priceFrom: undefined,
    priceTo: undefined,
    planId: undefined,
    landNumber: undefined,
    isAgricalture: false,
    locationSearchTerm: '',
};
const RealestateFilter = ({
    closeDrawer,
    onChange,
    isLoading,
    hideCloseButton,
    selectedView,
    setCallSearchByIdentity,
}: RealestateFilterProps) => {
    const { t } = useTranslation();
    const [searchFilters, setSearchFilters] = React.useState<RealestateAndDealsFilterProps>({
        ...realestateAndDealsStore.realestateFilters,
    });
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<ILocation[]>([]);
    const [showSelect, setShowSelect] = useState(true);
    const [selectedSerial, setSelectedSerial] = useState<ILocation | undefined>(undefined);
    const [selectedPlanParent, setSelectedPlanParent] = useState<string | undefined>(undefined);
    const [planOptions, setPlanOptions] = useState<any[]>([]);
    const [landOptions, setLandOptions] = useState<any[]>([]);
    const [showPlanSelect, setShowPlanSelect] = useState(true);
    const [showLandSelect, setshowLandSelect] = useState<boolean>(true);
    const [resetFiltersFlag, setResetFiltersFlag] = useState<boolean | undefined>(undefined);
    const [showFilters, shouldShowFilters] = useState<boolean>(true);
    const { onResetFilters, realestateTypes } = realestateAndDealsStore;
    let realestateTypesOptions: SelectProps['options'] = [];

    realestateTypesOptions = realestateTypes.map((item: { Name: string; Id: number }) => ({
        label: item.Name,
        value: item.Id.toString(),
    }));

    const resetSubFilters = useCallback(
        ({ resetLandNumber, resetPlanNumber, filters }) => {
            const baseFilters = filters || searchFilters;
            if (resetLandNumber || resetPlanNumber) {
                const baseObject: any = {};
                if (resetPlanNumber) {
                    setShowPlanSelect(false);
                    baseObject.planId = null;
                    setPlanOptions([]);
                }
                if (resetLandNumber) {
                    setshowLandSelect(false);
                    baseObject.landNumber = null;
                    setLandOptions([]);
                }
                setSearchFilters({
                    ...baseFilters,
                    ...baseObject,
                });
                setTimeout(() => {
                    shouldShowFilters(true);
                    setshowLandSelect(true);
                    setShowPlanSelect(true);
                }, 10);
            }
        },
        [searchFilters]
    );

    const handleMinValueBlur = useCallback(() => {
        setSearchFilters({
            ...searchFilters,
            priceFrom: searchFilters.priceFrom ? Number(searchFilters.priceFrom) : searchFilters.priceFrom,
        });
        if (searchFilters.priceTo && Number(searchFilters.priceFrom) > searchFilters.priceTo) {
            notification.error({
                message: t('realestate-filters.errors.min.title'),
                description: t('realestate-filters.errors.min.message'),
            });
            // we had to set the filters twice to trigger the change on NumberInput
            setSearchFilters({
                ...searchFilters,
                priceFrom: 1,
            });
            setSearchFilters({
                ...searchFilters,
                priceFrom: undefined,
            });
        }
    }, [searchFilters, t]);
    const setMinValue = useCallback(
        (event) => {
            const value = convertMixNumbersToEnglish(event.target.value) || 0;
            const splitted = value.toString().split('');
            let hasDecimal = false;
            if (splitted[splitted.length - 1] === '.') {
                hasDecimal = true;
            }
            const updatedValue = hasDecimal ? value : event.target.value !== '' ? Number(value) : undefined;
            setSearchFilters({
                ...searchFilters,
                priceFrom: updatedValue as number,
            });
        },
        [searchFilters]
    );

    const handleMaxValueBlur = useCallback(() => {
        setSearchFilters({
            ...searchFilters,
            priceTo: searchFilters.priceTo ? Number(searchFilters.priceTo) : searchFilters.priceTo,
        });
        if (searchFilters.priceFrom && Number(searchFilters.priceTo) < searchFilters.priceFrom) {
            notification.error({
                message: t('realestate-filters.errors.max.title'),
                description: t('realestate-filters.errors.max.message'),
            });
            // we had to set the filters twice to trigger the change on NumberInput
            setSearchFilters({
                ...searchFilters,
                priceTo: 1,
            });
            setSearchFilters({
                ...searchFilters,
                priceTo: undefined,
            });
        }
    }, [searchFilters, t]);

    const setMaxValue = useCallback(
        (event) => {
            const value = convertMixNumbersToEnglish(event.target.value) || 0;
            const splitted = value.toString().split('');
            let hasDecimal = false;
            if (splitted[splitted.length - 1] === '.') {
                hasDecimal = true;
            }
            const updatedValue = hasDecimal ? value : event.target.value !== '' ? Number(value) : undefined;

            setSearchFilters({
                ...searchFilters,
                priceTo: updatedValue as number,
            });
        },
        [searchFilters]
    );

    const handleMinAreaBlur = useCallback(() => {
        setSearchFilters({
            ...searchFilters,
            areaFrom: searchFilters.areaFrom ? Number(searchFilters.areaFrom) : searchFilters.areaFrom,
        });
        if (searchFilters.areaTo && Number(searchFilters.areaFrom) > searchFilters.areaTo) {
            notification.error({
                message: t('realestate-filters.errors.min-area.title'),
                description: t('realestate-filters.errors.min-area.message'),
            });
            // we had to set the filters twice to trigger the change on NumberInput
            setSearchFilters({
                ...searchFilters,
                areaFrom: 1,
            });
            setSearchFilters({
                ...searchFilters,
                areaFrom: undefined,
            });
        }
    }, [t, searchFilters]);

    const setMinArea = useCallback(
        (event) => {
            const value = convertMixNumbersToEnglish(event.target.value) || 0;
            const splitted = value.toString().split('');
            let hasDecimal = false;
            if (splitted[splitted.length - 1] === '.') {
                hasDecimal = true;
            }
            const updatedValue = hasDecimal ? value : event.target.value !== '' ? Number(value) : undefined;
            setSearchFilters({
                ...searchFilters,
                areaFrom: updatedValue as number,
            });
        },
        [searchFilters]
    );

    const handleMaxAreaBlur = useCallback(() => {
        setSearchFilters({
            ...searchFilters,
            areaTo: searchFilters.areaTo ? Number(searchFilters.areaTo) : searchFilters.areaTo,
        });
        if (searchFilters.areaFrom && Number(searchFilters.areaTo) < searchFilters.areaFrom) {
            notification.error({
                message: t('realestate-filters.errors.max-area.title'),
                description: t('realestate-filters.errors.max-area.message'),
            });
            // we had to set the filters twice to trigger the change on NumberInput
            setSearchFilters({
                ...searchFilters,
                areaTo: 1,
            });
            setSearchFilters({
                ...searchFilters,
                areaTo: undefined,
            });
        }
    }, [t, searchFilters]);
    const setMaxArea = useCallback(
        (event) => {
            const value = convertMixNumbersToEnglish(event.target.value) || 0;
            const splitted = value.toString().split('');
            let hasDecimal = false;
            if (splitted[splitted.length - 1] === '.') {
                hasDecimal = true;
            }
            const updatedValue = hasDecimal ? value : event.target.value !== '' ? Number(value) : undefined;
            setSearchFilters({
                ...searchFilters,
                areaTo: updatedValue as number,
            });
        },
        [searchFilters]
    );

    useEffect(() => {
        if (
            typeof realestateAndDealsStore.onResetFilters === 'boolean' &&
            realestateAndDealsStore.onResetFilters !== resetFiltersFlag
        ) {
            setResetFiltersFlag(realestateAndDealsStore.onResetFilters);
            setOptions([]);
            resetSubFilters({ resetLandNumber: true, resetPlanNumber: true, filters: { ...initialFilters } });
            setShowSelect(false);
            setTimeout(() => {
                setShowSelect(true);
            }, 10);
        }
    }, [onResetFilters, resetSubFilters, setSearchFilters, resetFiltersFlag]);

    useEffect(() => {
        if (onChange) {
            onChange(searchFilters);
        }
    }, [searchFilters, onChange]);

    useEffect(() => {
        setSearchFilters(realestateAndDealsStore.realestateFilters);
    }, []);

    useEffect(() => {
        realestateAndDealsStore.updateFilters({ ...searchFilters });
    }, [searchFilters]);

    const searchPlans = debounce(
        React.useCallback(
            (searchTerm: string) => {
                if (!selectedSerial) {
                    return;
                }
                setPlanOptions([]);
                if (searchTerm) {
                    setFetching(true);
                    realestateAndDealsService
                        .searchPlans(selectedSerial.AreaSerial, selectedSerial.AreaLevel, searchTerm)
                        .then((response: GenericResponse) => {
                            setFetching(false);
                            if (response.IsSuccess && response.Data && response.Data.Entities) {
                                setPlanOptions(response.Data.Entities);
                            }
                        })
                        .then((e) => {
                            setFetching(false);
                        });
                }
            },
            [selectedSerial]
        ),
        1000
    );

    const searchLands = debounce(
        React.useCallback(
            (searchTerm: string) => {
                if (!selectedPlanParent) {
                    return;
                }
                setLandOptions([]);
                if (searchTerm) {
                    setFetching(true);
                    realestateAndDealsService
                        .searchLands(
                            selectedSerial?.AreaSerial!!,
                            selectedSerial?.AreaLevel!!,
                            selectedPlanParent,
                            searchTerm
                        )
                        .then((response: GenericResponse) => {
                            setFetching(false);
                            if (response.IsSuccess && response.Data && response.Data.Entities) {
                                setLandOptions(response.Data.Entities);
                            }
                        })
                        .then((e) => {
                            setFetching(false);
                        });
                }
            },
            [selectedPlanParent]
        ),
        1000
    );

    const onSearch = debounce(
        React.useCallback((searchTerm: string) => {
            setOptions([]);
            if (searchTerm) {
                setFetching(true);
                realestateAndDealsService
                    .getAddressInfo(searchTerm)
                    .then((response: GenericResponse) => {
                        setFetching(false);
                        if (response.IsSuccess && response.Data && response.Data.Entities) {
                            setOptions(response.Data.Entities);
                        }
                    })
                    .then((e) => {
                        setFetching(false);
                    });
            }
        }, []),
        1000
    );
    const onSearchOptionSelect = (value: any, option: any) => {
        const updatedFilters = {
            ...searchFilters,
            cityId: option.cityCode || null,
            districtId: option.districtCode,
            locationSearchTerm: option.fullName,
            regionid: option.RegionCode,
        };
        setSearchFilters(updatedFilters);
        setSelectedSerial(option);
        resetSubFilters({ resetLandNumber: true, resetPlanNumber: true, filters: updatedFilters });
    };

    const onPlanSelect = (value: any, option: any) => {
        setSearchFilters({
            ...searchFilters,
            planId: option.code,
        });
        setSelectedPlanParent(option.code);
    };

    const onLandSelect = (value: any, option: any) => {
        setSearchFilters({
            ...searchFilters,
            landNumber: option.serial,
        });
    };

    const onSelectRealestateTypes = (value: string[]) => {
        setSearchFilters({
            ...searchFilters,
            realEstateTypes: value,
        });
    };
    const handleRealestateIdentityBlur = useCallback(() => {
        if (
            searchFilters.realEsateIdentity &&
            (searchFilters.realEsateIdentity.toString().length < 14 ||
                searchFilters.realEsateIdentity.toString().length > 16)
        ) {
            notification.error({
                message: t('realestate-filters.errors.realestate-identity.title'),
                description: t('realestate-filters.errors.realestate-identity.message'),
            });
            setSearchFilters({
                ...searchFilters,
                realEsateIdentity: undefined,
            });
        }
    }, [searchFilters, t]);
    const onChangeRealEstateIdentity = useCallback(
        (value?: number) => {
            setSearchFilters({
                ...searchFilters,
                realEsateIdentity: value,
            });
        },
        [searchFilters]
    );

    return showFilters ? (
        <Col flex="100%" className={selectedView === realStateViews.map ? 'map-filter-col' : ''}>
            <Spin spinning={isLoading}>
                <div className="RealestateFilter">
                    {selectedView === realStateViews.map && realestateAndDealsStore?.dataMessage && (
                        <Card>
                            <Row justify="start" align="middle">
                                <Col flex="auto">
                                    <span className="filters-heading">
                                        {t('map-info.lbl-total-number-of-properties')}
                                    </span>
                                </Col>
                                <Col>
                                    <span className="map-properties-number">
                                        {realestateAndDealsStore?.dataMessage}
                                    </span>
                                </Col>
                            </Row>
                        </Card>
                    )}

                    <Card>
                        <Row justify="start" align="middle">
                            <Col flex="auto">
                                <span className="filters-heading">{t('shared.realestate-filters')}</span>
                            </Col>
                            {!hideCloseButton && (
                                <Col>
                                    <FilterButton variant={FilterButtonVariants.close} onClick={closeDrawer} />
                                </Col>
                            )}
                        </Row>
                    </Card>

                    {selectedView === realStateViews.map && (
                        <Card>
                            <div className="identity-search-container">
                                <div className="identity-search-header">
                                    <p>{t('map-info.lbl-identity-search')}</p>
                                </div>
                                <div className="identity-search">
                                    <Input
                                        allowClear
                                        value={searchFilters.realEsateIdentity ?? undefined}
                                        onChange={(event) => {
                                            if (event.target.value === '') {
                                                onChangeRealEstateIdentity(undefined);
                                            } else {
                                                const number =
                                                    Number(convertMixNumbersToEnglish(event.target.value)) || undefined;
                                                onChangeRealEstateIdentity(number);
                                            }
                                        }}
                                        onBlur={handleRealestateIdentityBlur}
                                        className="moj-input moj-input--success moj-input--fix-border"
                                        type="text"
                                        inputMode="numeric"
                                        placeholder={'الهوية العقارية'}
                                        onKeyPress={(event) => {
                                            if (
                                                !/[\u0660-\u06690-9.]/.test(event.key) &&
                                                event.key !== '.' &&
                                                !arabicToEnglishMap[event.key]
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        maxLength={16}
                                    />

                                    <MojButton
                                        color="success"
                                        onClick={() => {
                                            setCallSearchByIdentity(true);
                                            closeDrawer();
                                        }}
                                    >
                                        {t('shared.search')}{' '}
                                    </MojButton>
                                </div>{' '}
                            </div>
                        </Card>
                    )}

                    {selectedView !== realStateViews.map && (
                        <Card>
                            <RealestateCheckboxFilters />
                        </Card>
                    )}
                    {selectedView === realStateViews.map && (
                        <Card>
                            <NewRealestateCheckboxFilters closeDrawer={closeDrawer} />
                        </Card>
                    )}
                    {/* {selectedView === realStateViews.map && (
                        <Card>
                            <NewRealestateMapViewFilters closeDrawer={closeDrawer} />
                        </Card>
                    )} */}
                    {selectedView !== realStateViews.map && (
                        <AddressForm
                            fetching={fetching}
                            address={{
                                show: showSelect,
                                onSearch: onSearch,
                                onSelect: onSearchOptionSelect,
                                options: options,
                                value: searchFilters.locationSearchTerm,
                            }}
                            plan={{
                                show: showPlanSelect,
                                onSearch: (term) => {
                                    searchPlans(convertToEnglishNumbers(term));
                                },
                                disabled:
                                    !searchFilters.cityId &&
                                    searchFilters.cityId !== 0 &&
                                    !searchFilters.districtId &&
                                    searchFilters.districtId !== 0,
                                onSelect: onPlanSelect,
                                options: planOptions,
                                value: '',
                            }}
                            land={{
                                show: showLandSelect,
                                onSearch: (term) => {
                                    searchLands(convertToEnglishNumbers(term));
                                },
                                disabled: !searchFilters.planId && searchFilters.planId?.toString() !== '0',
                                onSelect: onLandSelect,
                                options: landOptions,
                            }}
                        />
                    )}

                    <MinMax
                        title={t('shared.area')}
                        min={{
                            onChange: setMinArea,
                            onBlur: handleMinAreaBlur,
                            placeholder: t('shared.minimum-area'),
                            value: searchFilters.areaFrom,
                        }}
                        max={{
                            onChange: setMaxArea,
                            onBlur: handleMaxAreaBlur,
                            placeholder: t('shared.maximum-area'),
                            value: searchFilters.areaTo,
                        }}
                    />

                    <MinMax
                        title={t('shared.price')}
                        min={{
                            onChange: setMinValue,
                            onBlur: handleMinValueBlur,
                            placeholder: t('shared.minimum-value'),
                            value: searchFilters.priceFrom,
                        }}
                        max={{
                            onChange: setMaxValue,
                            onBlur: handleMaxValueBlur,
                            placeholder: t('shared.maximum-value'),
                            value: searchFilters.priceTo,
                        }}
                    />

                    {selectedView !== realStateViews.map && (
                        <Card className="section__container">
                            <Row justify="start" align="middle">
                                <Col span={24}>
                                    <Card.Head className="section__title">{'الهوية العقارية'}</Card.Head>
                                    <Card.Body>
                                        <Row gutter={[16, 0]} justify="start" align="middle">
                                            <Col flex="1">
                                                <Input
                                                    allowClear
                                                    value={searchFilters.realEsateIdentity ?? undefined}
                                                    onChange={(event) => {
                                                        if (event.target.value === '') {
                                                            onChangeRealEstateIdentity(undefined);
                                                        } else {
                                                            const number =
                                                                Number(
                                                                    convertMixNumbersToEnglish(event.target.value)
                                                                ) || undefined;
                                                            onChangeRealEstateIdentity(number);
                                                        }
                                                    }}
                                                    onBlur={handleRealestateIdentityBlur}
                                                    className="moj-input moj-input--success moj-input--fix-border"
                                                    type="text"
                                                    inputMode="numeric"
                                                    placeholder={'الهوية العقارية'}
                                                    onKeyPress={(event) => {
                                                        if (
                                                            !/[\u0660-\u06690-9.]/.test(event.key) &&
                                                            event.key !== '.' &&
                                                            !arabicToEnglishMap[event.key]
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={16}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    )}
                    {selectedView !== realStateViews.map && (
                        <Card className="section__container">
                            <Row justify="start" align="middle">
                                <Col flex="auto">
                                    <Card.Head className="section__title">{'نوع العقار'}</Card.Head>
                                    <Card.Body>
                                        <Col span={24}>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                placeholder="نوع العقار"
                                                className="moj-input moj-input--success"
                                                showArrow={true}
                                                autoClearSearchValue
                                                value={searchFilters.realEstateTypes}
                                                filterOption={true}
                                                defaultActiveFirstOption
                                                optionFilterProp="children"
                                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                                allowClear
                                                options={realestateTypesOptions}
                                                onChange={onSelectRealestateTypes}
                                            />
                                        </Col>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    )}
                </div>
            </Spin>
        </Col>
    ) : (
        <></>
    );
};
export default observer(RealestateFilter);
