import { useMemo } from 'react';
import { Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import RealestateCheckboxFilters from '@open-market/components/RealestateFilter/RealestateCheckboxFilters/RealestateCheckboxFilters';
import { IRealEstateFileStatus, RealEstateFileStatuses } from '@/modules/srem/screens/MyRealestates/models/MyRealestates.models';
import FilterButton, { FilterButtonVariants } from '@core/components/shared/FilterButton/FilterButton';
import { ReactComponent as CsvIcon } from '@/core/assets/images/assets-icons-csv.svg';
import { realestateAndDealsStore } from '@common/stores/realestate-and-deals.store';
import DownloadCsvIcon from '@/core/assets/images/assets-icons-download-svg.png';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import { Card, DateFormat, MojProgressBar } from '@core/components/shared';
import { RadioGroupType } from '../RadioGroup/RadioGroup';
import RadioGroup from '../RadioGroup/RadioGroup';
import './_Header.scss';

export interface RealestateHeaderFiltersChangeProps {
    openMarket: boolean;
    otherRealestates: boolean;
}

export type HeaderProps = {
    headerTitle: string;
    showModal?: () => void;
    closeDrawer?: () => void;
    showActions?: boolean;
    progressbar?: number;
    hideProgressBar?: boolean;
    hideRealestateTypeFilters?: boolean;
    hideSortActions?: boolean;
    showDownloadIcon?: boolean;
    downloadFile?: () => void;
    realEstateFileStatus?: IRealEstateFileStatus;
    radioGroup?: RadioGroupType;
    isMapEnabled?: boolean;
    progressBarSpan?: number;
    headerClassName?: string;
    csvDownloadMethod?: "new" | "old";
    isDownloading?: boolean;
    downloadFileNewMethod?: () => void;
};

const Header = ({
    headerTitle,
    progressbar,
    showActions,
    showModal,
    closeDrawer,
    hideProgressBar,
    hideSortActions = false,
    showDownloadIcon = false,
    hideRealestateTypeFilters,
    downloadFile,
    realEstateFileStatus,
    radioGroup,
    isMapEnabled,
    progressBarSpan = 16,
    headerClassName,
    csvDownloadMethod = "new",
    isDownloading,
    downloadFileNewMethod
}: HeaderProps) => {
    const isMobile = useMemo(() => isMobileOrTabletDevice(), []);
    const { t } = useTranslation();
    return (
        <Card className={clsx("Header", headerClassName)}>
            <Row justify="start" gutter={[8, 0]}>
                <Col flex="auto">
                    <h2 className="Header__title">{headerTitle}</h2>
                </Col>
                {isMapEnabled && radioGroup && (
                    <Col className={clsx({ 'radio-group': !isMobile })} span={hideSortActions && !showActions ? 24 : 20}>
                        <RadioGroup {...radioGroup} />
                    </Col>
                )}
                {showActions && (
                    <>
                        {!hideSortActions && (
                            <Col>
                                <FilterButton variant={FilterButtonVariants.sort} onClick={showModal} />
                            </Col>
                        )}

                        <Col className='filters'>
                            <FilterButton
                                variant={
                                    !realestateAndDealsStore.isFiltersNotApplied
                                        ? FilterButtonVariants.filter
                                        : FilterButtonVariants.filterNotification
                                }
                                onClick={closeDrawer}
                            />
                        </Col>
                    </>
                )}
                {progressbar && !hideProgressBar && (
                    <Col className="Header__progress" span={progressBarSpan}>
                        <MojProgressBar progress={progressbar} />
                    </Col>
                )}
                {showDownloadIcon ? <>
                    {csvDownloadMethod === "old" ? <Spin spinning={realEstateFileStatus?.RealEstateFileStatus === RealEstateFileStatuses.Pending} indicator={<LoadingOutlined />}>
                        <Row justify={"center"}>
                            <Col span={24}>
                                <Row className={clsx({
                                    'generating-csv-container': realEstateFileStatus?.RealEstateFileStatus === RealEstateFileStatuses.Pending
                                })} align={"middle"}>
                                    {realEstateFileStatus?.RealEstateFileStatus !== RealEstateFileStatuses.Completed ? <Col span={12}>
                                        <CsvIcon width={32} height={32} onClick={downloadFile} style={{ cursor: 'pointer' }} />
                                    </Col> : <>
                                        <Col className='downloading-csv-icon' span={24}>
                                            <img onClick={downloadFile} src={DownloadCsvIcon} alt="download csv" />
                                        </Col>
                                        {realEstateFileStatus?.CreatedAt ? <Col span={24} className='downloading-csv-label'>
                                            {t("my-realestates.created-date")}
                                            <br />
                                            <div className='date-time'>
                                                <DateFormat value={realEstateFileStatus.CreatedAt} />
                                            </div>
                                        </Col> : <></>}
                                    </>}
                                </Row>
                                <Row>
                                </Row>
                            </Col>
                            {realEstateFileStatus?.RealEstateFileStatus === RealEstateFileStatuses.Pending && <Col className='downloading-csv-label' span={24}>جاري التحميل...</Col>}
                        </Row>
                    </Spin> : <>
                        <Spin spinning={isDownloading}>
                            <CsvIcon width={32} height={32} onClick={downloadFileNewMethod} style={{ cursor: 'pointer' }} />
                        </Spin>
                    </>}
                </> : <></>}
            </Row>

            {showActions && !hideRealestateTypeFilters && (
                <div className="border-top">
                    <RealestateCheckboxFilters />
                </div>
            )}
        </Card>
    );
};

export default Header;
