import { createClient } from '@common/services/index';
import { AxiosInstance, AxiosResponse } from 'axios';
import GenericResponse from '@common/models/GenericResponse';
import { authStore } from '../stores/auth.store';

export default class DeedService {
    network!: AxiosInstance;
    diNetworkInstance!: AxiosInstance;
    HttpClient!: AxiosInstance;
    pdfHttpClient!: AxiosInstance;

    constructor() {
        this.network = createClient(`${process.env.REACT_APP_API_URL_REPLACE}api/v1`);
        this.diNetworkInstance = createClient(`${process.env.REACT_APP_DI_API_URL}api/v1`);
        this.HttpClient = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
        this.pdfHttpClient = createClient(`${process.env.REACT_APP_PDF_API_URL}api/v1`);
    }

    getDeed = async (value: { deedNumber: any; idNumber: string; idType: string }) => {
        return await this.network
            .get(`Deed/GetDeed/${value.deedNumber}/${value.idNumber}/${value.idType}`)
            .then((response: any) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedDetails = async (body: { deedNumber: string; idNumber: string; idType: string }) => {
        await this.network
            .get(`Deed/Details/${body.deedNumber}/${body.idNumber}/${body.idType}`)
            .then((value) => {
                return value.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedRelease = async (body: {
        deedNumber: string;
        idNumber: string;
        idType: string;
    }): Promise<GenericResponse> => {
        let res: GenericResponse;
        res = await this.network
            .get(`Deed/GetDeed/Release/${body.deedNumber}/${body.idNumber}/${body.idType}`)
            .then((value) => {
                return value.data as GenericResponse;
            })
            .catch((reason) => {
                return reason;
            });

        return res;
    };

    getDeedText = async (body: { deedNumber: string; idNumber: string; idType: string }) => {
        await this.network
            .get(`Deed/Text/${body.deedNumber}/${body.idNumber}/${body.idType}`)
            .then((value) => {
                return value.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    // /api/v{version}/Deed/GetCompanyMortgagedDeeds/{company700Id}/{pageSize}/{pageNumber}/{getCount}
    getCompanyMortgagedDeeds = async (body: {
        company700Id: string;
        pageSize: number;
        pageNumber: number;
        count: number;
    }) => {
        return await this.network
            .get(`Deed/GetCompanyMortgagedDeeds/${body.company700Id}/${body.pageSize}/${body.pageNumber}/${body.count}`)
            .then((response: AxiosResponse<any>) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    verifyDeedCheckInfo = async (idType: number, idNumber: string, idDate: string) => {
        return await this.diNetworkInstance
            .post(`DeedInquiry/InquiryOtp/`, {
                idNumber: idNumber,
                idType: idType,
                birthDate: idDate.replaceAll('/', ''),
            })
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedInfoByDate = async (
        isLoggedIn: boolean,
        body: { deedNumber: string; isHijri?: boolean; year: string; month: string; day: string }
    ) => {
        const data = {
            deedNumber: body.deedNumber,
            deedDateYear: body.year,
            deedDateMonth: body.month,
            deedDateDay: body.day,
            isHijriDate: body.isHijri,
        };
        if (!isLoggedIn) {
            localStorage.setItem('diByDate', JSON.stringify(data));
        } else {
            localStorage.removeItem('diByDate');
        }
        return await this.diNetworkInstance
            .post(isLoggedIn ? 'DeedInquiry/GetDeedByNumber' : 'DeedInquiry/AnonymousGetDeedByNumber', data)
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedInfoByOwners = async (
        isLoggedIn: boolean,
        body: { deedNumber: string; idNumber: string; idType: number }
    ) => {
        const data = {
            deedNumber: body.deedNumber,
            idNumber: body.idNumber,
            idType: body.idType,
        };
        if (!isLoggedIn) {
            localStorage.setItem('diByOwner', JSON.stringify(data));
        } else {
            localStorage.removeItem('diByOwner');
        }
        return await this.diNetworkInstance
            .post(isLoggedIn ? 'DeedInquiry/GetDeedByOwner' : 'DeedInquiry/AnonymousGetDeedByOwner', data)
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedInfoByRealEstateIdentityNo = async (
        isLoggedIn: boolean,
        body: { realEstateIdentity: string; idNumber: string; idType: number }
    ) => {
        const data = {
            realEstateIdentity: body.realEstateIdentity,
            idNumber: body.idNumber,
            idType: body.idType,
        };
        if (!isLoggedIn) {
            localStorage.setItem('diByRealEstateIdentityNo', JSON.stringify(data));
        } else {
            localStorage.removeItem('diByRealEstateIdentityNo');
        }
        return await this.diNetworkInstance
            .post(
                isLoggedIn
                    ? 'DeedInquiry/GetDeedByRealEstateIdentityAndIdNumber'
                    : 'DeedInquiry/AnonymousGetDeedByRealEstateIdentityAndIdNumber',
                data
            )
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getSharedRealStateOwners = async (deedSerial: number, hideDetails: boolean) => {
        return await this.diNetworkInstance
            .post(`DeedInquiry/GetSharedRealStates`, { deedSerial: deedSerial, hideDetails: hideDetails })
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    mergeSharedRealStatesIntoOneDeed = async (body: any) => {
        return await this.HttpClient.post(`Deed/MergeSharedRealStatesIntoOneDeed`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    canMergeSharedDeeds = async (body: any) => {
        return await this.diNetworkInstance
            .post(`DeedInquiry/CanMergeSharedRealStates`, body)
            .then((response: AxiosResponse<any>) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    ignorePurchaseOrderNotification = async (deedSerial: number, disableNotification: boolean) => {
        return await this.HttpClient.post(`Deed/IgnorePurchaseOrderNotification`, {
            deedSerial: deedSerial,
            disableNotification: disableNotification,
        })
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    registerRealStates = async (body: any) => {
        return await this.HttpClient.post(`RERDeedRequest/Create`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    registerRealEstateCancel = async (body: any) => {
        return await this.HttpClient.post(`RERDeedRequest/Cancel`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    registerRealEstateAccept = async (body: any) => {
        return await this.HttpClient.post(`RERDeedRequest/Accept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedPdfBySerial = async (body: { deedSerial: string }) => {
        const token = `${authStore.user?.token_type} ${authStore.user?.access_token}`;
        const url = `${process.env.REACT_APP_PDF_API_URL}api/v1/GetDeedPdfBySerial?deedSerial=${body.deedSerial}`;
        let responseType = 'blob';

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `${token}`,
                    'accept-language': 'ar',
                },
            });

            if (response.ok) {
                if (responseType === 'blob') {
                    const blob = await response.blob();
                    return {
                        data: blob,
                        IsSuccess: true,
                    };
                }
            } else {
                responseType = 'json';

                if (responseType === 'json') {
                    return await response.json();
                } else {
                    console.log(`Request failed with status code ${response.status}`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
}
