import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { User } from 'oidc-client-ts';
import clsx from 'clsx';

import { Attorney, AttorneyItem } from '@common/models/Attorney';
import TransactionsServices from '@/common/services/transactions.services';
import { Card } from '../shared';
import MobileNavbar from './MobileNavbar';
import { Brand } from './Brand';
import { ProfileMenu } from './ProfileMenu';
import { PostLoginPopup } from './PostLoginPopup';
import './Header.scss';

interface MobileHeaderProps {
    userInfo: User | null | undefined;
    attorneys: AttorneyItem[] | null;
    isLoggedIn?: boolean;
    login: () => void;
    logout: () => void;
    onExpandedChange?: (visibilityFlag: boolean) => void;
    authedAttorney?: Attorney;
    getAttorneys?: () => void;
}

function MobileHeader({
    onExpandedChange,
    isLoggedIn,
    login,
    logout,
    userInfo,
    attorneys,
    authedAttorney,
    getAttorneys,
}: Readonly<MobileHeaderProps>) {
    const location = useLocation();
    const navigate = useNavigate();

    const [isExpanded, updateIsExpanded] = useState(false);
    const [isExpanded2, updateIsExpanded2] = useState(attorneys !== undefined);
    const [beneficiaryCount, setBeneficiaryCount] = useState<number>();
    const [beneficiaryCountLoader, setBeneficiaryCountLoader] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const transactionService = new TransactionsServices();

    const setIsExpanded = useCallback(
        (flag: boolean) => {
            updateIsExpanded(flag);
            onExpandedChange && onExpandedChange(flag || isExpanded2);
        },
        [updateIsExpanded, onExpandedChange, isExpanded2]
    );

    const setIsExpanded2 = useCallback(
        (flag: boolean) => {
            updateIsExpanded2(flag);
            onExpandedChange && onExpandedChange(flag || isExpanded);
        },
        [updateIsExpanded2, onExpandedChange, isExpanded]
    );

    useEffect(() => {
        if (isLoggedIn) {
            beneficiaryActionsCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, location]);

    useEffect(() => {
        const isJustLoggedIn = sessionStorage.getItem('isJustLoggedIn') === 'true';
        const shouldRedirect = isJustLoggedIn && beneficiaryCount! > 0;
        const shouldDisplayModal = isJustLoggedIn && beneficiaryCount === 0;

        if (shouldRedirect) {
            setIsModalVisible(false);
            sessionStorage.setItem('isModalShouldDisplay', 'false');
            sessionStorage.setItem('isJustLoggedIn', 'false');
            if (!location.pathname.includes('transactions')) {
                navigate('/transactions', { replace: true });
            }
        } else if (shouldDisplayModal) {
            setIsModalVisible(true);
            sessionStorage.setItem('isModalShouldDisplay', 'true');
            sessionStorage.setItem('isJustLoggedIn', 'false');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryCount]);

    const beneficiaryActionsCount = async () => {
        setBeneficiaryCountLoader(true);

        const response = await transactionService.getBeneficiaryActionsCount();
        if (response?.IsSuccess) {
            setBeneficiaryCount(response?.Data?.PendingTransactionsCount);
        }

        setBeneficiaryCountLoader(false);
    };

    return (
        <>
            <Card
                noPadding={true}
                className={clsx('mobile-header test', {
                    'mobile-header--expanded': isExpanded || isExpanded2,
                })}
            >
                <Brand
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    beneficiaryCount={beneficiaryCount}
                    isLoggedIn={isLoggedIn!}
                    authedAttorney={authedAttorney}
                    isExpanded2={isExpanded2}
                    setIsExpanded2={setIsExpanded2}
                    userInfo={userInfo}
                    login={login}
                />

                <PostLoginPopup
                    attorneys={attorneys}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                />

                <MobileNavbar
                    setIsExpanded={setIsExpanded}
                    authedAttorney={authedAttorney}
                    beneficiaryCountLoader={beneficiaryCountLoader}
                    beneficiaryCount={beneficiaryCount}
                    attorneys={attorneys}
                    isExpanded={isExpanded}
                />

                <ProfileMenu
                    setIsExpanded2={setIsExpanded2}
                    userInfo={userInfo}
                    authedAttorney={authedAttorney}
                    getAttorneys={getAttorneys}
                    setIsExpanded={setIsExpanded}
                    logout={logout}
                    isExpanded2={isExpanded2}
                    attorneys={attorneys}
                />
            </Card>

            {isExpanded || (isExpanded2 && <div className="backdrop"></div>)}
        </>
    );
}

export default observer(MobileHeader);
