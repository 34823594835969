import { Card, MojButton, MojPopup } from '@/core/components/shared';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import './__RefundManagmentResponseModal.scss';
import { ReactComponent as SuccessFlag } from '@core/assets/images/assets-icons-status.svg';

export interface Props {
    handleCloseButton: (flag: boolean) => void;
}

export default function RefundManagmentResponseModal({handleCloseButton} : Props) {
    const { t } = useTranslation();
    return (
        <MojPopup
            title={<Col span={24}>{t('refund-management.ibanRenewalNotification')}</Col>}
            open={true}
            zIndex={99999}
            className="RefundResponseModal"
            closable={false}
            footer={
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <MojButton onClick={() => handleCloseButton(false)} 
                        color={'success'} style={{ marginTop: '15px' }}>
                            {t('shared.continue')}
                        </MojButton>
                    </Col>
                </Row>
            }
        >
            <Card className="content">
                <Row gutter={[16, 16]}>
                    <Col className="successFlag">
                        <SuccessFlag />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col className="refundMessageError" lg={24} md={24} sm={24} xs={24}>
                        {t('refund-management.ibanIsUpdated')}
                    </Col>
                </Row>
            </Card>
        </MojPopup>
    );
}
