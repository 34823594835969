import dayjs from 'dayjs';

import { ReactComponent as MaintenanceIcon } from '@core/assets/images/maintenance.svg';
import { ReactComponent as BrandLogo } from '@/core/assets/images/brand-logo-primary.svg';
import "./Maintenance.scss";

type Props = {
    data: any[];
}

const Maintenance = ({ data }: Props) => {
    return <div className="maintenance">
        <div className="maintenance--container">
            <div>
                <div className="maintenance--header">
                    <MaintenanceIcon className="maintenance-icon" />
                    <h3 className="maintenance--header-title">المنصة تحت الصيانة</h3>
                </div>

                <div className="maintenance--body">
                    <p>
                        نأسف للإزعاج، نود إعلامكم بأننا نقوم حاليًا بأعمال صيانة للمنصة لتحسين تجربتكم. مما سيؤدي إلى إيقاف جميع خدماتنا مؤقتًا.
                    </p>
                    <ul className="maintenance--body-list">
                        <li>
                            <div> ستبدأ في:</div>
                            <div> يوم <span>{dayjs(data[0].StartTime).format('DD/MM/YYYY')}</span> الساعة <span>{dayjs(data[0].StartTime).format('HH:mm')}</span></div>
                        </li>
                        <li>
                            <div>وتنتهي في:</div>
                            <div>يوم <span>{dayjs(data[0].EndTime).format('DD/MM/YYYY')}</span> الساعة <span>{dayjs(data[0].EndTime).format('HH:mm')}</span></div>
                        </li>
                    </ul>

                    <h3 className="maintenance--body-footer">نقدر تفهمكم وتعاونكم.</h3>
                </div>
            </div>

            <div className="maintenance--footer">
                <BrandLogo />
            </div>
        </div>
    </div>
}

export default Maintenance;
