import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, InputNumberProps, notification } from 'antd';
import './NumberInput.scss';
import { useTranslation } from 'react-i18next';
import { convertToEnglishNumbers } from "@common/utils/numbers-utils";
import clsx from 'clsx';

export interface NumberInputProps extends InputNumberProps {
    defaultValue?: any;
    max?: any;
    min?: any;
    parser?: any;
    allowClear?: boolean;
    minValue?: number;
    containerClass?: string;
}

const CloseIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="close-circle"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
    </svg>
);

export const NumberInput = ({
    value,
    onChange: originalOnChange,
    allowClear,
    minValue,
    containerClass,
    ...props
}: NumberInputProps) => {
    const [newValue, setValue] = useState<any>(value);
    const { t } = useTranslation();
    const onChange = useCallback(
        (v: any) => {
            const arabicDigitsRegex = /[\u0660-\u0669]/g;
            const isArabicNumber = arabicDigitsRegex.test(v);
            if (v && isArabicNumber) {
                v = convertToEnglishNumbers(v);
            }

            setValue(v);
            originalOnChange && originalOnChange(v);
        },
        [originalOnChange]
    );

    useEffect(() => {
        setValue(value);
    }, [value]);

    const handleBlur = useCallback((e) => {
        if (typeof minValue !== 'undefined' && e.target.value < minValue) {
            notification.open({
                message: t('shared.warning'),
                description: `${t('shared.min-value-error')} ${minValue}`,
            });
            onChange(minValue);
        } else if (e.target.value.trim() !== "") {
            onChange(e.target.value);
        }
    }, [minValue, onChange, newValue, t]);

    return (
        <span className={clsx(containerClass, 'moj-number-input')}>
            <InputNumber onBlur={handleBlur} value={newValue} onChange={onChange} {...props} />
            {allowClear && typeof newValue !== 'undefined' ? (
                <span className="ant-input-suffix" onClick={() => onChange(undefined)}>
                    <span
                        role="button"
                        aria-label="close-circle"
                        tabIndex={-1}
                        className="anticon anticon-close-circle ant-input-clear-icon"
                    >
                        <CloseIcon />
                    </span>
                </span>
            ) : (
                <></>
            )}
        </span>
    );
};
