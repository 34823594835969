export enum NationalIdTypes {
    NATIONAL_ID = 1,
    IQAMA_ID = 3,
    FACILITY_NUMBER = 5,
}

export enum DUNationalIdTypes {
    NATIONAL_ID = 1,
    IQAMA_ID = 3,
    FACILITY_NUMBER = 5,
    GULF_ID = 8,
    GOV_RECORD = 15,
    WAQF = 26,
    CHARITY = 9,
    ESTABLISHMENT = 16,
}

// create array of object with national id from DUNationalIdTypes enum have id and label.
export const nationalIdTypes = [
    { id: DUNationalIdTypes.NATIONAL_ID, label: 'وطنية' },
    { id: DUNationalIdTypes.IQAMA_ID, label: 'إقامة' },
    { id: DUNationalIdTypes.FACILITY_NUMBER, label: 'شركة' },
    { id: DUNationalIdTypes.GULF_ID, label: 'هوية خليجية' },
    { id: DUNationalIdTypes.GOV_RECORD, label: 'سجل حكومي' },
    { id: DUNationalIdTypes.WAQF, label: 'وقف' },
    { id: DUNationalIdTypes.CHARITY, label: 'جمعية خيرية' },
    { id: DUNationalIdTypes.ESTABLISHMENT, label: 'مؤسسة' },
];
