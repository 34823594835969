import { action, computed, makeObservable, observable } from 'mobx';
import { IRealestate } from '@/core/interfaces/Realestate.interface';
import { RealestateAndDealsFilterProps } from '../models/realestate-filters';

const realStateViews = { map: 'الخريطة العقارية', list: 'قائمة العقارات' };

const defaultState = {
    cityId: null,
    districtId: null,
    areaFrom: null,
    areaTo: null,
    priceFrom: null,
    priceTo: null,
    planId: null,
    landNumber: null,
    isOpenMarket: true,
    isRealEstate: true,
    isAgricalture: false,
    locationSearchTerm: '',
    regionid: null,
};
interface IRealestatePrices {
    [key: number | string]: number;
}

export const DEFAULT_CENTER = { lat: 50, lng: 26 };
class RealestateAndDealsAdvancedStore {
    @observable
    public realestates!: IRealestate[];
    @observable
    public realestateOptions: number[] = [0, 1, 2];
    @observable
    public realestateFilters: RealestateAndDealsFilterProps = { ...defaultState };
    @observable
    public realestatePrices: IRealestatePrices = {};
    @observable
    public onResetFilters: boolean | undefined = undefined;
    @observable
    public realestateTypes: {
        Name: string;
        Id: number;
    }[] = [];

    @observable
    sortingType = { sortType: 1, sortField: 0 };

    @observable
    selectedSortingType = 0;

    @observable
    public searchCords: object | undefined = undefined;
    @observable
    public zoomLvl: any = null;
    @observable
    public mapViews: object | null = null;
    @observable
    public mapViewType: string | null = null;
    @observable
    public selectedView = realStateViews.list;
    @observable
    public pointerFeatures: any = null;
    @observable
    public dataMessage: string | null = null;
    @observable
    public searchByIdentityPosition: { lat: number; lng: number } | null = null;

    @observable
    public featuresWithPrice: any[] = [];

    @observable
    public lineLengths: any[] = [];

    @observable
    public toolsVisible: boolean = true;

    @observable
    public desktopFiltersVisible = true;

    @observable
    center = DEFAULT_CENTER;

    @observable
    showRealestateDataOnMap = true;

    constructor() {
        makeObservable(this);
        this.realestates = [];
    }

    @computed
    get isFiltersNotApplied(): boolean {
        return (
            !!this.realestateFilters.cityId ||
            !!this.realestateFilters.areaFrom ||
            !!this.realestateFilters.areaTo ||
            !!this.realestateFilters.priceFrom ||
            !!this.realestateFilters.priceTo
        );
    }
    @action
    updateRealestatePrice(realestateNumber: string | number, price: number) {
        this.realestatePrices[realestateNumber] = price;
    }

    @action
    getRealestatePrice(realesateNumber: string | number) {
        return this.realestatePrices[realesateNumber];
    }

    @action
    updateRealestates(realestates: IRealestate[]) {
        this.realestates = realestates;
    }

    @action
    addRealestates(realestates: IRealestate[]) {
        this.realestates = [...this.realestates, ...realestates];
    }

    @action
    updateFilters(filters: RealestateAndDealsFilterProps) {
        this.realestateFilters = filters;
    }

    @action
    updateRealestatesOptions(options: number[]) {
        this.realestateOptions = options;
    }

    @action
    resetFilters() {
        this.onResetFilters = !this.onResetFilters;
        this.realestateFilters = { ...defaultState };
    }

    @action
    resetPage() {
        this.realestates = [];
        this.realestateFilters = { ...defaultState };
    }

    @action
    setSortingType(sortingType) {
        this.sortingType = sortingType;
    }

    @action
    setSelectedSortingType(selectedSortingType) {
        this.selectedSortingType = selectedSortingType;
    }

    @action
    setRealestateTypes = (
        data: {
            Name: string;
            Id: number;
        }[]
    ) => {
        this.realestateTypes = data;
    };
    @action
    setMapViewType = (type: string | null) => {
        this.mapViewType = type;
    };
    @action
    setSelectedView = (view: string) => {
        this.selectedView = view;
    };
    @action
    setMapViews = (views: object) => {
        this.mapViews = views;
        // to fix the order of the enums
        //@ts-ignore
        if(this.mapViews.propertyStatusEnums) {
            //@ts-ignore
            this.mapViews.propertyStatusEnums && this.mapViews.propertyStatusEnums.reverse();
            // @ts-ignore
            this.mapViews.propertyStatusEnums.push(this.mapViews.propertyStatusEnums.shift());
        }
    };
    @action
    setPointersFeatures = (layers: any) => {
        this.pointerFeatures = layers;
    };
    @action
    setZoomLvl = (zoomLvl: number) => {
        this.zoomLvl = zoomLvl;
    };
    @action
    setDataMessage = (dataMessage: string | null) => {
        this.dataMessage = dataMessage;
    };
    @action
    setSearchByIdentityPosition = (position: { lat: number; lng: number }) => {
        this.searchByIdentityPosition = position;
    };

    @action
    updateToolsVisible(flag: boolean) {
        this.toolsVisible = flag;
    }
    
    @action
    toggleToolsVisible() {
        this.toolsVisible = !this.toolsVisible;
    }

    @action
    setCenter = (center: { lat: number, lng: number }) => {
        this.center = center;
    };

    @action
    setShowDataOnMap = (flag: boolean) => {
        this.showRealestateDataOnMap = flag;
    };

    @action
    toggleShowDataOnMap = () => {
        this.showRealestateDataOnMap = !this.showRealestateDataOnMap;
    };

}

export const realestateAndDealsAdvancedStore = new RealestateAndDealsAdvancedStore();
