import { Checkbox, CheckboxProps } from 'antd';
import clsx from 'clsx';
import './MojCheckbox.scss';

interface MojCheckboxProps extends CheckboxProps {
    color?: string;
}

export function MojCheckbox({ className, children, color, ...props }: MojCheckboxProps) {
    return <Checkbox style={{ backgroundColor: color }} className={clsx('moj-input', className)} {...props}>
        <span className="content">{children}</span>
    </Checkbox>;
}
