import React, { FunctionComponent } from 'react';
import { ReactComponent as AlinmaBank } from '@/core/assets/images/bank-logos/assets-logos-alinma-bank-full-logo.svg';
import './BankLogo.scss';
import { Link, LinkProps } from 'react-router-dom';

type Props = Omit<LinkProps, 'to'> & {
    variant: BankLogoVariants;
    to?: LinkProps['to'];
};

enum BankLogoVariants {
    alinma = 'alinma',
}

const BankLogo: FunctionComponent<Props> = ({ variant, to, ...props }) => {
    function Icon({ className }: { className: string }) {
        switch (variant) {
            case BankLogoVariants.alinma:
                return <AlinmaBank className={className} />;
        }
    }

    return (
        <Link to={to || '#'} {...props}>
            <Icon className="bank-logo" />
        </Link>
    );
};

export { BankLogo, BankLogoVariants };
