import React, { ReactNode, useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Alert, AlertVariants, Card, MojButton, MojRadio } from '@core/components/shared';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { MojFormItem } from '@core/components/shared/MojFormItem/MojFormItem';
import AddedBankAccount from '@profile/components/AddedBankAccount/AddedBankAccount';
import { observer } from 'mobx-react-lite';
import { authStore } from '@/common/stores/auth.store';
import { AddIban } from '@/core/components/shared/AddIban/AddIban';
import { loaderStore } from '@/common/stores/loader.store';
import './_SelectBankAccount.scss';
import { attorneyStore } from '@common/stores/attorney.store';
import { useFlag, useHint } from '@/common/hooks';
import { refundManagmentStore } from '@/common/stores/refundManagment.store';

export type SelectBankAccountProps = {
    formikProps: FormikProps<any>;
    HeadingIcon?: ReactNode;
    showAddIbanAction?: boolean;
    onChange?: (iban: string) => void;
    currentRefundInfo?: any;
    isRefund?: boolean;
};

const SelectBankAccount = ({HeadingIcon, showAddIbanAction, onChange, formikProps, currentRefundInfo, isRefund}: SelectBankAccountProps) => {
    const { t } = useTranslation();
    const { flag: addIbanVisible, on, off } = useFlag();
    const { Icon } = useHint({
        title: t('select-bank-account.title'),
        message: t('select-bank-account.message'),
    });

    const { values, errors, touched, setFieldValue } = formikProps!!;
    const { updateRefundTermVisiblity, setIbanStep ,setNewChosenIban,resetRefundTerms,setDisableSubmitButton} = refundManagmentStore;
    const [selectedIban, setSelectedIban] = React.useState(values?.selectedIban);

    useEffect(() => {
        setSelectedIban(values?.selectedIban);
    }, [values?.selectedIban]);
    
    const userIbans = React.useMemo(() => {
        if (attorneyStore.attorney) {
            const ibans = attorneyStore.selectedAttorneyIbans?.filter((iban) => iban.IsActive).map((iban) => iban.Iban);
            return ibans;
        } else if (authStore.userDetails && authStore.userDetails.Ibans) {
            const ibans = authStore.userDetails?.Ibans?.filter((iban) => iban.IsActive).map((iban) => iban.Iban);
            return ibans;
        } else {
            return [];
        }
    }, [authStore.userDetails, attorneyStore.attorney, attorneyStore.selectedAttorneyIbans, setFieldValue]);

    const refreshUserProfile = useCallback(() => {
        loaderStore.setLoader(true);
        authStore.getUserProfile().then(() => {
            off();
            loaderStore.setLoader(false);
        });
    }, [off]);

    useEffect(() => {
        if (isRefund) {
            var refundIban = userIbans.find((x) => x === currentRefundInfo?.BeneficiaryIban);
            if (refundIban?.length! > 0) {
                refundManagmentStore.setIbanStep(1);
                refundManagmentStore.NewIbanTerms();
                setSelectedIban(null);
                updateRefundTermVisiblity(0, true);
                setIbanStep(0);
                setSelectedIban(refundIban);
                setNewChosenIban(refundIban!);
            }
        }
    }, [addIbanVisible]);

    const changeSelectedIban = (e: RadioChangeEvent) => {
        setSelectedIban(e.target.value);
        setFieldValue('selectedIban', e.target.value);

        if (onChange) {
            onChange(e.target.value);
        }

        if (isRefund) {
            resetRefundTerms();
            setNewChosenIban(e.target.value);
            setDisableSubmitButton(true);
            if (e.target.value !== currentRefundInfo?.BeneficiaryIban) {
                refundManagmentStore.setIbanStep(1);
                updateRefundTermVisiblity(0, false);
            } else {
                updateRefundTermVisiblity(0, true);
            }
        }
    };

    const handleOnAddIban = () => {
        if (attorneyStore.attorney) {
            attorneyStore.getAttorneyIbans();
        } else if (authStore.userDetails && authStore.userDetails.Ibans) {
            refreshUserProfile();
        } else {
            refreshUserProfile();
        }
    }

    return (
        <div className="section">
            <Card className="section__container">
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Card.Head className="section__title border-bottom">
                            <Col flex="1 1 auto">
                                {isRefund ? t('refund-management.correctBankInfo') :  t('buying-requests.deal-info.transaction-details.your-bank-details')}
                            </Col>
                            <Col>
                                {!isRefund && <Icon />}
                            </Col>
                        </Card.Head>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span="24">
                        {userIbans.length > 0 && (
                            <MojFormItem
                                label={t('shared.choose-iban')}
                                htmlFor="selectedIban"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                validateStatus={errors['selectedIban'] && 'error'}
                                help={touched['selectedIban'] && errors['selectedIban']}
                            >
                                <Radio.Group
                                    onChange={(e) => changeSelectedIban(e)}
                                    defaultValue={selectedIban}
                                    value={selectedIban}
                                >
                                    <Row gutter={[16, 0]}>
                                        {userIbans.map((iban: any, index: number) => (
                                            <Col lg={24} md={24} sm={24} xs={24} key={`iban-${iban}-${index}`}>
                                                <MojRadio value={iban}>
                                                    <AddedBankAccount
                                                        iban={iban}
                                                        hideActions={true}
                                                        noBorder={true}
                                                        isRefund={isRefund}
                                                    />
                                                </MojRadio>
                                            </Col>
                                        ))}
                                    </Row>
                                </Radio.Group>
                            </MojFormItem>
                        )}

                        {addIbanVisible || (userIbans.length === 0 && authStore.userDetails?.Ibans.length === 0) ? (
                            <AddIban
                                hideBack
                                onAddIban={handleOnAddIban}
                                isRefund={isRefund}
                                handleSelectedIban={setSelectedIban}
                            />
                        ) : (
                            <></>
                        )}
                        {userIbans.length === 0 && authStore.userDetails?.Ibans.length > 0 && (
                            <div className="no-active-iban-message">
                                <Alert variant={AlertVariants.Danger}> {t('shared.no-active-iban-message')} </Alert>
                            </div>
                        )}

                        {showAddIbanAction ? (
                            <MojButton color="warning" onClick={on}>
                                {t('shared.add-iban')}
                            </MojButton>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default observer(SelectBankAccount);
