import { arabicToEnglishMap } from '@/common/utils/constants';

export const getFormItemError = (formik, field, onlyTouched = true) => {
    const { errors, touched } = formik;
    if (onlyTouched) {
        return errors[field] && touched[field] ? errors[field] : null;
    }
    return errors[field] ? errors[field] : null;
};

export const getFormItemValidateStatus = (formik, field, onlyTouched) => {
    return getFormItemError(formik, field, onlyTouched) ? 'error' : '';
};

export const wait = async (timeout = 3000) => new Promise((res) => setTimeout(() => res(), timeout));

export const getFormattedTime = (timeInSeconds) => {
    const seconds = timeInSeconds % 60;

    const minutes = ((timeInSeconds - seconds) / 60) % 60;

    const hours = (timeInSeconds - minutes * 60 - seconds) / 60 / 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
};

export const parseShare = (n) => {
    if (!n) return '';
    const baseNumber = Number(n);
    const isFloat = baseNumber === n && n % 1 !== 0;
    const fixedNumber = isFloat ? baseNumber.toFixed(2) : baseNumber;

    return `${fixedNumber}%`;
};

export const isNaN = (n) => Number.isNaN(Number(n));

export const formatDate = (date /*: string*/) => {
    date = new Date(date);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS, hence +1
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}/${mm}/${dd}`;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const deedInputEvents = {
    placeholder: "مثال: 1 / 2 / 3 / 4 / ب ",
    onInput: (e) => {
        if (e.currentTarget.value.startsWith('/')) e.currentTarget.value = e.currentTarget.value.slice(1);
        e.currentTarget.value = e.currentTarget.value.replace(/[^ء-ي0-9٠-٩/]/g, '').replace(/\/\/|\/-|\//g, '/ ').replace(/--|-\/ |-/g, '- ').split('- ', 5).join('- ').split('/ ', 5).join('/ ');
    },
    onKeyDown: (e) => {
        var key = e.keyCode || e.charCode; if (key === 8 && e.currentTarget.value.slice(-1) === ' ') e.currentTarget.value = e.currentTarget.value.slice(0, -1)
    }
}

export const parseErrors = (errors) => {
    const baseErrors = [];
    errors.forEach(error => {
        error.split('\n').forEach(err => {
            if (err) {
                baseErrors.push(err);
            }
        });
    });

    return baseErrors;
}