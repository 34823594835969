import { formatDate, isValidDate } from "@/common/utils/helper.utils";

export default function DateFormat({ value, hour12 }: { value: string, hour12?: boolean }) {
  const date = new Date(value);
  if (isValidDate(date)) {

    return (
      <div>{formatDate(date, hour12)}</div>
    )
  } else {
    return <>{value}</>;
  }
}
