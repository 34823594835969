export const days = [...Array(31).keys()].filter((day) => day !== 0);
export const months = [
    { number: 1, name: 'محرم' },
    { number: 2, name: 'صفر' },
    { number: 3, name: 'ربيع الأول' },
    { number: 4, name: 'ربيع الثاني' },
    { number: 5, name: 'جمادي الأولى' },
    { number: 6, name: 'جمادي الآخرة' },
    { number: 7, name: 'رجب' },
    { number: 8, name: 'شعبان' },
    { number: 9, name: 'رمضان' },
    { number: 10, name: 'شوال' },
    { number: 11, name: 'ذو القعدة' },
    { number: 12, name: 'ذو الحجة' },
];

export const GregorianYear = new Date().getFullYear();
export const HijriYear = Math.floor((GregorianYear - 621) * (33 / 32));
export const years = [...Array(HijriYear - 18).keys()].filter((year) => year >= 1200).reverse();
export const deedYears = [...Array(HijriYear).keys()].filter((year) => year >= 1300).reverse();
export const todayHijri = new Intl.DateTimeFormat('en-u-ca-islamic-umalqura-nu-latn', { dateStyle: 'short' }).format(
    new Date(Date.now())
); //format: m/d/yyyy
const todayHijriArray = todayHijri.split('/');
export const todayHijriObject = {
    day: todayHijriArray[1].padStart(2, '0'),
    month: todayHijriArray[0].padStart(2, '0'),
    year: todayHijriArray[2].replaceAll(/\D/g, ''),
};
