import { UserManagerSettings, WebStorageStateStore  } from 'oidc-client-ts';

export const authTsConfig : UserManagerSettings = {
    userStore: new WebStorageStateStore({
        store: localStorage,
    }),
    checkSessionIntervalInSeconds: 1000,
    authority: process.env.REACT_APP_IAM_SERVER!!,
    client_id: process.env.REACT_APP_IAM_CLIENT_ID!!,
    redirect_uri: process.env.REACT_APP_IAM_REDIRECT_URL!!,
    response_type: 'code',
    scope:
        'openid profile iam.profile epm.business.api ept.business.api di.business.api shared.attorney.api' +
        ' shared.profile.api shared.inquiry.api offline_access du.business.api',
    post_logout_redirect_uri: process.env.REACT_APP_IAM_REDIRECT_URL,
    silent_redirect_uri: process.env.REACT_APP_IAM_REDIRECT_SILENT_URL,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: true,
    accessTokenExpiringNotificationTimeInSeconds: parseInt(process.env.REACT_APP_IAM_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME!),
    silentRequestTimeoutInSeconds: 60000,
    revokeTokenTypes:["refresh_token","access_token"],
    revokeTokensOnSignout:true
}
