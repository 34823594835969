import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ReadOnlyField, ReadOnlyFieldBorderVariant, ReadOnlyFieldVariant } from '@core/components/shared';
import { ReactComponent as DeleteIcon } from '@core/assets/images/assets-icons-remove.svg';
import AddedBankAccount from '@profile/components/AddedBankAccount/AddedBankAccount';
import MojLabel, { LabelVariant } from '@/core/components/shared/Label/MojLabel';
import { RequestTypes } from '@/common/models/Transaction';
import './AddedBuyer.scss';

type Props = {
    index: number | string;
    idNumber: string | number;
    name: string;
    share?: string;
    sellingAmount?: string;
    last?: boolean;
    iban?: string;
    removeBuyer?: () => void;
    sellerLabel?: string;
    showShareLabel?: boolean;
    children?: any;
    isSeller?: boolean;
    isOwner?: boolean;
    requestType?: RequestTypes;
    showShareValue?: boolean;
    MobileNumber?: string;
};

export function AddedBuyer({
    index,
    idNumber,
    name,
    share,
    sellingAmount,
    last,
    iban,
    removeBuyer,
    sellerLabel,
    showShareLabel = true,
    children,
    isSeller,
    isOwner,
    requestType,
    showShareValue = true,
    MobileNumber,
}: Props) {
    const { t } = useTranslation();

    return (
        <div className="border-bottom" dir="rtl">
            <Row style={{ width: '100%' }} align="middle">
                <Col md={7} xs={12}>
                    <ReadOnlyField
                        small
                        label={t('add-buyers.id-number')}
                        title={idNumber}
                        variant={ReadOnlyFieldVariant.success}
                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                        hasLtrContent
                    />
                </Col>
                <Col md={7} xs={12}>
                    <ReadOnlyField
                        small
                        label={t('add-buyers.name')}
                        title={name}
                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                    />
                </Col>

                {share && !sellingAmount && showShareValue && (
                    <Col md={4} xs={12} className="added-buyer__share">
                        <ReadOnlyField
                            small
                            label={
                                showShareLabel
                                    ? !isOwner
                                        ? requestType === RequestTypes.Gift
                                            ? t('add-buyers.gift-percentage')
                                            : t('add-buyers.buying-percentage')
                                        : t('add-buyers.owning-percentage')
                                    : ''
                            }
                            title={share}
                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                        />
                    </Col>
                )}

                {(requestType === RequestTypes.OpenMarket || requestType === RequestTypes.PurchaseOrder) &&
                    MobileNumber && (
                        <Col md={6} xs={12}>
                            <ReadOnlyField
                                small
                                label={t('shared.mobile-number')}
                                title={MobileNumber}
                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                            />
                        </Col>
                    )}

                {removeBuyer && (
                    <Col span={2} className="added-buyer__share" onClick={removeBuyer}>
                        <DeleteIcon />
                    </Col>
                )}

                {sellerLabel && (
                    <Col span={4}>
                        <MojLabel color={LabelVariant.success} style={{ minWidth: '60px' }}>
                            {sellerLabel}
                        </MojLabel>
                    </Col>
                )}

                {isSeller && (
                    <Col span={4}>
                        <MojLabel color={LabelVariant.success} style={{ minWidth: '60px' }}>
                            {requestType === RequestTypes.Gift ? t(`new-deal.donor`) : t(`new-deal.seller`)}
                        </MojLabel>
                    </Col>
                )}
            </Row>

            {share && sellingAmount && (
                <Row style={{ width: '100%' }} align="middle">
                    {share && (
                        <Col span={7} className="added-buyer__share">
                            <ReadOnlyField
                                small
                                label={showShareLabel ? t('add-buyers.owning-percentage') : ''}
                                title={share}
                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                            />
                        </Col>
                    )}
                    {sellingAmount && (
                        <Col span={7} className="added-buyer__share">
                            <ReadOnlyField
                                small
                                label={
                                    requestType === RequestTypes.Gift
                                        ? t(`add-buyers.gifting-percentage`)
                                        : t(`add-buyers.selling-percentage`)
                                }
                                title={sellingAmount}
                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                            />
                        </Col>
                    )}
                </Row>
            )}

            {iban && (
                <Row className={'added-buyer__iban'}>
                    <Col span={24}>
                        <AddedBankAccount iban={iban} hideActions={true} noBorder={false} isVirtualIban={false} />
                    </Col>
                </Row>
            )}

            {children && children}
        </div>
    );
}
