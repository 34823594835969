import React, { useEffect } from 'react';
import { Col, List, Row, Spin } from 'antd';
import { useNavigate } from 'react-router';
import { Card, MojButton, MojPopup } from '@/core/components/shared';
import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';
import { attorneyStore } from '@common/stores/attorney.store';
import { observer } from 'mobx-react-lite';

interface ExternalRedirectProps {
    to: string;
}

const ExternalRedirectComponent: React.FC<ExternalRedirectProps> = ({ to }) => {
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = React.useState(true);
    const [isInfoModalVisible, setIsInfoModalVisible] = React.useState(false);
    const isRedirectModalShouldDisplay = sessionStorage.getItem('isRedirectModalShouldDisplay');
    const isModalShouldDisplay = sessionStorage.getItem('isModalShouldDisplay');
    const isJustLoggedIn = sessionStorage.getItem('isJustLoggedIn');

    useEffect(() => {
        sessionStorage.setItem('isRedirectModalShouldDisplay', 'true');
    }, []);

    useEffect(() => {
        if (isRedirectModalShouldDisplay === 'true' && (isJustLoggedIn === 'false' || isJustLoggedIn === null)) {
            window.location.href = to;
        } else if (isModalShouldDisplay === 'false') {
            window.location.href = to;
        }

        setTimeout(() => {
            handleRedirect();
        }, 3000);
    }, [to]);

    const handleRedirect = () => {
        window.location.href = to;
        setIsModalVisible(false);
        sessionStorage.setItem('isModalShouldDisplay', 'false');
    };

    const handleAttorneyRedirect = () => {
        const profilePath = attorneyStore.attornies?.length ? '/profile' : '/profile/attorney';
        navigate(profilePath);
        setIsModalVisible(false);
        sessionStorage.setItem('isModalShouldDisplay', 'false');
    };

    return (
        <>
            {isRedirectModalShouldDisplay === 'true' &&
                isJustLoggedIn === 'true' &&
                isModalShouldDisplay === 'false' ? (
                    <MojPopup
                        footer={null}
                        open={isModalVisible}
                        onOk={() => {
                            setIsModalVisible(false);
                        }}
                        onCancel={() => {
                            setIsModalVisible(false);
                        }}
                        closable={false}
                        maskClosable={false}
                    >
                        <Col>
                            <div style={{ display: 'flex' }}>
                                <span style={{ marginInlineEnd: 5 }}>{'هل تود استخدام البورصة العقارية'}</span>
                                <InfoIcon onClick={() => setIsInfoModalVisible(true)} />
                            </div>
                        </Col>
                        <Card>
                            <Row gutter={[16, 0]} justify="start" align="middle">
                                <Col span="12">
                                    <MojButton
                                        color="success"
                                        onClick={handleRedirect}
                                        style={{ height: 'fit-content' }}
                                    >
                                        {'اصالة'}
                                        <div>{'(الحساب الشخصي)'}</div>
                                    </MojButton>
                                </Col>
                                <Col span="12">
                                    <MojButton
                                        color="success"
                                        onClick={handleAttorneyRedirect}
                                        style={{ height: 'fit-content' }}
                                    >
                                        {'وكالة'}
                                        <div>{'(عن جهة او فرد)'}</div>
                                    </MojButton>
                                </Col>
                            </Row>
                        </Card>
                    </MojPopup>
                ) : <>
                    <Spin>
                        جاري اعادة التوجية
                    </Spin>
                </>}

            <MojPopup
                open={isInfoModalVisible}
                title={'معلومات دخول البورصة العقارية'}
                onOk={() => {
                    setIsInfoModalVisible(false);
                }}
                footer={null}
                onCancel={() => {
                    setIsInfoModalVisible(false);
                }}
                closable={true}
            >
                <List className="DashboardTermsList">
                    <div style={{ paddingTop: 0, paddingBottom: 0, alignItems: 'flex-start' }}>
                        <div style={{ marginBottom: 5 }}>
                            <strong>اصالة تعني:</strong> الحساب الشخصي
                        </div>

                        <div>
                            <strong>انواع الوكالة:</strong>
                        </div>
                        <div>1- وكيل عن منشأة او جهة</div>
                        <div>2- وكيل عن ورثة</div>
                        <div>3- وكيل عن فرد</div>
                        <div>4- مفوض من جهة حكومية</div>
                        <div>5- مالك مؤسسة فردية</div>
                    </div>
                </List>
            </MojPopup>
        </>
    );
};

export default observer(ExternalRedirectComponent);
