
export enum DuDeedGroup {
    EDIT_OWNER_SHIP = 22,
    EDIT_DEED_CONSTRAINS = 120,
    EDIT_REALESTATE = 113
}

export enum EduLinkDeedBy {
    RealEstateIdentityNumber = 1,
    LetterNumber = 2
}

export enum EduLinkDeedByEnd {
    RealEstateIdentityNumber = 114,
    LetterNumber = 115
}

export interface IUserInput { type: EduLinkDeedByEnd, value: number, isValid: boolean }