import React, { FC, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '@common/stores/auth.store';
import { useTranslation } from 'react-i18next';
import { useHint } from '../hooks';
import { MojButton } from '@/core/components/shared';
import { useNavigate } from 'react-router';
import { attorneyStore } from '@common/stores/attorney.store';
import HintPopup from '@core/components/shared/HintPopup/HintPopup';

type ComponentWithChildren = {
    children?: any;
};

const RequireProfile: FC<ComponentWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const DEFAULT_TEMPLATE = undefined;

    const [messageTemplate, setMessageTemplate] = useState<ReactNode>(DEFAULT_TEMPLATE);
    const [showHint, setShowHint] = useState(false);

    // const {show, hideBefore} = useHint({
    //     title: t('profile.profile-incomplete'),
    //     message: messageTemplate,
    //     footer: (
    //         <div>
    //             <MojButton
    //                 onClick={() => {
    //                     hideBefore(() => {
    //                         if ( authStore.noProfile ) {
    //                             navigate('/onboarding');
    //                         } else if ( authStore.noIbans ) {
    //                             navigate('/profile');
    //                         } else {
    //                             navigate('/onboarding');
    //                         }
    //                     });
    //                 }}
    //                 color="success"
    //             >
    //                 {t('shared.update-data')}
    //             </MojButton>
    //         </div>
    //     ),
    //     onCloseClick: () => {
    //         navigate('/');
    //     },
    //     ghost: true,
    // });

    useEffect(() => {
        if (authStore.user && authStore.isLoggedIn && authStore.errorTemplate) {
            setMessageTemplate(authStore.errorTemplate);
            //delegate to next render
            setTimeout(() => {
                setShowHint(true);
            }, 10);
        } else if (!attorneyStore.attorney && authStore.user && authStore.isLoggedIn && authStore.noIbans === true) {
            setMessageTemplate('لاستخدام خدمات البورصة العقارية الرجاء اضافة حساب بنكي جديد في الملف الشخصي');
            setTimeout(() => {
                setShowHint(true);
            }, 10);
        } else if (authStore.noProfile) {
            navigate('/onboarding');
        }
    }, [setShowHint, navigate, authStore, authStore.userDetails]);

    return (
        <>
            {children}
            <HintPopup
                title={t('profile.profile-incomplete')}
                message={messageTemplate}
                visible={showHint}
                onCloseClick={() => navigate('/')}
                footer={
                    <div>
                        <MojButton
                            onClick={() => {
                                setShowHint(false);

                                setTimeout(() => {
                                    if (authStore.noProfile) {
                                        navigate('/onboarding');
                                    } else if (authStore.noIbans) {
                                        navigate('/profile');
                                    } else {
                                        navigate('/onboarding');
                                    }
                                }, 10);
                            }}
                            color="success"
                        >
                            {t('shared.update-data')}
                        </MojButton>
                    </div>
                }
            />
        </>
    );
};

export default observer(RequireProfile);
