import { useMemo, ReactNode, useEffect, useCallback } from 'react';
import { ConfigProvider, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import clsx from 'clsx';

import { useFlag } from '.';
import { MojPopup } from '@core/components/shared';
import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';

interface IUsePopup extends ModalProps {
    title: string;
    hideClose?: boolean;
    message: string | ReactNode;
    onCloseClick?: () => void;
    footer?: ReactNode;
    ghost?: boolean;
    ghostId?: string;
    manualClose?: any;
}

export const useHint = ({
    title,
    message,
    hideClose,
    onCloseClick,
    footer = null,
    ghost = false,
    ghostId = 'ghostModals',
    maskClosable = false,
    manualClose = undefined,
    ...modalProps
}: IUsePopup) => {
    const { flag, on, off } = useFlag();
    const { i18n } = useTranslation();

    const onCloseHandler = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        off();
        // to run it in next cycle and prevent immediate action
        setTimeout(() => {
            onCloseClick && onCloseClick();
        }, 10);
    }, [off, onCloseClick]);

    const Modal = useMemo(() => {
        return (
            <ConfigProvider direction={i18n.dir(i18n.language)}>
                <MojPopup
                    closable={!hideClose}
                    title={title}
                    open={manualClose !== undefined ? manualClose : flag}
                    maskClosable={maskClosable}
                    {...modalProps}
                    onCancel={(e) => {
                        onCloseHandler(e);
                    }}
                    footer={footer}
                >
                    {message}
                </MojPopup>
            </ConfigProvider>
        );
    }, [i18n, hideClose, title, manualClose, flag, maskClosable, modalProps, footer, message, onCloseHandler]);

    useEffect(() => {
        if (ghost) {

            const root = createRoot(
                document.getElementById(ghostId) as HTMLElement
            );

            root.render(Modal);
        }
    }, [ghost, Modal, flag, ghostId]);

    const onOpenHandler = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        on();
    }, [on]);

    const Icon = useMemo(() => {
        return ({ className }: { className?: string }) => (
            <>
                <InfoIcon className={clsx(className)} onClick={onOpenHandler} style={{ cursor: 'pointer' }} />
                {Modal}
            </>
        );
    }, [onOpenHandler, Modal]);

    const hideBefore = useCallback(
        (method: (args?) => any) => {
            off();
            setTimeout(() => {
                method();
            }, 10);
        },
        [off]
    );

    return {
        Icon,
        show: onOpenHandler,
        close: onCloseHandler,
        hideBefore,
    };
};
