import './_NotificationIcon.scss';
import { ReactComponent as NotifyIcon } from '@core/assets/images/assets-icons-notification.svg';
import { Badge } from "antd";
interface Props {
    count: number;
}
const NotificationIcon = ({ count }: Props) => {
    return (
        <Badge count={count}>
           <NotifyIcon />
        </Badge>
    );
};

export default NotificationIcon;
