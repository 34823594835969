import signalR, { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { GetGeneralStatsInput } from '@common/models/GeneralStats.input';
import { TrendingDistrictsInput } from '@common/models/TrendingDistricts.input';
import { DealEntry } from '@common/models/DealEntry';
import { AxiosInstance } from 'axios';
import { createClient } from '@common/services/index';

export default class DashboardService {
    HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_DASHBOARD_URL}api/v1`);
    }

    // SignalR
    connection: signalR.HubConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_DASHBOARD_URL}deals`, {
            withCredentials: false,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

    // Restful Api's
    getGroupStats = async (params: GetGeneralStatsInput) => {
        return await this.HttpClient.post('Dashboard/GetAreaInfo', params)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    // Restful Api's
    getSremIndex = async () => {
        return await this.HttpClient.get('Dashboard/GetMarketIndex')
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    // Restful Api's
    getTrendingDistricts = async (body: TrendingDistrictsInput) => {
        return await this.HttpClient.post('Dashboard/GetTrendingDistricts', body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    async startConnection(): Promise<void> {
        try {
            this.connection.onreconnected(() => {
                // this.getGroupStats(statsStore.selectedTimeFrame);
                // this.getTrendingDistricts(statsStore.selectedTrends);
                this.onLiveDeals();
            });
            this.connection.onreconnecting(() => {
                this.disposeLiveDeals();
            });

            await this.connection.start();
        } catch (err) {
            setTimeout(this.startConnection, 5000);
            return;
        }
    }

    onClose(): void {
        this.connection.onclose(async () => {
            await this.startConnection();
        });
    }

    onLiveDeals = (pusher?: (deal: DealEntry) => void): signalR.ISubscription<DealEntry> => {
        // this.statsStore.setSessionReInit(true);
        return this.connection.stream('StreamDeals').subscribe({
            next(value: DealEntry) {
                pusher!!(value);
            },
            complete() {
                return;
            },
            error(err: any) {
                return;
            },
        });
    };

    disposeLiveDeals(): void {
        this.onLiveDeals().dispose();
    }

    getAllDeals = () => {
        return this.connection.invoke('GetAllDeals').then(function (stocks) {
            return stocks;
        });
    };

    getMVIndex = async (timeFrame) => {
        return await this.HttpClient.get(`/Dashboard/GetMarketIndexByDateCategory?dateCategory=${timeFrame}`)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
}
