import React from 'react';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { convertNumberWithFractionCurrency, getReadablePrice } from '@common/utils/numbers-utils';
import MortgageeDetails from '@property-mortgage/components/MortgageeDetails/MortgageeDetails';
import { Card, ReadOnlyField, ReadOnlyFieldBorderVariant, ReadOnlyFieldVariant } from '@core/components/shared';
import { IMortgageEntity, RequestTypes } from '@common/models/Transaction';

import './MortgageeFullDetails.scss';

const MortgageeFullDetails = (props: {
    mortgagee: IMortgageEntity;
    isNew?: boolean;
    hideHeading?: boolean;
    headerText?: string;
    requestType? : RequestTypes
}) => {
    const { t } = useTranslation();
    
    return (
        <div className="section mortgagee-full-details">
            <Card className="section__container" noPadding={false}>
                {!props.hideHeading && (
                    <Row gutter={[16, 0]}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Card.Head className="section__title  border-bottom">
                                {props.requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT || props.requestType === RequestTypes.MortagageSell
                                    ? t('mortgage.new-mortgage-details')
                                    : t('finance.title')
                                }
                            </Card.Head>
                        </Col>
                    </Row>
                )}

                <Row gutter={[16, 0]}>
                    {props.mortgagee?.BeneficiaryAmount && (
                        <>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={getReadablePrice(props.mortgagee?.BeneficiaryAmount)}
                                    unit={`(${t('shared.sar')})`}
                                    label={t('mortgage.price-as-number')}
                                    small
                                />
                            </Col>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={convertNumberWithFractionCurrency(props.mortgagee?.BeneficiaryAmount)}
                                    label={t('mortgage.price-as-text')}
                                    unit={`(${t('shared.sar')})`}
                                    small
                                />
                            </Col>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    small
                                    label={t('finance.mortgage-claiming-date')}
                                    title={dayjs(props.mortgagee?.LastInstalmentDate!).format('DD/MM/YYYY')}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </Card>

            <MortgageeDetails mortgagee={props.mortgagee} isNew={props.isNew} />
        </div>
    );
};

export default MortgageeFullDetails;
