import React, { useEffect } from 'react';
import { Card } from '@/core/components/shared';
import { Col, Row, Spin } from 'antd';
import LineColumnChart from '../../components/DashboardChart/line-column-chart';
import { useTranslation } from 'react-i18next';
import MojRadioGroup from '@/core/components/shared/MojRadioGroup/MojRadioGroup';
import { PeriodCategory, marketValueTimeList } from '@/common/utils/temp.utils';
import MojRadioButton from '@/core/components/shared/MojRadioButton/MojRadioButton';
import GenericResponse from '@/common/models/GenericResponse';
import { loaderStore } from '@/common/stores/loader.store';
import { getDefaultDates } from './utils';
import DashboardService from '@/common/services/dashboard.service';
import { dashboardStore } from '../SremHome/store/dashboardStore';
import "./SremStockIndexes.scss";

const SremStockIndexes = () => {
    const { t } = useTranslation();
    const chartService = new DashboardService();
    const [timeFilter, setTimeFilter] = React.useState(PeriodCategory.Week);
    const { defaultFromDate, defaultToDate } = getDefaultDates();

    const onTimeFrameChange = (e: any) => {
        setTimeFilter(e.target.value);
    }
    
    const getFromDateValue = () => {
        return `${defaultFromDate.year}-${defaultFromDate.month}-${defaultFromDate.day}`;
    }

    const getToDateValue = () => {
        return `${defaultToDate.year}-${defaultToDate.month}-${defaultToDate.day}`;
    }

    useEffect(() => {
        getVMIndex();
    }, [timeFilter]);

    async function getVMIndex() {
        loaderStore.setLoader(true);
        const response: GenericResponse = await chartService.getMVIndex(timeFilter);
        if (response?.IsSuccess) {
            dashboardStore.setVMIndexes(response.Data?.marketIndexDtos);
        }
        loaderStore.setLoader(false);
    }

    return (
        <div className="section srem-index">
            <Card className="section__container">
                <Spin spinning={loaderStore.isLoading}>
                    <Card className="section__container">
                        <Row>
                            <Col flex="auto">
                                <Card.Head className="section__title">
                                    <Col flex="1 1 auto">{t('header.srem-index')}</Col>
                                </Card.Head>
                                <Card.Body className="section__basics">
                                    <Row justify={'end'} align={'middle'}>
                                        <Col lg={8} md={10} sm={24} xs={24}>
                                            <MojRadioGroup value={timeFilter}>
                                                {marketValueTimeList.map((value) => {
                                                    return (
                                                        <MojRadioButton
                                                            value={value.id}
                                                            checked={value.isSelected}
                                                            key={value.id}
                                                            onClick={onTimeFrameChange}
                                                        >
                                                            {value.filter}
                                                        </MojRadioButton>
                                                    );
                                                })}
                                            </MojRadioGroup>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <LineColumnChart timeFrame={timeFilter} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Spin>
            </Card>
        </div>
    );
};

export default SremStockIndexes;
