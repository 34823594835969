import React from 'react';
import { ReactComponent as EmptyIcon } from '@/core/assets/images/assets-arts-empty-state.svg';
import './_Empty.scss';
export type EmptyProps = {
    emptyMessage: string;
};

const Empty = ({ emptyMessage }: EmptyProps) => {
    return (
        <div className="Empty">
            <EmptyIcon />
            <h1>{emptyMessage}</h1>
        </div>
    );
};

export default Empty;
