import { useEffect, useState } from 'react';

export const useCountdown = (seconds: number) => {
    const [time, updateCurrentTime] = useState<number>(seconds);
    useEffect(() => {
        const id = setInterval(() => updateCurrentTime((count) => count - 1), 1000);
        return () => id && clearInterval(id);
    }, []);

    return time;
};
