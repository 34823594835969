import { useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { ClickOutsideListener, MojPopup } from '../shared';
import NotificationIcon from '../shared/NotificationIcon/NotificationIcon';
import { Attorney, AttorneyItem, DelegatorTypes } from '@/common/models/Attorney';
import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';
import { Navbar } from './Navbar/Navbar';
import { FEATURES } from '@/common/utils/features';
import MojLabel, { LabelVariant } from '../shared/Label/MojLabel';

type MobileNavbarProps = {
    setIsExpanded: (value: boolean) => void;
    authedAttorney?: Attorney;
    attorneys: AttorneyItem[] | null;
    beneficiaryCountLoader?: boolean;
    beneficiaryCount?: number;
    isExpanded: boolean;
}

const MobileNavbar = ({ setIsExpanded, authedAttorney, beneficiaryCountLoader, beneficiaryCount, attorneys, isExpanded }: MobileNavbarProps) => {
    const { t: translate } = useTranslation();

    const [isInfoModelVisible, setIsInfoModelVisible] = useState(false);
    const [isOpenMarketInfoModelVisible, setIsOpenMarketInfoModelVisible] = useState(false);
    const [isSplitAndMergeModelVisible, setIsSplitAndMergeModelVisible] = useState(false);

    const disableMenuForCompanyAttorney = () => {
        if (!authedAttorney) {
            return true;
        }

        return authedAttorney && (authedAttorney.DelegatorType !== DelegatorTypes.Organisation && authedAttorney.DelegatorType !== DelegatorTypes.Government);
    };

    const showDealInfoModel = (e) => {
        setIsInfoModelVisible(true);
        e.preventDefault();
        e.stopPropagation();
    };

    const showOpenMarketInfoModel = (e) => {
        setIsOpenMarketInfoModelVisible(true);
        e.preventDefault();
        e.stopPropagation();
    };

    const showSplitAndMergeModel = (e) => {
        setIsSplitAndMergeModelVisible(true);
        e.preventDefault();
        e.stopPropagation();
    }

    const isGovernment = authedAttorney?.DelegatorType === DelegatorTypes.Government;

    const governmentOptions = isGovernment ? [
        {
            title: translate('header.constrains-deeds'),
            link: "/government/constrains-list",
            suffix: "( متاح للجهات الحكومية )",
            noBorder: true
        }
    ] : [];

    const options = [
        {
            title: translate('header.dashboard'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.srem-index'),
                    link: "/realestate-stock-indexes"
                },
                {
                    title: translate('header.direct-deals'),
                    link: "/"
                },
                {
                    title: translate('header.realestate-info'),
                    link: "/realestates-info"
                }
            ]
        },
        {
            title: translate('header.inquiry-service'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.my-realestates-inquiry'),
                    link: "/my-realestates"
                },
                {
                    title: translate('header.deed-inquiry'),
                    link: "/deed-inquiry"
                },
                {
                    title: translate('header.heir-inquiry'),
                    link: "/heir-realestates"
                },
            ]
        },
        ...(FEATURES.AUCTION ? [{
            title: translate('auction.title'),
            link: "#",
            subOptions: [
                {
                    title: translate('auction.initiate'),
                    link: "/auction",
                    suffix: <MojLabel className={'shared-tag'} color={LabelVariant.warning}> {'تجريبي'}</MojLabel>
                },
                {
                    title: translate('auction.auction-offers'),
                    link: "/auction/list",
                    suffix: <MojLabel className={'shared-tag'} color={LabelVariant.warning}> {'تجريبي'}</MojLabel>
                }
            ]
        }] : []),
        ...(FEATURES.MERGE_SPLIT ? [{
            title: translate('merge-split.title'),
            link: "#",
            subOptions: [
                {
                    title: translate('merge-split.split.split-deeds'),
                    link: "/merge-split/split",
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showSplitAndMergeModel} />
                },
                {
                    title: translate('merge-split.split-land.title'),
                    link: "/merge-split/split-land",
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showSplitAndMergeModel} />
                },
                {
                    title: translate('merge-split.merge.title'),
                    link: "/merge-split/merge",
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showSplitAndMergeModel} />
                },
                {
                    title: translate('header.upload-shapefile'),
                    link: "/merge-split/update-shape-file",
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showSplitAndMergeModel} />
                }
            ]
        }] : []),
        {
            title: translate('header.deals-services'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.register-land.title'),
                    link: authedAttorney?.DelegatorType === DelegatorTypes.Government ? "/government-deal" : "/deal",
                    suffix: translate('header.register-land.eptm-gift-open-market'),
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showDealInfoModel} />
                },
                {
                    title: translate('header.offers-realestate'),
                    link: "/open-market",
                    suffix: translate('header.free-purchase-order-free-deal'),
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showOpenMarketInfoModel} />
                },
                {
                    title: translate('header.realestate-map'),
                    link: "/open-market/realestate-map",
                    suffix: translate('header.free-purchase-order-free-deal'),
                    FixedIcon: () => <InfoIcon className="deal-info-icon" onClick={showOpenMarketInfoModel} />
                }
            ]
        },
        {
            title: translate('header.mortgage.title'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.mortgage.new-mortgage'),
                    link: !disableMenuForCompanyAttorney() ? '/mortgage' : '#',
                    disabled: disableMenuForCompanyAttorney(),
                    suffix: "( متاح لوكلاء الجهات المرتهنة )"
                },
                {
                    title: translate('header.mortgage.release-mortgage'),
                    link: '/mortgage/release',
                    suffix: "( متاح للمرتهن  )"
                },
                {
                    title: translate('mortgage.edit-mortgage-amount'),
                    link: '/mortgage/edit-mortgage-amount',
                    suffix: "( متاح للمرتهن  )"
                },
                {
                    title: translate('header.mortgage.EPU-MortgageDeed'),
                    link: '/mortgage/epu',
                    suffix: "( متاح للمرتهن  )"
                },
                {
                    title: translate('header.mortgage.transfer-mortgage'),
                    link: !disableMenuForCompanyAttorney() ? "/mortgage/transfer" : "#",
                    disabled: disableMenuForCompanyAttorney(),
                    suffix: "( متاح لوكلاء الجهات المرتهنة )",
                    noBorder: true
                },
                ...governmentOptions
            ]
        },
        {
            title: translate('header.operations'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.my-operations'),
                    link: "/transactions",
                    FixedIcon: () => beneficiaryCountLoader ? <Spin spinning={beneficiaryCountLoader} /> : <NotificationIcon count={beneficiaryCount!} />
                },
                {
                    title: translate('header.my-archived-operations'),
                    link: "/archived-transactions"
                }
            ]
        },
        {
            title: translate('header.deed-upgrade.title'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.deed-upgrade.create'),
                    link: "/deed-upgrade/new-request"
                },
                {
                    title: translate('header.deed-upgrade.update'),
                    link: "/deed-upgrade/edit-request"
                },
                {
                    title: translate('header.e-deed-edit'),
                    link: "/edit-request",
                },
                {
                    title: translate('header.deed-upgrade.requests'),
                    link: "/deed-upgrade/requests"
                },
            ]
        },
        {
            title: translate('profile.title'),
            link: "#",
            subOptions: [
                {
                    title: translate('header.view-profile'),
                    link: "/profile",
                    onClick: () => {
                        setIsExpanded(false);
                    }
                },
                {
                    title: translate('header.wakalat'),
                    link: attorneys && attorneys!!.length === 0 ? '/profile/attorney' : '/profile',
                    onClick: () => {
                        setIsExpanded(false);
                    }
                },
                ...(FEATURES.WALLET ? [{
                    title: translate('profile.wallet'),
                    link: '/profile/wallet',
                    onClick: () => {
                        setIsExpanded(false);
                    }
                }] : []),
                {
                    title: translate('update-dob.title'),
                    link: '/profile/update-dob',
                    onClick: () => {
                        setIsExpanded(false);
                    }
                }
            ]
        },
        {
            title: translate('header.user-support'),
            link: "#",
            onClick: () => {
                setIsExpanded(false);
                window.open('https://new.najiz.sa/applications/crm/create', '_blank');
            }
        }
    ];

    return (
        <>
            <div className="mobile-header__options">
                <ClickOutsideListener
                    onClickOutside={() => {
                        if (!isInfoModelVisible) {
                            setIsExpanded(false);
                        }
                    }}
                >
                    <Navbar options={options} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </ClickOutsideListener>
            </div>

            <MojPopup
                open={isInfoModelVisible}
                title={translate('header.deal-info-modal.title')}
                onOk={() => {
                    setIsInfoModelVisible(false);
                }}
                footer={null}
                onCancel={() => {
                    setIsInfoModelVisible(false);
                }}
                closable={true}
            >
                <div className="deal-info-popup-content">
                    <p>{translate('header.deal-info-modal.text-line1')}</p>
                    <ol>
                        <li>
                            <strong>{translate('header.deal-info-modal.item1-title')}</strong>
                            <p>{translate('header.deal-info-modal.item1-desc')}</p>
                        </li>
                        <li>
                            <strong>{translate('header.deal-info-modal.item2-title')}</strong>
                            <p>{translate('header.deal-info-modal.item2-desc')}</p>
                        </li>
                        <li>
                            <strong>{translate('header.deal-info-modal.item3-title')}</strong>
                            <p>{translate('header.deal-info-modal.item3-desc')}</p>
                        </li>
                    </ol>
                </div>
            </MojPopup>

            <MojPopup
                open={isOpenMarketInfoModelVisible}
                title={translate('header.deal-info-modal.title')}
                onOk={() => {
                    setIsOpenMarketInfoModelVisible(false);
                }}
                footer={null}
                onCancel={() => {
                    setIsOpenMarketInfoModelVisible(false);
                }}
                closable={true}
            >
                <div className="deal-info-popup-content">
                    <p>{translate('header.openMarket-info-modal.text-line1')}</p>
                    <ol>
                        <li>
                            <strong>{translate('header.openMarket-info-modal.item1-title')}</strong>
                            <p>{translate('header.openMarket-info-modal.item1-desc')}</p>
                        </li>
                        <li>
                            <strong>{translate('header.openMarket-info-modal.item2-title')}</strong>
                            <p>{translate('header.openMarket-info-modal.item2-desc')}</p>
                        </li>
                        <li>
                            <strong>{translate('header.openMarket-info-modal.item3-title')}</strong>
                            <p>{translate('header.openMarket-info-modal.item3-desc')}</p>
                        </li>
                    </ol>
                </div>
            </MojPopup>

            <MojPopup
                open={isSplitAndMergeModelVisible}
                title={translate('merge-split.title')}
                onOk={() => {
                    setIsSplitAndMergeModelVisible(false);
                }}
                footer={null}
                onCancel={() => {
                    setIsSplitAndMergeModelVisible(false);
                }}
                closable={true}
            >
                <div className="deal-info-popup-content">
                    <p>
                        بإمكانك استخدام الأداة التالية <a href='https://shapefile-tool.red.sa/FormUploader/' target="_blank" rel="noreferrer">Shapefile</a> لتعبئة ملف البيانات الجغرافية (Shapefile)
                    </p>
                </div>
            </MojPopup>
        </>
    );
};

export default MobileNavbar;
