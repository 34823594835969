import React from 'react';
import './_MojRadioGroup.scss';
import { Radio, RadioGroupProps } from 'antd';

export type MojRadioGroupProps = {};

const MojRadioGroup = ({ className, children, ...props }: RadioGroupProps) => {
    return (
        <Radio.Group {...props} className="MojRadioGroup">
            {children}
        </Radio.Group>
    );
};

export default MojRadioGroup;
