import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ar from './ar.json';
import en from './en.json';

export function initTranslations() {
    i18n.use(initReactI18next).init({
        resources: {
            ar: {
                translation: ar,
            },
            en: {
                translation: en,
            },
        },
        lng: 'ar',
        fallbackLng: 'ar',
        interpolation: {
            escapeValue: false,
        },
    });
}
