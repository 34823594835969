import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttachmentDto from './AttachmentDto';
import { toBase64 } from '@/common/utils/helper.utils';
import './UploadFile.scss';
import { PaperClipOutlined } from '@ant-design/icons';
import { notification } from 'antd';

export interface IUploadFile {
    onUpload: (file: AttachmentDto, index: number, nativeFile: File) => void;
    hideLabel?: boolean;
    maxSize?: number; // MB
    title?: string,
    fileName?: string,
    acceptedFileTypes?: string[];
}

export function UploadFile({ onUpload, hideLabel, maxSize, title = "المرفقات", fileName = '', acceptedFileTypes = [] }: IUploadFile) {
    const { t } = useTranslation();
    const [attachmentName, setAttachmentName] = useState(fileName);
    const [isMaxSize, setIsMaxSize] = useState<boolean>(false);

    const handleChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.files && event.target.files.length > 0) {
                const file = event.target.files[0];
                const fileType = file.name.substring(file.name.lastIndexOf("."));
                if (acceptedFileTypes.length > 0 && !acceptedFileTypes.includes(fileType.toLowerCase())) {
                    notification.warning({
                        message: t("deed-upgrade.create-update.unsupported-file-type")
                    });
                    return;
                }
                if (maxSize) {
                    // Validate the file size before updating attachments
                    const maxSizeInBytes = maxSize * 1024 * 1024; // 2MB
                    if (file.size > maxSizeInBytes) {
                        // Handle the case where the file size exceeds the maximum allowed size
                        console.error('File size exceeds the maximum allowed size (2MB).');
                        setIsMaxSize(true);
                        return;
                    }

                    setIsMaxSize(false);
                }

                const contentType = file.type || file.name.substring(file.name.lastIndexOf(".") + 1);

                const attachment = new AttachmentDto(
                    file.name,
                    contentType,
                    (await toBase64(file)) as string,
                    file.size,
                    false,
                    true,
                    true,
                    '',
                    undefined
                );

                setAttachmentName(file.name);

                onUpload(attachment, 0, file);
            }
        },
        [onUpload, t, acceptedFileTypes, maxSize]
    );

    const fileRef = useRef<HTMLInputElement>(null);

    const triggerFileUpload = useCallback(() => {
        if (fileRef && fileRef.current) {
            fileRef.current.click();
        }
    }, []);

    return (
        <div>
            <div className="file-input" onClick={triggerFileUpload}>
                <div className="elements-wrapper">
                    <div><PaperClipOutlined width={18} height={18} /></div>
                    <div>
                        <div className="title">{title}</div>
                        <div style={{ direction: 'ltr', textAlign: 'right' }}>{acceptedFileTypes && acceptedFileTypes.length > 0 ? acceptedFileTypes.map(type => `*${type}`).join(" , ") : "*.jpg, *.jpeg, *.png, *.pdf"}</div>
                    </div>
                </div>
            </div>

            {attachmentName && !hideLabel ? <div>{attachmentName}</div> : <></>}
            {isMaxSize && <div className="ant-form-item-explain-error">{t('user-support.maximum-allowed-size', { maxSize })}</div>}
            <input
                ref={fileRef}
                type="file"
                accept={acceptedFileTypes.join(',')}
                style={{ visibility: 'hidden', display: 'none' }}
                onChange={handleChange}
            />
        </div>
    );
}
