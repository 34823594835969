import { AxiosInstance } from 'axios';
import { createClient } from '@common/services/index';
import { OtpRequestOperationType } from '@/core/assets/enums';
import { Seller } from '../models/OpenMarketRequest';
import { RequestTypes } from '../models/Transaction';
import { NewRequestTypes } from '../enums/deal-request-types.enum';

export default class SellOrderService {
    HttpClient!: AxiosInstance;
    OtpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
        this.OtpClient = createClient(`${process.env.REACT_APP_OTP_URL}api/v1`);
    }

    create = async (body: any) => {
        return await this.HttpClient.post(`SellOrder/Create`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    sellerAccept = async (body: any) => {
        return await this.HttpClient.post(`SellOrder/SellerAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    cancel = async (body: any) => {
        return await this.HttpClient.post(`SellOrder/Cancel`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    reject = async (body: any) => {
        return await this.HttpClient.post(`SellOrder/Reject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    accept = async (body: {
        id: string;
        deedSerial: number;
        iban: string;
        mobileNumber: string;
        otp: number;
        // mortgageValues?: IMortgageValues;
        mortgageEntity?: number;
        mortgageAmount?: number;
        otpVerify: { key: string; otp: string };
        preferredPaymentMethod: number;
    }) => {
        return await this.HttpClient.post(`SellOrder/Accept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    buyerAcceptEptm = async (body: {
        id: string;
        deedSerial: number;
        iban: string;
        mobileNumber: string;
        // otp: number;
        // mortgageValues?: IMortgageValues;
        // mortgageEntity?: number;
        // mortgageAmount?: number;
        mortgageeAmount: number;
        otpVerify: { key: string; otp: string };
        preferredPaymentMethod: number;
    }) => {
        return await this.HttpClient.post(`EptmRequest/BuyerAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    acceptEPT3M = async (body: {
        id: string;
        deedSerial: number;
        mobileNumber: string;
        otp: number;
        iban: string;
        // mortgageValues?: IMortgageValues;
        mortgageEntity?: number;
        mortgageAmount?: number;
        otpVerify: { key: string; otp: string };
        preferredPaymentMethod: number;
    }) => {
        return await this.HttpClient.post(`EPT3MOrder/BuyerAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    acceptGPT3M = async (body: {
        id: string;
        deedSerial: number;
        mobileNumber: string;
        otp: number;
        iban: string;
        mortgageEntity?: number;
        mortgageAmount?: number;
        otpVerify: { key: string; otp: string };
    }) => {
        return await this.HttpClient.post(`GPT3MOrder/BuyerAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    entityAcceptEPT3M = async (body: {
        id: string;
        deedSerial: number;
        iban: string;
        mortgageAmount: number;
        otpVerify: { key: string; otp: string };
        preferredPaymentMethod?: number;
    }) => {
        return await this.HttpClient.post(`EPT3MOrder/EntityAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    entityRejectEPT3M = async (body: any) => {
        return await this.HttpClient.post(`EPT3MOrder/EntityReject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    entityAcceptGPT3M = async (body: {
        id: string;
        deedSerial: number;
        iban: string;
        mortgageAmount: number;
        otpVerify: { key: string; otp: string };
    }) => {
        return await this.HttpClient.post(`GPT3MOrder/EntityAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    entityRejectGPT3M = async (body: any) => {
        return await this.HttpClient.post(`GPT3MOrder/EntityReject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    sendOtp = async (body: any, requestOperationType = OtpRequestOperationType.Create) => {
        return await this.HttpClient.post(`Otp/Send`, {
            ...body,
            requestOperationType,
        })
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    verifyOtp = async (body: { otp: string; key: string }) => {
        return await this.OtpClient.post(`Otp/Verify`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    verifyVat = async (body: any) => {
        return await this.HttpClient.post(`Validation/VatPaymentStatus`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    verifyRedfSerial = async (body: any) => {
        return await this.HttpClient.post(`EptmRequest/Inquire`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    createEptmRequest = async (body: any) => {
        return await this.HttpClient.post(`EptmRequest/Create`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    sellerAcceptEptm = async (body: any) => {
        return await this.HttpClient.post(`EptmRequest/SellerAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    acceptEptmRequest = async (body: any) => {
        return await this.HttpClient.post(`EptmRequest/EntityAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    rejectEptmRequest = async (body: any) => {
        return await this.HttpClient.post(`EptmRequest/EntityReject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    rejectEptmRequestByBuyer = async (body: any) => {
        return await this.HttpClient.post(`EptmRequest/BuyerReject`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    checkVatPaymentStatus = async (body: any) => {
        return await this.HttpClient.post(`Validation/CheckVatPaymentStatus`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    calculateTax = async (body: {
        sellers: Seller[];
        dealAmount: number;
        requestType: RequestTypes | NewRequestTypes;
    }) => {
        return await this.HttpClient.post(`SellOrder/CalculateDueAmount`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getCheckboxes = async (serial: number) => {
        return await this.HttpClient.get(`/DeedUpgrade/GetAllowedEditDeed/${serial}`)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    createEditDeedRequest = async (body: any) => {
        return await this.HttpClient.post(`/DeedUpgrade/CreateEditDeedRequest`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    sellersAccept3rdParty = async (body: any) => {
        return await this.HttpClient.post(`SellOrder/SellersAccept3rdParty`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    sellerCancel3rdParty = async (body: any) => {
        return await this.HttpClient.post(`SellOrder/SellerReject3rdParty`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getAllGroupsForAllowedEditFields = async () => {
        return await this.HttpClient.get(`/DeedUpgrade/GetAllGroupsForAllowedEditFields`)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getChildsByGroupId = async (groupId: number, deedSerial: number) => {
        return await this.HttpClient.get(`/DeedUpgrade/GetChildsByGroupId/${groupId}/${deedSerial}`)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    processDeedLinkRequest = async (body: {
        OtpVerify: {
            Key: string;
            Otp: number;
        };
        DeedSerial: number;
        UserInput: number[];
        LinkProcessType: number;
        DeedLinkDto: {
            attachementModel: any;
            EditNotes: string;
        };
    }) => {
        return await this.HttpClient.post(`/DeedLink/ProcessDeedLinkRequest`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    acceptEPT3M3RdParty = async (body: {
        id: string;
        mobileNumber: string;
        otpVerify: { key: string; otp: string };
    }) => {
        return await this.HttpClient.post(`EPT3MOrder/BuyerAccept3rdParty`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    thirdPartyAcceptByBuyer = async (body: { id: string; otpVerify: { key: string; otp: string } }) => {
        return await this.HttpClient.post(`SellOrder/BuyersAccept3rdParty`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
}

export const sellOrderServiceInstance = new SellOrderService();
