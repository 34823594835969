import { PropsWithChildren } from 'react';
import { MojButtonProps } from '.';
export enum PhaseItemSizes {
    Small = 'small',
    Regular = 'regular',
}
export interface PhaseItemProps extends PropsWithChildren<MojButtonProps> {
    size?: PhaseItemSizes;
    hasBorder?: boolean;
    date?: string;
    statusString?: string;
    isDesktop?: boolean;
    step?: number;
}
