import React, { useCallback } from 'react';

import { FormikProps, FormikValues } from 'formik';
import { Card } from '@core/components/shared';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeedOwner, IDeed } from '@common/models/Deed';
import { parseShare } from '@common/utils/forms-utils/forms-utils';
import { AddedBuyer } from '@open-market/components/AddedBuyer/AddedBuyer';
import { PlainLabel, PlainLabelVariant } from '@core/components/shared/PlainLabel/PlainLabel';
import DatePickerInputText from '@core/components/shared/DatePickerInputText/DatePickerInputText';

import './UpdateBeneficiaryBirthDateForm.scss';

type UpdateBeneficiaryBirthDateFormProps = {
    formikProps: FormikProps<FormikValues>;
    owners: any[];
    deed?: IDeed;
    showShareValue?: boolean;
};

const UpdateBeneficiaryBirthDateForm = ({
    formikProps,
    deed,
    owners,
    showShareValue,
}: UpdateBeneficiaryBirthDateFormProps) => {
    const { t } = useTranslation();
    const { values } = formikProps;

    const getDayValue = useCallback(
        (index) => {
            return formikProps.values &&
                formikProps.values[`ownerBirthDates`] &&
                formikProps.values[`ownerBirthDates`][index]
                ? formikProps.values[`ownerBirthDates`][index]?.day
                : '';
        },
        [formikProps]
    );

    const getMonthValue = useCallback(
        (index) => {
            return formikProps.values &&
                formikProps.values[`ownerBirthDates`] &&
                formikProps.values[`ownerBirthDates`][index]
                ? formikProps.values[`ownerBirthDates`][index]?.month
                : '';
        },
        [formikProps]
    );

    const getYearValue = useCallback(
        (index) => {
            return formikProps.values &&
                formikProps.values[`ownerBirthDates`] &&
                formikProps.values[`ownerBirthDates`][index]
                ? formikProps.values[`ownerBirthDates`][index]?.year
                : '';
        },
        [formikProps]
    );

    const getDateValue = (index) => {
        return getYearValue(index) + '-' + getMonthValue(index) + '-' + getDayValue(index);
    };

    const handleChange = (value, index, formikProps) => {
        if (values?.ownerBirthDates?.length) {
            const newOwnerBirthDates = [...formikProps.values?.ownerBirthDates];
            newOwnerBirthDates[index] = { ...value, serial: owners[index].Serial };

            formikProps.setFieldValue('ownerBirthDates', newOwnerBirthDates);
        }
    };

    return (
        <Card className="update-beneficiary-birthdate-form">
            <Row>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Card.Head className="update-beneficiary-birthdate-form__title border-bottom">
                        <Col flex="1 1 auto">{t('my-realestate.update-birth-dates')}</Col>
                    </Card.Head>
                </Col>
            </Row>
            <Row className="update-beneficiary-birthdate-form__form">
                <Col span={24}>
                    {owners.map((owner, i) => (
                        <AddedBuyer
                            key={`my-realestate-added-buyer-${owner.IdNumber}`}
                            share={parseShare(owner.OwningAmount)}
                            index={`${i + 1}`}
                            idNumber={owner.IdNumber}
                            name={owner.OwnerName}
                            last={i === owners.length - 1}
                            showShareLabel={false}
                            isOwner={true}
                            showShareValue={showShareValue}
                        >
                            <Row gutter={[16, 0]} style={{ width: '100%' }} align="middle">
                                <Col span="24">
                                    <PlainLabel
                                        title={t('shared.owner-dob')}
                                        label={''}
                                        variant={PlainLabelVariant.primary}
                                        small
                                        noBorder
                                    />
                                </Col>

                                {/* <DatePickerInputText
                                    formikProps={formikProps}
                                    dayId={`day`}
                                    monthId={`month`}
                                    yearId={`year`}
                                    dateValue={`${getDateValue(i)}`}
                                    arrayKey={`ownerBirthDates`}
                                    itemIndex={i}
                                /> */}
                                <DatePickerInputText
                                    dayId={`day`}
                                    monthId={`month`}
                                    yearId={`year`}
                                    dateValue={`${values?.ownerBirthDates[i].year}-${values?.ownerBirthDates[i].month}-${values?.ownerBirthDates[i].day}`}
                                    onChange={(value) => handleChange(value, i, formikProps)}
                                />
                            </Row>
                        </AddedBuyer>
                    ))}
                </Col>
            </Row>
        </Card>
    );
};

export default UpdateBeneficiaryBirthDateForm;
