import { useCallback, useState } from 'react';

export const useFlag = (initialState?: boolean) => {
    const [flag, setFlag] = useState(initialState ?? false);

    const on = useCallback(() => {
        setFlag(true);
    }, []);

    const off = useCallback(() => {
        setFlag(false);
    }, []);

    const toggle = useCallback(() => {
        setFlag(!flag);
    }, [flag]);

    const set = useCallback((newVal: boolean) => {
        setFlag(newVal);
    }, []);

    return {
        flag,
        on,
        off,
        toggle,
        set,
    };
};
