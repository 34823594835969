import { PhaseItemProps, PhaseItemSizes } from '@/core/interfaces';
import clsx from 'clsx';
import React from 'react';
import './PhaseItem.scss';
import MojLabel from '@core/components/shared/Label/MojLabel';
import { labelColor } from '@core/components/shared/TransactionCard/TransactionCard';

export function PhaseItem({
    color,
    size = PhaseItemSizes.Regular,
    date,
    statusString,
    step,
    hasBorder,
    isDesktop,
    children,
}: PhaseItemProps) {
    return (
        <div
            className={clsx('phase-item', {
                'phase-item--has-border': hasBorder,
            })}
        >
            <span
                className={clsx(
                    'phase-item__period',
                    { 'phase-item__period--desktop': isDesktop },
                    `phase-item__period--${size}`
                )}
            />
            <div className="phase-item__content">
                {date && <div className="phase-item-date">{date}</div>}
                <MojLabel color={labelColor(step!!)!!}>{children}</MojLabel>
                {statusString && <div className="phase-item-status-string">{statusString}</div>}
            </div>
        </div>
    );
}
