import React, { useRef, useEffect, PropsWithChildren, HTMLAttributes } from 'react';

function useOutsideAlerter(
    ref: React.RefObject<{ contains(target: EventTarget | null): boolean }>,
    onClickOutside: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

interface ClickOutsideListenerProps extends HTMLAttributes<HTMLDivElement> {
    onClickOutside: () => void;
}
export function ClickOutsideListener({ children, onClickOutside }: PropsWithChildren<ClickOutsideListenerProps>) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onClickOutside);

    return <div ref={wrapperRef}>{children}</div>;
}
