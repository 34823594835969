/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Col, Divider, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Alert, AlertVariants, Card, DeedPaper, MojAccordion, MojButton, MojPopup, ReadOnlyField, ReadOnlyFieldBorderVariant } from '@core/components/shared';
import { getDeedConstrains, isDeedValid, getDeedGiftConditionText, getDeedGpt3mConditionText } from '@common/utils/deed-utils';
import { convertNumberWithFractionCurrency, getReadablePrice } from '@common/utils/numbers-utils';
import { RealestateDetails } from '@open-market/components/RealestateDetails/RealestateDetails';
import DeedPdfDownloaderV2 from '@dashboard/components/DeedPdfDownloaderV2/DeedPdfDownloaderV2';
import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';
import { ReadOnlyFieldVariant } from '../../../../core/components/shared';
import DeedMortgagee from '../../components/DeedMortgagee/DeedMortgagee';
import { RequestInformation } from '@common/models/RequestInformation';
import { Owner } from '@/modules/open-market/components/Owner/Owner';
import { RequestTypes } from '@/common/models/Transaction';
import { loaderStore } from '@common/stores/loader.store';
import { IDeed, RealStateDto } from '@common/models/Deed';
import DeedService from '@/common/services/deed.service';
import { Mortgagor } from '@common/models/ Mortgagor';
import { authStore } from '@common/stores/auth.store';
import { Mortgagee } from '@common/models/Mortgagee';
import { useHint } from '@/common/hooks';
import './DeedDetails.scss';
import { attorneyStore } from '@/common/stores/attorney.store';

const messageContent = <span>العقار مسجل عينياً، وبموجب النظام فإن الجهة المختصة هي الهيئة العامة للعقار، ويمكن الاطلاع على العقارات المسجلة عينياً عبر منصة السجل العقاري <a href="https://rer.sa" target="_blank" rel="noreferrer">https://rer.sa</a></span>

const DeedDetails = ({
    hideActions,
    hideBasicDetails,
    deed,
    requestInfo,
    hideOwners,
    mortgagee,
    mortgagor,
    isPurchaseOrder,
    isNewDeed,
    requestType,
    hideDetails,
    updateIdentityNumber,
    hideMap = false
}: {
    hideActions?: boolean;
    hideBasicDetails?: boolean;
    hideOwners?: boolean;
    isPurchaseOrder?: boolean;
    deed?: IDeed;
    requestInfo?: RequestInformation;
    mortgagee?: Mortgagee;
    mortgagor?: Mortgagor;
    isNewDeed?: boolean;
    requestType?: RequestTypes;
    hideDetails?: boolean;
    updateIdentityNumber?: (IdentityNo: number) => void;
    hideMap?: boolean;
}) => {
    const { t } = useTranslation();
    const deedService = new DeedService();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [sharedStates, setSharedStates] = useState<RealStateDto[]>([]);
    const [isCanDownloadDeed, setIsCanDownloadDeed] = useState<boolean>(false);
    const { Icon } = useHint({
        title: t('shared.info'),
        message: messageContent,
        maskClosable: true,
    });

    useEffect(() => {
        if (deed?.DeedStatus === 'A' && deed?.HasSharedRealStates) {
            setLoading(true);
            deedService.getSharedRealStateOwners(deed?.DeedSerial, hideDetails!).then((response) => {
                setSharedStates(response?.Data);
                setLoading(false);
            });
        }
    }, [deed]);

    const owners = useMemo(() => {
        return (deed?.DeedOwners) || [];
    }, [deed]);

    // verify the loggedIn from authStore user is the owner of the realestate in deedOwner.
    const isLoggedInUserOwner = deed?.DeedOwners?.some((owner) => owner.IdNumber === authStore.userDetails?.IdentityNo);

    // Create method to check if RealestateIdentityNumber in deed DeedRealState not null.
    const isRealestateIdentityNumberNotNull = (): boolean => {
        return deed?.DeedRealState?.some((realState) => realState.RealestateIdentityNumber !== null || realState.RealestateIdentityNumber?.toString().length < 16) || false;
    };

    const isAini = deed?.IsRealEstateRERConstrained || deed?.AiniRegistrationFlag;

    const hasMortgagee = deed?.DeedMortgagee?.MortgageeId ??
        deed?.DeedMortgagee?.MortgageeName ??
        deed?.DeedMortgagee?.MortgageeSerial ??
        deed?.DeedMortgagee?.MortgageAmount;

    const hasDeedRealStateAndDeedLimits = !isNewDeed &&
        !deed?.DeedLimits &&
        !deed?.DeedRealState &&
        !loaderStore.isLoading &&
        !authStore.isLoggedIn;

    const deedStatus = deed?.DeedContainerStatus === 'Active'
        ? t('deed-details.not-parked')
        : t('deed-details.parked');

    useEffect(() => {
        if (deed && deed.DeedOwners) {
            setIsCanDownloadDeed(
                deed?.DeedOwners.every((owner) => owner.IdNumber !== null && !owner.IdNumber.includes('*'))
            );
        }

        if (!deed?.DeedOwners || deed.DeedOwners.length === 0) {
            setIsCanDownloadDeed(false);
        }

        if (
            !location.pathname.includes('/deal') &&
            !location.pathname.includes('/my-realestates') &&
            !location.pathname.includes('/government-deal') &&
            !location.pathname.includes('/heir-realestates')
        ) {
            setIsCanDownloadDeed(false);
        };
    }, [deed, authStore.userDetails]);

    // check if the loggedIn user is attorney.
    const isAttorney = (): boolean => {
        return attorneyStore.isAttorneyLoggedIn();
    };

    return (
        <>
            {isPurchaseOrder && (
                <div className="section">
                    <Card className="section__basics">
                        <Alert variant={AlertVariants.Warning}>{t('deed-details.main-warning-statement')}</Alert>
                    </Card>
                </div>
            )}

            <div className="deed-details" dir='rtl'>
                {!hideBasicDetails && (
                    <Card className="deed-details__container">
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Card.Head className="deed-details__title border-bottom">
                                    <Col flex="1 1 auto">
                                        {isNewDeed ? t('deed-details.new-deed') : t('deed-details.title')}
                                    </Col>
                                </Card.Head>
                            </Col>
                        </Row>
                        <Row className="deed-details__basics">
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={deed?.DeedNo}
                                    label={t('deed-details.deed-number')}
                                    hasLtrContent
                                    small
                                />
                            </Col>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={deed?.DeedDate}
                                    label={t('deed-details.deed-date')}
                                    hasLtrContent
                                    small
                                />
                            </Col>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={deed?.DeedArea.toFixed(2)}
                                    label={t('deed-details.deed-space')}
                                    unit={t('deed-details.deed-unit')}
                                    small
                                />
                            </Col>
                        </Row>
                        {!loaderStore.isLoading && (
                            <>
                                <Row>
                                    <Col flex="1 1 auto">
                                        <ReadOnlyField
                                            variant={
                                                deed?.DeedStatus === 'A'
                                                    ? ReadOnlyFieldVariant.success
                                                    : ReadOnlyFieldVariant.danger
                                            }
                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            title={deed?.DeedStatus === 'A' ? t('shared.active') : t('shared.inactive')}
                                            label={t('deed-details.deed-condition')}
                                            small
                                        />
                                    </Col>
                                    {deed?.DeedStatus === 'A' && (
                                        <>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={
                                                        isDeedValid(deed)
                                                            ? ReadOnlyFieldVariant.success
                                                            : ReadOnlyFieldVariant.danger
                                                    }
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={<span className="constrain--container">{getDeedConstrains(deed)} {isAini && <Icon />}</span>}
                                                    label={t('deed-details.property-constrains')}
                                                    small
                                                />
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={
                                                        deed?.DeedContainerStatus === 'Active' ||
                                                            deed?.AiniRegistrationFlag === true
                                                            ? ReadOnlyFieldVariant.success
                                                            : ReadOnlyFieldVariant.danger
                                                    }
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={
                                                        deed?.AiniRegistrationFlag === true
                                                            ? t('deed-details.aini-registered')
                                                            : deedStatus
                                                    }
                                                    label={t('deed-details.property-condition')}
                                                    small
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Row>

                                {!!deed?.IsGiftConditioned && (
                                    <Row>
                                        <Col xs={24}>
                                            <ReadOnlyField
                                                variant={ReadOnlyFieldVariant.danger}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                title={getDeedGiftConditionText(deed, t)}
                                                label={''}
                                                small
                                            />
                                        </Col>
                                    </Row>
                                )}

                                {deed?.IsGPT3MConstraint && (
                                    <Row>
                                        <Col xs={24}>
                                            <ReadOnlyField
                                                variant={ReadOnlyFieldVariant.danger}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                title={getDeedGpt3mConditionText(deed, t)}
                                                label={''}
                                                small
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {deed?.BenefitsConstraintFlag === 1 ? <Row>
                                    <Col xs={24}>
                                        <ReadOnlyField
                                            variant={ReadOnlyFieldVariant.danger}
                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            title={t("deed-details.benefits-constraint")}
                                            label={''}
                                            small
                                        />
                                    </Col>
                                </Row> : <></>}
                            </>
                        )}
                    </Card>
                )}

                {hasMortgagee ? (
                    <>
                        {deed?.DeedMortgagee?.NewMortgageAmount && (
                            <Card className="my-realestate__buyers">
                                <Card.Head className="buyers-title border-bottom">{t('finance.new-title')}</Card.Head>
                                <Card.Body>
                                    <Row>
                                        <Col flex="1 1 auto">
                                            <ReadOnlyField
                                                label={t('finance.new-mortgagee-amount')}
                                                title={deed?.DeedMortgagee?.NewMortgageAmount}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                unit={`(${t('shared.sar')})`}
                                            />
                                        </Col>
                                        <Col flex="1 1 auto">
                                            <ReadOnlyField
                                                label={t('finance.edit-new-mortgagee-amount')}
                                                title={convertNumberWithFractionCurrency(
                                                    deed?.DeedMortgagee?.NewMortgageAmount
                                                )}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                unit={`(${t('shared.sar')})`}
                                            />
                                        </Col>

                                        {deed?.DeedMortgagee?.NewLastInstalmentDate && (
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    label={t('finance.new-mortgage-claiming-date')}
                                                    title={dayjs(deed?.DeedMortgagee?.NewLastInstalmentDate).format(
                                                        'DD/MM/YYYY'
                                                    )}
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}

                        <Card className="my-realestate__buyers">
                            <Card.Head className="buyers-title border-bottom">
                                {t('finance.mortgagee-details')}
                            </Card.Head>
                            <Card.Body>
                                {deed?.DeedMortgageeList?.map((deedMortgagee) => (
                                    <Fragment key={deedMortgagee.MortgageeId}>
                                        <DeedMortgagee
                                            key={deedMortgagee.MortgageeId}
                                            deedMortgagee={deedMortgagee}
                                            requestType={requestType!}
                                        />
                                        <Divider />
                                    </Fragment>
                                ))}
                            </Card.Body>
                        </Card>
                    </>
                ) : ""}

                {!hideOwners && owners.length > 0 && <Owner owners={owners} />}

                {!isNewDeed && deed?.DeedRealState && (
                    <RealestateDetails
                        deedRealStates={deed.DeedRealState}
                        sharedStates={sharedStates}
                        isSharedStatesLoaded={!loading}
                        updateIdentityNumber={updateIdentityNumber}
                        isOwner={isLoggedInUserOwner}
                        hideMap={hideMap}
                    />
                )}

                {!isNewDeed && requestInfo && (
                    <Card className="deed-details__container">
                        <Row>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Card.Head className="deed-details__title border-bottom">
                                    <Col flex="1 1 auto">{t('mortgage.details')}</Col>
                                </Card.Head>
                            </Col>
                        </Row>
                        <Row className="deed-details__basics">
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={getReadablePrice(requestInfo.amount)}
                                    unit={t('shared.sar')}
                                    label={t('mortgage.price-as-number')}
                                    small
                                />
                            </Col>
                            <Col flex="1 1 auto">
                                <ReadOnlyField
                                    variant={ReadOnlyFieldVariant.success}
                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    title={convertNumberWithFractionCurrency(requestInfo.amount)}
                                    label={t('mortgage.price-as-text')}
                                    unit={t('shared.sar')}
                                    small
                                />
                            </Col>
                        </Row>
                    </Card>
                )}

                {(!isNewDeed && (isCanDownloadDeed || isAttorney())) && (
                    <Card>
                        <Row
                            className={clsx("deed-details__review", { "deed-details__review--disabled": !deed?.DeedText })}
                            onClick={(e) => {
                                if (!deed?.DeedText) {
                                    console.log("preventing")
                                    e.stopPropagation(); e.preventDefault()
                                }
                            }}>
                            <MojAccordion>
                                <MojAccordion.Item
                                    key={1122}
                                    header={
                                        <span className="review-head">
                                            <div className="review-head__text"> {t('deed-details.deed-review')} </div>
                                            <Row className="center-vertically">
                                                <DeedPdfDownloaderV2 deed={deed} sharedStates={sharedStates} />
                                            </Row>
                                        </span>
                                    }
                                >
                                    {deed?.DeedText && (
                                        <>
                                            <Row justify="space-between">
                                                <Col>
                                                    <ReadOnlyField
                                                        variant={ReadOnlyFieldVariant.success}
                                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                        title={
                                                            !deed?.IsDeedObselete
                                                                ? t('deed-details.deed-text-matches')
                                                                : ""
                                                        }
                                                        label={''}
                                                        small
                                                    />
                                                </Col>
                                                <Col flex="1 1 auto">
                                                    <InfoIcon
                                                        style={{ marginRight: '5px', marginTop: '9px' }}
                                                        onClick={() => setIsVisible(true)}
                                                    />
                                                </Col>
                                            </Row>

                                            {(!isRealestateIdentityNumberNotNull() && !deed?.IsDeedObselete) && (
                                                <DeedPaper
                                                    deedText={deed?.DeedText.toString()}
                                                    deedDate={deed?.DeedDate}
                                                    deedNumber={deed?.DeedNo.toString()}
                                                />
                                            )}

                                            <Card.Body className="border">
                                                <Alert variant={AlertVariants.Warning}>
                                                    {t('deed-details.guarantee-alert')}
                                                </Alert>
                                            </Card.Body>
                                        </>
                                    )}
                                </MojAccordion.Item>
                            </MojAccordion>
                        </Row>
                    </Card>
                )
                }

                {!hideActions && (
                    <Card className="deed-details__actions">
                        <MojButton color="primary">{t('deed-details.back')}</MojButton>
                    </Card>
                )}

                {hasDeedRealStateAndDeedLimits && (
                    <Card>
                        <Alert variant={AlertVariants.Warning}>
                            {t('deed-details.more-details')}
                            <Link to="" onClick={() => authStore.login(window.location.pathname)}>
                                {t('deed-details.login')}
                            </Link>
                        </Alert>
                    </Card>
                )}

                <MojPopup
                    title={t('deed-inquiry.deed-number-not-matched')}
                    open={isVisible}
                    onCancel={() => setIsVisible(false)}
                    footer={null}
                >
                    {t('deed-inquiry.deed-number-not-matched-error')}
                </MojPopup>
            </div>
        </>
    );
};

export default observer(DeedDetails);
