import BANK_LOGOS from './bank-logos';

export const getBankLogo = function (IBAN: string) {
    if (!IBAN) {
        return '';
    }

    const bankCode = IBAN.substr(4, 2);
    const bankLogo = BANK_LOGOS.find((b) => b.bankCode === bankCode);
    return bankLogo ? bankLogo.logo : '';
};

export const getBankIcon = function (IBAN: string) {
    if (!IBAN) {
        return '';
    }

    const bankCode = IBAN.substr(4, 2);
    const bankIcon = BANK_LOGOS.find((b) => b.bankCode === bankCode);

    return bankIcon ? bankIcon.icon : '';
};

export const getlogosForBanks = function (supportedBanks: string[]) {
    return BANK_LOGOS.filter((b) => supportedBanks.includes(b.bankCode));
};
