import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import { ReactComponent as ArrowDownIcon } from '@/core/assets/images/assets-icons-arrow-up.svg';
import './Accordion.scss';

export function MojAccordion({ children, ...props }: PropsWithChildren<CollapseProps>) {
    return (
        <Collapse
            ghost
            expandIconPosition={'end'}
            expandIcon={({ isActive }) => <ArrowDownIcon className={!isActive ? 'icon-down-reversed' : ''} />}
            className="moj-collapse"
            {...props}
        >
            {children}
        </Collapse>
    );
}

function MojPanel({ header, children, ...props }: PropsWithChildren<CollapsePanelProps>) {
    return (
        <Collapse.Panel className="moj-panel" header={header} {...props}>
            {children}
        </Collapse.Panel>
    );
}

MojAccordion.Item = MojPanel;
