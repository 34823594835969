import { message } from 'antd';
import copyToClipboard from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useFlag } from './useFlag';
import { ReactComponent as CopyIcon } from '@core/assets/images/assets-icons-copy.svg';
import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';
import clsx from 'clsx';

const MESSAGE_DURATION = 2; //seconds

export function useCopyText({ text, successMessage }) {
    const { flag: copied, on, off } = useFlag(false);

    const copy = useCallback(() => {
        copyToClipboard(text);
        on();
        message.success(successMessage, MESSAGE_DURATION, off);
        return text;
    }, [text, on, off, successMessage]);

    const Icon = ({ className }: { className?: string }) => {
        return !copied ? (
            <CopyIcon className={clsx(className)} onClick={copy} />
        ) : (
            <InfoIcon className={clsx(className)} />
        );
    };

    return {
        copying: false,
        copied,
        copy,
        Icon,
    };
}
