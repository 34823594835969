import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { IDeedMortgagee } from '@/common/models/Deed';
import { ReadOnlyField, ReadOnlyFieldBorderVariant } from '@/core/components/shared';
import { RequestTypes } from '@/common/models/Transaction';
import { convertNumberWithFractionCurrency } from '@/common/utils/numbers-utils';

interface DeedMortgageeProps {
    deedMortgagee: IDeedMortgagee;
    requestType: RequestTypes;
}

const DeedMortgagee: React.FC<DeedMortgageeProps> = ({ deedMortgagee, requestType }) => {
    const { t } = useTranslation();
    return (
        <Row>
            {deedMortgagee?.MortgageeName ? (
                <Col flex="1 1 auto">
                    <ReadOnlyField label={t('finance.entity')} title={deedMortgagee?.MortgageeName} />
                </Col>
            ) : (
                ''
            )}
            {deedMortgagee?.MortgageeSerial ? (
                <Col flex="1 1 auto">
                    <ReadOnlyField label={t('shared.facility-national')} title={deedMortgagee?.MortgageeId} />
                </Col>
            ) : (
                ''
            )}
            {deedMortgagee?.MortgageAmount ? (
                <Col flex="1 1 auto">
                    <ReadOnlyField
                        label={
                            requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT
                                ? t('finance.old-mortgagee-amount')
                                : t('finance.mortgagee-amount')
                        }
                        title={deedMortgagee?.MortgageAmount}
                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                        unit={`(${t('shared.sar')})`}
                    />
                </Col>
            ) : (
                ''
            )}
            {deedMortgagee?.MortgageAmount ? (
                <Col flex="1 1 auto">
                    <ReadOnlyField
                        label={
                            requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT
                                ? t('finance.edit-old-mortgagee-amount')
                                : t('finance.mortgagee-amount')
                        }
                        title={convertNumberWithFractionCurrency(deedMortgagee?.MortgageAmount)}
                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                        unit={`(${t('shared.sar')})`}
                    />
                </Col>
            ) : (
                ''
            )}
            {deedMortgagee?.LastInstalmentDate ? (
                <Col flex="1 1 auto">
                    <ReadOnlyField
                        label={t('finance.mortgage-claiming-date')}
                        title={dayjs(deedMortgagee?.LastInstalmentDate).format('DD/MM/YYYY')}
                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                    />
                </Col>
            ) : (
                ''
            )}
            <Col flex="1 1 auto">
                <ReadOnlyField
                    label={'الموافقة على الفرز'}
                    title={deedMortgagee?.IsApprovedForEPU ? 'نعم' : 'لا'}
                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                />
            </Col>
        </Row>
    );
};

export default DeedMortgagee;
