import React from 'react';
import { MojButton } from '@core/components/shared';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from '@core/assets/images/assets-arts-empty-state.svg';
import './_CrachScreen.scss';
import { useTranslation } from 'react-i18next';

const CrashScreen = () => {
    const { t } = useTranslation();
    return (
        <div className="NotFound">
            <NotFoundIcon />
            <h2>{t('shared.crash-screen')}</h2>
            {/* eslint-disable-next-line no-restricted-globals */}
            <MojButton style={{ maxWidth: '200px' }} color={'primary'} onClick={() => location.reload()}>
                {t('shared.back')}
            </MojButton>
        </div>
    );
};

export default CrashScreen;
