import React from 'react';
import { createRoot } from 'react-dom/client';
import './global.scss';
import App from './App';

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    process.env.REACT_APP_STRICT_MODE_ENABLE === "true" ?
        <React.StrictMode>
            <App/>
        </React.StrictMode> :
        <App />
);
