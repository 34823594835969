import clsx from 'clsx';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import './FieldWrapper.scss';

export enum FieldWrapperSizes {
    Small = 'small',
    Large = 'large',
}

interface FieldWrapperProps extends HTMLAttributes<HTMLDivElement> {
    label: string | React.ReactNode;
    suffix?: string | React.ReactNode;
    isTransparent?: boolean;
    size?: FieldWrapperSizes;
    unit?: string;
    isLtr?: boolean;
    required?: boolean;
}

export function FieldWrapper({
    label,
    suffix,
    isTransparent,
    isLtr,
    children,
    size,
    unit,
    className,
    required = false,
    ...props
}: PropsWithChildren<FieldWrapperProps>) {
    return (
        <div
            className={clsx(
                'du-field-wrapper',
                {
                    'du-field-wrapper--large': size === FieldWrapperSizes.Large,
                    'du-field-wrapper--small': size === FieldWrapperSizes.Small,
                    'du-field-wrapper--transparent': isTransparent,
                    'du-field-wrapper--required': required
                },
                className
            )}
            {...props}
        >
            <div className="du-field-label">
                {label}
                {unit && <p className="unit">{' ' + unit}</p>}
            </div>
            <span className="du-field-children" dir={isLtr ? 'ltr' : ''}>
                {children}
            </span>
            {suffix && <div className="du-field-label__suffix">{suffix}</div>}
        </div>
    );
}
