import { AxiosInstance } from 'axios';
import { createClient } from '@common/services/index';
import { UpdatePendingRefundOrderDto } from '../models/RefundManagement';

export default class RefundService {
    private HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_SREM_URL}api/v1/RefundManagement`);
    }

    getPendingRefundOrders = async () => {
        return await this.HttpClient.get(`/PendingRefundOrders`)
            .then(RefundService.handleResponse)
            .catch(RefundService.handleResponse);
    };

    updatePendingRefundOrder = async (model: UpdatePendingRefundOrderDto) => {
        return await this.HttpClient.post(`/UpdatePendingRefundOrder`, model)
            .then(RefundService.handleResponse)
            .catch(RefundService.handleResponse);
    };

    private static handleResponse(response: any) {
        return response;
    }
}

export const refundServiceInstance = new RefundService();
