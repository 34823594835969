import { booleanPointInPolygon, center, featureCollection, intersect, points, polygon } from '@turf/turf';

export const hexToRgbA = (hex: string) => {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);
    const o = parseInt(tempHex.substring(6, 8) || "aa", 16);
    return [r, g, b, o];
};

export const OVERLAP_COLORS = {
    SELF: "#FFEE77",
    NEIGHBOR: "#FF0000",
    TARGETED_PROPERTY: "#008877"
}

export function transformData(inputData) {
    const features = inputData.map((item) => {
        return {
            type: "Feature",
            properties: {
                serial: "",
                realEstateType: 1,
                realestateIdentityNo: item.realEstateIdentityNo,
                name: `Real Estate ${item.realEstateIdentityNo}`,
                text: item.realEstateIdentityNo
            },
            geometry: {
                type: "Polygon",
                coordinates: [item.coordinates]
            }
        };
    });

    return {
        type: "FeatureCollection",
        realEstateCount: inputData.length,
        openMarketCount: 0,
        purchaseOrderCount: 0,
        totalCount: inputData.length,
        features: features
    };
}

export function fixPolygon(reIdentityPoints) {
    if(reIdentityPoints?.length > 0) {
        return reIdentityPoints.map(reIdentityPoint => {
            const { coordinates } = reIdentityPoint;
            // Check if the first and last points are different
            const firstPoint = coordinates[0];
            const lastPoint = coordinates[coordinates.length - 1];
        
            // If the polygon isn't closed, add the first point as the last point
            if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
                coordinates.push(firstPoint);
            }
        
            return {
                ...reIdentityPoint,
                coordinates
            };
        })
    } else {
        return [];
    }
}

const roundCoordinates = (coordinates, precision = 6) => {
    return coordinates.map(point => [
      Number(point[0].toFixed(precision)),
      Number(point[1].toFixed(precision)),
    ]);
  };

  const roundPolygon = (coordinates, precision = 6) => {
    return coordinates.map(coord => coord.map(c => c.map(p => Number(p.toFixed(precision)))));
}

export function getIntersection(shapeFileCoordinates, elasticCoordinates, checkOverlapByIdentity, checkSelfOverlap = false) {
    // Check for overlap between user and database polygons
    const overlaps: any[] = [];
    shapeFileCoordinates.features.forEach((shapeFileCoordinate, sfIndex) => {
    (elasticCoordinates?.features || []).forEach(elasticCoordinate => {
        if(shapeFileCoordinate.geometry.coordinates[0].length > 4 && elasticCoordinate.geometry.coordinates[0].length > 4) {
            try {
                shapeFileCoordinate.geometry.coordinates[0] = roundCoordinates(shapeFileCoordinate.geometry.coordinates[0]);
                elasticCoordinate.geometry.coordinates[0] = roundCoordinates(elasticCoordinate.geometry.coordinates[0]);
                
                const intersection = intersect(featureCollection([polygon(shapeFileCoordinate.geometry.coordinates), polygon(elasticCoordinate.geometry.coordinates)]));
                const userCenter = center(points(shapeFileCoordinate.geometry.coordinates[0]));
                let pointInPolygon = false;
                try {
                    pointInPolygon = booleanPointInPolygon(userCenter, polygon(elasticCoordinate.geometry.coordinates));
                } catch(e) {
                    console.error(e);
                    console.log({ userCenter, coordinates: elasticCoordinate.geometry.coordinates })
                }
                if(pointInPolygon) {
                    // console.log({ skipping: elasticCoordinate });
                }

                if(checkOverlapByIdentity) {
                    const overlappingCurrentPolygon = (shapeFileCoordinate.properties.realestateIdentityNo || "").toString() === (elasticCoordinate.properties.realestateIdentityNo || "").toString();
                    if(intersection && overlappingCurrentPolygon) {
                        intersection.properties = {
                            polygonColor: OVERLAP_COLORS.TARGETED_PROPERTY,
                            lineColor: OVERLAP_COLORS.TARGETED_PROPERTY
                        };
                        overlaps.push(intersection);
                    } else if(intersection) {
                        overlaps.push(intersection);
                    }
                } else {
                    if (intersection && !pointInPolygon) {
                        overlaps.push(intersection);
                    // console.log('Overlap found:', intersection, elasticCoordinate, shapeFileCoordinate);
                    } else if (intersection && pointInPolygon) {
                        intersection.properties = {
                            polygonColor: OVERLAP_COLORS.TARGETED_PROPERTY,
                            lineColor: OVERLAP_COLORS.TARGETED_PROPERTY
                        };
                        overlaps.push(intersection);
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
    });
    if(checkSelfOverlap) {
        shapeFileCoordinates.features.forEach((_shapeFileCoordinate, _sfIndex) => {
            if(sfIndex !== _sfIndex) {
                const intersection = intersect(featureCollection([polygon(roundPolygon(shapeFileCoordinate.geometry.coordinates)), polygon(roundPolygon(_shapeFileCoordinate.geometry.coordinates))]));
                if(intersection) {
                    intersection.properties = {
                        polygonColor: OVERLAP_COLORS.SELF,
                        lineColor: OVERLAP_COLORS.SELF
                    };
                    overlaps.unshift(intersection);
                }
            }
        });
    }
    });
    return overlaps.map(o => ({
        ...o,
        properties: {
            ...o.properties,
            polygonColor: o.properties.polygonColor || OVERLAP_COLORS.NEIGHBOR,
            lineColor: o.properties.lineColor || OVERLAP_COLORS.NEIGHBOR
        }
    }));
}

export const MAP_LAT_LNG_SORTER = (a, b) => b - a;

export function sortCoordinatesByMagnitude(data) {
    const features = data.Features;

    features.forEach(feature => {
      const { Geometry } = feature;

      if (Geometry && Geometry.Coordinates) {
        const coordinates = Geometry.Coordinates;
  
        if (Geometry.Type === 'Polygon') {
          coordinates.forEach(polygon => {
            polygon.forEach(coordinatePair => {
              coordinatePair.sort(MAP_LAT_LNG_SORTER);
            });
          });
        } else if (Geometry.Type === 'Point') {
          coordinates.sort(MAP_LAT_LNG_SORTER);
        }
      }
    });
  
    return data;
  }