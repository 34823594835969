import { DealEntry } from '@common/models/DealEntry';
import { ITransaction } from '@common/models/ITransaction';

export enum PeriodCategory {
    Hour = 'H',
    Day = 'D',
    Week = 'W',
    Month = 'M',
    Year = 'Y',
    AllTime = 'A',
}

export const filterByTimeList = [
    { id: PeriodCategory.Hour, filter: 'ساعة', isSelected: false },
    { id: PeriodCategory.Day, filter: 'يوم', isSelected: true },
    { id: PeriodCategory.Week, filter: 'أسبوع', isSelected: false },
    { id: PeriodCategory.Month, filter: 'شهر', isSelected: false },
    { id: PeriodCategory.Year, filter: 'سنة', isSelected: false },
    { id: PeriodCategory.AllTime, filter: 'الكل', isSelected: false },
];

export const marketValueTimeList = [
    { id: PeriodCategory.Week, filter: 'أسبوع', isSelected: true },
    { id: PeriodCategory.Month, filter: 'شهر', isSelected: false },
    { id: PeriodCategory.Year, filter: 'سنة', isSelected: false },
    { id: PeriodCategory.AllTime, filter: 'الكل', isSelected: false },
];

export const timeLabelByPeriodCategory = {
    [PeriodCategory.Hour]: 'ساعة',
    [PeriodCategory.Day]: 'يوم',
    [PeriodCategory.Week]: 'أسبوع',
    [PeriodCategory.Month]: 'شهر',
    [PeriodCategory.Year]: 'سنة',
    [PeriodCategory.AllTime]: 'الكل',
};

export const initialSearchInput = {
    areaType: 'A',
    areaSerial: 0,
    periodCategory: PeriodCategory.Day,
    period: 1,
};

export const convertTransactionToDealEntry = (transaction: ITransaction): DealEntry => {
    const entry: DealEntry = {
        date: transaction.TransDate,
        area: transaction.TransArea,
        cityCode: transaction.CitySerial,
        cityName: transaction.CityName,
        districtCode: transaction.NHSerial,
        districtName: transaction.NHName,
        id: transaction.Id,
        price: transaction.TransAmount,
        pricePerMeterSquare: transaction.PricePerMeterSquare,
        regionCode: transaction.RegionSerial,
        regionName: transaction.RegionName,
        change: transaction.Change,
        landNumber: transaction.LandNumber,
        planNumber: transaction.Plan,
        unitType: transaction.UnitType,
    };
    return entry;
};
