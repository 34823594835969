import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Col, Row, message } from 'antd';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';

import MultiStepContainer from '@core/components/shared/MultiStepContainer/MultiStepContainer';
import { IMultiStepContainer } from '@core/components/shared/MultiStepContainer/interface/multi-step-container.interface';
import OtpForm from '@property-mortgage/components/OtpForm/OtpForm';
import GenericResponse from '@common/models/GenericResponse';
import SellOrderService from '@common/services/sell-order.service';
import useMultiStepContainer from '@core/components/shared/MultiStepContainer/util/useMultiStepContainer';
import { OtpRequestOperationType, OtpRequestType } from '@/core/assets/enums';
import { OtpResponse } from '@/core/interfaces/OtpResponse.interface';
import { Terms } from '@dashboard/components/TermsAndConditionsLayout/builder/TermsBuilder';
import TermsAndConditions from '@dashboard/components/TermsAndConditionsLayout/TermsAndConditions';
import { Card, MojButton, MojPopup } from '@/core/components/shared';
import deedUpgradeService from '@/common/services/deed-upgrade.service';
import DURequestSuccess from '../DURequestSuccess';
import { requiredInput } from '@/core/components/shared/DeedInquiryForm/validator';
import { loaderStore } from '@/common/stores/loader.store';
import { MAP_LAT_LNG_SORTER } from '@/modules/open-market/components/RealStateMap/mapHelperFunctions';
import RealStateMapAdvanced from '@/modules/open-market/components/RealStateMap/RealStateMapAdvanced';

const OrderCancel = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const sellOrderService = useMemo(() => new SellOrderService(), []);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [otpConfiguration, setOtpConfiguration] = useState<OtpResponse | undefined>();
    const _useMultiStepContainer = useMultiStepContainer();
    const [terms, setTerms] = useState<Terms>(new Terms());
    const [done, setDone] = useState(false);
    const [coordinates, setCoordinates] = useState([]);

    const updateTerms = (terms: Terms) => {
        setTerms(terms);
    };

    const postAccept = useCallback(async (e: any, isRetrying = false) => {
        setIsLoading(true);
        setIsConfirmModalOpen(false);

        const response: GenericResponse = await sellOrderService.sendOtp(
            {
                requestType: OtpRequestType.DeedUpgrade,
                smsParameters: {
                    deedNumber: "0",
                    dealAmount: "0",
                },
            },
            OtpRequestOperationType.Create
        );

        if (response.IsSuccess && response?.Data?.MessageSent) {
            setOtpConfiguration(response.Data);
            if (!isRetrying) {
                _useMultiStepContainer.nextStep();
            }
        } else {
            _useMultiStepContainer.setErrorDetails(response.ErrorDetails);
            _useMultiStepContainer.setCorrelationId(response.correlationId);
        }
        setIsLoading(false);

    }, [_useMultiStepContainer, sellOrderService]);

    const reviewFormBuilder = () => {
        const submitForm = () => {
            if (terms.isAllTermsChecked()) {
                setIsConfirmModalOpen(true);
            } else {
                _useMultiStepContainer.setErrorDetails(['يجب الموافقة على جميع بنود التعهد والإقرار.']);
            }
        };
        return { submitForm };
    };

    const otpFormBuilder = useCallback(() => {
        const initialValues = {
            otpNumber: '',
        };
        const validationSchema = Yup.object().shape({
            otpNumber: requiredInput,
        });
        const submitForm = async (values: any) => {
            setIsLoading(true);
            _useMultiStepContainer.setErrorDetails([]);

            await verifyOtp(values);
            await endOrder();

            setIsLoading(false);
        };

        return { initialValues, validationSchema, submitForm };
    }, [_useMultiStepContainer]);

    const verifyOtp = async (values: { otpNumber: any; otpVerify: any; }) => {
        try {
            const otpResponse: GenericResponse = await sellOrderService.verifyOtp({
                key: otpConfiguration!.Key,
                otp: values.otpNumber,
            });
            if (otpResponse?.Data) {
                if (!otpResponse.Data.IsValid) {
                    setOtpConfiguration({
                        ...otpConfiguration!,
                        RemainingSeconds: otpResponse.Data.RemainingPeriod,
                        RemainingRetries: otpResponse.Data.RemainingRetries,
                    });
                    message.warn(t('otp.invalid'));
                    setIsLoading(false);
                    return;
                } else {
                    values = {
                        ...values,
                        otpVerify: {
                            key: otpConfiguration!.Key,
                            otp: values.otpNumber,
                        },
                    };
                }
            }
        } catch (e) {
            message.warn(t('otp.invalid'));
            return;
        }
    }

    const endOrder = async () => {
        const response: GenericResponse = await deedUpgradeService.endOrder(Number(id!));

        if (response.IsSuccess) {
            setDone(true);
            _useMultiStepContainer.nextStep();
        } else {
            if (response?.ErrorDetails?.length) {
                _useMultiStepContainer.setCorrelationAndErrors(response.CorrelationId, response.ErrorDetails);
            } else if (response?.ErrorList?.length) {
                _useMultiStepContainer.setErrorDetails(response.ErrorList);
            }
        }

        if (response.Data?.Identities?.length) {
            setCoordinates(response.Data.Identities.map((identity: any) => {
                return {
                    coordinates: identity.coordinates.map(coordinate => [coordinate.longitude, coordinate.latitude].sort(MAP_LAT_LNG_SORTER))
                }
            }));
        }
    }

    const getStepper = () => {

        return [
            {
                component: (formikProps: FormikProps<FormikValues>) => (
                    <TermsAndConditions terms={terms} updateTerms={updateTerms} />
                ),
                onSubmit: reviewFormBuilder().submitForm,
                confirmButtonText: t("request-details.end-request"),
                cancelButtonText: t(t('review-buying-request.back')),
                disableNextOnInvalid: true,
                customBackAction: () => navigate(-1),
            },
            {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return <>
                        {otpConfiguration ?
                            <OtpForm
                                otpConfiguration={otpConfiguration}
                                onRetry={() => postAccept(true)}
                                {...formikProps}
                            />
                            : <></>}
                        {coordinates?.length > 0 ? <Card>
                            <RealStateMapAdvanced
                                reIdentityPoints={coordinates}
                                callMap={false}
                                setCallMap={() => { }}
                                setCallSearchByIdentity={() => { }}
                                callSearchByIdentity={false}
                                showModal={() => { }}
                                allowUpdateCoordinates={false}
                                realestateIdentityError={""}
                                blinkProperties={true}
                                checkOverlapByIdentity={false}
                            />
                        </Card> : <></>}
                    </>
                },
                onSubmit: otpFormBuilder().submitForm,
                initialValues: otpFormBuilder().initialValues,
                values: otpFormBuilder().initialValues,
                validationSchema: otpFormBuilder().validationSchema,
                customBackAction: () => _useMultiStepContainer.currentStep !== 0 ? _useMultiStepContainer.previousStep() : navigate(-1),
                confirmButtonText: t('vat.ok'),
                cancelButtonText: t(t('shared.back')),
                disableNextOnInvalid: true,
            },
            {
                component: () => {
                    return (
                        <>
                            <DURequestSuccess
                                status={"success"}
                                title={"تمً انهاء الطلب بنجاح"}
                                backAction={() => navigate(-1)}
                                okAction={() => navigate(-1)}
                            >
                                {coordinates?.length > 0 ? <Card>
                                    <RealStateMapAdvanced
                                        reIdentityPoints={coordinates}
                                        callMap={false}
                                        setCallMap={() => { }}
                                        setCallSearchByIdentity={() => { }}
                                        callSearchByIdentity={false}
                                        showModal={() => { }}
                                        allowUpdateCoordinates={false}
                                        realestateIdentityError={""}
                                        blinkProperties={true}
                                        checkOverlapByIdentity={false}
                                    /></Card> : <></>}
                            </DURequestSuccess>
                        </>
                    );
                },
                confirmButtonText: t('vat.ok'),
                cancelButtonText: t(t('shared.back')),
            },
        ];
    };

    const stepper = getStepper();

    useEffect(() => {
        _useMultiStepContainer.setSteps(stepper.length);
        terms.footerTerms();
    }, []);

    return (
        <>
            <MojPopup
                title={t("request-details.end-request")}
                open={isConfirmModalOpen}
                onCancel={() => setIsConfirmModalOpen(false)}
                closable={false}
                maskClosable={false}
                footer={
                    <Card>
                        <Row gutter={[16, 0]} justify="start" align="middle">
                            <Col span="12">
                                <MojButton onClick={() => setIsConfirmModalOpen(false)} color="primary">
                                    {t('shared.back')}
                                </MojButton>
                            </Col>
                            <Col span="12">
                                <MojButton onClick={postAccept} color={"danger"}>
                                    {t("request-details.end-request")}
                                </MojButton>
                            </Col>
                        </Row>
                    </Card>
                }
            >
                هل انته متاكد من انهاء الطلب
                <br />
                سوف يتم اغلاق الطلب لدى مركز تحديث الصكوك

            </MojPopup>
            <MultiStepContainer
                stepper={stepper}
                stepperHeader={t("request-details.end-request")}
                currentStep={_useMultiStepContainer.currentStep}
                previousStep={_useMultiStepContainer.previousStep}
                isLoading={isLoading}
                errorDetails={_useMultiStepContainer.errorDetails}
                correlationId={_useMultiStepContainer.correlationId}
                hideActions={!!done}
            />
        </>
    );
};

export default observer(OrderCancel);
