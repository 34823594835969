interface DateFormat {
    year: string;
    month: string;
    day: string;
}

export function getDefaultDates(): { defaultToDate: DateFormat; defaultFromDate: DateFormat } {
    const today = new Date();

    // Create a new Date object for one month ago
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    // Create a new Date object for one week ago
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 6);

    // Format the dates in 'yyyy/mm/dd' pattern
    const formatDate = (date: Date): DateFormat => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS, hence +1
        const dd = String(date.getDate()).padStart(2, '0');
        return { year: yyyy.toString(), month: mm, day: dd };
    };

    return { defaultToDate: formatDate(today), defaultFromDate: formatDate(oneWeekAgo) };
}
