/* eslint-disable no-unused-vars */
import ncbLogo from '@/core/assets/images/bank-logos/ncb-bank-logo.svg';
// import ncbLogo from '@/assets/images/bank-logos/ncb-bank-logo.svg';
import arbLogo from '@/core/assets/images/bank-logos/arb-bank-logo.svg';
import aibLogo from '@/core/assets/images/bank-logos/aib-bank-logo.svg';
import anbLogo from '@/core/assets/images/bank-logos/anb-bank-logo.svg';
import sibLogo from '@/core/assets/images/bank-logos/sib-bank-logo.svg';
import abbLogo from '@/core/assets/images/bank-logos/abb-bank-logo.svg';
import fsbLogo from '@/core/assets/images/bank-logos/fsb-bank-logo.svg';
import sfgLogo from '@/core/assets/images/bank-logos/sfg-bank-logo.svg';
import sbbLogo from '@/core/assets/images/bank-logos/sbb-bank-logo.svg';
import rybLogo from '@/core/assets/images/bank-logos/ryb-bank-logo.svg';
import ajbLogo from '@/core/assets/images/bank-logos/ajb-bank-logo.svg';
import mnuLogo from '@/core/assets/images/bank-logos/mnu-bank-logo.svg';
import gibLogo from '@/core/assets/images/bank-logos/gib-bank-logo.svg';
import nbbLogo from '@/core/assets/images/bank-logos/nbb-bank-logo.svg';
import nbkLogo from '@/core/assets/images/bank-logos/nbk-bank-logo.svg';
import jpmLogo from '@/core/assets/images/bank-logos/jpm-bank-logo.svg';
import tzbLogo from '@/core/assets/images/bank-logos/tzb-bank-logo.svg';
import qnbLogo from '@/core/assets/images/bank-logos/qnb-bank-logo.svg';
import fabLogo from '@/core/assets/images/bank-logos/fab-bank-logo.svg';
import ccbLogo from '@/core/assets/images/bank-logos/ccb-bank-logo.svg';
import aabLogo from '@/core/assets/images/bank-logos/aab-bank-logo.svg';
import debLogo from '@/core/assets/images/bank-logos/deb-bank-logo.svg';
import ebiLogo from '@/core/assets/images/bank-logos/ebi-bank-logo.svg';
import nbpLogo from '@/core/assets/images/bank-logos/nbp-bank-logo.svg';
import sbiLogo from '@/core/assets/images/bank-logos/sbi-bank-logo.svg';

import ncbIcon from '@/core/assets/images/bank-logos/ncb-bank-icon.svg';
import arbIcon from '@/core/assets/images/bank-logos/arb-bank-icon.svg';
import aibIcon from '@/core/assets/images/bank-logos/aib-bank-icon.svg';
import anbIcon from '@/core/assets/images/bank-logos/anb-bank-icon.svg';
import sibIcon from '@/core/assets/images/bank-logos/sib-bank-icon.svg';
import abbIcon from '@/core/assets/images/bank-logos/abb-bank-icon.svg';
import fsbIcon from '@/core/assets/images/bank-logos/fsb-bank-icon.svg';
import sfgIcon from '@/core/assets/images/bank-logos/sfg-bank-icon.svg';
import sbbIcon from '@/core/assets/images/bank-logos/sbb-bank-icon.svg';
import rybIcon from '@/core/assets/images/bank-logos/ryb-bank-icon.svg';
import ajbIcon from '@/core/assets/images/bank-logos/ajb-bank-icon.svg';
import mnuIcon from '@/core/assets/images/bank-logos/mnu-bank-icon.svg';
import gibIcon from '@/core/assets/images/bank-logos/gib-bank-icon.svg';
import nbbIcon from '@/core/assets/images/bank-logos/nbb-bank-icon.svg';
import nbkIcon from '@/core/assets/images/bank-logos/nbk-bank-icon.svg';
import jpmIcon from '@/core/assets/images/bank-logos/jpm-bank-icon.svg';
import tzbIcon from '@/core/assets/images/bank-logos/tzb-bank-icon.svg';
import qnbIcon from '@/core/assets/images/bank-logos/qnb-bank-icon.svg';
import fabIcon from '@/core/assets/images/bank-logos/fab-bank-icon.svg';
import ccbIcon from '@/core/assets/images/bank-logos/ccb-bank-icon.svg';
import aabIcon from '@/core/assets/images/bank-logos/aab-bank-icon.svg';
import debIcon from '@/core/assets/images/bank-logos/deb-bank-icon.svg';
import ebiIcon from '@/core/assets/images/bank-logos/ebi-bank-icon.svg';
import nbpIcon from '@/core/assets/images/bank-logos/nbp-bank-icon.svg';
import sbiIcon from '@/core/assets/images/bank-logos/sbi-bank-icon.svg';

import { BanksCodes, BanksNames } from './banks.enum';

const BANK_LOGOS = [
    { bankCode: BanksCodes.AIB, logo: aibLogo, icon: aibIcon, name: BanksNames[BanksCodes.AIB] },
    { bankCode: BanksCodes.NCB, logo: ncbLogo, icon: ncbIcon, name: BanksNames[BanksCodes.NCB] },
    { bankCode: BanksCodes.ABB, logo: abbLogo, icon: abbIcon, name: BanksNames[BanksCodes.ABB] },
    { bankCode: BanksCodes.RYB, logo: rybLogo, icon: rybIcon, name: BanksNames[BanksCodes.RYB] },
    { bankCode: BanksCodes.SFG, logo: sfgLogo, icon: sfgIcon, name: BanksNames[BanksCodes.SFG] },
    { bankCode: BanksCodes.SBB, logo: sbbLogo, icon: sbbIcon, name: BanksNames[BanksCodes.SBB] },
    { bankCode: BanksCodes.AJB, logo: ajbLogo, icon: ajbIcon, name: BanksNames[BanksCodes.AJB] },
    { bankCode: BanksCodes.SIB, logo: sibLogo, icon: sibIcon, name: BanksNames[BanksCodes.SIB] },
    { bankCode: BanksCodes.ARB, logo: arbLogo, icon: arbIcon, name: BanksNames[BanksCodes.ARB] },
    { bankCode: BanksCodes.ANB, logo: anbLogo, icon: anbIcon, name: BanksNames[BanksCodes.ANB] },
    { bankCode: BanksCodes.FSB, logo: fsbLogo, icon: fsbIcon, name: BanksNames[BanksCodes.FSB] },

    { bankCode: BanksCodes.GIB, logo: gibLogo, icon: gibIcon, name: BanksNames[BanksCodes.GIB] },
    { bankCode: BanksCodes.MNU, logo: mnuLogo, icon: mnuIcon, name: BanksNames[BanksCodes.MNU] },
    { bankCode: BanksCodes.NBB, logo: nbbLogo, icon: nbbIcon, name: BanksNames[BanksCodes.NBB] },
    { bankCode: BanksCodes.NBK, logo: nbkLogo, icon: nbkIcon, name: BanksNames[BanksCodes.NBK] },
    { bankCode: BanksCodes.JPM, logo: jpmLogo, icon: jpmIcon, name: BanksNames[BanksCodes.JPM] },
    { bankCode: BanksCodes.TZB, logo: tzbLogo, icon: tzbIcon, name: BanksNames[BanksCodes.TZB] },
    { bankCode: BanksCodes.QNB, logo: qnbLogo, icon: qnbIcon, name: BanksNames[BanksCodes.QNB] },
    { bankCode: BanksCodes.FAB, logo: fabLogo, icon: fabIcon, name: BanksNames[BanksCodes.FAB] },
    { bankCode: BanksCodes.CCB, logo: ccbLogo, icon: ccbIcon, name: BanksNames[BanksCodes.CCB] },
    { bankCode: BanksCodes.AAB, logo: aabLogo, icon: aabIcon, name: BanksNames[BanksCodes.AAB] },
    { bankCode: BanksCodes.DEB, logo: debLogo, icon: debIcon, name: BanksNames[BanksCodes.DEB] },
    { bankCode: BanksCodes.EBI, logo: ebiLogo, icon: ebiIcon, name: BanksNames[BanksCodes.EBI] },
    { bankCode: BanksCodes.NBP, logo: nbpLogo, icon: nbpIcon, name: BanksNames[BanksCodes.NBP] },
    { bankCode: BanksCodes.SBI, logo: sbiLogo, icon: sbiIcon, name: BanksNames[BanksCodes.SBI] },
];

export default BANK_LOGOS;
