import { createClient } from '@common/services/index';
import { AxiosInstance } from 'axios';

class UserProfileService {
    HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_PROFILE_API_URL}api/v1/UserProfile`);
    }

    getUserProfileInfo = async (ignoreDuplicate = false) => {
        return this.HttpClient.get(`/GetUserProfile`, {
            headers: {
                ignoreDuplicate: ignoreDuplicate ? '1' : '',
            },
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    updatePurchaseRequestFlag = async ({
        notReceivingPurchaseRequestsNotifications,
    }: {
        notReceivingPurchaseRequestsNotifications: boolean;
    }) => {
        return this.HttpClient.post(`/UpdateExistingUserOptions`, { notReceivingPurchaseRequestsNotifications })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    updateDob = async (payload) => {
        return this.HttpClient.post(`/UpdateOrCreateListPerson`, payload)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    sendOTP = async (phoneNumber: string) => {
        phoneNumber = phoneNumber.startsWith('0') ? phoneNumber : `0${phoneNumber}`;
        return this.HttpClient.post(`/GetPhoneNumberOtp`, { mobile: phoneNumber })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    updateUserIbanStatus = async (iban: string, isActive: boolean) => {
        return this.HttpClient.post(`/ChangeUserIbanStatus`, { iban, isActive })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    confirmOTP = async (otp: string, mobile: string) => {
        mobile = mobile.startsWith('0') ? mobile : `0${mobile}`;
        return this.HttpClient.post(`/VerifyPhoneNumberOtp`, { mobile, otp })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    addIban = async (iban: string) => {
        return this.HttpClient.post(`/AddIban`, iban)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    updateEmail = async (email: string) => {
        return this.HttpClient.post(`/UpdateExistingUserContactInfo`, { email })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    createUserProfile = async () => {
        return this.HttpClient.post(`/CreateUserProfile`, {})
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    updateListPerson = async (
        personData: {
            identityNo: string;
            dateOfBirthHijri: string;
        }[]
    ) => {
        return this.HttpClient.post(`/UpdateListPerson`, personData)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };
}

const instance = new UserProfileService();

export default instance;
