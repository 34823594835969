import { useState } from 'react';
import {ErrorDetails} from "@common/models/GenericResponse";
import {errorsListToErrorDetails} from "@common/utils/deed-utils";

function useMultiStepContainer(isModal = false) {
    const [steps, setSteps] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [previousStep, setPreviousStep] = useState(0);
    const [errorList, setErrorList] = useState<string[]>([]);
    const [errorDetails, setErrorDetails] = useState<ErrorDetails[]>([]);
    const [correlationId, setCorrelationId] = useState<string>('');

    return {
        currentStep: currentStep,
        errorList: errorList,
        errorDetails: errorDetails,
        correlationId,
        setCurrentStep: (step) => {
            setPreviousStep(currentStep);
            setCurrentStep(step)
        },
        goBackDeep: () => {
            setCurrentStep(previousStep);
            setPreviousStep(previousStep - 1);
        },
        nextStep: () => {
            if (currentStep < steps - 1) {
                !isModal && window.scrollTo(0, 0);
                setErrorList([]);
                setErrorDetails([]);
                setCurrentStep(currentStep + 1);
            }
        },
        previousStep: () => {
            !isModal && window.scrollTo(0, 0);
            if (currentStep >= 0) {
                setErrorList([]);
                setErrorDetails([]);
                setCurrentStep(currentStep - 1);
            }
        },
        setStepByIndex: (stepIndex) => {
            setErrorList([]);
            setErrorDetails([]);
            setCurrentStep(stepIndex);
            !isModal && window.scrollTo(0, 0);
        },
        setSteps: (_steps: number) => {
            setSteps(_steps);
        },
        setErrorList: (_errorList: string[]) => {
            setErrorList(_errorList);
        },
        setErrorDetails: (_errorDetails?: any[]) => {
            if(!_errorDetails || _errorDetails.length === 0) {
                setErrorDetails([]);
            } else {
                if(typeof _errorDetails[0] === "string") {
                    setErrorDetails(errorsListToErrorDetails(_errorDetails));
                } else {
                    setErrorDetails(_errorDetails);
                }
            }
        },
        setCorrelationId: (_correlationId: string) => {
            setCorrelationId(_correlationId);
        },
        setCorrelationAndErrors(_correlationId: string, _errorDetails?: any[]) {
            this.setErrorDetails(_errorDetails);
            this.setCorrelationId(_correlationId);
        },
        steps:steps
    };
}

export default useMultiStepContainer;
