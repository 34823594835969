export enum OtpRequestType {
    DEAL = 1,
    GIFT = 2,
    EPTM = 3,
    OPENMARKET = 4,
    PURCHASEORDER = 5,
    EPT3M = 6,
    EPM_RELEASE = 7,
    EPM_CREATE = 8,
    EPM_TRANSFER = 9,
    ACCEPT_EPU_MORTGAGE = 12,
    OWNERSHIP_DEED_SEPARATION = 15,
    REALESTATE_DEED_SEPARATION = 16,
    UPDATE_MORTGAGE_AMOUNT = 17,
    MERGE_SHARED_REALSTATES = 18,
    GPT3M = 19,
    REGISTER_REALSTATES = 21,
    DU_EDIT_REQUEST = 22,
    DeedUpgrade = 27,

    GPT3M_RELEASE = 28,
    AUCTION = 30,
    //[Description("فرز المخططات")]
    SPLIT_REAL_ESTATES = 31,
    //[Description("تجزئة الاراضي")]
    SPLIT_REAL_ESTATES2 = 32,
    //[Description("دمج الاراضي")]
    MERGE_DEED_REAL_ESTATES = 33,

    CONTACT_OWNER = 34,
    //[Description("صفقة خاصة جهة خارجية")]
    PRIVATE_DEAL_3RD_PARTY = 24,
    //[Description("إفراغ بتمويل جهة خارجية")]
    EPT3M_3RD_PARTY = 23,

    //[Description("رهن جهة خارجية")]
    CREATE_MORTGAGE_ThirdParty = 29,
}
