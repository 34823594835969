import React, { PropsWithChildren } from 'react';
import { Modal, ModalProps } from 'antd';
import clsx from 'clsx';
import './MojPopup.scss';
import { ReactComponent as CloseIcon } from '@/core/assets/images/clear-text-normal.svg';

export function MojPopup({
    className,
    title,
    open,
    children,
    footer,
    ...props
}: PropsWithChildren<ModalProps>) {
    return (
        <Modal
            closeIcon={<CloseIcon />}
            className={clsx('moj-modal', className)}
            title={title}
            open={open}
            footer={footer}
            {...props}
        >
            {children}
        </Modal>
    );
}
