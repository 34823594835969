import React from 'react';
import clsx from 'clsx';

import { NavbarOption } from './NavbarOption';
import { NavbarSubOption } from './NavbarSubOption';
import { Card } from '../../shared';

type NavbarProps = {
    options: Array<{
        title: string;
        link: string;
        Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        FixedIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        suffix?: string;
        onClick?: () => void;
        subOptions?: Array<{
            title: string;
            link: string;
            disabled?: boolean;
            FixedIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
            suffix?: React.ReactNode;
        }>;
    }>;
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
};

export const Navbar: React.FC<NavbarProps> = ({ options, isExpanded, setIsExpanded }) => {
    return (
        <Card
            className={clsx('mobile-header__navbar', {
                'mobile-header__navbar--hidden': !isExpanded,
            })}
            noPadding
        >
            {options.map((option, index) => {
                const handleOptionClick = option.subOptions ? () => { } : option.onClick;
                return (
                    <NavbarOption
                        key={`${option.title}-${index}`}
                        title={option.title}
                        link={option.link}
                        FixedIcon={option.FixedIcon}
                        onClick={handleOptionClick}
                        suffix={option.suffix}
                    >
                        {option.subOptions?.map((subOption, subIndex) => (
                            <NavbarSubOption
                                key={`${subOption.title}-${index}`}
                                onClick={() => setIsExpanded(false)}
                                title={subOption.title}
                                link={subOption.disabled ? "#" : subOption.link}
                                disabled={subOption.disabled}
                                FixedIcon={subOption.FixedIcon}
                                suffix={subOption.suffix}
                                noBorder={subIndex === option?.subOptions!.length - 1}
                            />
                        ))}
                    </NavbarOption>
                );
            })}
        </Card>
    );
};
