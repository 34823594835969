import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import ReactDOMServer from 'react-dom/server';
import { Col, ConfigProvider, Empty, Row } from 'antd';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import {
    getReadableNumberWithFormat,
    getReadableNumberWithoutFormat,
    getReadablePrice,
} from '@common/utils/numbers-utils';
import { ReadOnlyField } from '@core/components/shared';
import { dashboardStore } from '@dashboard/screens/SremHome/store/dashboardStore';
import { PeriodCategory } from '@/common/utils/temp.utils';

export const dollarUSLocale = Intl.NumberFormat('en-US');

enum PeriodGap {
    W = 1,
    M = 7,
    Y = 30,
    A = 30,
}

interface OwnProps {
    timeFrame: string;
}

type Props = OwnProps;

const LineColumnChart: FunctionComponent<Props> = (props) => {
    const [maxAxisVisible, setMaxAxisVisible] = useState(true);
    const [minAxisVisible, setMinAxisVisible] = useState(true);

    const highChartRef = useRef<HighchartsReact.RefObject>(null);
    const vmIndexes = useMemo(() => [...dashboardStore.vmIndexes], [dashboardStore.vmIndexes]);

    const seriesData = vmIndexes.map((item, i) => ({
        calcDate: dayjs(item.CalcDate).format('DD-MM-YYYY'), // Convert date to timestamp
        mv: item.MarketValue,
        mi: item.MarketIndex,
        miChange: item.MarketIndexChange,
        mvChange: item.MarketValueChange,
    }));

    const formatxAxis = (timeFrame) => {
        return vmIndexes.map((item) => {
            if (timeFrame === PeriodCategory.Year || timeFrame === PeriodCategory.AllTime) {
                return dayjs(item.CalcDate).format('MMM YYYY');
            } else {
                return dayjs(item.CalcDate).format('DD MMM');
            }
        });
    }

    const tickPositions = dashboardStore.vmIndexes
        .map((entry, index) => {
            // Calculate the tick position for every three days
            if (index % PeriodGap[props.timeFrame] === 0) {
                return index;
            }
            return null;
        })
        .filter((position) => position !== null);

    useEffect(() => {
        if (highChartRef.current) {
            highChartRef.current.chart.redraw();
        }
    }, [maxAxisVisible, minAxisVisible]);

    const options = {
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
              animation: false, // Disable animation for all series
            },
          },
        chart: {
            zoomType: 'x',
            renderTo: 'container',
            type: 'line',
            direction: 'rtl',
            scrollablePlotArea: {
                scrollPositionX: 1,
            },
            style: {
                fontFamily: 'Tajawal',
            },
            animation: false,
        },
        colors: ['#00ad68', '#f6b221'],
        title: {
            text: '',
            direction: 'rtl',
            useHTML: true,
        },
        xAxis: [{
            type: 'datetime',
            direction: 'rtl',
            useHTML: true,
            reversed: true,
            categories: formatxAxis(props.timeFrame),
            crosshair: true,
            tickPositioner: function () {
                return tickPositions;
            }
        }],
        yAxis: [{
            labels: {
                formatter: function (this: { value: number }) {
                    // Format the yAxis label using the custom formatter
                    return dollarUSLocale.format(this.value);
                },
            },
            direction: 'rtl',
            title: {
                text: null,
            },
            style: {
                direction: 'rtl',
            },
            opposite: true,
        }],
        tooltip: {
            rtl: true,
            reversed: false,
            useHTML: true,
            crosshairs: false,
            shared: true,
            formatter: formatter,
        },
        series: [
            {
                name: 'مؤشر البورصة العقارية اليومي',
                type: 'spline',
                data: seriesData.map((item) => [+item.mi]),
            },
        ],
        responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 768, // Adjust this value based on your design requirements
                },
                chartOptions: {
                  xAxis: [
                    {
                        labels: {
                        rotation: 45,
                      },
                    },
                  ],
                },
              },
            ],
        },
    };

    function formatter() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const points = this.points;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const _point = points[0];
        const index = _point.point.index;
        const stat = dashboardStore.vmIndexes[index];
 
        return ReactDOMServer.renderToString(
            <Col>
                <Row>
                    <Col span={24}>
                        <p>{stat?.CalcDate}</p>
                    </Col>

                    <Col span={24}>
                        <ReadOnlyField
                            noBorder
                            label={'القيمة السوقية'}
                            title={getReadablePrice(stat?.MarketValue, true)}
                        />
                    </Col>
                    <Col span={24}>
                        <ReadOnlyField
                            noBorder
                            label={'مؤشر البورصة العقارية اليومي'}
                            title={getReadablePrice(stat?.MarketIndex, true)}
                        />
                    </Col>
                    <Col span={24}>
                        <ReadOnlyField noBorder label={'معدل تغيير مؤشر البورصة العقارية اليومي'} title={''}>
                            <div
                                className={clsx('percentage', {
                                    'percentage--primary': stat?.MarketIndexChange === 0,
                                    'percentage--success': stat?.MarketIndexChange!! > 0,
                                    'percentage--danger': stat?.MarketIndexChange!! < 0,
                                })}
                            >
                                {stat?.MarketIndexChange === 0
                                    ? '0'
                                    : getReadableNumberWithoutFormat(stat?.MarketIndexChange)}
                            </div>
                        </ReadOnlyField>
                    </Col>
                    <Col span={24}>
                        <ReadOnlyField noBorder label={'معدل تغيير القيمة السوقية'} title={''}>
                            <div
                                className={clsx('percentage', {
                                    'percentage--primary': stat?.MarketValueChange === 0,
                                    'percentage--success': stat?.MarketValueChange!! > 0,
                                    'percentage--danger': stat?.MarketValueChange!! < 0,
                                })}
                            >
                                {stat?.MarketValueChange === 0
                                    ? '0'
                                    : getReadableNumberWithFormat(stat?.MarketValueChange)}
                            </div>
                        </ReadOnlyField>
                    </Col>
                </Row>
            </Col>
        );
    }

    return (
        <>
            <ConfigProvider direction="rtl">
                {dashboardStore.vmIndexes.length > 0 ? (
                    <HighchartsReact highcharts={Highcharts} options={options} ref={highChartRef} />
                ) : (
                    <Empty description={''} style={{ minHeight: '400px', minWidth: 'available', paddingTop: '8em' }} />
                )}
            </ConfigProvider>
        </>
    );
};

export default observer(LineColumnChart);
