import { Checkbox, Col, Divider, Radio, RadioChangeEvent, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import constructionLicenseHint from "@core/assets/images/construction-license-hint.png";
import MojLabel, { LabelVariant } from "@/core/components/shared/Label/MojLabel";
import { IUserInput } from "@/modules/edit-deed/models/edit.deed.models";
import { convertToEnglishNumbers } from "@/common/utils/numbers-utils";
import { MojInput } from "@/core/components/shared/MojInput/MojInput";
import { arabicToEnglishMap } from "@/common/utils/constants";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Card, MojButton } from "@core/components/shared";
import AttachmentDto from "../UploadFile/AttachmentDto";
import { useHint } from "@/common/hooks";
import "./SelectEditFields.scss";
import { MojCheckbox } from "@/core/components/shared/Checkbox/MojCheckbox";

export interface onChangeEditFieldsProps {
    fields: number[],
    reason: string,
    attachment: AttachmentDto | undefined,
    dependOnRealEstateIdentityArea: boolean
}

const HINTS_BY_FIELD_ID = {
    115: {
        title: "التقرير المساحى",
        image: constructionLicenseHint
    }
}

interface IProps {
    onChange: (props: onChangeEditFieldsProps) => void;
    checkboxes: { value: number, label: string, multiSelectAllowed: boolean, isInputFieldRequired: boolean }[];
    values: number[];
    reason?: string;
    attachment?: AttachmentDto;
    handleEditUserInputs: (inputs) => void;
    inputs: IUserInput[],
    dependOnRealEstateIdentityArea: boolean;
}

const SelectEditFields = ({ onChange, checkboxes, values: defaultValues, reason = "", attachment, dependOnRealEstateIdentityArea: DependOnRealEstateIdentityArea, handleEditUserInputs, inputs }: IProps) => {
    const { t } = useTranslation();
    const [currentInput, setCurrentInput] = useState<number>();
    const [dependOnRealEstateIdentityArea, setDependOnRealEstateIdentityArea] = useState<boolean>(DependOnRealEstateIdentityArea);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(+e.target.value.trim());
    };

    const { Icon } = useHint({
        title: HINTS_BY_FIELD_ID[defaultValues[0]]?.title,
        className: "construction-license-hint-icon",
        message: <div className="hint-container">
            {HINTS_BY_FIELD_ID[defaultValues[0]]?.image && <img width={700} src={HINTS_BY_FIELD_ID[defaultValues[0]]?.image} alt="hint" />}
        </div>
    });

    const addInput = () => {
        if (currentInput) {
            handleEditUserInputs([...inputs, { type: defaultValues[0], value: currentInput, isValid: true }]);
            setCurrentInput(undefined);
        }
    };

    const deleteInput = (index: number) => {
        handleEditUserInputs(inputs.filter((_, i) => i !== index));
    };

    const onDependOnRealEstateIdentityAreaChange = useCallback((flag: boolean) => {
        setDependOnRealEstateIdentityArea(flag);
        onChange({
            reason,
            attachment,
            fields: defaultValues || [],
            dependOnRealEstateIdentityArea: flag
        });
    }, [attachment, defaultValues, onChange, reason]);

    const onCheckboxesChange = useCallback((values: CheckboxValueType[]) => {
        let defaultValuesCopy = [...(defaultValues || [])];
        const targetSet = values;
        const valuesToAdd = targetSet.filter(item => !defaultValuesCopy.includes(+item));
        const valuesToRemove = defaultValuesCopy.filter(item => !targetSet.includes(item));
        defaultValuesCopy = [...defaultValuesCopy.filter(item => targetSet.includes(item) || !valuesToRemove.includes(item)), ...valuesToAdd as number[]];

        onChange({
            reason,
            attachment,
            fields: defaultValuesCopy as number[],
            dependOnRealEstateIdentityArea
        });
    }, [reason, attachment, defaultValues, dependOnRealEstateIdentityArea, onChange]);

    const onRadioChange = useCallback((e: RadioChangeEvent) => {
        const value = e.target.value;
        // incase user clicked on the radio button reset all checkboxes
        onChange({
            reason,
            attachment,
            fields: value ? [value] : [],
            dependOnRealEstateIdentityArea
        });
    }, [attachment, onChange, reason, dependOnRealEstateIdentityArea]);

    // find isInputFieldRequired is true in any checkbox
    const isInputFieldRequired = checkboxes?.find(_item => _item.isInputFieldRequired);

    const getLabelByValue = (input: number) => checkboxes?.find(_item => _item.value === input)?.label;

    return (
        <div className="section select-edit-fields">
            <Card>
                <Card.Head className="section__title no-border">{t("edit-deed.fields-to-modify")}</Card.Head>
                <Card.Body>
                    <div className="section__title no-border checkbox-header">
                        <div className="checkbox-container">
                            {checkboxes && checkboxes[0]?.multiSelectAllowed ?
                                <Checkbox.Group value={defaultValues} onChange={(values) => onCheckboxesChange(values)}>
                                    {checkboxes.map((item, index) => <Checkbox key={`checkbox-${index}`} value={item.value}>{item.label}</Checkbox>)}
                                </Checkbox.Group>
                                : <Radio.Group onChange={(e) => onRadioChange(e)}>
                                    {checkboxes.map((item, index) => <Radio key={`checkbox-${index}`} value={item.value}>
                                        {item.label} {HINTS_BY_FIELD_ID[item.value]?.title ? <Icon /> : <></>}
                                    </Radio>)}
                                </Radio.Group>
                            }
                        </div>
                    </div>

                    {isInputFieldRequired && defaultValues.length ? (
                        <div className="input-container">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <MojInput
                                        value={currentInput}
                                        inputMode={'numeric'}
                                        placeholder={`أدخل ${getLabelByValue(defaultValues[0])}`}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key) && !arabicToEnglishMap[event.key]) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(event) => {
                                            if (event.target.value) {
                                                event.target.value = convertToEnglishNumbers(event.target.value);
                                                handleInputChange(event);
                                            }
                                        }}
                                        maxLength={16}
                                    />
                                </Col>
                                <Col span={4}>
                                    <MojButton onClick={addInput} color="primary">أضف</MojButton>
                                </Col>
                                <Col span={24}>
                                    <Divider />
                                    {inputs && inputs.map((input, index) => (
                                        <div className="label-container" key={index}>
                                            <MojLabel color={input.isValid ? LabelVariant.draft : LabelVariant.danger}>{input.value}</MojLabel>
                                            <button className="delete-btn" onClick={() => deleteInput(index)}>x</button>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <MojCheckbox onChange={e => onDependOnRealEstateIdentityAreaChange(!!e.target.checked)} defaultChecked={dependOnRealEstateIdentityArea} value={dependOnRealEstateIdentityArea}>{t("edit-deed.depend-on-re-identity-area")}</MojCheckbox>
                            </Row>
                        </div>
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    )
};

export default SelectEditFields;
