import { Col, Radio, RadioChangeEvent } from "antd";
import { Card } from "@/core/components/shared";

type Props = {
    onRadioChange: (e: RadioChangeEvent) => void;
    group: any[];
}

const EditFieldsForm = ({ onRadioChange, group }: Props) => {
    return (
        <Card>
            <Card.Head className="section__title border-bottom">
                <Col flex="1 1 auto">{'اختر نوع التعديل'}</Col>
            </Card.Head>
            <Card.Body className="section__basics">
                <Radio.Group onChange={onRadioChange}>
                    {group.map((item, index) => (
                        <Radio value={item.Id} key={index}>{item.GroupName}</Radio>
                    ))}
                </Radio.Group>
            </Card.Body>
        </Card>
    )
}

export default EditFieldsForm;
