import axios from 'axios';
import React from 'react';
import { attorneyStore } from '@common/stores/attorney.store';
import GenericResponse from '@common/models/GenericResponse';
import { authStore } from '@common/stores/auth.store';

let isAjaxInProgress = false;
const requestMap = new Map();
const CALL_DEBOUNCE_DURATION = 1000;
const ATTORNEY_TOKEN_HEADER = 'attorney-token';
const SKIP_ATTORENY_PATHS = [
    'Attorney/MyAttornies',
    'Attorney/AttorneyIbans',
    'Attorney/AddPersonalAttorney',
    'Attorney/AddOrganizationAttorney',
    'Attorney/AddAttorneyIban',
    'Attorney/ChangeAttorneyIban',
    'Delegation/InitiateSession',
    '/GetUserProfile',
];

// Array of URLs to skip token
const skipTokenForAnonymous = [
    'DeedInquiry/AnonymousGetDeedByNumber',
    'DeedInquiry/AnonymousGetDeedByOwner',
    'DeedInquiry/AnonymousGetDeedByRealEstateIdentityAndIdNumber',
    'Dashboard/GetAreaInfo',
    'Dashboard/GetMarketIndex',
    'Dashboard/GetTrendingDistricts',
    'MobileFeature/GetMaintenanceSchedules',
];

export function createClient(baseURL: string) {
    const instance = axios.create({
        baseURL: baseURL,
        headers: { 'Content-Type': 'application/json' },
    });

    instance.interceptors.request.use(
        function (config) {
            config.headers = {
                ...config.headers,
            };

            // Check if the request URL is in the skip list
            if (!skipTokenForAnonymous.includes(config.url!)) {
                config.headers = {
                    ...config.headers,
                    Authorization: `${authStore.user?.token_type} ${authStore.user?.access_token}`,
                };
            }
            const attorneyToken = sessionStorage.getItem(ATTORNEY_TOKEN_HEADER);
            if (
                !!attorneyToken &&
                SKIP_ATTORENY_PATHS.filter((url) => config.url && config.url.startsWith(url)).length === 0
            ) {
                config.headers = {
                    ...config.headers,
                    [ATTORNEY_TOKEN_HEADER]: attorneyToken,
                };
            }

            isAjaxInProgress = true;

            if (!config.headers.ignoreDuplicate) {
                if (requestMap.has(`${config.baseURL}${config.url}`)) {
                    const previousCall = requestMap.get(`${config.baseURL}${config.url}`);
                    if (Date.now() - CALL_DEBOUNCE_DURATION < previousCall) {
                        throw new axios.Cancel(`A call is already in progress at url: ${config.baseURL}${config.url}`);
                    }
                } else {
                    requestMap.set(`${config.baseURL}${config.url}`, Date.now());
                }
            }

            return config;
        },
        function (error) {
            isAjaxInProgress = false;
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        function (response) {
            isAjaxInProgress = false;
            return response.data as GenericResponse;
        },
        function (error) {
            isAjaxInProgress = false;
            try {
                switch (error.response.status) {
                    case 500:
                        return Promise.reject(error.response);

                    case 403:
                        return Promise.reject(error.response);

                    case 400:
                        return Promise.reject(error.response.data);

                    case 401: {
                        const err = error.response;
                        authStore.loadUser().then((r) => {
                            if (r === null) {
                                return Promise.reject({
                                    error,
                                    IsSuccess: false,
                                    ErrorList: ['الجلسة قد انتهت'],
                                });
                            } else {
                                try {
                                    if (err.data?.Data.IsValidAttorney === false) {
                                        attorneyStore.endAttorneySession();
                                    }
                                } catch {}
                            }
                        });
                        return Promise.reject({
                            error,
                            IsSuccess: false,
                            ErrorList: ['الجلسة قد انتهت'],
                        });
                    }

                    default:
                        return Promise.reject({
                            error,
                            isSuccess: false,
                            errorList: ['خطأ غير متوقع'],
                        });
                }
            } catch (e) {
                if (!(e instanceof TypeError)) {
                    return Promise.reject(error);
                }
            }
        }
    );

    return instance;
}

export const useLoader = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const intervalId = setInterval(() => {
        if (isAjaxInProgress !== isLoading) {
            setIsLoading(isAjaxInProgress);
        }
    }, 500);

    React.useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return isLoading;
};
