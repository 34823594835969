import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BankLogo,
    BankLogoVariants,
    ReadOnlyField,
    ReadOnlyFieldBorderVariant,
    ReadOnlyFieldVariant,
} from '@core/components/shared';
import { ReactComponent as EditIcon } from '@core/assets/images/assets-icons-edit.svg';
import { ReactComponent as RemoveIcon } from '@core/assets/images/assets-icons-remove.svg';
import './AddedBankAccount.scss';
import clsx from 'clsx';
import { getBankLogo } from '@common/utils/bank-logo.util/bank-logos.util';
import { useCopyText } from "@common/hooks";

export default function AddedBankAccount({
    iban,
    bankLogoVariant,
    hasBorder,
    hideActions,
    noBorder,
    onEdit,
    onDelete,
    label,
    isVirtualIban,
    children,
    isRefund
}: PropsWithChildren<{
    iban: string;
    label?: string;
    bankLogoVariant?: BankLogoVariants;
    onEdit?: (bankId: string) => void;
    onDelete?: (bankId: string) => void;
    hasBorder?: boolean;
    hideActions?: boolean;
    noBorder?: boolean;
    isVirtualIban?: boolean;
    isRefund?: boolean;
}>) {
    const { t } = useTranslation();
    const { Icon } = useCopyText({
        text: iban!.toString(),
        successMessage: t('shared.iban-number-copied'),
    });
    return (
        <div
            className={clsx('added-bank-account', {
                'added-bank-account--has-border': hasBorder,
            })}
        >
            <div className="added-bank-account__iban">
                <ReadOnlyField
                    label={label ? label : isRefund ? t('refund-management.previousStoredIban') : (isVirtualIban ? t('deal.deal-iban') : t('profile.added-accounts.iban-number'))}
                    title={iban}
                    noBorder={noBorder}
                    variant={ReadOnlyFieldVariant.primary}
                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                    small
                />
                <Icon className="copy" />
            </div>
            <div className="added-bank-account__bank-logo">
                {bankLogoVariant ? (
                    <BankLogo variant={bankLogoVariant} />
                ) : (
                    <img className="logo-image" src={getBankLogo(iban)} alt="" />
                )}
                {children}
            </div>
            {!hideActions && (
                <div className="added-bank-account__actions">
                    <div className="edit-icon">
                        <EditIcon />
                    </div>
                    <div className="remove-icon">
                        <RemoveIcon />
                    </div>
                </div>
            )}
        </div>
    );
}
