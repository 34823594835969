import { action, makeObservable, observable } from 'mobx';
import SessionStore from '@common/stores/session.store';
import { IDeed } from '@common/models/Deed';
import { RequestInformation } from '@common/models/RequestInformation';
import { Mortgagee } from '@common/models/Mortgagee';
import { Mortgagor } from '@common/models/ Mortgagor';

export default class UpdateMortgageAmountStore extends SessionStore {
    @observable
    currentStep!: number;

    @observable
    steps!: number;

    @observable
    deeds: IDeed[] = [];

    @observable
    totalDeeds: number = 0;

    constructor() {
        super();

        makeObservable(this);

        this.currentStep = 0;
    }

    @observable
    deed!: IDeed;

    @observable
    requestInfo!: RequestInformation;

    @observable
    mortgagee!: Mortgagee;

    @observable
    mortgagor!: Mortgagor;

    @action
    getSelectedDeed(selectedDeedSerial: number): IDeed | undefined {
        return this.deeds.find((deed) => deed.DeedSerial === selectedDeedSerial);
    }

    @action.bound
    nextStep = () => {
        if (this.currentStep < this.steps - 1) this.currentStep++;
    };

    @action.bound
    previousStep = () => {
        if (this.currentStep >= 0) this.currentStep--;
    };

    @action
    setDeed = (deed: IDeed) => {
        this.deed = deed;
        this.nextStep();
        this.setLoading(false);
    };

    @action
    getDeed = () => {
        return this.deed;
    };

    @action
    setDeeds = (deeds: IDeed[]) => {
        this.deeds = deeds;
        this.setLoading(false);
    };

    @action
    setTotalDeeds = (totalDeeds: number) => {
        this.totalDeeds = totalDeeds;
    };

    @action
    setSteps = (length: number) => {
        this.steps = length;
    };

    @action
    updateDeedAmount = (amount: number) => {
        if (this.deed?.DeedMortgagee !== undefined) {
            this.deed.DeedMortgagee.NewMortgageAmount = amount;
        }
    };

    @action
    updateLastInstalmentDate = (date: string) => {
        if (this.deed?.DeedMortgagee !== undefined) {
            this.deed.DeedMortgagee.NewLastInstalmentDate = date;
        }
    };
}

export const updateMortgageAmountStore = new UpdateMortgageAmountStore();
