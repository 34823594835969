import { useEffect, useMemo, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Card, MojAccordion } from '@/core/components/shared';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import { commonService } from "@common/services/common.service";
import "./_SremFAQ.scss";

type IFAQs = {
    QuestionId: number;
    Question: string;
    CategoryId: number;
    CategoryName: string;
    Answer: string;
};

const SremFAQ = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [FAQs, setFAQs] = useState<IFAQs[] | undefined>();
    const [categories, setCategories] = useState<any []>([]);

    const isMobile = useMemo(() => {
        return isMobileOrTabletDevice();
    }, []);

    useEffect(() => {
        async function fetchFaqs() {
            setIsLoading(true);
            try {
                const response: any = await commonService.getFaqs();
                setIsLoading(false);
    
                if (response?.Data) {
                    setFAQs(response.Data);
                }
            } catch (error) {
                console.error('Error fetching FAQs:', error);
                setIsLoading(false);
            }
        }
    
        fetchFaqs();
    }, []);

    useEffect(() => {
        if (FAQs) {              
            const uniqueCategories = [...new Set(FAQs.map(item => JSON.stringify({ CategoryId: item.CategoryId, CategoryName: item.CategoryName })))].map(str => JSON.parse(str));
            
            setCategories(uniqueCategories);
        }
    }, [FAQs]);

    return (
        <div className="sremFAQ">
            <Card className="section__container">
                <Row gutter={0}>
                    <Col span="24">
                        <Card.Head className="section__title border-bottom">الأسئلة الشائعة</Card.Head>
                    </Col>
                    <Col span="24">
                        {!isLoading && (
                            <Tabs
                                tabPosition={isMobile ? 'top' : 'right'}
                                style={{ minHeight: '70vh', paddingBlock: 20, marginBlockEnd: 100 }}
                            >
                                <Tabs.TabPane key={'all'} tab={`الكل`}>
                                    <MojAccordion accordion expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        {FAQs?.map(item => (
                                            <MojAccordion.Item key={item.QuestionId} header={<h3 className="sremFAQ--collapse__question">{item.Question}</h3>}>
                                                <p>{item.Answer}</p>
                                            </MojAccordion.Item>
                                        ))}
                                    </MojAccordion>
                                </Tabs.TabPane>

                                {categories.map(category => (
                                    <Tabs.TabPane key={category.CategoryId} tab={category.CategoryName}>
                                        <MojAccordion accordion expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                            {FAQs?.filter(item => item.CategoryId === category.CategoryId).map(item => (
                                                <MojAccordion.Item key={item.QuestionId} header={<h3 className="sremFAQ--collapse__question">{item.Question}</h3>}>
                                                    <p>{item.Answer}</p>
                                                </MojAccordion.Item>
                                            ))}
                                        </MojAccordion>
                                    </Tabs.TabPane>
                                ))}
                            </Tabs>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default SremFAQ;
