import { useHint } from '.';
import { useTranslation } from 'react-i18next';

interface HintConfig {
    title: string;
    message: React.ReactNode;
}

interface Hint {
    Icon: React.ComponentType;
}

export function useMultipleHints(hints: HintConfig[]): Hint[] {
    const { t } = useTranslation();
    const hintComponents = hints.map((hint) => ({
        title: t(hint.title),
        message: hint.message,
    }));

    return hintComponents.map(useHint);
}
