import { DeedOwner } from '@/common/models/Deed';
import { RequestTypes, Seller } from '@/common/models/Transaction';
import { Card } from '@/core/components/shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddedBuyer } from '../AddedBuyer/AddedBuyer';
import './Owner.scss';
import { getPercentageOwnership } from '@/common/utils/constants';

export interface Props {
    owners: DeedOwner[];
    sellers?: Seller[];
    requestType?: RequestTypes;
}
export function Owner({ owners = [], sellers, requestType }: Props) {
    const { t } = useTranslation();
    const loadedOwners =
        owners.map((owner: DeedOwner) => {
            const sellingOwner = sellers?.find((x) => x.SocialId == +owner.IdNumber);
            return {
                ...owner,
                isSeller: !!sellingOwner,
                iban: sellingOwner?.Iban,
                ownershipAmount: sellingOwner?.SellingOwnership,
            };
        }) || [];

    const renderTitleByRequestType = useMemo(
        () => (requestType: RequestTypes) => {
            switch (requestType) {
                case RequestTypes.Gift:
                    if (sellers!.length > 1) {
                        return t('my-realestate.givers-details');
                    } else {
                        return t('my-realestate.giver-details');
                    }
                case RequestTypes.PrivateSell:
                case RequestTypes.Ept3m:
                    if (sellers!.length > 1) {
                        return t('my-realestate.sellers-details');
                    } else {
                        return t('my-realestate.seller-details');
                    }
                case RequestTypes.CreateMortgage:
                case RequestTypes.MortgageTransfer:
                case RequestTypes.ReleaseMortgage:
                    if (sellers!.length > 1) {
                        return t('my-realestate.mortgagors-details');
                    } else {
                        return t('my-realestate.mortgagor-details');
                    }
                default:
                    return t('my-realestate.owners-details');
            }
        },
        [requestType, sellers]
    );

    return (
        <Card className="my-realestate__buyers">
            <Card.Head className="buyers-title border-bottom">{renderTitleByRequestType(requestType!)}</Card.Head>
            <Card.Body>
                {loadedOwners.map((owner, i) => (
                    <AddedBuyer
                        key={`my-realestate-added-buyer-${owner.IdNumber}`}
                        share={owner.OwningAmount ? getPercentageOwnership(owner.OwningAmount).toString() : undefined}
                        sellingAmount={
                            owner.ownershipAmount ? `${getPercentageOwnership(owner.ownershipAmount).toString()}%` : ''
                        }
                        index={`${i + 1}`}
                        idNumber={owner.IdNumber}
                        name={owner.OwnerName}
                        last={i === loadedOwners.length - 1}
                        sellerLabel={owner.sellerType}
                        isSeller={owner.isSeller}
                        iban={owner?.iban}
                        MobileNumber={owner?.MobileNo}
                        requestType={requestType}
                        isOwner={true}
                    />
                ))}
            </Card.Body>
        </Card>
    );
}
