import React from 'react';
import './DeedPaper.scss';
import { Row } from 'antd';
import titleImage from '@/core/assets/images/title@3x.png';
import BISMILLAH from '@/core/assets/images/BISMILLAH@3x.png';
import { ReactComponent as MojLogo } from '@/core/assets/images/moj-main-logo.svg';
import { useTranslation } from 'react-i18next';

let digitalCopySvg =
    "url(\"data:image/svg+xml;utf8,<svg style='font-size:22px;font-weight:bold;' xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'>" +
    "<text transform='translate(20, 100) rotate(-45)' fill='rgb(236, 155, 33, 0.5)' font-size='22' font-family='IBM Plex Sans Arabic' font-weight='bold'>" +
    'نسخة رقمية' +
    '</text></svg>")';

export function DeedPaper({
    deedDate,
    deedNumber,
    deedText,
}: {
    deedText: string;
    deedDate?: string;
    deedNumber?: string;
}) {
    const { t } = useTranslation();
    const updatedText = deedText ?? t('shared.text-not-available');

    return (
        <div className="deed-paper border-bottom">
            <Row className="digital-copy-overlay" style={{ backgroundImage: digitalCopySvg }}>
                    <Row className="deed-paper__logos" justify="space-between" align="middle">
                        <div className="title">
                            <img src={titleImage} className="title-image" alt="moj title" />
                        </div>
                        <div className="moj-logo">
                            <div className="BISMILLAH">
                                <img className="BISMILLAH-image" src={BISMILLAH} alt="BISMILLAH" />
                            </div>
                            <div className="moj-logo-file">
                                <MojLogo className="moj-logo-file-image" />
                            </div>
                        </div>
                        {(deedDate || deedNumber) && (
                            <div className="moj-deed-info">
                                <div className="cs-deed-barcode">{deedNumber}</div>
                                <div>
                                  التاريخ {deedDate} {t('deed-details.hijri')}
                                </div>
                                <div>
                                   الرقم {deedNumber}
                                </div>
                            </div>
                        )}
                    </Row>
                <div className="deed-paper__text">{updatedText}</div>
            </Row>
        </div>
    );
}
