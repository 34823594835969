import React, { FC, lazy, Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import MainLayout from '@core/components/shared/MainLayout/MainLayout';
import { observer } from 'mobx-react-lite';
import { Spin } from 'antd';
import RequireAuth from '@common/routes/requier-auth';
import RequireProfile from '@/common/routes/require-profile';
import SremTerms from '@dashboard/screens/SremTerms/SremTerms';
import SremAbout from '@dashboard/screens/SremPolicy/SremAbout';
import SremFAQ from '@dashboard/screens/SremFAQ/SremFAQ';
import SearchDeed from '@/modules/edit-mortgage-amount/Screens/SearchDeed/SearchDeed';
import SremStockIndexes from '@/modules/srem/screens/SremStockIndexes/SremStockIndexes';
import ExternalRedirectComponent from '@/core/screens/web/Redirect/Redirect';
import EditDeed from '@/modules/edit-deed/edit-deed';
import PaymentSuccess from '@/modules/open-market/screens/ContactOwner/PaymentSuccess';
import PaymentFailure from '@/modules/open-market/screens/ContactOwner/PaymentFailure';
import REMapStandalone from '@/modules/open-market/components/REMapStandalone/REMapStandalone';
import { FEATURES } from '../utils/features';
import i18next from 'i18next';
import { MyRealEstatesCategory } from "@common/stores/myRealestates.store";
import OrderCancel from '@modules/deed-upgrade/components/OrderCancel/OrderCancel';

// SREM
const DeedInquiry = lazy(() => import('@dashboard/screens/DeedInquiry/DeedInquiry'));
const SremHome = lazy(() => import('@dashboard/screens/SremHome/SremHome'));
const SremLogout = lazy(() => import('@dashboard/screens/SremLogout/SremLogout'));
const MyRealestate = lazy(() => import('@dashboard/screens/MyRealEstate/MyRealestate'));
const MyRealestates = lazy(() => import('@dashboard/screens/MyRealestates/MyRealestates'));
const MyInheritedRealestates = lazy(() => import('@dashboard/screens/MyInheritedRealestates/MyInheritedRealestates'));
const MyInheritedRealestate = lazy(() => import('@dashboard/screens/MyInheritedRealEstate/MyInheritedRealestate'));
//const TermsAndConditions = lazy(() => import('@dashboard/screens/SremTerms/SremTerms'));
const DeedDetailsByNumber = lazy(() => import('@/modules/srem/screens/DeedDetailsByNumber'));

const RealestateAndDealsPage = lazy(() => import('@open-market/screens/realestate-and-deals/RealestateAndDealsPage'));
const RealestateInfoPage = lazy(() => import('@/modules/realestate-info/screens/realestate-info'));
const RealestateInfoDetails = lazy(
    () => import('@/modules/realestate-info/screens/RealestateInfoDetails/RealestateInfoDetails')
);
// My Transaction
const MyTransactions = lazy(() => import('@my-transactions/components/Default/MyTransactions'));
const TransactionDetails = lazy(() => import('@my-transactions/screens/TransactionDetails/TransactionDetails'));

// My Pending Transactions
// const MyPendingTransactions = lazy(() => import('@my-transactions/screens/PendingTransactions/PendingTransactions'));

// Property Mortgage
const NewMortgage = lazy(() => import('@property-mortgage/screens/new-mortgage/new-mortgage'));
const ReleaseMortgage = lazy(() => import('@property-mortgage/screens/release-mortgage/release-mortgage'));
const TransferMortgage = lazy(() => import('@property-mortgage/screens/transfer-mortgage/transfer-mortgage'));
const AcceptEPUMortgage = lazy(() => import('@property-mortgage/screens/EPU-mortgage/EPUMortgageDeed'));
// const MortgageList = lazy(
//     () => import("@property-mortgage/screens/mortgage-list/mortgage-list")
// );

const ConstrainsList = lazy(() => import('@/modules/government/screens/ConstrainsList'));
const ReleaseConstrain = lazy(() => import('@/modules/government/screens/ReleaseConstrain'));


// Profile
const ProfilePage = React.lazy(() => import('@profile/screens/Profile/Profile'));
const OnboardingPage = React.lazy(() => import('@profile/screens/Onboarding/Onboarding'));
const AddAttorney = lazy(() => import('@profile/screens/AddAttorney/AddAttorney'));
const AttorneyDetails = lazy(() => import('@profile/screens/AttorneyDetails/AttorneyDetails'));
const Wallet = lazy(() => import('@profile/screens/Wallet/Wallet'));
const Withdraw = lazy(() => import('@profile/screens/Withdraw/Withdraw'));
const Topup = lazy(() => import('@profile/screens/Topup/Topup'));
const UpdateDob = lazy(() => import('@profile/screens/update-dob/updateDob'));

// Auth
const AuthCallback = lazy(() => import('@common/utils/auth.callback.utils/auth.callback.utils'));
const SilentAuthCallback = lazy(() => import('@common/utils/silent-auth.callback.utils/silent-auth.callback.utils'));
const NewDeal = lazy(() => import('@selling-offer/screens/new-deal/new-deal'));
const GovernmentEPT3M = lazy(() => import('@selling-offer/screens/new-deal/GovernmentEPT3M'));
const NewPurchaseOrder = lazy(() => import('@open-market/screens/NewPurchaseOrder/NewPurchaseOrder'));
const AcceptOpenMarket = lazy(() => import('@open-market/screens/AcceptOpenMarket/AcceptOpenMarket'));
const Notfound = lazy(() => import('@core/components/shared/NotFound/NotFound'));
const ManageMortgagePage = lazy(() => import('@modules/manage-mortgage/screens/ManageMortgage'));
const ArchivedTransactions = lazy(() => import('@archive-transactions/screens/ArchivedTransactions'));
const ArchivedTransactionsDetails = lazy(() => import('@archive-transactions/screens/ArchivedTransactionDetails/ArchivedTransactionDetails'));
const ContactOwner = lazy(() => import('@open-market/screens/ContactOwner/ContactOwner'));

// Auction
const Auction = lazy(() => import("@modules/auction/screens/auction/auction"));
const AuctionList = lazy(() => import("@modules/auction/screens/auction-list/AuctionList"));

// Merge Split
const SplitRealestate = lazy(() => import("@split-merge/screens/SplitRealestate"));
const SplitLand = lazy(() => import("@split-merge/screens/SplitLand"));
const MergeDeeds = lazy(() => import('@/modules/MergeSplitRealestate/screens/MergeDeeds'));
const UpdateShapeFileForm = lazy(() => import('@/modules/MergeSplitRealestate/screens/UpdateShapeFileForm'));

// DU - Deed Upgrade
const MyDURequests = lazy(() => import('@modules/deed-upgrade/screens/MyRequests/MyRequests'));
const NewDURequest = lazy(() => import('@modules/deed-upgrade/screens/NewRequest/NewRequest'));
const NewDURequestOldImplementation = lazy(() => import('@modules/deed-upgrade/screens/NewRequest/NewRequestOld'));
const DURequestDetails = lazy(() => import('@modules/deed-upgrade/screens/RequestDetails/RequestDetails'));
const OrderSubmit = lazy(() => import('@modules/deed-upgrade/components/OrderSubmit/OrderSubmit'));
const OrderEnd = lazy(() => import('@modules/deed-upgrade/components/OrderCancel/OrderCancel'));

const SremMobilePrivacyPolicy = lazy(() => import('@dashboard/screens/SremMobilePrivacy/SremMobilePrivacyPolicy'));

type RouteWithChildrenProps = {
    privateRoute?: boolean;
};

const RouteWithChildren: FC<RouteWithChildrenProps> = () => {
    return (
        <Suspense
            fallback={
                <div style={{ textAlign: 'center' }}>
                    <Spin spinning={true} />
                </div>
            }
        >
            <Routes>
                <Route path={'open-market'} element={<MainLayout />}>
                    <Route
                        path={'order/:id'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <NewPurchaseOrder />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'accept/:id'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <AcceptOpenMarket />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route path={':feature'} element={<RealestateAndDealsPage />} />
                    <Route path={''} element={<RealestateAndDealsPage />} />
                    <Route
                        path={'contact-owner/:id'}
                        element={
                            <RequireAuth>
                                <ContactOwner />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path={'payment-success'} element={<PaymentSuccess />} />
                <Route path={'payment-failure'} element={<PaymentFailure />} />

                <Route
                    path={'transactions'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':transactionId'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <TransactionDetails />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyTransactions />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'profile'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <ProfilePage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'attorney'}
                        element={
                            <RequireAuth>
                                <Outlet />
                            </RequireAuth>
                        }
                    >
                        <Route
                            caseSensitive
                            path={':id'}
                            element={
                                <RequireAuth>
                                    <AttorneyDetails />
                                </RequireAuth>
                            }
                        />
                        <Route
                            caseSensitive
                            path={':id/iban'}
                            element={
                                <RequireAuth>
                                    <AttorneyDetails />
                                </RequireAuth>
                            }
                        />
                        <Route
                            caseSensitive
                            path={''}
                            element={
                                <RequireAuth>
                                    <AddAttorney />
                                </RequireAuth>
                            }
                        />
                    </Route>
                    {/* Wallet screens */}
                    {FEATURES.WALLET ? <Route
                        path={'wallet'}
                    >
                        <Route
                            path={'withdraw'}
                            element={
                                <RequireAuth>
                                    <Withdraw />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={'topup'}
                            element={
                                <RequireAuth>
                                    <Topup />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={''}
                            element={
                                <RequireAuth>
                                    <Wallet />
                                </RequireAuth>
                            }
                        />
                    </Route> : <></>}
                    <Route path='update-dob'>
                        <Route
                            path=''
                            element={
                                <RequireAuth>
                                    <UpdateDob />
                                </RequireAuth>
                            }
                        >
                        </Route>
                    </Route>
                </Route>

                <Route
                    path={'onboarding'}
                    element={
                        <RequireAuth>
                            <OnboardingPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path={'my-realestates'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <MyRealestate />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyRealestates category={MyRealEstatesCategory.Default} />
                            </RequireAuth>
                        }
                    />
                </Route>
                <Route
                    path='my-deed-by-number'
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path=':deedNumber/:year/:month/:day'
                        element={
                            <RequireAuth>
                                <DeedDetailsByNumber />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path={'realestates-info'} element={<Outlet />}>
                    <Route path={''} element={<RealestateInfoPage />} />
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <RealestateInfoDetails />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path={'transactions-info'} element={<Outlet />}>
                    <Route path={''} element={<RealestateInfoPage />} />
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <RealestateInfoDetails />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'deal'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <NewDeal />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyRealestates category={MyRealEstatesCategory.Default} />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'/edit-request'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <EditDeed />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyRealestates screenTitle="تعديل صك الكترونى" category={MyRealEstatesCategory.Default} />
                                {/* <HolidayMessage title=" تعديل صك الكترونى " /> */}
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path='/rid'>
                    <Route
                        path=':id'
                        element={
                            // @ts-ignore
                            <REMapStandalone onError={() => { }} reIdentityNumbers={undefined} hideInfoWindow={false} draggable />
                        }
                    >
                    </Route>
                </Route>

                <Route
                    path={'/auction'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <Auction />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'list'}
                        element={
                            <RequireAuth>
                                <AuctionList />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyRealestates screenTitle={i18next.t("auction.stepper-heading")} excludeMortgage category={MyRealEstatesCategory.Auction} />
                            </RequireAuth>
                        }
                    />
                </Route>

                {FEATURES.MERGE_SPLIT ? <>
                    <Route
                        path={'merge-split'}
                        element={
                            <RequireAuth>
                                <Outlet />
                            </RequireAuth>
                        }
                    >
                        <Route
                            path={'split'}
                            element={
                                <RequireAuth>
                                    <Outlet />
                                </RequireAuth>
                            }
                        >
                            <Route
                                path=''
                                element={<MyRealestates screenTitle="فرز المخططات" category={MyRealEstatesCategory.Epu} />}
                            />
                            <Route
                                path=':id'
                                element={<SplitRealestate />}
                            />
                        </Route>
                        <Route
                            path={'split-land'}
                            element={
                                <RequireAuth>
                                    <Outlet />
                                </RequireAuth>
                            }
                        >
                            <Route
                                path=''
                                element={<MyRealestates screenTitle="تجزئة الاراضي" category={MyRealEstatesCategory.Epu} />}
                            />
                            <Route
                                path=':id'
                                element={<SplitLand />}
                            />
                        </Route>
                        <Route
                            path={'merge'}
                            element={
                                <RequireAuth>
                                    <Outlet />
                                </RequireAuth>
                            }
                        >
                            {/* <Route
                                path=''
                                element={<MyRealestates screenTitle="دمج الاراضي" />}
                            /> */}
                            <Route
                                path=""
                                element={<MergeDeeds />}
                            />
                        </Route>
                        {/* <Route
                            path={'split'}
                            element={
                                <RequireAuth>
                                    <HolidayMessage title=" تجزئة عقارات " />
                                </RequireAuth>
                            }
                        /> */}
                        <Route path='update-shape-file' element={<UpdateShapeFileForm />} />
                    </Route>
                </> : <></>}

                <Route
                    path={'government-deal'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <GovernmentEPT3M />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyRealestates category={MyRealEstatesCategory.Default} />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'government'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={':deedNumber/:day/:month/:year'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <ReleaseConstrain />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'constrains-list'}
                        element={
                            <RequireAuth>
                                <ConstrainsList />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'heir-realestates'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <MyInheritedRealestates />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={':id'}
                        element={
                            <RequireAuth>
                                <MyInheritedRealestate />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'mortgage'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <NewMortgage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'release'}
                        element={
                            <RequireAuth>
                                <ReleaseMortgage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'edit-mortgage-amount'}
                        element={
                            <RequireAuth>
                                <SearchDeed />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'epu'}
                        element={
                            <RequireAuth>
                                <AcceptEPUMortgage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'transfer/:deedSerial'}
                        element={
                            <RequireAuth>
                                <RequireProfile>
                                    <TransferMortgage />
                                </RequireProfile>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'transfer'}
                        element={
                            <RequireAuth>
                                <TransferMortgage />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'manage-mortgage'}
                    element={
                        <RequireAuth>
                            <ManageMortgagePage />
                        </RequireAuth>
                    }
                />

                <Route
                    path={'redirect'}
                    element={
                        <RequireAuth>
                            <ExternalRedirectComponent to="https://srem.moj.gov.sa/du/createEditRequest" />
                        </RequireAuth>
                    }
                />

                {/* <Route
                    path={'user-support'}
                    element={<Outlet />}
                >
                    <Route
                        path={'new-ticket'}
                        element={
                            <RequireAuth>
                                <UserSupportNewTicket />
                            </RequireAuth>
                        }
                    />
                    
                    <Route
                        path={'ticket-created/:ticketId/:note'}
                        element={
                            <RequireAuth>
                                <UserSupportNewTicketSuccess />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'tickets'}
                        element={
                            <RequireAuth>
                                <UserSupportTicketsList />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'tickets/:ticketId'}
                        element={
                            <RequireAuth>
                                <UserSupportNewTicketDetails />
                            </RequireAuth>
                        }
                    />
                </Route> */}

                <Route
                    path={'archived-transactions'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={''}
                        element={
                            <RequireAuth>
                                <ArchivedTransactions />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={':transactionId'}
                        element={
                            <RequireAuth>
                                <ArchivedTransactionsDetails />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path={'deed-upgrade'}
                    element={
                        <RequireAuth>
                            <Outlet />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={'requests'}
                        element={
                            <RequireAuth>
                                <MyDURequests />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="new-request"
                        element={
                            <RequireAuth>
                                {/* <NewDURequest key="mode-1" screenTitle={' تحديث صك ورقى '} /> */}
                                <NewDURequestOldImplementation key="mode-1" screenTitle={' تحديث صك ورقى '} />
                                {/* <HolidayMessage title=" تحديث صك ورقى " /> */}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="new-request-premium"
                        element={
                            <RequireAuth>
                                <NewDURequest key="mode-1" screenTitle={' تحديث صك ورقى '} />
                                {/* <HolidayMessage title=" تحديث صك ورقى " /> */}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="edit-request"
                        element={
                            <RequireAuth>
                                <NewDURequestOldImplementation key="mode-2" screenTitle={' تحديث صك مفقود / تالف '} />
                                {/* <HolidayMessage title=" تحديث صك مفقود / تالف " /> */}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="edit-request/:id/:mode"
                        // mode = edit or resubmit
                        element={
                            <RequireAuth>
                                <NewDURequestOldImplementation key="mode-3" />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="requests/:id"
                        element={
                            <RequireAuth>
                                <DURequestDetails />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="requests/:id/:mode"
                        element={
                            <RequireAuth>
                                <DURequestDetails />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="requests/:id/submit"
                        element={
                            <RequireAuth>
                                <OrderSubmit />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="requests/:id/end"
                        element={
                            <RequireAuth>
                                <OrderCancel />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path={'deed-inquiry'} element={<DeedInquiry />} />
                <Route path={'deed-inquiry/:deedNumber/:year/:month/:day'} element={<DeedInquiry />} />
                <Route path={'callback.html'} element={<AuthCallback />} />
                <Route path={'silent-refresh.html'} element={<SilentAuthCallback />} />
                <Route path={'logout'} element={<SremLogout />} />
                <Route path={'terms'} element={<SremTerms />} />
                <Route path={'about'} element={<SremAbout />} />
                <Route path={'FAQs'} element={<SremFAQ />} />
                <Route path={'privacy'} element={<SremMobilePrivacyPolicy />} />
                <Route path={'/srem'} element={<SremHome showSremIndex={true} />} />
                <Route path={'/realestate-stock-indexes'} element={<SremStockIndexes />} />
                <Route path={'/'} element={<SremHome showSremIndex={true} />} />
                <Route path="*" element={<Notfound />} />
            </Routes>
        </Suspense>
    );
};

export default observer(RouteWithChildren);
