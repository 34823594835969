import { FunctionComponent, useCallback, useEffect, useLayoutEffect } from 'react';
import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import RealEstateCard from '@/core/components/shared/RealEstateCard/RealestateCard';
import realestateAndDealsService from '@common/services/realestates-and-deals.service';
import { loaderStore } from '@/common/stores/loader.store';
import GenericResponse from '@/common/models/GenericResponse';
import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import { IRealestate } from '@/core/interfaces/Realestate.interface';
import InfiniteScroll from 'react-infinite-scroll-component';
import MojList from '@core/components/shared/MojList/MojList';
import './RealStateList.scss';

export type SortingType = {
    sortType: number;
    sortField: number;
};

type RealestateListProps = {
    loadRealestate: (isInitial?: boolean) => void;
    setCurrentPage: (page: number) => void;
    totalHits: number;
};

const RealestateList: FunctionComponent<RealestateListProps> = ({ loadRealestate, setCurrentPage, totalHits }) => {

    useEffect(() => {
        realestateAndDealsStore.setSortingType(realestateAndDealsStore.sortingType);
        realestateAndDealsStore.setSelectedSortingType(realestateAndDealsStore.selectedSortingType);
    }, [realestateAndDealsStore.sortingType, realestateAndDealsStore.selectedSortingType]);

    useLayoutEffect(() => {
        loaderStore.setLoader(true);
        setCurrentPage(1);
        loadRealestate(true);
    }, [realestateAndDealsStore.realestateOptions, realestateAndDealsStore.sortingType]);

    useEffect(() => {
        getRealestateTypes();
    }, []);

    const getRealestateLink = useCallback((realestate: IRealestate) => {
        if (realestate.OpenMarketOrders && realestate.OpenMarketPrice) {
            realestateAndDealsStore.updateRealestatePrice(realestate.Serial, realestate.OpenMarketPrice);
            return `/transactions/${realestate.RequestId}`;
        } else {
            return `order/${realestate.Serial}`;
        }
    }, []);

    const getRealestateTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getRealEstateTypes();

        if (response?.IsSuccess) {
            realestateAndDealsStore.setRealestateTypes(response.Data);
        }
    };

    return (
        <InfiniteScroll
            dataLength={realestateAndDealsStore.realestates.length}
            next={() => {
                if (!loaderStore.isLoading) {
                    loadRealestate();
                }
            }}
            hasMore={realestateAndDealsStore.realestates.length < totalHits}
            loader={<Skeleton paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv"
        >
            <MojList
                dataSource={realestateAndDealsStore.realestates}
                isLoading={loaderStore.isLoading}
                EmptyMessage={'لا توجد عروض عقارية'}
                renderItem={(item: IRealestate, index) => (
                    <RealEstateCard
                        key={`realestate-and-deal-${item.Serial}--${index}`}
                        realestate={item}
                        to={getRealestateLink(item)}
                    />
                )}
            />
        </InfiniteScroll>
    );
};

export default observer(RealestateList);
