import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Col, ConfigProvider, Layout, Spin } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import TopBarProgress from 'react-topbar-progress-indicator';
import { AuthProvider } from 'react-oidc-context';
import { reactPlugin } from '@/AppInsights';
import 'antd/dist/antd.less';
import MobileHeader from '@core/components/mobile/HeaderLayout';
import { initTranslations } from '@common/utils/language/initLanguage';
import { Footer } from '@core/components/mobile';
import { authStore } from '@common/stores/auth.store';
import { loaderStore } from './common/stores/loader.store';
import { attorneyStore } from '@common/stores/attorney.store';
import { AttorneyItem } from '@common/models/Attorney';
import AttorneyService from '@common/services/attorney.service';
import { isMobileOrTabletDevice } from '@common/utils/device-helper.utils';
import RouteWithChildren from '@common/routes/route-with-children';
import TokenExpiringPopUp from '@core/components/shared/TokenExpiringPopUp/TokenExpiringPopUp';
import AuthIAMPopUp from '@core/components/shared/AuthIAMPopUp/AuthIAMPopUp';
import { MojButton } from './core/components/shared/MojButton/MojButton';
import AttorneyExpiringPopUp from '@core/components/shared/AttorneyExpiringPopUp/AttorneyExpiringPopUp';
import { AppInsightsErrorBoundary, withAITracking } from '@microsoft/applicationinsights-react-js';
import CrashScreen from '@core/components/shared/CrachScreen/CrashScreen';
import HintPopup from '@core/components/shared/HintPopup/HintPopup';
import RefundService from './common/services/refund.service';
import UpdateIbanModal from './modules/refund-mangement/UpdateIbanModal/UpdateIbanNotificationModal';
import RefundManagmentResponseModal from './modules/refund-mangement/RefundManagmentResponseModal/RefundManagmentResponseModal';
import { refundManagmentStore } from './common/stores/refundManagment.store';
import useDeepLink from '@common/utils/deep-link.utils/deep-link-hook';
import { MojPopup } from '@core/components/shared';
import { FilePdfOutlined } from '@ant-design/icons';
import { realestateAndDealsStore } from './common/stores/realestate-and-deals.store';
import { realStateViews } from './modules/open-market/screens/realestate-and-deals/RealestateAndDealsPage';
import CommonService from '@common/services/common.service';
import Maintenance from './core/screens/web/Maintenance/Maintenance';
import dayjs from 'dayjs';
import { MobileFooter } from './core/components/mobile/Footer/MobileFooter';

initTranslations();

TopBarProgress.config({
    barColors: {
        '0': '#f6b221',
        '0.5': '#f6b221',
        '1.0': '#f6b221',
    },
    barThickness: 5,
    shadowBlur: 0,
});

function App() {
    const attorneyService = new AttorneyService();
    const refundService = new RefundService();
    const commonService = new CommonService();
    const { i18n } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [headerScrolled, setHeaderScrolled] = React.useState(false);
    const [openUpdateIbanModal, setUpdateIbanHandler] = useState(false);
    const [currentRefundInfo, setCurrentRefundInfo] = useState<any>(undefined);
    const [refundResponseModalState, setRefundResponseState] = useState(false);
    const [maintenanceResponse, setMaintenanceResponse] = useState<any[]>();
    const [nextMaintenanceResponse, setNextMaintenanceResponse] = useState<any[]>();

    const { isDeepLinking, openApp } = useDeepLink({
        appStoreUrls: {
            ios: 'https://apps.apple.com/app/com.srem.moj',
            android: 'https://play.google.com/store/apps/details?id=com.srem.moj',
        },
        deepLinkSchemas: {
            ios: `com.srem.moj://${window.location.pathname}`,
            android: `com.srem.moj://${window.location.pathname}`,
        },
        fallbackUrl: window.location.href,
        timeoutDuration: 3000,
    });

    const isMobile = React.useMemo(() => {
        return isMobileOrTabletDevice();
    }, []);

    const noPadding = useMemo(() => {
        return realestateAndDealsStore.selectedView === realStateViews.map;
    }, [realestateAndDealsStore.selectedView]);

    useEffect(() => {
        getMaintenanceSchedules();
        getNextMaintenanceSchedules();
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const scrollThreshold = 75; // Adjust this threshold as needed

            if (scrollPosition > scrollThreshold) {
                setHeaderScrolled(true);
            } else {
                setHeaderScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onExpandedChange = React.useCallback(
        (flag: boolean) => {
            setExpanded(flag);
        },
        [setExpanded]
    );

    const getPendingRefunds = async () => {
        let response = await refundService.getPendingRefundOrders();
        if (response?.Data !== null && response?.Data !== undefined) {
            if (response.Data.length > 0) {
                setCurrentRefundInfo(response?.Data[0]);
                setUpdateIbanHandler(true);
                refundManagmentStore.setAllRefundTerms();
            }
        }
    };

    const getAttorneys = async () => {
        if (!attorneyStore.attornies) {
            let response = await attorneyService.getMyAttornies();
            if (!response) return;

            if (response.Data !== null && response.Data !== undefined) {
                attorneyStore.setAttornies(response.Data as AttorneyItem[]);
            }
        }
    };

    const getMaintenanceSchedules = async () => {
        loaderStore.setLoader(true);
        let response: any = await commonService.getMaintenanceSchedules();
        loaderStore.setLoader(false);
        if (response?.Data !== null && response?.Data !== undefined) {
            setMaintenanceResponse(response?.Data);
        }
    }

    const getNextMaintenanceSchedules = async () => {
        loaderStore.setLoader(true);
        let response: any = await commonService.getNextMaintenanceSchedules();
        loaderStore.setLoader(false);
        if (response?.Data !== null && response?.Data !== undefined) {
            setNextMaintenanceResponse(response?.Data);
        }
    }

    useEffect(() => {
        if (authStore.user && authStore.isLoggedIn) {
            attorneyStore.fetchAttorney();
            getAttorneys();
        }
    }, [authStore.user, authStore.isLoggedIn]);

    useEffect(() => {
        if (
            authStore.userIbans.length > 0 ||
            (attorneyStore?.selectedAttorneyIbans && attorneyStore?.selectedAttorneyIbans.length > 0)
        ) {
            getPendingRefunds();
        }
    }, [authStore.userIbans, authStore.userDetails, attorneyStore?.selectedAttorneyIbans]);

    useEffect(() => {
        return () => {
            openApp();
        };
    }, []);

    return (
        <div lang={i18n.language} dir={i18n.dir(i18n.language)}>
            {loaderStore.isLoading && <TopBarProgress />}
            {(maintenanceResponse && maintenanceResponse?.length) ? <Maintenance data={maintenanceResponse} /> :
                <Spin spinning={loaderStore.isLoading}>
                    <ConfigProvider direction={i18n.dir(i18n.language)}>
                        {nextMaintenanceResponse && nextMaintenanceResponse?.length ?
                            <Alert className="maintenance-alert" message={<div>
                                تنويه: ستبدأ أعمال الصيانة يوم {' '}
                                <span>{dayjs(nextMaintenanceResponse[0].StartTime).format('DD/MM/YYYY')}</span>
                                الساعة {' '}
                                <span>{dayjs(nextMaintenanceResponse[0].StartTime).format('HH:mm')}</span>
                                وتنتهي يوم {' '}
                                <span>{dayjs(nextMaintenanceResponse[0].EndTime).format('DD/MM/YYYY')}</span>
                                الساعة {' '}
                                <span>{dayjs(nextMaintenanceResponse[0].EndTime).format('HH:mm')}</span>
                                . خلال هذه الفترة، قد تتوقف جميع الخدمات مؤقتًا. نشكركم على تفهمكم.
                            </div>} type="error" closeText="إغلاق" />
                            : null}

                        <div className="demo-release">
                            <a
                                className="link"
                                href={`${process.env.REACT_APP_GUIDE_URL}api/v1/Download/DownloadGuide`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                تحميل "الدليل الإرشادي" لمنصة البورصة العقارية
                                <FilePdfOutlined style={{ marginRight: '6px' }} />
                            </a>
                        </div>
                        <Router>
                            <SessionLayer>
                                <Layout.Header
                                    className={clsx('main-header', headerScrolled && !expanded && 'main-header--fixed')}
                                >
                                    <MobileHeader
                                        onExpandedChange={onExpandedChange}
                                        login={authStore.login}
                                        logout={() => {
                                            authStore.logout();
                                            attorneyStore.clearAttorney();
                                        }}
                                        userInfo={authStore.user}
                                        isLoggedIn={authStore.isLoggedIn}
                                        authedAttorney={attorneyStore.attorney!!}
                                        attorneys={attorneyStore.attornies}
                                        getAttorneys={getAttorneys}
                                    />
                                </Layout.Header>

                                <Layout>
                                    <Layout.Content
                                        className={clsx('moj-page-content', {
                                            'moj-page-content--hidden': expanded,
                                            'moj-page-content--web': !isMobile,
                                            'moj-page-content--no-padding': noPadding,
                                        })}
                                    >
                                        <AppInsightsErrorBoundary
                                            appInsights={reactPlugin}
                                            onError={(props) => <CrashScreen />}
                                        >
                                            <AuthProvider userManager={authStore.manager}>
                                                <RouteWithChildren />
                                                <AttorneyExpiringPopUp />
                                                <TokenExpiringPopUp />
                                            </AuthProvider>
                                        </AppInsightsErrorBoundary>

                                        {authStore.isLoggedIn && currentRefundInfo! && (
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <UpdateIbanModal
                                                    openUpdateIbanModal={openUpdateIbanModal}
                                                    handleUpdateIbanHandler={setUpdateIbanHandler}
                                                    currentRefundInfo={currentRefundInfo}
                                                    handleRefundResponseModal={setRefundResponseState}
                                                />
                                            </Col>
                                        )}
                                        {refundResponseModalState && (
                                            <RefundManagmentResponseModal handleCloseButton={setRefundResponseState} />
                                        )}
                                    </Layout.Content>
                                    <Layout.Footer
                                        id="main-footer"
                                        className={clsx('moj-footer', {
                                            'moj-footer--hidden': expanded,
                                        })}
                                    >
                                        {isMobile ? <MobileFooter /> : <Footer />}
                                        <div id="ghostModals" />
                                        <div id="sessionModal" />
                                        <div id="otpModal" />
                                    </Layout.Footer>
                                </Layout>
                                <AuthIAMPopUp />
                            </SessionLayer>
                        </Router>
                    </ConfigProvider>
                </Spin>
            }
        </div>
    );
}

function SessionLayer({ children }: PropsWithChildren<{}>) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const DEFAULT_TEMPLATE = (
        <div className="onboarding-message">
            <div className="onboarding-message__p1">{t('profile.profile-incomplete-message')}</div>
            <div className="onboarding-message__p1">{t('profile.missing-phone-number')}</div>
            <div>{t('profile.missing-iban-number')}</div>
        </div>
    );

    const [messageTemplate, setMessageTemplate] = useState<ReactNode>(DEFAULT_TEMPLATE);
    const [showHint, setShowHint] = useState(false);
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);

    const location = useLocation();
    // const { show, hideBefore, close } = useHint({
    //     title: t('profile.profile-incomplete'),
    //     message: messageTemplate,
    //     hideClose: true,
    //     footer: (
    //         <div>
    //             <MojButton
    //                 onClick={() => {
    //                         hideBefore(() => {
    //                             navigate('/onboarding');
    //                         });
    //                 }}
    //                 color="success"
    //             >
    //                 {t('shared.update-data')}
    //             </MojButton>
    //         </div>
    //     ),
    //     onCloseClick: () => {
    //         navigate('/');
    //     },
    //     ghost: true,
    //     ghostId: 'sessionModal',
    // });

    const { user, isLoggedIn } = authStore;
    useEffect(() => {
        if (user && isLoggedIn) {
            authStore.getUserProfile(true).then((res: any) => {
                if (!res) {
                    return;
                }

                if (res.error) {
                    // if Http code 401 means session end or unauthorized then logout user
                    if (res.error.response.status === 401) {
                        setShowLogoutPopup(true);

                        setTimeout(() => {
                            authStore.logout();
                            authStore.user = undefined;
                        }, 1000);
                    }

                    return;
                }

                if (res.HttpCode === 400) {
                    if (location && location.pathname !== '/onboarding') {
                        if (res.ErrorDetails && String(res.ErrorDetails[0].ErrorCode) === '5001') {
                            setMessageTemplate(res.ErrorDetails.map((error) => error.ErrorDescription).join('\n'));
                            //delegate to next render
                            setTimeout(() => {
                                setShowHint(true);
                            }, 100);
                        }
                    } else if (location && location.pathname.includes('onboarding')) {
                        setTimeout(() => {
                            setShowHint(false);
                        }, 100);
                    }
                } else if (res.HttpCode === 200 && !res.IsSuccess) {
                    setShowHint(false);
                    navigate('/onboarding');
                }
            });
        }
    }, [user, isLoggedIn, navigate, location]);

    return (
        <>
            {children}
            <HintPopup
                title={t('profile.profile-incomplete')}
                message={messageTemplate}
                visible={showHint}
                hideClose
                onCloseClick={() => navigate('/')}
                footer={
                    <div>
                        <MojButton
                            color="success"
                            onClick={() => {
                                navigate('/onboarding');
                            }}
                        >
                            {t('shared.update-data')}
                        </MojButton>
                    </div>
                }
            />
            <MojPopup
                open={showLogoutPopup}
                title={t('auth.session-end')}
                footer={
                    <MojButton color={'success'} onClick={() => setShowLogoutPopup(false)}>
                        {t('shared.continue')}
                    </MojButton>
                }
            >
                <div style={{ textAlign: 'center' }}>
                    <p>{t('auth.session-end-you-will-be-logout')}</p>
                </div>
            </MojPopup>
        </>
    );
}

export default withAITracking(reactPlugin, observer(App));
