export const BanksCodes = {
    AIB: '05', //Alinma
    NCB: '10', //Al Ahli
    ABB: '15', //Albilad
    RYB: '20', //Riyadh
    ANB: '30', //Arab National
    SFG: '40', //Samba Financial group
    SBB: '45', //The Saudi British
    FSB: '55', //Banque Saud Fransi
    AJB: '60', //Al Jazira
    SIB: '65', //Saudi Investment
    ARB: '80', //Al-Rajhi
    GIB: '90', //Gulf International Bank - v4
    MNU: '76', //BANK MUSCAT - v4
    NBB: '71', //National Bank of Bahrain  - v4
    NBK: '75', //National Bank of Kuwait - v4
    JPM: '86', //JPMorgan
    TZB: '84', //Turkiye Cumhuriyeti Ziraat Bankasi
    BNP: '98', //BNP Paribas
    EBI: '95', //Emirates Bank International
    SAM: '1', //Saudi Arabian Monetary Agency
    NBP: '82', //National Bank of Pakistan
    DEB: '81', //Deutsche Bank
    CCB: '87', //Industrial and Commercial Bank of China Ltd
    SBI: '83', //State Bank of India
    AAB: '50', //Saudi Holandi Bank/alawwal Bank
    QNB: "99",          //National Bank of Qatar
    FAB: "98",          //First Abu Dhabi Bank
};

export const BanksNames = {
    [BanksCodes.AIB]: 'مصرف الإنماء',
    [BanksCodes.NCB]: 'البنك الأهلي التجاري',
    [BanksCodes.ABB]: 'بنك البلاد',
    [BanksCodes.RYB]: 'بنك الرياض',
    [BanksCodes.ANB]: 'البنك العربي الوطني',
    [BanksCodes.SFG]: 'مجموعة سامبا المالية',
    [BanksCodes.SBB]: 'البنك السعودي البريطاني',
    [BanksCodes.FSB]: 'البنك السعودي الفرنسي',
    [BanksCodes.AJB]: 'بنك الجزيرة',
    [BanksCodes.SIB]: 'البنك السعودي للاستثمار',
    [BanksCodes.ARB]: 'مصرف الراجحي',
    [BanksCodes.GIB]: 'بنك الخليج الدولي',
    [BanksCodes.MNU]: 'بنك مسقط',
    [BanksCodes.NBB]: 'فرع بنك البحرين الوطني',
    [BanksCodes.NBK]: 'فرع بنك الكويت الوطني',
    [BanksCodes.JPM]: 'فرع بنك جي بي مورجان',
    [BanksCodes.TZB]: 'فرع بنك زراعات التركي',
    [BanksCodes.BNP]: 'مصرف باريس الوطني',
    [BanksCodes.EBI]: 'بنك الإمارات الدولي',
    [BanksCodes.SAM]: 'مؤسسة النقد العربي السعودي',
    [BanksCodes.NBP]: 'البنك الوطني الباكستاني',
    [BanksCodes.DEB]: 'البنك الألماني',
    [BanksCodes.CCB]: 'البنك الصناعي والتجاري الصيني المحدود',
    [BanksCodes.SBI]: 'البنك المركزى الهندى',
    [BanksCodes.AAB]: 'البنك السعودي الهولندي/البنك الأول',
    [BanksCodes.QNB]: 'فرع بنك قطر الوطني',
    [BanksCodes.FAB]: 'فر ع بنك أبو ظبي الأول',
};

export const SupportedBanks = [BanksCodes.NCB];

export const VerifiableBanks = [BanksCodes.NCB];
