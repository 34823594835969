export function b64EncodeUnicode(str) {
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        })
    );
}

export function b64DecodeUnicode(str: string) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
}

export function checkOverflowForPdf(el: HTMLElement) {
    // eslint-disable-next-line
    // @ts-nocheck
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
    const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
}

export function cutLinesForPdf(deedText: string) {
    if (deedText.split('\n').length > 10) {
        // cut lines
        const x = deedText.split('\n');
        x.pop();
        return x.join('\n');
    } else return deedText.split(' ').slice(0, -10).join(' ');
}

export function diffHours(dt2: number, dt1: number) {
    let diff = (dt2 - dt1) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
}

export const toBase64 = (file: File) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export function toBase64Cb(file: File, callback: (base64: string | ArrayBuffer | null) => {}) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
}

export function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
}

export function formatDate(date: Date, hour12 = true) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    let shouldIncludeTime = true;
    if (hours === '00' && minutes === '00' && seconds === '00') {
        shouldIncludeTime = false;
    }

    const timePart = shouldIncludeTime
        ? new Intl.DateTimeFormat('default', {
              hour12,
              hour: 'numeric',
              minute: 'numeric',
          }).format(date)
        : '';

    return `${day}/${month}/${year} ${timePart}`;
}
