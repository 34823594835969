import { useTranslation } from 'react-i18next';

import Legend from '../Legend/Legend';

import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import './_MapViewLegend.scss';
import clsx from 'clsx';
import { ReactComponent as LayersIcon } from '@core/assets/images/layers.svg';
import { ReactComponent as FiltersIcon } from '@core/assets/images/filters.svg';
import { ReactComponent as LocationIcon } from '@core/assets/images/location.svg';
import { ReactComponent as FullScreenIcon } from '@core/assets/images/full-screen.svg';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

export type featureOptions = "layers" | "location" | "full-screen" | "filters" | "data-layers" | "reset-coordinates";
interface MapViewLegendProps {
    enumKey: string;
    isProperty: boolean;
    mapViewType?: string | null;
    isMobile?: boolean;
    locateMe: () => void;
    mapType: boolean;
    setMapType: (flag: boolean) => void;
    showModal: () => void;
    features?: featureOptions[];
}

function MapViewLegend({
    enumKey,
    isProperty,
    mapViewType,
    isMobile,
    locateMe,
    mapType,
    setMapType,
    showModal,
    features = ["layers", "location", "full-screen", "filters"]
}: Readonly<MapViewLegendProps>) {
    const { t } = useTranslation();
    const getStatusText = (item: { purchaseOrder: boolean; openMarketOrder: boolean }) => {
        if (item?.purchaseOrder) {
            return t('demanded');
        } else if (item?.openMarketOrder) {
            return t('supplied');
        }
        return t('default');
    };

    const showFilters = useMemo(() => features.includes("filters"), [features]);
    const showLayers = useMemo(() => features.includes("layers"), [features]);
    const showLocation = useMemo(() => features.includes("location"), [features]);
    const showFullScreen = useMemo(() => features.includes("full-screen"), [features]);

    return (
        <div className={clsx("statusLegendContainer", { desktopActive: !isMobile && realestateAndDealsStore.toolsVisible })}>
            <div className="statusLegend">
                <div className={clsx("legendContainter", { density: !isProperty })}>
                    {isProperty &&
                        <div className={clsx('controls', { desktop: !isMobile })}>
                            {realestateAndDealsStore.toolsVisible ? <>
                                {showFilters ? <div className={clsx('control filters', { filterHidden: !isMobile, isMobile })} onClick={() => showModal()}>
                                    <FiltersIcon />
                                    المحددات
                                </div> : <></>}
                                {showLayers ? <div className={clsx('control layers', { isMobile })} onClick={() => setMapType(!mapType)}>
                                    <LayersIcon />
                                    الطبقات
                                </div> : <></>}
                                {showLocation ? <div onClick={locateMe} className={clsx('control location', { isMobile })}>
                                    <LocationIcon />
                                    تحدید الموقع
                                </div> : <></>}
                            </> : <></>}
                            {showFullScreen ? <div className={clsx('control toggle', { active: realestateAndDealsStore.toolsVisible, isMobile })} onClick={() => realestateAndDealsStore.toggleToolsVisible()}>
                                {isMobile ? "+" : <FullScreenIcon />}
                            </div> : <></>}
                        </div>
                    }
                    {!isProperty &&
                        mapViewType &&
                        realestateAndDealsStore?.mapViews?.[enumKey]
                            ?.filter((item) => item?.mapViewType === mapViewType)?.[0]
                            ?.ranges.map((item2) => {
                                return (
                                    <div>
                                        <Legend
                                            key={item2?.color}
                                            color={item2?.color}
                                            label={item2?.range}
                                            isProperty={isProperty}
                                        />
                                    </div>
                                );
                            })}
                </div>
            </div>
        </div >
    );
}

export default observer(MapViewLegend);
