import { createClient } from '@common/services/index';
import { AxiosInstance } from 'axios';
import { IPreValidateModel } from '../models/OpenMarketRequest';
import {IMyRealestatesFilters, MyRealEstatesCategory} from '../stores/myRealestates.store';
import { IdentityNumberPayload } from '../models/Realestate';

export default class RealEstatesServices {
    diNetworkInstance!: AxiosInstance;
    sremInstance!: AxiosInstance;
    eduApiGeeInstance!: AxiosInstance;

    constructor() {
        this.diNetworkInstance = createClient(`${process.env.REACT_APP_DI_API_URL}api/v1`);
        this.sremInstance = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
        this.eduApiGeeInstance = createClient(`${process.env.REACT_APP_EDU_APIGEE_URL}api/v1`);
    }

    getMyRealEstates = async (filters: IMyRealestatesFilters, category: number) => {
        let endpoint = 'MySrem/RealEstates';

        switch(category)
        {
            case MyRealEstatesCategory.Auction:
                endpoint = 'MySrem/AuctionRealEstates';
                break;
            case MyRealEstatesCategory.Epu:
                endpoint = 'MySrem/EpuRealEstates';
                break;
        }
        return await this.diNetworkInstance
            .post(endpoint, {
                ...filters,
            })
            .then((response: any) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyHierListDeedsRealEstates = async (id: string) => {
        return await this.diNetworkInstance
            .post(`HierListDeeds/HierListDeeds`, {
                idNumber: id,
            })
            .then((response: any) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });
    };
    getDeedBySerial = async (body: { deedSerial: string }) => {
        return await this.diNetworkInstance
            .post(`DeedInquiry/GetDeedBySerial`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    getMyDeedBySerial = async (body: { deedSerial: string }) => {
        return await this.diNetworkInstance
            .post(`DeedInquiry/GetMyDeedBySerial`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMyDeedByNumber = async (body: { DeedNumber: string }) => {
        return await this.diNetworkInstance
            .post(`DeedInquiry/GetMyDeedByDeedNumber`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getHierDeedBySerial = async (body: { deedSerial: string; idNumber: string }) => {
        return await this.diNetworkInstance
            .post(`HierListDeeds/GetHierListDeedBySerial`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedMortgageInfo = async ({ deedSerial }: { deedSerial: string }) => {
        return await this.sremInstance
            .get(`Deed/DeedMortgageeInfo/${deedSerial}`)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    prevalidateDeed = async (input: IPreValidateModel) => {
        return await this.sremInstance
            .post(`Deed/PreValidate`, input)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    updateRealEstateIdentityNumber = async (body: IdentityNumberPayload) => {
        return await this.eduApiGeeInstance
            .post(`/EDURequest/Send`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getGovernmentConstrainsList = async (filters: IMyRealestatesFilters) => {
        return await this.diNetworkInstance
            .post(`MySrem/GovConstraintDeeds`, {
                ...filters,
            })
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
}

export const realEstatesServices = new RealEstatesServices();
