import { ChangeEvent, useCallback, useState } from "react";
import { Card } from "@core/components/shared";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import { MojTextArea } from "@/core/components/shared/MojTextArea/MojTextArea";
import AttachmentDto from "@/modules/srem/components/UploadFile/AttachmentDto";
import { UploadFile } from "@/modules/srem/components/UploadFile/UploadFile";

export interface onChangeEditFieldsProps {
    fields: number[],
    reason: string,
    attachment: AttachmentDto | undefined
}

interface IProps {
    onChange: (props: onChangeEditFieldsProps) => void,
    values: number[]
}

const EditReasonSupportedFilesForm = ({ onChange, values: defaultValues }: IProps) => {
    const [reason, setReason] = useState("");
    const [attachment, setAttachment] = useState<AttachmentDto | undefined>();
    const { t } = useTranslation();

    const onUpload = useCallback((file: AttachmentDto) => {
        setAttachment(file);
        onChange({
            reason,
            attachment: file,
            fields: defaultValues || []

        });
    }, [reason, defaultValues, onChange]);

    const handleReasonChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setReason(e.target.value);
        onChange({
            reason: e.target.value,
            attachment,
            fields: defaultValues || []
        });
    }, [onChange, attachment, defaultValues]);

    return (
        <div className="section select-edit-fields">
            <Card>
                <Card.Head className="section__title no-border">{t("edit-deed.edit-reason")}</Card.Head>
                <Card.Body>
                    <MojTextArea value={reason} onChange={handleReasonChange} className="reasons-input" rows={4} />
                </Card.Body>
            </Card>

            <Card>
                <Card.Head className="section__title no-border">
                    {t("edit-deed.attachments-title")}
                </Card.Head>
                <Card.Body>
                    <Row>
                        <Col span={8}>
                            <UploadFile onUpload={onUpload} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
};

export default EditReasonSupportedFilesForm;
