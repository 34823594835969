import writtenNumber from 'written-number';
import i18n from 'i18next';

export const dollarUSLocale = Intl.NumberFormat('en-US');

export const getReadablePrice = (price: number | undefined, showLessThanOne?: boolean): string => {
    if (!price) return '-';
    return price < 1
        ? showLessThanOne
            ? `${i18n.t('shared.less-than')} 1`
            : Number(price).toFixed(2)
        : `${dollarUSLocale.format(price)}`;
};

export const getNearestNumber = (number: number | undefined): string => {
    if (number === undefined) return '';

    // less than a million
    if (number <= 1000000) return getReadableNumber(number);
    // greater than a million & less than a billion
    else if (number > 1000000 && number < 1000000000) {
        return (number / 1000000).toFixedNoRounding(2) + ' مليون+';
    }
    // greater than 999,999,999
    else return (number / 1000000000).toFixedNoRounding(2) + ' مليار+';
};

export const getReadableNumber = (number: number | undefined): string => {
    if (number === undefined) return '';
    return number === 0 ? '0' : `${dollarUSLocale.format(Math.round(number))}`;
};

export const getReadableNumberWithFormat = (price: number | undefined): string => {
    if (!price) return '-';
    return dollarUSLocale.format(+price.toFixed(2));
};

export const getReadableNumberWithoutFormat = (price: number | undefined): string => {
    if (!price) return '-';
    return toFixed(price, 2);
};

export const getReadableNumberWithLongFraction = (price: number | undefined): string => {
    if (!price) return '-';
    return price.toFixed(3);
};

export const convertToEnglishNumbers = (text) => {
    let arabic_numbers = '٠١٢٣٤٥٦٧٨٩'.split('');
    let english_numbers = '0123456789'.split('');
    let convertedNumber = '';
    for (let i = 0; i < text.length; i++) {
        let isArabicDigit = arabic_numbers.includes(text[i]);
        let state = isArabicDigit ? arabic_numbers.indexOf(text[i]) : english_numbers.indexOf(text[i]);
        if (state >= 0) {
            convertedNumber += state;
        } else {
            convertedNumber += text[i];
        }
    }
    return convertedNumber;
};

export const convertEnglishNumbersToArabic = (text) => {
    if (typeof text !== 'string') {
        return text;
    }

    const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

    // Replace English numbers with Arabic numbers in the text
    for (let i = 0; i < 10; i++) {
        const englishDigit = i.toString();
        const arabicDigit = arabicNumbers[i];
        text = text.replace(new RegExp(englishDigit, 'g'), arabicDigit);
    }

    return text;
};

declare global {
    // @ts-ignore
    interface Number {
        toFixedNoRounding: (fractionDigits: number) => string;
    }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNoRounding = function (n: number) {
    const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g');
    // @ts-ignore
    let a = this.toString().match(reg)[0];
    const dot = a.indexOf('.');
    if (dot === -1) {
        // integer, insert decimal dot and pad up zeros
        return a + '.' + '0'.repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + '0'.repeat(b) : a;
};

export const toFixed = (num: number, fixed: number): string => {
    const decimalPlaces = fixed || -1;
    const regex = new RegExp(`^-?\\d+(?:\.\\d{0,${decimalPlaces}})?`);
    const matchResult = num.toString().match(regex);
    return matchResult?.[0] || '';
};

export function isPositiveNumber(input: string) {
    return Number(input) > 0;
}

export function convertMixNumbersToEnglish(text: string) {
    if (!text) return '';
    if (typeof text !== 'string') {
        return text;
    }

    const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const englishNumber = {
        '٠': 0,
        '١': 1,
        '٢': 2,
        '٣': 3,
        '٤': 4,
        '٥': 5,
        '٦': 6,
        '٧': 7,
        '٨': 8,
        '٩': 9,
    };

    for (let i of arabicNumbers) {
        const arabicDigit = i.toString();
        const englishDigit = englishNumber[i];
        text = text.replace(new RegExp(arabicDigit, 'g'), englishDigit);
    }

    return text;
}

export function convertNumberWithFractionCurrency(number) {
    const [wholePart, fractionalPart] = number.toString().split('.');
    const wholeNumberInWords = writtenNumber(parseInt(wholePart, 10), { lang: 'ar' });

    if (+fractionalPart) {
        const fractionalNumberInWords = writtenNumber(parseInt(fractionalPart, 10), { lang: 'ar' });
        return `${wholeNumberInWords} ريال سعودي و${fractionalNumberInWords} هللة`;
    } else {
        return `${wholeNumberInWords} ريال سعودي`;
    }
}

export function restrictFractionToSix(number) {
    if (number === undefined) return '';
    if (number.toString().split('.')[1]?.length > 6) {
        return Number(Number(number).toFixed(6));
    } else {
        return number;
    }
}
