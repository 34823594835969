import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './_NoData.scss';
import NoDataIcon from '@core/assets/images/assets-icons-no-data.svg';

interface NoDataProps {
    label: string;
}
const NoData: FunctionComponent<NoDataProps> = ({ label }) => {
    const { t } = useTranslation();

    return (
        <div className="noDataContainer">
            <img src={NoDataIcon} alt="" />
            <p className="noDataMessage">{t(label)}</p>
        </div>
    );
};
export default NoData;
