import { IMortgageEntity } from '@common/models/Transaction';
import { useTranslation } from 'react-i18next';
import { Card, ReadOnlyField, ReadOnlyFieldBorderVariant } from '@core/components/shared';
import { Col, Divider, Row } from 'antd';
import React, { Fragment } from 'react';

const MortgageeDetails = (props:{mortgagee: IMortgageEntity,isNew?:boolean}) => {
    const mortgagee = props.mortgagee

    const { t } = useTranslation();
    return (
        <Card className="section__container">
            <Row>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Card.Head className="section__title border-bottom">
                        <Col flex="1 1 auto">{props.isNew ?  t('finance.new-mortgagee-details') :  t('finance.mortgagee-details')}</Col>
                    </Card.Head>
                </Col>
            </Row>
            <Row style={{ width: '100%' }}>
                {mortgagee.Name && (
                    <Col flex="auto">
                        <ReadOnlyField
                            small
                            label={t('mortgage.provider-name')}
                            title={mortgagee.Name!!}
                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                        />
                    </Col>
                )}
                {mortgagee.SocialId && (
                    <Col flex="auto">
                        <ReadOnlyField
                            small
                            label={t('shared.facility-national')}
                            title={mortgagee.SocialId!!}
                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                        />
                    </Col>
                )}
            </Row>
            {
                mortgagee.Attorneys?.map((beneficiary, index) => {
                    return (
                        <Fragment key={index}>
                            <Divider/>
                            <Row>
                                <Col flex="1 1 auto">
                                    <ReadOnlyField
                                        small
                                        label={t('mortgage.agent-name')}
                                        title={beneficiary.Name!!}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        small
                                        label={t('mortgage.agent-id')}
                                        title={beneficiary.SocialId!!}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <ReadOnlyField
                                        small
                                        label={t('shared.mobile-number')}
                                        title={beneficiary.MobileNumber!!}
                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                    )
                })
            }
        </Card>
    );
};

export default MortgageeDetails;
