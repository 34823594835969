import clsx from 'clsx';
import React, { HTMLProps } from 'react';
import { FieldWrapper } from '../index';
import './PlainLabel.scss';

export enum PlainLabelVariant {
    success = 'success',
    danger = 'danger',
    primary = 'primary',
    warning = 'warning',
    white = 'white',
    disabled = 'disabled',
}

export enum PlainLabelBorderVariant {
    success = 'success',
    warning = 'warning',
    primary = 'primary',
}

export interface PlainLabelProps extends Omit<Omit<HTMLProps<HTMLDivElement>, 'title'>, 'label'> {
    label?: string | React.ReactNode;
    title: string | React.ReactNode;
    noBorder?: boolean;
    variant?: PlainLabelVariant;
    className?: string;
    small?: boolean;
    large?: boolean;
    unit?: string;
    inline?: boolean;
    borderVariant?: PlainLabelBorderVariant;
}
export function PlainLabel({
    title,
    label,
    noBorder,
    unit,
    variant = PlainLabelVariant.success,
    className,
    small,
    large,
    borderVariant = PlainLabelBorderVariant.success,
    inline,
    ...props
}: PlainLabelProps) {
    return (
        <div
            className={clsx(
                'plain-label',
                `plain-label--${variant}`,
                {
                    'plain-label--no-border': noBorder,
                    'plain-label--small': small,
                    'plain-label--large': large,
                    'plain-label--inline': inline,
                },
                className
            )}
            {...props}
        >
            <FieldWrapper className={`plain-label-border--${borderVariant}`} label={label} unit={unit}>
                {title}
            </FieldWrapper>
        </div>
    );
}
