/* eslint-disable eqeqeq */
import React from 'react';
import AddedBankAccount from '@profile/components/AddedBankAccount/AddedBankAccount';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
    Alert,
    AlertVariants,
    Card,
    FieldWrapper,
    MojProgressBar,
    ReadOnlyField,
    ReadOnlyFieldBorderVariant,
} from '@core/components/shared';
import './DealInfo.scss';
import { convertNumberWithFractionCurrency, getReadableNumberWithoutFormat, getReadablePrice } from '@common/utils/numbers-utils';
import { FormikProps, FormikValues } from 'formik';
import { IMortgageEntity, RequestTypes } from '@common/models/Transaction';
import { BuyingMethods as PaymentMethod } from '@/core/assets/enums';
import SadadInfo from '@/modules/my-transactions/components/SadadInfo/SadadInfo';
import { NewRequestTypes } from '@/common/enums/deal-request-types.enum';
import { ITaxInfo } from '@/common/models/OpenMarketRequest';

type Props = {
    hideActions?: boolean;
    progressTitle?: string;
    progress?: number;
    price?: number;
    targetPrice?: number;
    pricePerMeter?: number;
    iban?: string;
    isMortgage?: boolean;
    mortgageValues?: IMortgageEntity;
    formikProps?: FormikProps<FormikValues>;
    virtualAccountBalance?: number;
    requestType?: RequestTypes;
    PreferredPaymentMethod?: PaymentMethod;
    newRequestType?: NewRequestTypes;
    LastInstalmentDate?: string;
    taxInfo?: ITaxInfo | null;
    taxAmount?: number;
    basePrice?: number;
}

export default function DealInfo({
    hideActions,
    progressTitle,
    progress,
    formikProps,
    price,
    targetPrice,
    pricePerMeter,
    iban,
    isMortgage,
    mortgageValues,
    virtualAccountBalance,
    requestType,
    PreferredPaymentMethod,
    newRequestType,
    LastInstalmentDate,
    taxInfo,
    taxAmount,
    basePrice
}: Props) {
    const { t } = useTranslation();
    const getTransactionDetailsTitle = () => {
        const isNotReleaseOrAccept = requestType !== RequestTypes.ReleaseMortgage && requestType !== RequestTypes.AcceptEPUMortageDeed;
        const isMortgageCondition = (isMortgage && requestType !== RequestTypes.CreateMortgage && requestType !== RequestTypes.CREATE_MORTGAGE_ThirdParty) || requestType === RequestTypes.MortagageSell;
        const isDealAndPositivePrice = newRequestType === NewRequestTypes.DEAL && price! > 0;

        if (!isMortgage || (price! >= 0 && isNotReleaseOrAccept && isMortgageCondition) || isDealAndPositivePrice) {
            return t("buying-requests.deal-info.transaction-details.title");
        } else if (
            requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT ||
            requestType === RequestTypes.MortgageTransfer ||
            requestType === RequestTypes.AcceptEPUMortageDeed
        ) {
            return t("finance.new-title");
        } else {
            return t("finance.title");
        }
    };

    return (
        <div className="deal-info">
            {!hideActions ? (
                <Card>
                    <Row className="deal-info__title">
                        <Col span={8}>{progressTitle || t('buying-requests.deal-info.title')}</Col>
                        <Col className="progressbar-container" span={16}>
                            <MojProgressBar progress={typeof progress === 'undefined' ? 40 : progress} />
                        </Col>
                    </Row>
                    <Divider />
                </Card>
            ) : (
                <></>
            )}

            {(
                (price == 0 && (isMortgage || requestType == RequestTypes.MortagageSell)) || price || iban) && (
                    <Card className="transaction-details">
                        <Card.Head className="transaction-details-head ">
                            <div className="transaction-details__title border-bottom">
                                {getTransactionDetailsTitle()}
                            </div>
                        </Card.Head>
                        <Card.Body>
                            {hideActions && (
                                <>
                                    {(price || (price == 0 && (isMortgage || requestType == RequestTypes.MortagageSell)))
                                        && (
                                            <Row>
                                                <Col flex="1 1 auto">
                                                    <ReadOnlyField
                                                        small
                                                        label={
                                                            (!isMortgage ||
                                                                ((price >= 0 && requestType !== RequestTypes.ReleaseMortgage) &&
                                                                    ((isMortgage && requestType !== RequestTypes.CreateMortgage && requestType !== RequestTypes.CREATE_MORTGAGE_ThirdParty) || requestType == RequestTypes.MortagageSell)) ||
                                                                (newRequestType == NewRequestTypes.DEAL && price > 0)
                                                            )
                                                                ? t('buying-requests.transaction-amount-in-number')
                                                                : (requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT
                                                                    ? t('finance.new-amount-in-digits')
                                                                    : t('finance.amount-in-digits'))
                                                        }
                                                        title={(price == 0 && (isMortgage || requestType == RequestTypes.MortagageSell))
                                                            ? 0 : getReadablePrice(formikProps?.values.price || price || 0)}
                                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                        unit={`(${t('shared.sar')})`}
                                                    />
                                                </Col>

                                                {(
                                                    // Check if it's a mortgage with a positive price
                                                    // (isMortgage && price > 0)

                                                    // Check if it's not a mortgage
                                                    !isMortgage ||

                                                    // Check for a specific case when price is 0 and other conditions
                                                    (price === 0 && ((isMortgage && requestType !== RequestTypes.CreateMortgage && requestType !== RequestTypes.CREATE_MORTGAGE_ThirdParty) || requestType === RequestTypes.MortagageSell)) ||

                                                    // Check if the request type is not one of these options
                                                    (requestType !== RequestTypes.MortgageTransfer &&
                                                        requestType !== RequestTypes.UPDATE_MORTGAGE_AMOUNT &&
                                                        requestType !== RequestTypes.CreateMortgage &&
                                                        requestType !== RequestTypes.ReleaseMortgage &&
                                                        requestType !== RequestTypes.AcceptEPUMortageDeed &&
                                                        requestType !== RequestTypes.CREATE_MORTGAGE_ThirdParty
                                                    )
                                                ) && (
                                                        <Col flex="1 1 auto">
                                                            <ReadOnlyField
                                                                small
                                                                label={t('deal.price-per-meter')}
                                                                title={(price == 0 &&
                                                                    (isMortgage || requestType == RequestTypes.MortagageSell)) ? 0 :
                                                                    getReadableNumberWithoutFormat(
                                                                        formikProps?.values.pricePerMeter || pricePerMeter!! || 0
                                                                    )}
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                unit={`(${t('shared.sar')})`}
                                                            />
                                                        </Col>
                                                    )}

                                                <Col flex="1 1 auto">
                                                    <ReadOnlyField
                                                        small
                                                        label={
                                                            (!isMortgage ||
                                                                (newRequestType == NewRequestTypes.DEAL) ||
                                                                ((price >= 0 && requestType !== RequestTypes.ReleaseMortgage) &&
                                                                    ((isMortgage && requestType !== RequestTypes.CreateMortgage && requestType !== RequestTypes.CREATE_MORTGAGE_ThirdParty) || requestType == RequestTypes.MortagageSell)))
                                                                ? t('buying-requests.transaction-amount-in-words')
                                                                : (requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT
                                                                    ? t('finance.new-amount-in-words') : t('finance.amount-in-words'))
                                                        }
                                                        title={convertNumberWithFractionCurrency(formikProps?.values.price || price || 0)}
                                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                        unit={`(${t('shared.sar')})`}
                                                    />
                                                </Col>
                                                {(formikProps?.values.LastInstalmentDate || LastInstalmentDate) && isMortgage && (
                                                    <Col flex="1 1 auto">
                                                        <ReadOnlyField
                                                            small
                                                            label={requestType === RequestTypes.UPDATE_MORTGAGE_AMOUNT ? t('finance.new-mortgage-claiming-date') : t('finance.mortgage-claiming-date')}
                                                            title={dayjs(formikProps?.values.LastInstalmentDate || LastInstalmentDate).format('DD/MM/YYYY')}
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                        />
                                                    </Col>
                                                )}

                                                {((taxInfo?.RequestAmounts && taxInfo.RequestAmounts.length) || taxAmount) && (
                                                    <>
                                                        <Col flex="1 1 auto">
                                                            <ReadOnlyField
                                                                small
                                                                label={t('finance.foreign-transaction-tax-value')}
                                                                title={getReadablePrice(taxAmount || taxInfo?.RequestAmounts?.at(0)?.Amount)}
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                unit={`(${t('shared.sar')})`}
                                                            />
                                                        </Col>
                                                        <Col flex="1 1 auto">
                                                            <ReadOnlyField
                                                                small
                                                                label={t('finance.total-after-tax')}
                                                                title={getReadablePrice(price + (taxInfo?.RequestAmounts?.at(0)?.Amount! || taxAmount!))}
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                unit={`(${t('shared.sar')})`}
                                                            />
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        )}

                                    {iban ? (
                                        <>
                                            <Row>
                                                <Col flex="1 1 auto">
                                                    {PreferredPaymentMethod === PaymentMethod.SADAD ?
                                                        <SadadInfo
                                                            iban={iban}
                                                            noBorder={false}
                                                        />
                                                        :
                                                        <AddedBankAccount
                                                            iban={iban}
                                                            hideActions={true}
                                                            noBorder={false}
                                                            isVirtualIban={true}
                                                        />
                                                    }
                                                </Col>
                                                <Col flex="1 1 auto">
                                                    {PreferredPaymentMethod === PaymentMethod.SADAD ?
                                                        <ReadOnlyField
                                                            small
                                                            label={"اسم المفوتر"}
                                                            title={"312 - الإفراغ العقاري الإلكتروني"}
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                        />
                                                        :
                                                        <ReadOnlyField
                                                            small
                                                            label={"اسم الحساب"}
                                                            title={"عملية الإفراغ العقاري لوزارة العدل"}
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                        />
                                                    }
                                                </Col>
                                            </Row>

                                            <Row>
                                                {
                                                    targetPrice ? (
                                                        <Col flex="3 1 auto">
                                                            <ReadOnlyField
                                                                small
                                                                label={t('buying-requests.virtual-account-target-price')}
                                                                title={getReadablePrice(targetPrice)}
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                unit={`(${t('shared.sar')})`}
                                                            />
                                                        </Col>
                                                    ) : null
                                                }
                                                {
                                                    virtualAccountBalance ? (
                                                        <Col flex="17 1 auto">
                                                            <ReadOnlyField
                                                                small
                                                                label={PreferredPaymentMethod === PaymentMethod.SADAD ? t('buying-requests.transaction-invoice-balance') : t('buying-requests.virtual-account-balance')}
                                                                title={getReadablePrice(virtualAccountBalance)}
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                unit={`(${t('shared.sar')})`}
                                                            />
                                                        </Col>
                                                    ) : null
                                                }
                                            </Row>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {basePrice && (<Row>
                                        <Col flex="1 1 auto">
                                            <ReadOnlyField
                                                small
                                                label={t('auction.base-price')}
                                                title={getReadablePrice(basePrice)}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                unit={`(${t('shared.sar')})`}
                                            />
                                        </Col>
                                    </Row>)}
                                </>
                            )}

                            {!hideActions ? (
                                <Row>
                                    <Col span="24">
                                        <FieldWrapper
                                            label={t(
                                                'buying-requests.deal-info.transaction-details.transaction-amount-in-numbers'
                                            )}
                                        >
                                            <input
                                                type="search"
                                                className="moj-input moj-input--success moj-input--full-width"
                                            />
                                        </FieldWrapper>
                                    </Col>
                                    <Col span="24">
                                        <FieldWrapper
                                            label={t('buying-requests.deal-info.transaction-details.transaction-amount-in-words')}
                                            className="amount-in-words"
                                        >
                                            <Alert
                                                hasCloseIcon={false}
                                                className="amount-in-words-alert"
                                                variant={AlertVariants.Primary}
                                            >
                                                سبعمائة وخمسون ألف ريال سعودي
                                            </Alert>
                                        </FieldWrapper>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                        </Card.Body>
                    </Card>
                )}

            {mortgageValues && mortgageValues.BeneficiaryAmount ? (
                <>
                    {mortgageValues.BeneficiaryAmount && (
                        <Card>
                            <Card.Head className="transaction-details-head">
                                <div className="transaction-details__title border-bottom">{t('finance.new-title')}</div>
                            </Card.Head>
                            <Card.Body>
                                <Row>
                                    <Col flex="auto">
                                        <ReadOnlyField
                                            small
                                            label={t('buying-requests.mortgage-details.amount')}
                                            title={getReadablePrice(mortgageValues.BeneficiaryAmount)}
                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            unit={`(${t('shared.sar')})`}
                                        />
                                    </Col>
                                    <Col flex="auto">
                                        <ReadOnlyField
                                            small
                                            label={t('buying-requests.mortgage-details.amount-written')}
                                            title={convertNumberWithFractionCurrency(mortgageValues.BeneficiaryAmount || 0)}
                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            unit={`(${t('shared.sar')})`}
                                        />
                                    </Col>
                                    {mortgageValues.LastInstalmentDate && (
                                        <Col flex="auto">
                                            <ReadOnlyField
                                                small
                                                label={t('finance.mortgage-claiming-date')}
                                                title={dayjs(mortgageValues.LastInstalmentDate!).format('DD/MM/YYYY')}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                    )}
                </>
            ) : (
                <></>
            )}

            {mortgageValues && (
                <Card>
                    <Card.Head className="transaction-details-head">
                        <div className="transaction-details__title border-bottom">{t('finance.mortgagee-details')}</div>
                    </Card.Head>
                    <Card.Body>
                        {mortgageValues ? (
                            <div>
                                <Row style={{ width: '100%' }} align="middle">
                                    {mortgageValues.Name && (
                                        <Col flex="auto">
                                            <ReadOnlyField
                                                small
                                                label={t('mortgage.provider-name')}
                                                title={mortgageValues.Name!!}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            />
                                        </Col>
                                    )}
                                    {mortgageValues.SocialId && (
                                        <Col flex="auto">
                                            <ReadOnlyField
                                                small
                                                label={t('shared.facility-national')}
                                                title={mortgageValues.SocialId!!}
                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                {
                                    mortgageValues.Attorneys?.map((beneficiary, index) => (
                                        <>
                                            <Divider />
                                            <Row key={index}>
                                                <Col flex="1 1 auto">
                                                    <ReadOnlyField
                                                        small
                                                        label={t('mortgage.agent-name')}
                                                        title={beneficiary.Name!!}
                                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    />
                                                </Col>
                                                <Col flex="auto">
                                                    <ReadOnlyField
                                                        small
                                                        label={t('mortgage.agent-id')}
                                                        title={beneficiary.SocialId!!}
                                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    />
                                                </Col>
                                                <Col flex="auto">
                                                    <ReadOnlyField
                                                        small
                                                        label={t('shared.mobile-number')}
                                                        title={beneficiary.MobileNumber!!}
                                                        borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    ))
                                }
                            </div>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
            )}
        </div>
    );
}
