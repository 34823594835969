import { Radio, RadioGroupProps, RadioProps } from 'antd';
import clsx from 'clsx';
import React from 'react';
import './MojRadio.scss';

interface MojRadioProps extends RadioProps {
    className?: string;
}

export function MojRadio({ className, ...props }: MojRadioProps) {
    return <Radio className={clsx('moj-radio', className)} {...props}></Radio>;
}

function RadioGroup({ children, ...props }: RadioGroupProps) {
    return <Radio.Group {...props}>{children}</Radio.Group>;
}
MojRadio.Group = RadioGroup;

