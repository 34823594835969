import clsx from 'clsx';
import React from 'react';
import './Progressbar.scss';

interface ProgressBarProps {
    progress: number;
    className?: string;
}

export function MojProgressBar({ progress: percentage, className }: ProgressBarProps) {
    return (
        <div className={clsx('moj-progress-bar', className)}>
            <div className="moj-progress-bar--progress" style={{ width: `${percentage}%` }}>
                {' '}
            </div>
        </div>
    );
}
