import React from "react";

import {Card} from "@core/components/shared";
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import {AddedBuyer} from "@open-market/components/AddedBuyer/AddedBuyer";
import {Buyer, RequestTypes} from "@common/models/Transaction";

import "./BuyerSellerTemplate.scss";
import { getPercentageOwnership } from "@/common/utils/constants";

interface IProps {
    buyers?: Buyer[];
    hideHeading?: boolean;
    headerText?: string;
    requestType?: RequestTypes;
}

const BuyerSellerTemplate = ({hideHeading, headerText, buyers, requestType}: IProps) => {
    const {t} = useTranslation();

    return (
        <div className="section buyer">
            <Card className="section__container" noPadding={false}>
                {
                    !hideHeading && (
                        <Row gutter={[16, 0]}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Card.Head className="section__title border-bottom">{headerText ? headerText : t('my-realestate.owners-details')}</Card.Head>
                            </Col>
                        </Row>
                    )
                }

                {buyers?.map((buyer, index) => {
                    return (
                        <Card noPadding key={buyer.SocialId ? buyer.SocialId!!.toString() : buyer.Id}>
                            <AddedBuyer
                                index={`${index + 1}`}
                                idNumber={buyer.SocialId ? buyer.SocialId!!.toString() : buyer.Id}
                                name={buyer.Name!!}
                                share={`${buyer?.Ownership ? getPercentageOwnership(buyer?.Ownership) : 100}%`}
                                iban={buyer.Iban}
                                isOwner={buyer.BeneficiaryType !== 2}
                                requestType={requestType}
                            />
                        </Card>
                    );
                })}
            </Card>
        </div>
    )
}

export default BuyerSellerTemplate;
