import React from 'react';
import { useTranslation } from 'react-i18next';
import './ReviewBuyingRequest.scss';
import DeedDetails from '@dashboard/screens/DeedDetails/DeedDetails';
import { FormikProps, FormikValues } from 'formik';
import { DeedOwner, IDeed } from '@common/models/Deed';
import DealInfo from '@core/components/shared/DealInfo/DealInfo';
import { ITaxInfo, Buyer as _Buyer } from '@common/models/OpenMarketRequest';
import { Buyer, IMortgageEntity, IVatDetails, RequestTypes, Seller } from '@common/models/Transaction';
import MortgageeDetails from '@property-mortgage/components/MortgageeDetails/MortgageeDetails';
import { SadadTaxInfo } from '../../SadadTaxInfo/SadadTaxInfo';
import MortgageeFullDetails from "@property-mortgage/components/MortgageeFullDetails/MortgageeFullDetails";
import BuyerSellerTemplate from "@core/components/shared/BuyerSellerTemplate/BuyerSellerTemplate";
import { NewRequestTypes } from '@/common/enums/deal-request-types.enum';

function _buyerToBuyer(buyer: _Buyer): Buyer {
    return {
        Id: buyer.id,
        Name: buyer.name!!,
        IdType: buyer.idType,
        DobHijri: buyer.dobHijri,
        MobileNumber: null,
        BeneficiaryType: 2,
        Email: null,
        Gender: null,
        Ownership: buyer.ownershipAmount
    };
}

export type ReviewRequestProps = {
    deed?: IDeed;
    amount?: number;
    buyer?: string;
    _buyers?: _Buyer[];
    buyers?: Buyer[];
    sellers?: Seller[];
    owners?: DeedOwner[];
    mortgagee?: IMortgageEntity;
    isBuyer?: boolean;
    mortgageValues?: IMortgageEntity;
    isMortgage?: boolean;
    newMortgage?: IMortgageEntity;
    formikProps: FormikProps<FormikValues>;
    buyersLabel?: string;
    vatDetails?: IVatDetails;
    mortgageeAttorneyName?: string;
    isOpenMarketDeal?: boolean;
    requestType?: RequestTypes;
    sharedArea?: number;
    pricePerMeter?: number;
    hideSharedRealStateDetails?: boolean;
    newRequestType?: NewRequestTypes;
    LastInstalmentDate?: string;
    taxInfo?: ITaxInfo | null;
    taxAmount?: number;
};
export default function ReviewRequest({
    formikProps,
    deed,
    amount,
    _buyers,
    buyers,
    sellers,
    mortgagee,
    isBuyer,
    mortgageValues,
    isMortgage,
    newMortgage,
    buyersLabel,
    vatDetails,
    isOpenMarketDeal = false,
    mortgageeAttorneyName,
    requestType,
    sharedArea = deed?.DeedArea!!,
    pricePerMeter,
    hideSharedRealStateDetails,
    newRequestType,
    LastInstalmentDate,
    taxInfo,
    taxAmount
}: ReviewRequestProps) {
    const { t } = useTranslation();

    if (!sharedArea) sharedArea = deed?.DeedArea!!;
    const buyersList = (): Buyer[] | undefined => {
        if (_buyers) {
            return _buyers!!.map((value, index) => {
                return _buyerToBuyer(value);
            });
        }
    };

    return (
        <div className="review-buying-request">
            <DealInfo
                price={amount}
                mortgageValues={mortgageValues}
                pricePerMeter={pricePerMeter || amount!! / sharedArea}
                isMortgage={isMortgage ?? deed?.IsRealEstateMortgaged}
                hideActions
                requestType={requestType}
                newRequestType={newRequestType}
                LastInstalmentDate={LastInstalmentDate}
                taxInfo={taxInfo}
                taxAmount={taxAmount}
            />

            {mortgagee && <MortgageeDetails mortgagee={mortgagee} isNew={mortgagee.BeneficiaryType === 6} />}
            {newMortgage && (
                <MortgageeFullDetails mortgagee={newMortgage} isNew headerText={t('mortgage.new-mortgagee-details')} requestType={requestType} />
            )}
            {_buyers && _buyers.length > 0 && (
                <BuyerSellerTemplate buyers={buyersList()} headerText={buyersLabel ?? t('add-buyers.buyer-details')} />
            )}
            {buyers && (
                <BuyerSellerTemplate buyers={buyers} headerText={buyersLabel ?? t('add-buyers.buyer-details')} />
            )}
            {sellers && (
                <BuyerSellerTemplate buyers={sellers} headerText={t('my-realestate.owners-details')} />
            )}

            {vatDetails && <SadadTaxInfo vatDetails={vatDetails} />}

            <DeedDetails deed={deed} hideActions requestType={requestType} hideDetails={hideSharedRealStateDetails} />
        </div>
    );
}
