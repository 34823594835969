/* eslint-disable */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import {
    getReadableNumber,
    getReadableNumberWithFormat,
    getReadableNumberWithoutFormat,
} from '@common/utils/numbers-utils';
import { ReadOnlyField, ReadOnlyFieldVariant } from '@core/components/shared/ReadonlyField/ReadonlyField';
import { ReactComponent as ShareIcon } from '@/core/assets/images/assets-icons-share.svg';
import MojLabel, { LabelVariant } from '@core/components/shared/Label/MojLabel';
import { IRealestate } from '@/core/interfaces/Realestate.interface';
import { getDateAndTimeFormatted } from '@/common/utils/date-utils';
import { FEATURES } from '@/common/utils/features';
import './RealestateCard.scss';

interface OwnProps extends Omit<LinkProps, 'onClick'> {
    realestate: IRealestate;
    isOpenMarket?: boolean;
}

export enum RealestateTypes {
    Supplied,
    Demanded,
    Default,
}

type Props = OwnProps;

export const realestateType = (realestate: IRealestate) => {
    if (realestate.OpenMarketOrders && realestate.OpenMarketPrice) {
        return {
            message: 'supplied',
            color: LabelVariant.warningLight,
            type: RealestateTypes.Supplied,
            date: getDateAndTimeFormatted(new Date(realestate.OpenMarketDate as string)),
        };
    } else if (realestate.PurchaseOrders) {
        return {
            message: 'demanded',
            color: LabelVariant.success,
            type: RealestateTypes.Demanded,
            //no date required for Purchase orders
            //date: getDateAndTimeFormatted(new Date(realestate.OpenMarketDate as string)),
        };
    } else {
        return {
            message: null,
            color: LabelVariant.primary,
            type: RealestateTypes.Default,
        };
    }
}

const RealEstateCard: FunctionComponent<PropsWithChildren<Props>> = ({
    realestate,
    isOpenMarket = true,
    children,
    ...linkProps
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formattedArea = React.useMemo(() => {
        return `${realestate.District ? `${realestate.District}` : ''}, ${realestate.City || ''}`;
    }, [realestate]);

    const onShare = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigator
            .share({
                title: formattedArea,
                url: `/transactions/${realestate.RequestId}`,
            })
            .then(() => { })
            .catch((error: any) => { });
    };

    const realEstatePrice = () => {
        return realestate.MeterPrice ? <>{getReadableNumberWithoutFormat(realestate.MeterPrice)}</> : null;
    };

    const areaPercentageInfo = React.useMemo(() => {
        const openMarketPercentage = Math.floor(
            ((realestate.DeedRealEstateArea <= 0 ? realestate.Area : realestate.DeedRealEstateArea) / realestate.Area) *
            100
        );
        const openMarketPercentageValue = openMarketPercentage + '%';
        const areaText = getReadableNumber(realestate.Area);
        const suffix =
            openMarketPercentage < 100 ? (
                <MojLabel className={'shared-tag'} color={LabelVariant.warning}>
                    {t(`${t('realestate.shared')}`)}
                </MojLabel>
            ) : (
                ''
            );
        return {
            percentage: suffix ? (
                <>
                    {openMarketPercentageValue} {suffix}
                </>
            ) : (
                openMarketPercentageValue
            ),
            area: suffix ? (
                <>
                    {areaText} {suffix}
                </>
            ) : (
                areaText
            ),
        };
    }, [realestate]);

    const onClickContactOwner = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`contact-owner/${realestate.Serial}`);
    };

    return (
        <Link className="realestate-card" {...linkProps}>
            <Row justify="start" align="middle" gutter={[8, 0]}>
                <Col className="card-col__area" flex="60%">
                    <h3>{formattedArea}</h3>
                    <h5>{realestate.SremRealestateDescription}</h5>
                </Col>

                {isOpenMarket && (
                    <Col className="inline-block" flex="40%">
                        {realestateType(realestate).message && (
                            <Col>
                                <MojLabel color={realestateType(realestate).color}>{t(`${realestateType(realestate).message}`)}</MojLabel>
                            </Col>
                        )}
                        {realestateType(realestate).type === RealestateTypes.Supplied && (
                            <Col onClick={onShare} flex="24px">
                                <ShareIcon />
                            </Col>
                        )}
                        {FEATURES.CONTACT_OWNER && (realestateType(realestate).type !== RealestateTypes.Supplied) ? (
                            <Col onClick={onClickContactOwner} flex="24px">
                                <Button onClick={onClickContactOwner} type="link">
                                    <MojLabel color={LabelVariant.primary}>{t('contact-owner.title')}</MojLabel>
                                </Button>
                            </Col>
                        ) : ""}
                    </Col>
                )}
            </Row>

            {realestate.RealEstateIdentityNo && (
                <Row justify="end" align="middle" gutter={[0, 8]}>
                    <Col className="card-col" flex="1">
                        <ReadOnlyField
                            title={realestate.RealEstateIdentityNo}
                            label={t('shared.realestate-identity-no')}
                            variant={ReadOnlyFieldVariant.success}
                        />
                    </Col>
                </Row>
            )}

            <Row justify="end" align="middle" gutter={[0, 8]}>
                <Col className="card-col" flex="1">
                    <ReadOnlyField
                        title={realestate.Landnumber}
                        label={t('realestate.land-number')}
                        variant={ReadOnlyFieldVariant.primary}
                    />
                </Col>

                <Col className="card-col" flex="1">
                    <ReadOnlyField
                        title={realestate.Plan}
                        label={t('realestate.plan-number')}
                        variant={ReadOnlyFieldVariant.primary}
                    />
                </Col>

                <Col className="card-col" flex="1">
                    <ReadOnlyField
                        label={t('shared.area')}
                        title={areaPercentageInfo.area}
                        variant={ReadOnlyFieldVariant.primary}
                        unit={t('shared.square-meter')}
                    />
                </Col>

                {!isOpenMarket && (
                    <Col className="card-col" flex="1">
                        <ReadOnlyField
                            label={t('realestate.value')}
                            title={getReadableNumberWithFormat(realestate.LastPrice)}
                            variant={ReadOnlyFieldVariant.primary}
                        />
                    </Col>
                )}
            </Row>

            {Number(realestate.OpenMarketOrders) > 0 && (
                <Row>
                    <Col span={8}>
                        <ReadOnlyField
                            label={t('shared.owner-percentage')}
                            title={areaPercentageInfo.percentage}
                            variant={ReadOnlyFieldVariant.success}
                            unit={t('shared.percentage')}
                        />
                    </Col>
                    <Col span={8}>
                        <ReadOnlyField
                            label={t('shared.offer-value')}
                            title={getReadableNumber(realestate.OpenMarketPrice ?? 0)}
                            variant={ReadOnlyFieldVariant.success}
                            unit={t('shared.sar')}
                        />
                    </Col>
                    <Col span={8}>
                        <ReadOnlyField
                            label={t('deal.price-per-meter')}
                            title={realEstatePrice()}
                            variant={ReadOnlyFieldVariant.primary}
                            unit={t('shared.sar-per-meter')}
                        />
                    </Col>
                </Row>
            )}

            {realestateType(realestate).date && (
                <Row justify="end">
                    <Col span={24} className="openmarket-label">
                        {t('shared.ago')}
                    </Col>
                    <Col className="openmarket-date"> {realestateType(realestate).date}</Col>
                </Row>
            )}
            {children && <>{children}</>}
        </Link>
    );
};

export default RealEstateCard;
