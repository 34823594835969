import React from 'react';
import { Col, Row } from 'antd';
import { Card } from '../../../../core/components/shared';
import { useTranslation } from 'react-i18next';
import './_RealestateSort.scss';

type RealestateSortProps = {
    sortingList: { id: number; label: string; dir: string }[];
    onClickAction: (obj: { id: number; label: string; dir: string; key: number }) => void;
    onClose: () => void;
    showTitle?: boolean;
    selectedSortingType: number;
};

export default function RealestateSort({
    onClickAction,
    sortingList,
    selectedSortingType,
    showTitle,
}: RealestateSortProps) {
    const { t } = useTranslation();
    return (
        <Col flex="100%" className="section RealestateSort">
            <Card className="section__container">
                <Row justify="start" className="section__basics" gutter={0}>
                    <Col flex="100%">
                        {showTitle && (
                            <Card.Head>
                                <Row
                                    justify="space-between"
                                    align="middle"
                                    gutter={0}
                                    className="section__title border-bottom"
                                >
                                    <Col flex="auto" className="">
                                        {t('shared.sort-by')}
                                    </Col>
                                </Row>
                            </Card.Head>
                        )}

                        <Col flex="100%">
                            <Card.Body>
                                {sortingList.map((value, index) => {
                                    return (
                                        <Col
                                            className={`sort-button ${selectedSortingType === index && 'active'}`}
                                            onClick={() => onClickAction({ key: index, ...value })}
                                            key={index}
                                        >
                                            {value.label}
                                        </Col>
                                    );
                                })}
                            </Card.Body>
                        </Col>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
}
