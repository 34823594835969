/**
 * Attachment Interface used as the uploader array
 * */
export class AttachmentDto {
	constructor(
		public fileName: string,
		public contentType: string,
		public fileBase64: string,
		public size: number,
		public IsDeleted: boolean,
		public IsNew: boolean,
		public IsModified: boolean,
		public title: string,
		public AttachementId?: string,
		public key?: string
	) {}

	static default() {
		return new AttachmentDto('', '', '', 0, false, false, false, '');
	}
}

export default AttachmentDto;
