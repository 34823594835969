import { Row, Radio, RadioChangeEvent, RowProps } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import clsx from 'clsx';
import React from 'react';
import './Tabs.scss';

interface TabOptions extends RadioButtonProps {
    value: number | string;
    label: string;
    disabled?: boolean;
}
export interface TabsProps extends Omit<RowProps, 'onChange'> {
    className?: string;
    value: number | string;
    onChange?: (value: RadioChangeEvent) => void;
    options: TabOptions[];
    optionType?: "default" | "button"
}
export function Tabs({ options, value, className, optionType = "default", onChange }: TabsProps) {
    return (
        <Row className={clsx('moj-tabs', className)}>
            <Radio.Group
                optionType={optionType}
                options={options}
                value={value}
                onChange={(e) => onChange && onChange(e)}
            />
        </Row>
    );
}
