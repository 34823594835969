import { AxiosInstance } from 'axios';
import { createClient } from '@common/services/index';
import { NewRequestTypes } from '../enums/deal-request-types.enum';
export interface ValidatePerson {
    id: number;
    idType: number;
    dobHijri?: string;
}
export default class OpenMarketService {
    HttpClient!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
    }

    create = async (body: any) => {
        return await this.HttpClient.post(`OpenMarket/Create`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    validateBuyer = async (buyer: ValidatePerson, requestType?: NewRequestTypes | null) => {
        var submittedObject =
            requestType == null || undefined ? { buyer: buyer } : { buyer: buyer, requestType: requestType };
        return await this.HttpClient.post(`Validation/Buyer`, submittedObject)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    accept = async (props) => {
        return await this.HttpClient.post(`OpenMarket/Accept`, props)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    sellerAccept = async (body: any) => {
        return await this.HttpClient.post(`OpenMarket/SellerAccept`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    cancel = async (body: any) => {
        return await this.HttpClient.post(`OpenMarket/Cancel`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    updateDealAmount = async (body: any) => {
        return await this.HttpClient.post(`OpenMarket/UpdatePrice`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
    initiatePayment = async (body: { amount?: number; category?: number, RequestId?: string }) => {
        return await this.HttpClient.post(`PaymentGateway/InitiatePayment`, body)
            .then((value) => {
                return value;
            })
            .catch((reason) => {
                return reason;
            });
    };
}

export const openMarketService = new OpenMarketService();
