import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import moment from 'moment/moment';

import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import MojLabel, { LabelVariant } from '@/core/components/shared/Label/MojLabel';
import { MojButton, ReadOnlyField, ReadOnlyFieldVariant } from '@/core/components/shared';
import { getReadableNumber } from '@/common/utils/numbers-utils';
import './_MapInfo.scss';
import { FEATURES } from '@/common/utils/features';
import { realestateType, RealestateTypes } from '@/core/components/shared/RealEstateCard/RealestateCard';
import { useNavigate } from 'react-router';

interface MapInfoProps {
    data: any;
    handleViewMore: any;
}

const MapInfo: FunctionComponent<MapInfoProps> = ({ data, handleViewMore }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getStatusColor = () => {
        return data?.polygonColor;
    };
    const getStatusText = () => {
        if (data?.purchaseOrders) {
            return t('demanded');
        } else if (data?.openMarketOrders) {
            return t('supplied');
        }
        return t('default');
    };

    let realStateType =
        realestateAndDealsStore?.realestateTypes.filter((item) => item.Id === data?.realEstateType)?.length > 0
            ? realestateAndDealsStore?.realestateTypes.filter((item) => item.Id === data?.realEstateType)[0]
            : null;

    const onClickContactOwner = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/open-market/contact-owner/${data.serial}`);
    };

    return (
        <div>
            <div className="header">
                <Row justify="space-between" align="middle">
                    <Col span="12">
                        <MojLabel
                            className={`label `}
                            style={{
                                backgroundColor: getStatusColor(),
                            }}
                            color={LabelVariant.trans}
                        >
                            {getStatusText()}
                        </MojLabel>
                    </Col>

                    <Col span="12">
                        <div className="date">
                            {`${t('map-info.lbl-from')} ${moment(data?.openMarketDate || new Date())
                                .locale('en')
                                .format('DD/MM/YYYY - HH:mm')}`}
                        </div>
                    </Col>
                </Row>
                <Row justify="space-between" align="middle">
                    <Col span="18">
                        <MojLabel className="title" color={LabelVariant.trans}>
                            {data?.name ?? ''}
                        </MojLabel>
                    </Col>
                    <Col span="6">
                        <MojLabel className="label type" color={LabelVariant.trans}>
                            {realStateType?.Name ?? 'ارض'}
                        </MojLabel>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row justify="space-between" align="middle">
                    {data.openMarketPrice !== null && (
                        <Col span={8}>
                            <ReadOnlyField
                                title={getReadableNumber(data?.openMarketPrice) || t('map-info.lbl-no-data')}
                                unit={t('map-info.lbl-sar')}
                                label={t('map-info.lbl-offer-price')}
                                noBorder={true}
                                variant={ReadOnlyFieldVariant.primary}
                                small
                            />
                        </Col>
                    )}
                    {data.meterPrice && (
                        <Col span={8}>
                            <ReadOnlyField
                                title={getReadableNumber(data?.meterPrice) || t('map-info.lbl-no-data')}
                                unit={t('map-info.lbl-metresqaure-sar')}
                                label={t('map-info.lbl-metre-price')}
                                noBorder={true}
                                variant={ReadOnlyFieldVariant.primary}
                                small
                            />
                        </Col>
                    )}
                    {data.area && (
                        <Col span={8}>
                            <ReadOnlyField
                                title={getReadableNumber(data?.area) || t('map-info.lbl-no-data')}
                                unit={t('map-info.lbl-metresqaure')}
                                label={t('map-info.lbl-area')}
                                noBorder={true}
                                variant={ReadOnlyFieldVariant.primary}
                                small
                            />
                        </Col>
                    )}
                </Row>
            </div>

            <Button className="view-more" onClick={() => handleViewMore(data)}>
                {t('map-info.lbl-view-more')}
            </Button>
            {FEATURES.CONTACT_OWNER && (realestateType(data).type !== RealestateTypes.Supplied) ? (
                <MojButton color="label" onClick={onClickContactOwner}>
                    {t('contact-owner.title')}
                </MojButton>
            ) : null}
        </div>
    );
};
export default MapInfo;
