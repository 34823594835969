import { createClient } from '@common/services';
import { AxiosInstance } from 'axios';

export default class CommonService {
    HttpClient!: AxiosInstance;
    ValidationInstance!: AxiosInstance;
    OtpInstance!: AxiosInstance;
    SremInstance!: AxiosInstance;
    diNetworkInstance!: AxiosInstance;

    constructor() {
        this.HttpClient = createClient(`${process.env.REACT_APP_API_URL_REPLACE}api/v1`);
        this.ValidationInstance = createClient(`${process.env.REACT_APP_SREM_URL}api/v1/Validation`);
        this.OtpInstance = createClient(`${process.env.REACT_APP_SREM_URL}api/v1/Otp`);
        this.SremInstance = createClient(`${process.env.REACT_APP_SREM_URL}api/v1`);
        this.diNetworkInstance = createClient(`${process.env.REACT_APP_DI_API_URL}api/v1`);
    }
    // Restful Api's
    getDeed = async (body: { deedNumber: string; ownerId: string; idType: string }) => {
        await this.HttpClient.get(`Deed/GetDeed/${body.deedNumber}/${body.ownerId}/${body.idType}`)
            .then((value) => {
                return value.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedDetails = async (body: { deedNumber: string; ownerId: string; idType: string }) => {
        await this.HttpClient.get(`Deed/Details/${body.deedNumber}/${body.ownerId}/${body.idType}`)
            .then((value) => {
                return value.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getDeedText = async (body: { deedNumber: string; ownerId: string; idType: string }) => {
        await this.HttpClient.get(`Deed/Text/${body.deedNumber}/${body.ownerId}/${body.idType}`)
            .then((value) => {
                return value.data;
            })
            .catch((reason) => {
                return reason;
            });
    };

    getMortgageeByCompanyId = (companyId) => {
        return this.SremInstance.get(`Validation/Mortgagee?company700Id=${companyId}`);
    };

    async sendValidationOtp(mobileNumber: string, service: string) {
        await this.OtpInstance.post(`/Send`, {
            mobileNumber,
            service,
        })
            .then((value) => {
                return value.data;
            })
            .catch((reason) => {
                return reason;
            });
    }

    getFaqs = async () => {
        try {
            const response = await this.diNetworkInstance.get(`MobileFeature/GetFaqs`);
            return response;
        } catch (reason) {
            console.error('Error in getFaqs:', reason);
            throw reason;
        }
    };

    getMaintenanceSchedules = async () => {
        try {
            const response = await this.diNetworkInstance.get(`MobileFeature/GetMaintenanceSchedules`);
            return response;
        } catch (reason) {
            throw reason;
        }
    };

    getNextMaintenanceSchedules = async () => {
        try {
            const response = await this.diNetworkInstance.get(`MobileFeature/GetNextMaintenanceSchedules`);
            return response;
        } catch (reason) {
            throw reason;
        }
    };
}

export const commonService = new CommonService();
