import { FunctionComponent, SVGProps } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

export function NavbarSubOption({
    title,
    noBorder,
    link,
    onClick,
    FixedIcon,
    disabled,
    suffix,
}: {
    title: string;
    noBorder?: boolean;
    link: string;
    onClick: () => void;
    disabled?: boolean;
    suffix?: React.ReactNode;
    FixedIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}) {
    return (
        <Link className="nav-link" onClick={onClick} to={link}>
            <div
                className={clsx('navbar-option__sub-option', {
                    'navbar-option__sub-option--no-border': noBorder,
                    'navbar-option__sub-option--disabled': disabled,
                })}
            >
                <span className="navbar-option__sub-option__inline">
                    <span className="navbar-option__sub-option__title">{title}</span>
                    {suffix && <span className="navbar-option__sub-option__suffix">{suffix}</span>}
                </span>

                {FixedIcon && (
                    <div className={clsx('navbar-fixed-icon')}>
                        <FixedIcon />
                    </div>
                )}
            </div>
        </Link>
    );
}
