import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requiredInput, validationSchema1 } from '@core/components/shared/DeedInquiryForm/validator';
import './_search_deed.scss';
import DeedDetails from '@dashboard/screens/DeedDetails/DeedDetails';
import OtpForm from '@property-mortgage/components/OtpForm/OtpForm';
import { FormikProps, FormikValues } from 'formik';
import { observer } from 'mobx-react-lite';
import { IMultiStepContainer } from '@core/components/shared/MultiStepContainer/interface/multi-step-container.interface';
import MultiStepContainer from '@core/components/shared/MultiStepContainer/MultiStepContainer';
import * as Yup from 'yup';
import DeedService from '@common/services/deed.service';
import GenericResponse from '@/common/models/GenericResponse';
import { OtpRequestType } from '@/core/assets/enums';
import { OtpResponse } from '@/core/interfaces/OtpResponse.interface';
import { Col, message, Row } from 'antd';
import MortgageInquiryForm from '@property-mortgage/components/MortgageInquiryForm/MortgageInquiryForm';
import useMultiStepContainer from '@core/components/shared/MultiStepContainer/util/useMultiStepContainer';
import { authStore } from '@common/stores/auth.store';
import { Card, MojButton, MojPopup } from '@core/components/shared';
import ReviewRequest from '@open-market/screens/ReviewBuyingRequest/ReviewRequest';
import MortgageService from '@common/services/mortgage.service';
import { sellOrderServiceInstance } from '@common/services/sell-order.service';
import { MortgageeRequest } from '@common/models/MortgageRequest';
import { NewRequestResponse } from '@common/models/NewRequestResponse';
import RequestSuccess from '@open-market/screens/NewPurchaseOrder/RequestSuccess';
import RealEstatesServices from '@common/services/realestate.services';
import { attorneyStore } from '@common/stores/attorney.store';
import { IPreValidateResult, convertDeedOwnersToSellers } from '@/common/models/OpenMarketRequest';
import TermsAndConditions from '@dashboard/components/TermsAndConditionsLayout/TermsAndConditions';
import { Terms } from '@dashboard/components/TermsAndConditionsLayout/builder/TermsBuilder';
import UpdateBeneficiaryBirthDateForm from '@property-mortgage/components/UpdateBeneficiaryBirthDateForm/UpdateBeneficiaryBirthDateForm';
import MortgageInfo from '@/modules/property-mortgage/components/MortgageInfo/MortgageInfo';
import { mortgageAmountValidator } from '@core/components/shared/DeedInquiryForm/validator';
import { updateMortgageAmountStore } from '@/common/stores/update-mortgage-amount.store';
import { IDeed, IDeedMortgagee } from '@/common/models/Deed';
import { RequestTypes } from '@/common/models/Transaction';
import { NationalIdTypes } from '@/common/enums/national-id-types';

const SearchDeed = () => {
    const { t } = useTranslation();
    const deedService = new DeedService();
    const mortgageService = useMemo(() => new MortgageService(), []);
    const [deed, setDeed] = useState<IDeed>();
    const _useMultiStepContainer = useMultiStepContainer();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [newRequestResponse, setNewRequestResponse] = React.useState<NewRequestResponse>();
    const [ownerBirthDates, setOwnerBirthDates] = useState([]);
    const [terms, setTerms] = useState(new Terms());
    const [otpConfiguration, setOtpConfiguration] = useState<OtpResponse | undefined>();
    const [canProceed, setCanProceed] = useState(true);
    const realestatesService = new RealEstatesServices();
    const [missingBirthDateOwners, setMissingBirthDateOwners] = useState([]);
    const [mortgageRequest, setMortgageRequest] = useState<MortgageeRequest>({
        deedSerial: +deed?.DeedSerial!!,
        expiryDateDays: 10,
        LastInstalmentDate: undefined
    });
    const [releaseMortgageRequest, setReleaseMortgageRequest] = useState<MortgageeRequest>({
        deedSerial: +updateMortgageAmountStore.deed?.DeedSerial!!,
        mortgageAmount: 0,
    });
    const [releaseFormValues, setReleaseFormValues] = useState(undefined);
    const updateTerms = (terms: Terms) => {
        setTerms(terms);
    };

    const getOwnersWithMissingBirthDates = () => {
        let owners: any = [];
        if(updateMortgageAmountStore!.deed) {
            updateMortgageAmountStore!.deed!.DeedOwners!.forEach(owner => {
                if ((!owner.BirthDateYear || !owner.BirthDateMonth || !owner.BirthDateDay) && (+owner.IdType === NationalIdTypes.NATIONAL_ID || +owner.IdType === NationalIdTypes.IQAMA_ID)) {
                    owners = [...owners, owner];
                }
            });
        }
        return owners;
    }    

    const mortgageDetailsFromBuilder = useCallback(() => {
        const initialValues = {
            duration: mortgageRequest!!.expiryDateDays,
            mortgageAmount: mortgageRequest!!.mortgageAmount,
            LastInstalmentDate: ''
        };

        if (initialValues.mortgageAmount!! > 0) {
            updateMortgageAmountStore.updateDeedAmount(mortgageRequest!!.mortgageAmount!);
        }

        const validationSchema = Yup.object().shape({
            mortgageAmount: mortgageAmountValidator,
            duration: requiredInput,
        });

        const submitForm = async (values?: any) => {
            if (!values.year || !values.month || !values.day) {
                _useMultiStepContainer.setErrorDetails([t('shared.required-claiming-mortgage-date')]);
                return;
            }

            if (values.year.toString().length !== 4) {
                _useMultiStepContainer.setErrorDetails([t('shared.invalid-date')]);
                return;
            }

            updateMortgageAmountStore.updateLastInstalmentDate(values?.LastInstalmentDate);

            const _omr = mortgageRequest;

            _omr!!.mortgageAmount = +values?.mortgageAmount!!;

            _omr!!.expiryDateDays = +values?.duration!!;

            _omr!!.deedSerial = updateMortgageAmountStore!.deed!.DeedSerial;

            _omr!!.mortgagors = convertDeedOwnersToSellers(updateMortgageAmountStore!.deed!.DeedOwners!!);

            _omr!!.LastInstalmentDate = values?.LastInstalmentDate;

            setMortgageRequest(_omr);


            _useMultiStepContainer.nextStep();
        };

        return { initialValues, validationSchema, submitForm };
    }, [_useMultiStepContainer, deed?.DeedSerial, mortgageRequest]);

    const diFromBuilder = () => {
        const initialValues = {
            idNumber: '',
            deedNumber: '',
            idType: 1,
            deedDate: '',
            validationType: 0,
        };

        const ensureDeedMortgagee = async (deed: IDeed): Promise<IDeedMortgagee | undefined> => {

            if (!deed.IsRealEstateMortgaged) {
                _useMultiStepContainer.setErrorDetails(['لا توجد بيانات مطابقة']);

                return undefined;
            }

            const mortgageResponse: GenericResponse = await realestatesService.getDeedMortgageInfo({
                deedSerial: deed.DeedSerial!!.toString(),
            });

            
            if (mortgageResponse?.IsSuccess!!) {
                let mortgageeId = mortgageResponse?.Data?.Mortgagee?.MortgageeId;
                
                if (attorneyStore.isAttorneyLoggedIn()) {
                    let currentMorgagee = attorneyStore.attorney?.PersonInfo;

                    if (mortgageeId === currentMorgagee?.SocialId.toString()) {
                        return mortgageResponse.Data.Mortgagee as IDeedMortgagee;
                    }

                    _useMultiStepContainer.setErrorDetails([
                        `الجهة الراهنة الحالية ليست ${currentMorgagee?.Name}، يرجى التحقق من البيانات المدخلة`,
                    ]);
                } else {
                    if (mortgageeId === authStore.userDetails.IdentityNo) {
                        return mortgageResponse.Data.Mortgagee as IDeedMortgagee;
                    }

                    _useMultiStepContainer.setErrorDetails([
                        `الجهة الراهنة الحالية ليست ${deed.DeedMortgagee?.MortgageeName}، يرجى التحقق من البيانات المدخلة`,
                    ]);
                }
                return undefined;
            }

            /*     var errors = ReleaseMortgageManager.getValidationErrors(deed, undefined, t)

            if(errors.length) {

                _useMultiStepContainer.setErrorDetails(errors);

            } */

            return undefined;
        };

        const submitForm = async (values?: any) => {
            const _omr = releaseMortgageRequest;

            _omr!!.deedSerial = updateMortgageAmountStore.deed?.DeedSerial;
            setReleaseMortgageRequest(_omr);

            setReleaseFormValues(values);

            let response: GenericResponse | undefined = await fetchDeedDetails(values);


            if (response === undefined) {
                setCanProceed(false);

                return;
            }

            if (response?.IsSuccess!!) {
                updateMortgageAmountStore.setLoading(true);

                const mortgageResult = await ensureDeedMortgagee(response.Data as IDeed);

                if (mortgageResult) {
                    let deed = response.Data as IDeed;

                    var result = await realestatesService.prevalidateDeed({
                        deedSerial: deed.DeedSerial.toString(),
                        requestTypes: [OtpRequestType.UPDATE_MORTGAGE_AMOUNT],
                    });

                    var errors: string[] = [];

                    if (result.IsSuccess) {
                        var preVResult = result.Data as IPreValidateResult;
                        if(preVResult.Errors?.length! > 0) {
                            setCanProceed(false);
                            _useMultiStepContainer.setErrorDetails(preVResult.Errors);
                              updateMortgageAmountStore.setLoading(false);
                              return undefined;
                        }
                    }

                    deed.DeedMortgagee = mortgageResult;
                    updateMortgageAmountStore.setDeed(deed);
                    _omr!!.deedSerial = updateMortgageAmountStore.deed.DeedSerial;
                    setReleaseMortgageRequest(_omr);
                    setMissingBirthDateOwners(getOwnersWithMissingBirthDates());
                    _useMultiStepContainer.setSteps(stepper.length);

                    if (errors.length) {
                        setCanProceed(false);

                        _useMultiStepContainer.setErrorDetails(errors);
                    } else {
                        setCanProceed(true);

                        _useMultiStepContainer.nextStep();
                    }
                }

                updateMortgageAmountStore.setLoading(false);
            }
        };

        return { initialValues, submitForm };
    };

    const fetchDeedDetails = async (values) => {
        _useMultiStepContainer.setErrorDetails([]);

        updateMortgageAmountStore.setLoading(true);

        let response: GenericResponse;

        if (values?.validationType === 1) {
            response = await deedService.getDeedInfoByDate(authStore.isLoggedIn, {
                deedNumber: values?.deedNumber!!,
                year: values?.year,
                month: values?.month.toString().padStart(2, '0'),
                day: values?.day.toString().padStart(2, '0'),
            });
        } else {
            response = await deedService.getDeedInfoByOwners(authStore.isLoggedIn, {
                deedNumber: values?.deedNumber!!,

                idNumber: values?.idNumber!!,

                idType: values?.idType!!,
            });
        }

        updateMortgageAmountStore.setLoading(false);

        if (!response?.IsSuccess!!) {
            if (response.ErrorDetails && response.ErrorDetails.length) {
                _useMultiStepContainer.setErrorDetails(response.ErrorDetails);

                _useMultiStepContainer.setCorrelationId(response.CorrelationId);
            } else if (response.ErrorList && response.ErrorList.length) {
                _useMultiStepContainer.setErrorDetails(response.ErrorList);
            }

            return undefined;
        }

        return response;
    };

    const reviewBuyingRequestFormBuilder = useCallback(() => {
        const submitForm = async (values?: any) => {
            if (terms.isAllTermsChecked()) {
                setIsConfirmModalOpen(true);
            } else {
                _useMultiStepContainer.setErrorDetails(['يجب الموافقة على جميع بنود التعهد والإقرار.']);
            }
        };

        const postNewMortgage = async (isRetrying = false) => {
            _useMultiStepContainer.setErrorDetails([]);

            setIsConfirmModalOpen(false);

            updateMortgageAmountStore.setLoading(true);
            const response: GenericResponse = await sellOrderServiceInstance.sendOtp({
                requestType: OtpRequestType.UPDATE_MORTGAGE_AMOUNT,

                smsParameters: {
                    deedNumber: updateMortgageAmountStore.deed?.DeedNo.toString(),
                    dealAmount: mortgageRequest.mortgageAmount?.toString(),
                },
            });

            if (response.IsSuccess && response.Data && response.Data.MessageSent) {
                setOtpConfiguration(response.Data);

                if (!isRetrying) {
                    _useMultiStepContainer.nextStep();
                }
            } else {
                _useMultiStepContainer.setCorrelationAndErrors(response.CorrelationId, response.ErrorDetails);
            }

            updateMortgageAmountStore.setLoading(false);
        };

        return { submitForm, postNewMortgage };
    }, [_useMultiStepContainer, releaseMortgageRequest, mortgageService, updateMortgageAmountStore.deed]);

    const otpFormBuilder = useCallback(() => {
        const initialValues = {
            otpNumber: '',
        };

        const validationSchema = Yup.object().shape({
            otpNumber: requiredInput,
        });

        const submitForm = async (values?: typeof initialValues) => {
            let updatedOpenMarketRequest = releaseMortgageRequest;

            const _omr = releaseMortgageRequest;

            _omr!!.otp = +values?.otpNumber!!;

            setReleaseMortgageRequest(_omr);

            try {
                updateMortgageAmountStore.setLoading(true);

                const otpResponse: GenericResponse = await sellOrderServiceInstance.verifyOtp({
                    key: otpConfiguration!.Key,

                    otp: values!.otpNumber,
                });

                if (otpResponse && otpResponse.Data) {
                    if (!otpResponse.Data.IsValid) {
                        setOtpConfiguration({
                            ...otpConfiguration!,

                            RemainingSeconds: otpResponse.Data.RemainingPeriod,

                            RemainingRetries: otpResponse.Data.RemainingRetries,
                        });

                        message.warn(t('otp.invalid'));

                        updateMortgageAmountStore.setLoading(false);

                        return;
                    } else {
                        updatedOpenMarketRequest = {
                            deedSerial: updateMortgageAmountStore.deed!!.DeedSerial,

                            oldMortgageAmount: updateMortgageAmountStore.deed!!.DeedMortgagee?.MortgageAmount,

                            ...mortgageRequest!!,

                            otpVerify: {
                                key: otpConfiguration!.Key,

                                otp: values!.otpNumber,
                            },
                        };
                    }
                }
            } catch (e) {
                message.warn(t('otp.invalid'));

                return;
            }

            const res: GenericResponse = await mortgageService.updateMortgageAmount(updatedOpenMarketRequest!!);

            if (res.IsSuccess) {
                setNewRequestResponse(res.Data);
                _useMultiStepContainer.nextStep();
            } else {
                _useMultiStepContainer.setCorrelationAndErrors(res.CorrelationId, res.ErrorDetails);
            }
            updateMortgageAmountStore.setLoading(false);
        };
        return { initialValues, validationSchema, submitForm };
    }, [
        _useMultiStepContainer,
        releaseMortgageRequest,
        mortgageService,
        otpConfiguration,
        t,
        updateMortgageAmountStore.deed,
    ]);

    const updateBeneficiaryBirthDateFormBuilder = useCallback(() => {
        let initialValues: any = {
            ownerBirthDates: []
        };

        let validationRules: any = {};

        const owners = missingBirthDateOwners;

        if(updateMortgageAmountStore.deed && owners && owners.length > 0) {
            owners.forEach((owner: any, i) => {
                initialValues.ownerBirthDates.push({
                    day: (owner.BirthDateDay ? owner.BirthDateDay : ''),
                    month: (owner.BirthDateMonth ? owner.BirthDateMonth : ''),
                    year: (owner.BirthDateYear ? owner.BirthDateYear : ''),
                    serial: owner.Serial });

                validationRules[`day`] = requiredInput;
                validationRules[`month`] = requiredInput;
                validationRules[`year`] = requiredInput;
            });
        }

        const validationSchema = Yup.object().shape({
            ownerBirthDates: Yup.array()
                .of(Yup.object().shape(validationRules))
        });

        const submitForm = async (values) => {
            if(!values || (!values.ownerBirthDates)) {
                _useMultiStepContainer.setErrorDetails([t('mortgage.please-fill-the-missing-birth-dates')]);
                setCanProceed(false);
                return false;
            }

            let error = false;

            values.ownerBirthDates.forEach(owner => {
                if(!owner.day || !owner.month || !owner.year) {
                    error = true;
                    return;
                }
            });

            if(error) {
                _useMultiStepContainer.setErrorDetails([t('mortgage.please-fill-the-missing-birth-dates')]);
                setCanProceed(false);
                return false;
            }
            setOwnerBirthDates(values.ownerBirthDates);

            const deed = updateMortgageAmountStore.deed;

            const ownerInfoDates = values.ownerBirthDates.map(owner => {
                return {
                    ownerSerial: owner.serial,
                    birthDateY: owner.year.toString(),
                    birthDateM: owner.month.toString(),
                    birthDateD: owner.day.toString()
                }
            });

            updateMortgageAmountStore.setLoading(true);

            const updateDateResponse: GenericResponse = await mortgageService.updateDeedOwnersBirthDate({
                deedSerial: deed.DeedSerial!!.toString(),
                ownerInfo: ownerInfoDates
            });

            updateMortgageAmountStore.setLoading(false);

            if (!updateDateResponse?.IsSuccess!!) {
                if(updateDateResponse.ErrorDetails && updateDateResponse.ErrorDetails.length) {
                    _useMultiStepContainer.setErrorDetails(updateDateResponse.ErrorDetails);
                    _useMultiStepContainer.setCorrelationId(updateDateResponse.CorrelationId);
                }

                setCanProceed(false);

                return;
            }

            // refetch deed details so that the updated owners reflected
            const _omr = releaseMortgageRequest;
            _omr!!.deedSerial = updateMortgageAmountStore.deed?.DeedSerial;

            let response: GenericResponse | undefined = await fetchDeedDetails(releaseFormValues);

            if(response !== undefined && response.IsSuccess) {
                const deedMortgage = updateMortgageAmountStore.deed.DeedMortgagee;

                let deed  = response.Data as IDeed;


                deed.DeedMortgagee = deedMortgage
                updateMortgageAmountStore.setDeed(deed);
                _omr!!.deedSerial = updateMortgageAmountStore.deed.DeedSerial;
                setReleaseMortgageRequest(_omr);

                // reset missing birthdate owners
                setMissingBirthDateOwners([]);

                setTimeout(() => {
                    _useMultiStepContainer.setSteps(stepper.length);

                    _useMultiStepContainer.setStepByIndex(2);
                }, 200);

                return;
            }

            if(!response?.IsSuccess && response?.ErrorDetails) {
                _useMultiStepContainer.setErrorDetails(updateDateResponse.ErrorDetails);
                _useMultiStepContainer.setCorrelationId(updateDateResponse.CorrelationId);
                setCanProceed(false);
            }

        }

        return { initialValues, submitForm, validationSchema }

    }, [_useMultiStepContainer, t, mortgageService, updateMortgageAmountStore.deed]);

    const updateBeneficiaryBirthDateStep: any = () => {
        let owners: any = missingBirthDateOwners;

        if(owners.length > 0) {
            return {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return <UpdateBeneficiaryBirthDateForm
                                formikProps={formikProps}
                                owners={owners}
                                deed={updateMortgageAmountStore.deed}
                                showShareValue = {false}
                            />;
                },
                initialValues: updateBeneficiaryBirthDateFormBuilder().initialValues,
                values: updateBeneficiaryBirthDateFormBuilder().initialValues,
                onSubmit: updateBeneficiaryBirthDateFormBuilder().submitForm,
                validationSchema: updateBeneficiaryBirthDateFormBuilder().validationSchema,
                confirmButtonText: t('shared.continue'),
                cancelButtonText: t(t('shared.back')),
            }
        }
        return {};
    }

    const getStepper = () => {
        let stepper: IMultiStepContainer[] = [
            {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return <MortgageInquiryForm formikProps={formikProps} />;
                },

                initialValues: diFromBuilder().initialValues,
                onSubmit: diFromBuilder().submitForm,
                values: diFromBuilder().initialValues,
                validationSchema: validationSchema1,
                confirmButtonText: t('shared.show-deed'),
                cancelButtonText: t(t('shared.back')),
            },

            {
                component: () => {
                    return <DeedDetails hideActions deed={updateMortgageAmountStore.deed} />;
                },

                disableNext: !canProceed,
                onSubmit: _useMultiStepContainer.nextStep,
                values: null,
                validationSchema: null,
                confirmButtonText: t('shared.continue'),
                cancelButtonText: t(t('shared.back')),
            }
        ];
        if(updateBeneficiaryBirthDateStep().component !== undefined) {
            stepper.push(updateBeneficiaryBirthDateStep());
        }
        return [
            ...stepper,
            {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return <MortgageInfo formikProps={formikProps} isDeal={false} deedArea={deed?.DeedArea} />;
                },
                initialValues: mortgageDetailsFromBuilder().initialValues,
                onSubmit: mortgageDetailsFromBuilder().submitForm,
                values: mortgageDetailsFromBuilder().initialValues,
                validationSchema: mortgageDetailsFromBuilder().validationSchema,
                confirmButtonText: t('shared.continue'),
                cancelButtonText: t(t('shared.back')),
            },
            {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return (
                        <>
                            <ReviewRequest
                                deed={updateMortgageAmountStore.deed}
                                formikProps={formikProps}
                                isBuyer={true}
                            />

                            <TermsAndConditions terms={terms} updateTerms={updateTerms} />
                        </>
                    );
                },

                onSubmit: reviewBuyingRequestFormBuilder().submitForm,
                confirmButtonText: t('mortgage.edit-mortgage-amount'),
                cancelButtonText: t(t('review-sell-request.back')),
            },

            {
                component: (formikProps: FormikProps<FormikValues>) => {
                    return (
                        otpConfiguration && (
                            <OtpForm
                                onRetry={() => reviewBuyingRequestFormBuilder().postNewMortgage(true)}
                                otpConfiguration={otpConfiguration}
                                {...formikProps}
                            />
                        )
                    );
                },

                onSubmit: otpFormBuilder().submitForm,

                initialValues: otpFormBuilder().initialValues,

                values: otpFormBuilder().initialValues,

                validationSchema: otpFormBuilder().validationSchema,

                confirmButtonText: t('vat.ok'),

                cancelButtonText: t(t('shared.back')),
            },

            {
                component: () => {
                    return (
                        <RequestSuccess
                            area={newRequestResponse?.DeedArea!!}
                            deedDate={newRequestResponse?.DeedDate!!}
                            deedNumber={newRequestResponse?.DeedNumber!!}
                            iban={newRequestResponse?.iban!!}
                            requestNumber={newRequestResponse?.RequestNumber!!}
                            requestId={newRequestResponse?.RequestId!!}
                            requestStatusText={'تم قبول طلب  تعديل قيمة الرهن'}
                            statusNote={'سيتم اشعار المالك بالطلب.'}
                            isSeller={true}
                            isMortgage
                            price={mortgageRequest!!.mortgageAmount}
                            lastInstalmentDate={mortgageRequest!!.LastInstalmentDate}
                            requestType={RequestTypes.UPDATE_MORTGAGE_AMOUNT}
                        />
                    );
                },

                confirmButtonText: t('vat.ok'),

                cancelButtonText: t(t('shared.back')),
            },
        ];
    };

    const stepper = getStepper();

    useEffect(() => {
        _useMultiStepContainer.setSteps(stepper.length);

        updateMortgageAmountStore.setLoading(false);

        if (terms.terms.length < 7) {
            terms.agreeOnMortgageTerms();

            terms.footerTerms();
        }
    }, []);

    return (
        <>
            <MojPopup
                title={t('mortgage.edit-mortgage-amount-confirm-head')}
                open={isConfirmModalOpen}
                onCancel={() => setIsConfirmModalOpen(false)}
                footer={
                    <Card>
                        <Row gutter={[16, 0]} justify="start" align="middle">
                            <Col span="12">
                                <MojButton onClick={() => setIsConfirmModalOpen(false)} color="primary">
                                    {t('shared.back')}
                                </MojButton>
                            </Col>

                            <Col span="12">
                                <MojButton
                                    onClick={() => reviewBuyingRequestFormBuilder().postNewMortgage()}
                                    color="success"
                                >
                                    {t('shared.ok')}
                                </MojButton>
                            </Col>
                        </Row>
                    </Card>
                }
            >
                {t('mortgage.edit-mortgage-confirm-content')}
            </MojPopup>

            <MultiStepContainer
                stepper={stepper}
                stepperHeader={t('mortgage.edit-mortgage-amount')}
                currentStep={_useMultiStepContainer.currentStep}
                previousStep={_useMultiStepContainer.previousStep}
                isLoading={updateMortgageAmountStore.isLoading}
                errorDetails={_useMultiStepContainer.errorDetails}
                correlationId={_useMultiStepContainer.correlationId}
                hideActions={!!newRequestResponse}
            />
        </>
    );
};

export default observer(SearchDeed);
