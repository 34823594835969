import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

import { Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as crossCircleIcon } from '@core/assets/images/assets-icons-cross-circle.svg';
import { ReactComponent as earthIcon } from '@core/assets/images/assets-icons-earth.svg';
import { ReactComponent as greenEarthIcon } from '@core/assets/images/assets-icons-green-earth.svg';
import Icon from '@ant-design/icons/lib/components/Icon';

import './_SearchBar.scss';

interface SearchBarProps {
    setGeoLocation: ({ lat, lng }) => void;
    mapType: boolean;
    setMapType: (flag: boolean) => void;
}
const SearchBar: FunctionComponent<SearchBarProps> = ({ setGeoLocation, mapType, setMapType }) => {
    const [options, setOptions] = useState<Array<object>>([]);
    const { t } = useTranslation();

    const {
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        debounce: 300,
        requestOptions: {
            componentRestrictions: {
                country: 'sa',
            },
        },
    });
    const searchBarChangeHandler = (val: any) => {
        setValue(val);
    };
    const handleSelect = (val: any) => {
        setValue(val);
        clearSuggestions();

        getGeocode({ address: val }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            setGeoLocation({ lat: lat, lng: lng });
        });
    };
    useEffect(() => {
        if (status === 'OK') {
            let temp: Array<object> = [];

            data.forEach(({ description }) => {
                temp.push({
                    label: description,
                    value: description,
                });
            });
            setOptions(temp);
        } else {
            setOptions([]);
        }
    }, [status, data]);

    return (
        <div className="searchBarContainer">
            <Select
                className="searchBar"
                placeholder={t('map-info.lbl-map-search')}
                notFoundContent={t('map-info.lbl-no-data')}
                onSearch={searchBarChangeHandler}
                onSelect={handleSelect}
                allowClear
                showSearch
                onClear={() => {
                    setValue('');
                }}
                suffixIcon={null}
                clearIcon={<Icon component={crossCircleIcon} size={24} />}
                options={options}
            ></Select>
            <SearchOutlined className="searchIcon" size={23} />
        </div>
    );
};
export default SearchBar;
