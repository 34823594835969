import { FunctionComponent, useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import camelcaseKeys from 'camelcase-keys';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import {
    APIProvider,
    Map,
    InfoWindow,
    useMap,
    useMarkerRef,
    AdvancedMarker,
    ControlPosition,
    MapControl,
} from '@vis.gl/react-google-maps';

import MapInfo from '../MapInfo/MapInfo';

import realestateAndDealsService from '@common/services/realestates-and-deals.service';
import { loaderStore } from '@/common/stores/loader.store';
import GenericResponse from '@/common/models/GenericResponse';
import { reIdentityStandaloneStore } from '@/common/stores/reIdentityStandalone.store';
import { INITIAL_VIEW_STATE, MAP_LAT_ADJUSTMENT, center } from '@/common/utils/constants';
import { observer } from 'mobx-react-lite';
import { IMapRealestate } from '@/core/interfaces/MapRealstate.interface';

import './_RealStateMap.scss';
import { geocode, RequestType, setDefaults, OutputFormat } from 'react-geocode';
import { hexToRgbA } from './mapHelperFunctions';
import { isMobileOrTabletDevice } from '@/common/utils/device-helper.utils';
import MapViewLegend from '../MapViewLegend/MapViewLegend';

export type SortingType = {
    sortType: number;
    sortField: number;
};

function haversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371000; // Radius of the Earth in meters
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
}

function midpoint(lat1, lon1, lat2, lon2) {
    return [
        (lat1 + lat2) / 2,
        (lon1 + lon2) / 2
    ];
}

const getText = (d: any) => {
    if (d?.geometry?.type === 'Point') {
        return d?.properties?.text;
    }
    return null;
};

const getRealStateData = async (coordinates: object, zoom: number | undefined, reIdentityNumber: number[]) => {
    const filters = { ...reIdentityStandaloneStore.realestateFilters };
    delete filters.locationSearchTerm;
    if (filters.hasOwnProperty('isOpenMarket')) {
        delete filters['isOpenMarket'];
    }
    if (filters.hasOwnProperty('isRealEstate')) {
        delete filters['isRealEstate'];
    }

    let response = await realestateAndDealsService.getRealestatesMapData(
        true,
        true,
        true,
        coordinates,
        { ...filters, isWithCoordinates: true },
        {
            randomData: false,
            mapViewType: reIdentityStandaloneStore.mapViewType,
        },
    );
    if (response?.IsSuccess!! && response?.Data!!) {
        return camelcaseKeys(response.Data, { deep: true });
    }
};

const updateLineLengths = (features: any[]) => {
    let output: any[] = [];
    features?.forEach((feature) => {
        if (feature.geometry && feature.geometry.coordinates) {
            if (feature.geometry.coordinates[0][0]) {
                const points = feature.geometry.coordinates[0];
                for (let i = 0; i < points.length - 1; i++) {
                    const p1 = points[i];
                    const p2 = points[(i + 1) % points.length];
                    const mid = midpoint(p1[0], p1[1], p2[0], p2[1]);
                    const dist = haversineDistance(p1[1], p1[0], p2[1], p2[0]);
                    output.push({
                        point: mid,
                        text: `${Number(dist) % 1 !== 0 ? Number(dist).toFixed(2) : Number(dist)}`,
                    });
                }
            }
        }
    }
    );

    const lineLengths = (output || []).filter((line) => line.point && line.text);
    reIdentityStandaloneStore.lineLengths = lineLengths;
}

const updateFeaturesWithText = (features: any[]) => {
    return features?.map((feature) => {
        let base: any = {};
        if (feature.geometry && feature.geometry.type === "Polygon" && feature.geometry.coordinates && feature.properties.realestateIdentityNo) {
            if (feature.geometry.coordinates[0][0]) {
                let coordinatesAtZero = 0;
                let coordinatesAtOne = 0;


                feature.geometry.coordinates[0].forEach((coordinate) => {
                    coordinatesAtZero += coordinate[0];
                    coordinatesAtOne += coordinate[1];
                });
                base.coordinates = [coordinatesAtZero / feature.geometry.coordinates[0].length, coordinatesAtOne / feature.geometry.coordinates[0].length];
                base.backgroundColor = feature.properties.lineColor;
                base.textColor = feature.properties.backgroundColor;
                base.text = feature.properties.text;
            }
        }

        return base;
    }
    );
}

const loadRealestate = async (
    coordinates: object,
    zoom: number | undefined,
    callMap: boolean,
    callSearchByIdentity: boolean,
    setViewState: (viewstate: {
        latitude: number;
        longitude: number;
        zoom: number;
        pitch: number;
        bearing: number;
    }) => void,
    setShowIdentityPinPosition: (showFlag: boolean) => void,
    reIdentityNumbers: number[] | string[],
    onError
) => {
    let fetchedData: any = null;
    let openMarketOrders = 0;
    let purchaseOrders = 0;
    let realestates = 0;
    if (
        reIdentityNumbers
        && reIdentityNumbers.length &&
        callSearchByIdentity
    ) {
        const identity = reIdentityNumbers;
        reIdentityStandaloneStore.resetFilters();
        reIdentityStandaloneStore.updateFilters({
            ...reIdentityStandaloneStore.realestateFilters,
            realEstateIdentities: identity,
        });
        reIdentityStandaloneStore.updateRealestatesOptions([0, 1, 2]);
        reIdentityStandaloneStore.setMapViewType(null);

        fetchedData = await getRealStateData(coordinates, zoom, reIdentityNumbers as number[]);
        if (!fetchedData) {
            console.log("cannot find data");
            onError();
            return;
        }
        updateLineLengths(fetchedData?.features);
        if (fetchedData?.features?.length === 0) {
            onError();
            setShowIdentityPinPosition(false);
            fetchedData = fetchedData?.features;
            openMarketOrders = fetchedData?.features?.filter((feature) => feature?.properties?.openMarketOrders)?.length || 0;
            purchaseOrders = fetchedData?.features?.filter((feature) => feature?.properties?.purchaseOrders)?.length || 0;
            realestates = fetchedData?.features?.filter((feature) => !feature?.properties?.purchaseOrders && !feature?.properties?.openMarketOrders)?.length || 0;
        } else {
            let centerPointCoords = fetchedData?.features?.filter((feature) => feature?.geometry?.type === 'Point')[0]
                ?.geometry?.coordinates;
            const reIdentityObjs = fetchedData?.features?.filter((feature) => (!!feature?.properties?.realestateIdentityNo || "").toString());
            setViewState({
                latitude: centerPointCoords?.[1] + MAP_LAT_ADJUSTMENT,
                longitude: centerPointCoords?.[0],
                zoom: 18,
                pitch: 0,
                bearing: 0,
            });
            reIdentityStandaloneStore.setSearchByIdentityPosition({
                lat: centerPointCoords?.[1],
                lng: centerPointCoords?.[0],
            });
            const featuresWithText = (updateFeaturesWithText(reIdentityObjs));
            reIdentityStandaloneStore.featuresWithPrice = (featuresWithText || []).filter((feature) => feature.text && feature.coordinates);

            setShowIdentityPinPosition(true);
        }
    }

    const saudiArabiaLabel = [{ text: 'Saudi Arabia', coordinates: [center?.lng, center?.lat] }];
    const textLayer: any = new TextLayer({
        id: 'text-layer',
        data: [...saudiArabiaLabel],
        pickable: true,
        getPosition: (d: { text: string; coordinates: Array<number> }) => d.coordinates,
        getText: (d: { text: string; coordinates: Array<number> }) => d.text,
        getSize: 18,
        getColor: [0, 0, 0],
        textCharacterSet: 'auto',
        getBackgroundColor: [33, 43, 53],
        sizeUnits: 'pixels',
    });
    const geoJsonLayer: any = new GeoJsonLayer({
        id: 'geojson-layer',
        data: fetchedData,
        pickable: true,
        filled: true,
        stroked: true,
        pointType: zoom && zoom < 15 ? 'icon' : 'circle+text',
        getFillColor: (d: any) => {
            return [88, 173, 153, 77];
        },
        getLineColor: (d: any) => {
            return [87, 173, 153, 204];
        },
        getLineWidth: () => 3,
        lineWidthUnits: 'pixels',
        getPointRadius: () => 0,
        getText:
            zoom && zoom > 17
                ? (d: any) => {
                    return getText(d) || '';
                }
                : () => '',
        textFontFamily: 'IBM Plex Sans Arabic',
        textFontWeight: '500',
        textCharacterSet: 'auto',
        getTextColor: (d) => d?.properties?.lineColor ? hexToRgbA(d?.properties?.lineColor) : [0, 0, 0],
        getTextBackgroundColor: () => [33, 43, 53],
        getTextSize: () => 18,
        getIcon: () => null,
        getPosition: (d: { coordinates: Array<number> }) => d.coordinates,
        getIconSize: () => 20,
        iconSizeUnits: 'pixels',
    });
    if (zoom && zoom >= 4 && zoom <= 15) {
        return { layers: [textLayer, geoJsonLayer], counts: { openMarketOrders: Math.ceil(openMarketOrders / 2), purchaseOrders: Math.ceil(purchaseOrders / 2), realestates: Math.ceil(realestates / 2) } }
    }
    return { layers: [geoJsonLayer], counts: { openMarketOrders: Math.ceil(openMarketOrders / 2), purchaseOrders: Math.ceil(purchaseOrders / 2), realestates: Math.ceil(realestates / 2) } };
};

export const DeckGlOverlay = (props: any) => {
    let {
        callMap,
        setCallMap,
        callSearchByIdentity,
        setCallSearchByIdentity,
        setViewState,
        setShowIdentityPinPosition,
        reIdentityNumbers,
        handleError
    } = props;
    const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);
    const map = useMap("DEMO_MAP_ID");

    useEffect(() => {
        deck.setMap(map);
        setDefaults({
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            language: 'en',
            region: 'SA',
            outputFormat: OutputFormat.JSON,
        });

        geocode(RequestType.ADDRESS, 'Saudi Arabia').then(({ results }) => {
            const boundsObj = {
                ne: results[0]?.geometry?.viewport?.northeast,
                sw: results[0]?.geometry?.viewport?.southwest,
            };
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend({ lat: boundsObj.sw.lat, lng: boundsObj.sw.lng });
            bounds.extend({ lat: boundsObj.ne.lat, lng: boundsObj.ne.lng });
            map?.fitBounds(bounds);
            map?.setOptions({
                restriction: {
                    latLngBounds: bounds,
                    strictBounds: true,
                },
            });
        });
    }, [map]);

    const loadData = async (searchCords: any, zoom: number | undefined) => {
        const realestates = await loadRealestate(
            {
                Latitude: searchCords?.lat,
                Longitude: searchCords?.lng,
            },
            zoom,
            callMap,
            callSearchByIdentity,
            setViewState,
            setShowIdentityPinPosition,
            reIdentityNumbers,
            handleError
        );


        // @ts-ignore
        deck.setProps({ ...props, layers: realestates.layers!! });
        setCallMap(false);
        setCallSearchByIdentity(false);
        setTimeout(() => {
            loaderStore.setLoader(false);
        }, 3000);
    };

    useEffect(() => {
        if (callSearchByIdentity) {
            setTimeout(() => {
                loadData(map?.getBounds()?.getCenter()?.toJSON(), map?.getZoom());
            }, 1000)
        }
    }, [callSearchByIdentity]);

    return null;
};

interface REMapStandaloneProps {
    reIdentityNumbers: number[];
    onError: () => void;
    hideInfoWindow?: boolean;
    draggable?: boolean;
    handleClick?: (e) => void;
}
const REMapStandalone: FunctionComponent<REMapStandaloneProps> = ({ reIdentityNumbers, onError, hideInfoWindow = false, draggable, handleClick }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [currentData, setCurrentData] = useState(null);
    const [markerRef, marker] = useMarkerRef();
    const [position, setPosition] = useState<any>(null);
    const [currentPosition, setCurrentPosition] = useState<any>(null);
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
    const [callSearchByIdentity, setCallSearchByIdentity] = useState(true);
    const [callMap, setCallMap] = useState(false);
    const [showIdentityPinPosition, setShowIdentityPinPosition] = useState(false);
    const [mapType, setMapType] = useState(false);

    const isMobile = useMemo(() => isMobileOrTabletDevice(), []);

    const getRealestateTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getRealEstateTypes();

        if (response?.IsSuccess) {
            reIdentityStandaloneStore.setRealestateTypes(response.Data);
            loaderStore.setLoader(false);
        }
    };
    const getMapViewTypes = async () => {
        const response: GenericResponse = await realestateAndDealsService.getMapViewTypes();
        if (!response?.IsSuccess) return false;
        loaderStore.setLoader(false);
        reIdentityStandaloneStore.setMapViews(camelcaseKeys(response.Data, { deep: true }));
    };

    const closeInfoWindow = () => {
        setShowIdentityPinPosition(true);
    };

    const getRealestateLink = useCallback((realestate: IMapRealestate) => {
        if (realestate.openMarketOrders && realestate.openMarketPrice) {
            reIdentityStandaloneStore.updateRealestatePrice(realestate?.serial, realestate.openMarketPrice);
            return navigate(`/transactions/${realestate?.requestId}`);
        } else {
            return navigate(`/open-market/order/${realestate?.serial}`);
        }
    }, []);

    useEffect(() => {
        loaderStore.setLoader(true);
        getRealestateTypes();
        getMapViewTypes();
        reIdentityStandaloneStore.setMapViewType(null);
    }, []);

    return (
        <div className="map-contanier" onClick={handleClick}>
            {viewState && (
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}>
                    <Map
                        scrollwheel={true}
                        draggable={!!draggable}
                        id="DEMO_MAP_ID"
                        mapId={'DEMO_MAP_ID'}
                        zoom={4}
                        center={center}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        viewState={viewState}
                        initialViewState={INITIAL_VIEW_STATE}
                        mapTypeId={mapType ? 'hybrid' : 'roadmap'}
                        className="map"
                    >
                        <DeckGlOverlay
                            initialViewState={INITIAL_VIEW_STATE}
                            controller={true}
                            callMap={callMap}
                            setCallMap={setCallMap}
                            callSearchByIdentity={callSearchByIdentity}
                            setCallSearchByIdentity={setCallSearchByIdentity}
                            setViewState={setViewState}
                            setShowIdentityPinPosition={setShowIdentityPinPosition}
                            setCurrentPosition={setCurrentPosition}
                            reIdentityNumbers={reIdentityNumbers || [Number(params.id)]}
                            handleError={onError}
                            onError={() => { }} // silence the error related to icon missing
                            onClick={(data: any) => {
                                setPosition({ lat: data?.coordinate[1], lng: data?.coordinate[0] });
                                setCurrentData(data?.object?.properties);
                                setShowIdentityPinPosition(true);
                            }}
                            onKeyDown={(e) => { }}
                        />

                        {reIdentityStandaloneStore?.mapViews && (
                            <MapControl position={isMobile ? ControlPosition.LEFT_BOTTOM : ControlPosition.BOTTOM_CENTER}>
                                <MapViewLegend
                                    enumKey={'propertyStatusEnums'}
                                    isProperty={true}
                                    mapViewType={reIdentityStandaloneStore.mapViewType}
                                    isMobile={isMobile}
                                    locateMe={() => { }}
                                    mapType={mapType}
                                    setMapType={setMapType}
                                    showModal={() => { }}
                                    features={["layers"]}
                                />
                            </MapControl>
                        )}

                        {showIdentityPinPosition && !hideInfoWindow && currentData && (
                            <>
                                <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
                                    <div id="map-info" className="map-info">
                                        <MapInfo data={currentData} handleViewMore={getRealestateLink} />
                                    </div>
                                </InfoWindow>
                            </>
                        )}

                        {reIdentityStandaloneStore.featuresWithPrice && reIdentityStandaloneStore.featuresWithPrice.length > 0 ? reIdentityStandaloneStore.featuresWithPrice.map((marker, index) => {
                            return <AdvancedMarker key={`advance-marker-${index}`} position={{ lat: marker.coordinates[1], lng: marker.coordinates[0] }}>
                                <span className='map-distance'>{marker.text}</span>
                            </AdvancedMarker>
                        }) : <></>}

                        {reIdentityStandaloneStore.lineLengths && reIdentityStandaloneStore.lineLengths.length > 0 ? reIdentityStandaloneStore.lineLengths.map((line, index) => {
                            return <AdvancedMarker key={`distance-marker-${index}`} position={{ lat: line.point[1], lng: line.point[0] }}>
                                <span className='map-distance'>{line.text}</span>
                            </AdvancedMarker>
                        }) : <></>}
                    </Map>
                </APIProvider>
            )}
        </div>
    );
};

export default observer(REMapStandalone);
