import { useCallback } from 'react';
import { t } from 'i18next';
import { realestateAndDealsStore } from '@/common/stores/realestate-and-deals.store';
import LegendIcon from '../LegendIcon/LegendIcon';
import './_LegendWithNumbers.scss';

export interface LegendWithNumbersProps {
	openMarketOrders: number;
	purchaseOrders: number;
	realestates: number;
}

const checkboxOptions = [
	{ label: 'معروض', value: 0, purchaseOrders: 0, openMarketOrders: 1 },
	{ label: 'مطلوب', value: 1, purchaseOrders: 1, openMarketOrders: 0 },
	{ label: 'متاح', value: 2, purchaseOrders: 0, openMarketOrders: 0 },
];

export default function LegendWithNumbers({ openMarketOrders: openMarket, purchaseOrders, realestates }: LegendWithNumbersProps) {
	const checkboxGroupHandler = useCallback((option: { label: string; value: number }) => {
		// making sure you can't uncheck the only remaining option
		// business want's at all times at least one option is checked
		if (
			realestateAndDealsStore.realestateOptions.length - 1 === 0 &&
			realestateAndDealsStore.realestateOptions?.includes(option.value)
		)
			return;

		if (realestateAndDealsStore.realestateOptions?.includes(option.value)) {
			realestateAndDealsStore.updateRealestatesOptions(
				realestateAndDealsStore.realestateOptions.filter((item) => item !== option.value)
			);
		} else {
			realestateAndDealsStore.updateRealestatesOptions([
				...realestateAndDealsStore.realestateOptions,
				option.value,
			]);
		}
	}, []);

	const countsByLabel = useCallback((label: string) => {
		switch (label) {
			case t("supplied"):
				return openMarket;
			case t("demanded"):
				return purchaseOrders;
			case t("default"):
				return realestates;
			default:
				return 0;
		}
	}, [openMarket, purchaseOrders, realestates]);

	return (
		<div id="legendWithNumbers" className='legendWithNumbers'>
			{realestateAndDealsStore?.mapViews?.['propertyStatusEnums'].map((value, index) => {
				let option = checkboxOptions.filter(
					(item) =>
						item?.purchaseOrders === value?.purchaseOrder &&
						item.openMarketOrders === value?.openMarketOrder
				)[0];

				return (
					<div className='iconWithText' key={option?.value} onClick={() => {
						checkboxGroupHandler(option);
					}}>
						<LegendIcon
							color={value?.color}
							isProperty={true}
							mapViewType={realestateAndDealsStore.mapViewType}
						>{realestateAndDealsStore.realestateOptions?.includes(option?.value) ? "✓" : ""}</LegendIcon>
						{option?.label} {countsByLabel(option?.label) || 0}
					</div>
				);
			})}
		</div>
	)
}
