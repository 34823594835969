import { useState } from 'react';
import { Col, List, Row } from 'antd';
import { useNavigate } from 'react-router';

import { ReactComponent as InfoIcon } from '@core/assets/images/assets-icons-info.svg';
import { Card, MojButton, MojPopup } from '../shared';
import { AttorneyItem } from '@/common/models/Attorney';

type PostLoginPopupProps = {
    attorneys: AttorneyItem[] | null;
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
};

export const PostLoginPopup: React.FC<PostLoginPopupProps> = ({ attorneys, isModalVisible, setIsModalVisible }) => {
    const navigate = useNavigate();
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

    return (
        <>
            <MojPopup
                maskClosable={true}
                footer={null}
                open={isModalVisible}
                onOk={() => {
                    setIsModalVisible(false);
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                closable={true}
            >
                <Col>
                    <div style={{ display: 'flex' }}>
                        <span style={{ marginInlineEnd: 5 }}>{'هل تود استخدام البورصة العقارية'}</span>
                        <InfoIcon onClick={() => setIsInfoModalVisible(true)} />
                    </div>
                </Col>
                <Card>
                    <Row gutter={[16, 0]} justify="start" align="middle">
                        <Col span="12">
                            <MojButton
                                color="success"
                                onClick={() => {
                                    setIsModalVisible(false);
                                    sessionStorage.setItem('isModalShouldDisplay', 'false');
                                }}
                                style={{ height: 'fit-content' }}
                            >
                                {'اصالة'}
                                <div>{'(الحساب الشخصي)'}</div>
                            </MojButton>
                        </Col>
                        <Col span="12">
                            <MojButton
                                color="success"
                                onClick={() => {
                                    attorneys?.length ? navigate('/profile') : navigate('/profile/attorney');
                                    setIsModalVisible(false);
                                    sessionStorage.setItem('isModalShouldDisplay', 'false');
                                }}
                                style={{ height: 'fit-content' }}
                            >
                                {'وكالة'}
                                <div>{'(عن جهة او فرد)'}</div>
                            </MojButton>
                        </Col>
                    </Row>
                </Card>
            </MojPopup>

            <MojPopup
                open={isInfoModalVisible}
                title={'معلومات دخول البورصة العقارية'}
                onOk={() => {
                    setIsInfoModalVisible(false);
                }}
                footer={null}
                onCancel={() => {
                    setIsInfoModalVisible(false);
                }}
                closable={true}
            >
                <List className="DashboardTermsList">
                    <div style={{ paddingTop: 0, paddingBottom: 0, alignItems: 'flex-start' }}>
                        <div style={{ marginBottom: 5 }}>
                            <strong>اصالة تعني:</strong> الحساب الشخصي
                        </div>

                        <div>
                            <strong>انواع الوكالة:</strong>
                        </div>
                        <div>1- وكيل عن منشأة او جهة</div>
                        <div>2- وكيل عن ورثة</div>
                        <div>3- وكيل عن فرد</div>
                        <div>4- مفوض من جهة حكومية</div>
                        <div>5- مالك مؤسسة فردية</div>
                    </div>
                </List>
            </MojPopup>
        </>
    );
};
