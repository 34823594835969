import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    Card,
    MojAccordion,
    MojButton,
    ReadOnlyField,
    ReadOnlyFieldBorderVariant,
    ReadOnlyFieldVariant,
} from '@/core/components/shared';
import { DeedRealState, RealStateDto } from '@common/models/Deed';
import { ReactComponent as MapIcon } from '@core/assets/images/assets-icons-map.svg';
import MojLabel, { LabelVariant } from '@core/components/shared/Label/MojLabel';
import { DeedRealStateOwner } from '@/common/models/DeedRealStateOwner';
import { ReactComponent as DeleteIcon } from '@core/assets/images/assets-icons-remove.svg';
import './RealestateDetails.scss';
import Empty from '@/core/components/shared/Empty/Empty';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import RealStateMapAdvanced from '../RealStateMap/RealStateMapAdvanced';
import { MAP_LAT_LNG_SORTER } from '../RealStateMap/mapHelperFunctions';

type Props = {
    deedRealStates: DeedRealState[];
    sharedStates: RealStateDto[];
    isSharedStatesLoaded: boolean;
    handleDeleteRealestate?: (index: number) => void;
    updateIdentityNumber?: (IdentityNo: number) => void;
    isOwner?: boolean;
    hideMap?: boolean;
};

export const RealestateDetails: React.FC<Props> = ({ deedRealStates, handleDeleteRealestate, sharedStates, isSharedStatesLoaded, updateIdentityNumber, isOwner = false, hideMap }) => {
    const { t } = useTranslation();
    const [isHideImg, setHideImg] = useState<boolean>(false);
    const [imgLoaded, setImgLoaded] = useState<boolean>(true);
    const [openedPopups, setOpenedPopups] = useState<{ [key: number]: boolean }>({});
    const [activeKey, setActiveKey] = useState('');

    const getFirstRowSpan = useCallback((selectedTag?: string) => {
        return selectedTag ? 7 : 8;
    }, []);

    const hideImg = () => {
        setHideImg(true);
        setActiveKey('');
    };

    // fire updateIdentityNumber action without open MojAccordion collapse action
    const updateRealestateIdentityNumber = (e, IdentityNo: number) => {
        e.stopPropagation();
        updateIdentityNumber && updateIdentityNumber(IdentityNo);
    };

    // create method to check if RealestateIdentityNumber is null to setActiveKey empty string.
    const checkRealestateIdentityNumber = () => {
        if (deedRealStates.some(deedRealestate => deedRealestate.RealestateIdentityNumber !== null || deedRealestate.RealestateIdentityNumber?.toString().length === 16)) {
            setActiveKey('0');
        }
    };

    useEffect(() => {
        checkRealestateIdentityNumber();
    }, [deedRealStates]);

    // create a function to check if number is float or integer and convert it to float in case of float
    const convertToFloat = (number: number) => {
        return Number.isInteger(+number) ? number : parseFloat(Number(number).toFixed(2));
    }

    const reIdentityPoints = useMemo(() => {
        return deedRealStates.filter(realestate => realestate.Coordinates.length > 0).map(realestate => ({
            ...realestate,
            coordinates: realestate.Coordinates?.map(c => [c.Longitude, c.Latitude].sort(MAP_LAT_LNG_SORTER))
        }))
    }, [deedRealStates]);

    return (
        <Card className="realestate-details">
            <Card.Head className="realestate-details__title border-bottom">
                <div>{t('buying-requests.realestate-details.title')}</div>
                <div className="added-realestate-number">
                    {t('buying-requests.realestate-details.number-of-realestates')} {deedRealStates.length}
                </div>
            </Card.Head>
            <Card.Body>
                {deedRealStates.length === 0 && <Empty emptyMessage={t("deed-details.no-realestates")} />}
                <MojAccordion defaultActiveKey={[activeKey]}>
                    {deedRealStates.map((deedRealestate, index) => {
                        let realStateOwners: DeedRealStateOwner[] = [];
                        let realStates = sharedStates?.filter((x) => x.RealStateSerial === deedRealestate.Serial);
                        // create flag if RealestateIdentityNumber is null
                        const isRealestateIdentityNumberNotNull = () => {
                            return deedRealestate.RealestateIdentityNumber != null && deedRealestate.RealestateIdentityNumber?.toString().length === 16;
                        };

                        if (realStates !== undefined) {
                            realStates.forEach((element) => {
                                element.RealStateOwners.forEach((owner) => {
                                    realStateOwners.push(owner);
                                });
                            });
                        }

                        return (
                            <MojAccordion.Item
                                key={index}
                                className={clsx({ "accordion-disabled": isRealestateIdentityNumberNotNull() })}
                                header={
                                    <>
                                        {deedRealestate.RealestateIdentityNumber &&
                                            !!deedRealestate.Coordinates.length &&
                                            !isHideImg && !hideMap && (
                                                <Row className="border-info border-info--has-border" align={'middle'}>
                                                    <Col span={24} style={{ marginBottom: 15 }}>
                                                        {t('buying-requests.realestate-details.realestateLocation')}
                                                    </Col>
                                                    <Col lg={24} xs={24}>
                                                        <Link
                                                            to={`/rid/${deedRealestate.RealestateIdentityNumber}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <RealStateMapAdvanced
                                                                reIdentityPoints={reIdentityPoints}
                                                                callMap={false}
                                                                setCallMap={() => { }}
                                                                setCallSearchByIdentity={() => { }}
                                                                callSearchByIdentity={false}
                                                                showModal={() => { }}
                                                            />
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            )}
                                        <Row>
                                            <Col span={handleDeleteRealestate ? 22 : 24}>
                                                <Row align="middle">
                                                    <Col span={getFirstRowSpan(deedRealestate.selectedTag)}>
                                                        <ReadOnlyField
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                            small
                                                            title={deedRealestate.LandNumber}
                                                            label={t('buying-requests.realestate-details.part-number')}
                                                        />
                                                    </Col>
                                                    <Col span={getFirstRowSpan(deedRealestate.selectedTag)}>
                                                        <ReadOnlyField
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                            small
                                                            title={deedRealestate.PlanNumber}
                                                            label={t('buying-requests.realestate-details.chart-number')}
                                                        />
                                                    </Col>
                                                    <Col span={getFirstRowSpan(deedRealestate.selectedTag)}>
                                                        <ReadOnlyField
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                            small
                                                            title={convertToFloat(deedRealestate.Area)}
                                                            label={t('buying-requests.realestate-details.space')}
                                                        />
                                                    </Col>
                                                    {deedRealestate.selectedTag && (
                                                        <Col span={3}>
                                                            <MojLabel color={LabelVariant.success} style={{ minWidth: '70px' }}>
                                                                {deedRealestate.selectedTag}
                                                            </MojLabel>
                                                        </Col>
                                                    )}
                                                    {!!deedRealestate.RealEstateTypeName && (
                                                        <Col span={getFirstRowSpan(deedRealestate.selectedTag)}>
                                                            <ReadOnlyField
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                small
                                                                title={deedRealestate.RealEstateTypeName}
                                                                label={t('buying-requests.realestate-details.RealEstateTypeName')}
                                                            />
                                                        </Col>
                                                    )}
                                                    <Col
                                                        span={getFirstRowSpan(deedRealestate.selectedTag)}
                                                        className="realestate-details__neighbourhood"
                                                    >
                                                        <ReadOnlyField
                                                            borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                            small
                                                            title={`${deedRealestate.DistrictName ? deedRealestate.DistrictName + ',' : ''
                                                                } ${deedRealestate.CityName ? deedRealestate.CityName : ''}`}
                                                            label={t('buying-requests.realestate-details.neighborhood-city')}
                                                        />
                                                    </Col>

                                                    {(deedRealestate.RealestateIdentityNumber && deedRealestate.RealestateIdentityNumber?.toString().length === 16) && (<>
                                                        <Col lg={getFirstRowSpan(deedRealestate.selectedTag)} xs={14}
                                                            className="realestate-details__neighbourhood"
                                                        >
                                                            <ReadOnlyField
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                small
                                                                title={
                                                                    <Link
                                                                        to={`/rid/${deedRealestate.RealestateIdentityNumber}`}
                                                                        target="_blank"
                                                                        className="inline-link"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <Row gutter={[16, 16]}>
                                                                            <Col style={{ marginTop: '7px' }}>
                                                                                {deedRealestate.RealestateIdentityNumber}
                                                                            </Col>
                                                                            <MapIcon style={{ width: '18px' }} />
                                                                        </Row>
                                                                    </Link>
                                                                }
                                                                label={t('shared.realestate-identity-location')}
                                                            />
                                                        </Col>
                                                        {(isOwner && updateIdentityNumber) && (
                                                            <Col span={24} className="realestate-details__neighbourhood">
                                                                <MojButton color="success" onClick={(e) => updateRealestateIdentityNumber(e, deedRealestate?.RealestateIdentityNumber!)}>
                                                                    {t("shared.update-identity-number")}</MojButton>
                                                            </Col>
                                                        )}
                                                    </>)}

                                                    {deedRealestate.UsingType && (
                                                        <Col span={getFirstRowSpan(deedRealestate.selectedTag)}>
                                                            <ReadOnlyField
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                small
                                                                title={deedRealestate.UsingType}
                                                                label={t('buying-requests.realestate-details.usingType')}
                                                            />
                                                        </Col>
                                                    )}

                                                    {(deedRealestate?.RealEstateRemarks && deedRealestate?.ParantRealestateSerial) && (
                                                        <Col span={24}>
                                                            <ReadOnlyField
                                                                borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                                small
                                                                title={deedRealestate?.RealEstateRemarks}
                                                                label={"الوصف الإضافي"}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            {handleDeleteRealestate ?
                                                <Col className='delete-icon' span={2} onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}>
                                                    <Popover onOpenChange={(flag) => setOpenedPopups(openedPopups => ({ ...openedPopups, [index]: flag }))} open={!!openedPopups[index]} trigger={"click"} placement="topLeft" content={<>
                                                        <Row>
                                                            <Col>
                                                                {t('deed-upgrade.create-update.delete-realestate-confirmation')}
                                                            </Col>
                                                        </Row>
                                                        <Row justify={"center"}>
                                                            <Col span={6}>
                                                                <MojButton className='popover-action-button' color='danger' onClick={() => {
                                                                    handleDeleteRealestate(index);
                                                                }}>{t('deed-upgrade.create-update.confirm-delete')}</MojButton>
                                                            </Col>
                                                            <Col span={6}>
                                                                <MojButton className='popover-action-button' color='label' onClick={() => {
                                                                    setOpenedPopups({});
                                                                }}>{t('deed-upgrade.create-update.cancel')}</MojButton>
                                                            </Col>
                                                        </Row>
                                                    </>}>

                                                        <DeleteIcon />
                                                    </Popover>
                                                </Col> : <></>}
                                        </Row>
                                    </>
                                }
                            >
                                {!isRealestateIdentityNumberNotNull() && (
                                    <>
                                        <Row className="border-info border-info--has-border">
                                            <Col span={24}>{t('buying-requests.realestate-details.north-border')}</Col>
                                            <BorderInfo
                                                type={deedRealestate.NorthLimitName}
                                                length={deedRealestate.NorthLimitLengthChar}
                                                description={deedRealestate.NorthLimitDescription}
                                                t={t}
                                            />
                                        </Row>

                                        <Row className="border-info border-info--has-border">
                                            <Col span={24}>{t('buying-requests.realestate-details.east-border')}</Col>
                                            <BorderInfo
                                                type={deedRealestate.EastLimitName}
                                                length={deedRealestate.EastLimitLengthChar}
                                                description={deedRealestate.EastLimitDescription}
                                                t={t}
                                            />
                                        </Row>

                                        <Row className="border-info border-info--has-border">
                                            <Col span={24}>{t('buying-requests.realestate-details.west-border')}</Col>
                                            <BorderInfo
                                                type={deedRealestate.WestLimitName}
                                                length={deedRealestate.WestLimitLengthChar}
                                                description={deedRealestate.WestLimitDescription}
                                                t={t}
                                            />
                                        </Row>

                                        <Row className="border-info">
                                            <Col span={24}>{t('buying-requests.realestate-details.south-border')}</Col>
                                            <BorderInfo
                                                type={deedRealestate.SouthLimitName}
                                                length={deedRealestate.SouthLimitLengthChar}
                                                description={deedRealestate.SouthLimitDescription}
                                                t={t}
                                            />
                                        </Row>
                                    </>
                                )}

                                {realStateOwners?.length > 0 && (
                                    <Row className="border-info border-info--has-border">
                                        <Col span={24}>{t('header.shared-realStates-owners')}</Col>
                                    </Row>
                                )}

                                {isSharedStatesLoaded &&
                                    realStateOwners?.map((owner, index) => (
                                        <Row className="deed-details__basics" key={`${owner.DeedNo}_${index}`}>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={ReadOnlyFieldVariant.success}
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={owner.DeedNo}
                                                    label={t('deed-details.deed-number')}
                                                    small
                                                    hasLtrContent
                                                />
                                                {owner.DeedNo.toString().length !== 12 && (
                                                    <ReadOnlyField
                                                        variant={ReadOnlyFieldVariant.warning}
                                                        title={t('deed-details.need-update')}
                                                        label=""
                                                        small
                                                        hasLtrContent
                                                        noBorder
                                                        style={{ padding: 0 }}
                                                    />
                                                )}
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={ReadOnlyFieldVariant.success}
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={owner.OwnerName}
                                                    label={t('shared.name')}
                                                    small
                                                />
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={ReadOnlyFieldVariant.success}
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={owner.OwnerIdNumber}
                                                    label={t('shared.id-number')}
                                                    small
                                                    hasLtrContent
                                                />
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={ReadOnlyFieldVariant.success}
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={owner.DeedDate}
                                                    label={t('common.deed-date')}
                                                    hasLtrContent
                                                />
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <ReadOnlyField
                                                    variant={ReadOnlyFieldVariant.success}
                                                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                                                    title={convertToFloat(owner.DeedRealestateAmount) + '%'}
                                                    label={t('shared.owner-percentage')}
                                                    unit={t('shared.percentage')}
                                                    small
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                            </MojAccordion.Item>
                        );
                    })}
                </MojAccordion>
            </Card.Body>
        </Card>
    );
};

type BorderInfoProps = {
    type: string;
    length: string;
    description: string;
    t: (literal: string) => string;
};

export function BorderInfo({ type, length, description, t }: Readonly<BorderInfoProps>) {
    return (
        <>
            <Col span={12}>
                <ReadOnlyField
                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                    small
                    title={type}
                    label={t('buying-requests.realestate-details.border-type')}
                />
            </Col>
            <Col span={12}>
                <ReadOnlyField
                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                    small
                    title={length}
                    label={t('buying-requests.realestate-details.border-length')}
                />
            </Col>
            <Col span={24}>
                <ReadOnlyField
                    borderVariant={ReadOnlyFieldBorderVariant.primary}
                    small
                    title={description}
                    label={t('buying-requests.realestate-details.border-description')}
                />
            </Col>
        </>
    );
}
