export enum RealEstateFileStatuses {
    Pending = 'Pending',
    Completed = 'Completed',
    Failed = 'Failed',
    NotFound = 'NotFound',
}

export interface IRealEstateFileStatus {
    RealEstateFileStatus: RealEstateFileStatuses;
    IsDownloaded: boolean;
    CreatedAt?: string;
    CompletedAt?: string;
}
